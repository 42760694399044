import { Component } from 'react';
import { commentsClassFor, notificationsClassFor } from '@symbolic/rn-lib';
import { resourceClassesFor, connect } from '@symbolic/redux';
import { store, resourceActions } from '~/redux';

var {
  CommentComponent,  CommentsComponent,
  NotificationComponent, NotificationsComponent,
} = resourceClassesFor(
  ['comments', 'notifications'],
  {store, resourceActions, Component}
);

var Comments = commentsClassFor({CommentsComponent, CommentComponent, connect, resourceActions, appKey: 'weflowLite'});
var Notifications = notificationsClassFor({NotificationsComponent, NotificationComponent, resourceActions, appKey: 'weflowLite'});

export { Notifications, Comments };
