import { PureComponent } from 'react';
import { View } from 'react-native';
import { Text } from '@symbolic/rn-lib';
import { getLogoForOrg } from '~/helpers/get-logo-for-org';
import { getShouldRenderElementForOrg } from '../helpers/get-should-render-helpers';

import K from '~/k';
import moment from 'moment';

class PageHeader extends PureComponent {

  render() {
    var {pageNumber, pageCount, productOrder, renderForDrawings, activeOrgId} = this.props;

    return (<>
      <View style={{position: 'absolute', bottom: K.spacing * 3, left: K.spacing * 3, height: 50}}>
        <img //WARNING react image causes blurry logo in pdf
          src={getLogoForOrg({orgId: activeOrgId, colorVariant: 'black'})}
          style={{width: Math.round(K.calc(200))}}
        />
      </View>
      <View style={{position: 'absolute', bottom: K.spacing * 3, right: K.spacing * 3, alignItems: 'flex-end', textAlign: 'right'}}>
        <Text style={{...K.fonts.label, textTransform: 'none'}}>Order #{productOrder.orgSpecificId}</Text>
        <Text style={{...K.fonts.label}}>{moment(productOrder.lastUpdated).format('M/D/YYYY')}</Text>
        <Text style={{...K.fonts.label}}>Page {pageNumber + 1} of {pageCount}</Text>
        {!!renderForDrawings && getShouldRenderElementForOrg({activeOrgId, visibleOrgIds: [850]}) && <Text style={{...K.fonts.label}}>{`Prints to scale on ${this.props.drawingSetMode === 'manufacturing' ? '11" x 17"' : '8.5" x 11"'}`}</Text>}
      </View>
    </>);
  }
}

export default PageHeader;
