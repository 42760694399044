function getTitleFor (customName, productName, productOrder) {
  if (customName !== '' && !productOrder.productId) {
    return `${customName} - ${productName}`;
  }
  else if (customName !== '' && productOrder.productId) {
    return `${customName}`;
  }
  else if ( customName === '') {
    return `${productName}`;
  }
}

export { getTitleFor };
