import { products } from '@symbolic/lib';

import getBevel from '~/helpers/meljac/get-bevel';
import getScrews from '~/helpers/meljac/get-screws';
import getKeypadMechanisms from '~/helpers/meljac/get-keypad-mechanisms';
import _ from 'lodash';

var {getKeypadData, getMechanismLayoutData, getMechanismSpacings} = products.meljac;

export default function getKeypadGraphicNodes({deps, group, rect, circle, text, path, dimension, image}) {
  var {propertiesDataById, properties, productId} = deps.productInstance;

  var ledIndicatorIsEnabled = _.get(propertiesDataById, '85.selectedProductOption.id') === 519;
  var ledPosition = _.get(propertiesDataById, '109.selectedProductOption.id') === 627 ? 'above' : 'below';

  var plateFinish = _.get(propertiesDataById, '5.selectedProductOption.data.fill', '#C6C5C4');
  var mechanismFinish = productId === 5 || productId === 1 ? plateFinish : _.get(propertiesDataById, '7.selectedProductOption.data.fill', '#CC7D2D');

  var fontStyleId = _.get(propertiesDataById, '26.selectedProductOption.id');
  var isMechanicalStyleEngraving = 0;
  var fontColor;

  if (_.includes([138, 773], fontStyleId)) { //HINT resin filled and screenprint style engravings
    var styleData = _.get(propertiesDataById, '82.selectedProductOption.data');
    fontColor = _.get(styleData, 'fill', 'black');
    if (fontColor === 'transparent') {
      fontColor = 'black';
      isMechanicalStyleEngraving = 1;
    }
  }
  if (fontStyleId === 139) {
    fontColor = '#f7e997';
  }
  if (fontStyleId === 137) {
    fontColor = 'black';
    isMechanicalStyleEngraving = 1;
  }

  var engravingsData = {
    index: 0, //TODO confirm this is correct - where is the actual text being mapped from mech positino to engraving index
    engravingArrays: _.get(properties, '27', []),
    isEnabled: {133: false, 134: true}[_.get(propertiesDataById, '24.selectedProductOption.id')],
    fontSize: _.get(propertiesDataById, '38.selectedProductOption.data.fontSize'),
    fontFamily: _.get(propertiesDataById, '25.selectedProductOption.data.fontFamily'),
    lineHeight: 1.2,
    positionId: _.get(propertiesDataById, '108.selectedProductOption.id'),
    fontColor,
    isMechanicalStyleEngraving,
  };

  var {plateSizePropertyId, backBoxType, backBoxId, mechanismLayoutPropertyId, collection} = getKeypadData({productId, productInstanceWithData: deps.productInstance});
  var {layoutStringArray, totalRows, totalColumns} = getMechanismLayoutData({mechanismLayoutPropertyId, productInstanceWithData: deps.productInstance});

  var plateSizeData = (_.get(propertiesDataById, `${plateSizePropertyId}` + '.selectedProductOption.data'));
  var rectWidth = _.get(plateSizeData, 'width');
  var rectHeight = _.get(plateSizeData, 'height');

  var edgeTypeId = _.get(propertiesDataById, '2.selectedProductOption.id');
  var isBeveled = {240: true, 241: false}[edgeTypeId] || false;

  var screwTypeOptionId = _.get(propertiesDataById, '3.selectedProductOption.id') || 0;
  var isScrewVisible = {5: true, 6: true, 103: false, 0: false}[screwTypeOptionId];
  var customInteraxis = (plateSizeData.height === 117 || plateSizeData.width === 117) && _.get(propertiesDataById, '29.selectedProductOption.id') === 144 ? 88 : 0;

  if (plateSizeData.width === 38 && plateSizeData.height === 144) {
    let layoutString = layoutStringArray.join('');

    if (layoutString.length === 4) customInteraxis = 119;
  }

  if (plateSizeData.width === 38 && plateSizeData.height === 175) {
    let layoutString = layoutStringArray.join('');

    if (layoutString.length === 6) customInteraxis = 150;
  }

  var screws = getScrews({group, path, circle, screwTypeOptionId, rectWidth, rectHeight, customInteraxis});

  var bevel = getBevel({group, path, rectWidth, rectHeight});

  var buttonsYPosition = 0;
  var mechanisms = [];

  var mechanismTypeData = _.get(propertiesDataById, '23.selectedProductOption', {});

  var {xSpacing, ySpacing} = getMechanismSpacings({width: rectWidth, height: rectHeight, backBoxId, backBoxType, totalRows, totalColumns, productId, mechanismTypeData});

  if (rectWidth === 117 && rectHeight === 117) {
    xSpacing = 46;

    if (totalRows === 2) {
      ySpacing = 32;

      if (productId === 10 || productId === 11) { //if solaris or ellipse
        ySpacing = 36;
      }
    }
    else if (totalRows === 3) {
      ySpacing = _.get(mechanismTypeData, 130) ? 30 : 24; //if it has a toggle set to 30 otherwise 24
    }

    if (totalColumns === 3) {
      xSpacing = 36;
    }
    else if (totalColumns === 4) {
      xSpacing = 22;
    }
  }

  // TODO combine these three and use one array
  let specialCaseMechanismGroupYPosition = [];
  let specialCaseKeypadRowSpacings = [];
  let specialCaseVerticalMechanismDimensions = [];

  //https://drive.google.com/file/d/1TH1asb61ZVRyWeTNOXYsxE9bz2_4RXHL/view - mixed-spacing set 1
  //https://drive.google.com/file/d/1bfLipLnNJSQ6UBhiasZtzjTp-MiN9aSR/view - mixed-spacing set 1

  if (rectWidth === 82 && rectHeight === 117 && productId === 2) {
    if (totalColumns === 1 && totalRows === 3) {
      if (layoutStringArray.join('') === 'BBT') {
        specialCaseKeypadRowSpacings.push(20.5, 49);
        specialCaseMechanismGroupYPosition.push(30);
        specialCaseVerticalMechanismDimensions.push(30, 50.5, 79);
      }
      if (layoutStringArray.join('') === 'TBB') {
        specialCaseKeypadRowSpacings.push(28.5, 49);
        specialCaseMechanismGroupYPosition.push(38);
        specialCaseVerticalMechanismDimensions.push(38, 66.5, 87);
      }
    }
    if (totalColumns === 2 && totalRows === 3) {
      let layoutString = layoutStringArray.join('');
      if (layoutString === 'BBBBB' || layoutString === 'BBBBT' || layoutString === 'TTBBB' || layoutString === 'BBTTT') {
        specialCaseKeypadRowSpacings.push(28, 56);
        specialCaseMechanismGroupYPosition.push(30.5);
        specialCaseVerticalMechanismDimensions.push(30.5, 58.5, 86.5);
      }
      if (layoutString === 'TTBBBB') {
        specialCaseKeypadRowSpacings.push(25, 45);
        specialCaseMechanismGroupYPosition.push(40.5);
        specialCaseVerticalMechanismDimensions.push(40.5, 65.5, 85.5);
      }
      if (layoutString === 'BBBBTT') {
        specialCaseKeypadRowSpacings.push(20, 45);
        specialCaseMechanismGroupYPosition.push(31.5);
        specialCaseVerticalMechanismDimensions.push(31.5, 51.5, 76.5);
      }
    }
  }

  if (rectWidth === 38 && rectHeight === 144 && (_.includes([2, 5], productId))) {
    let layoutString = layoutStringArray.join('');
    if (layoutString === 'BBBB') {
      ySpacing = 30;
    }

    if (layoutString === 'BBTT') {
      specialCaseKeypadRowSpacings.push(26, 52, 82);
      specialCaseMechanismGroupYPosition.push(30);
      specialCaseVerticalMechanismDimensions.push(30, 56, 82, 112);
    }

    if (_.includes(['TBTB', 'BTBT'], layoutString)) {
      specialCaseKeypadRowSpacings.push(32, 58.6, 85.2, 111.8);
      specialCaseMechanismGroupYPosition.push(32);
      specialCaseVerticalMechanismDimensions.push(32, 58.6, 85.2, 111.8);
    }
  }

  if (rectWidth === 38 && rectHeight === 175 && (_.includes([2, 5], productId))) {
    let layoutString = layoutStringArray.join('');

    if (layoutString === 'BBBBBB') {
      specialCaseMechanismGroupYPosition.push(35);
      ySpacing = 21;
      specialCaseVerticalMechanismDimensions.push(35, 56, 77, 98, 119, 140);
    }
  }

  var justifyPositionId = _.get(propertiesDataById, '130.selectedProductOption.id', 1002);
  var justifyPosition;

  if (justifyPositionId === 1002) justifyPosition = 'center';
  if (justifyPositionId === 1001) justifyPosition = 'start';
  if (justifyPositionId === 1003) justifyPosition = 'end';

  var mechanisms = getKeypadMechanisms({propertiesDataById, collection, layoutStringArray, path, rect, circle, group, mechanismFinish, xSpacing, ySpacing: !_.isEmpty(specialCaseKeypadRowSpacings) ? specialCaseKeypadRowSpacings : ySpacing, totalColumns, buttonsYPosition, mechanisms, engravingsData, text, image, deps, dimension, plateFinish, rectHeight, rectWidth, totalRows, justifyPosition, ledIndicatorIsEnabled, ledPosition});

  var mechanismGroup = {
    x: (rectWidth / 2) - (((xSpacing ? xSpacing : 0) * (totalColumns - 1)) / 2),
    y: !_.isEmpty(specialCaseMechanismGroupYPosition) ? specialCaseMechanismGroupYPosition[0] : (rectHeight / 2) - (((ySpacing ? ySpacing : 0) * (totalRows - 1)) / 2)
  };

  var widthDimensionProps = [{x: rectWidth, y: rectHeight}, {x: 0, y: rectHeight}];
  var heightDimensionProps = [{x: 0, y: rectHeight}, {x: 0, y: 0}];

  var xAxisMechanismPoints = [];

  _.forEach(mechanisms, (mechanismData) => {
    var {props} = mechanismData;

    if (!props.x) {
      xAxisMechanismPoints.push(0);
    }
    else {
      xAxisMechanismPoints.push(props.x);
    }
  });

  var xSpacingDimensionPositions = [
    {x: rectWidth, y: rectHeight},
    ..._.map(_.sortBy(_.uniq(xAxisMechanismPoints), value => value), x => ({x: mechanismGroup.x + x, y: rectHeight})).reverse(),
    {x: 0, y: rectHeight}
  ];

  var ySpacingDimensionPositions = [
    {x: 0, y: rectHeight},
    ..._.times(totalRows, (index) => ({
      x: 0,
      y: !_.isEmpty(specialCaseVerticalMechanismDimensions)
        ? specialCaseVerticalMechanismDimensions[index]
        : ySpacing ? (mechanismGroup.y + (ySpacing * index)) : rectHeight / 2
    })).reverse(),
    {x: 0, y: 0}
  ];

  return group({width: rectWidth, height: rectHeight, padding: 35}, [
    rect({width: rectWidth, height: rectHeight, strokeWidth: 1, stroke: 'black', fill: plateFinish, rx: productId === 10 ? 10 : 0, ry: productId === 10 ? 10 : 0}),

    ...(isScrewVisible ? [screws] : []),
    ...(isBeveled ? [bevel] : []),

    group({x: mechanismGroup.x, y: mechanismGroup.y}, [
      ...mechanisms
    ]),

    //HINT uncomment next 2 lines to show mechanism spacing dimensions
    // dimension({props: {positions: xSpacingDimensionPositions, extrudeDistance: 30}}),
    // dimension({props: {positions: ySpacingDimensionPositions, extrudeDistance: 30}}),
    dimension({props: {positions: widthDimensionProps, extrudeDistance: 60}}),
    dimension({props: {positions: heightDimensionProps, extrudeDistance: 60}})
  ]);
}
