import _ from 'lodash';
import { getProductInstanceWithData, filterInstancePropertiesFor } from '~/helpers/product-order-helper';
import { getInvoiceInlinePropertiesString } from '~/helpers/get-invoice-inline-properties-string';

export default function getProductSpecString({productInstance, activeOrgId, productsById, productPropertiesById, productRulesById, productOptionsById}) {
  var productInstanceWithData = getProductInstanceWithData({productInstance}, {productsById, productPropertiesById, productRulesById, productOptionsById});

  if (activeOrgId === 1053) {
    var productPropertyIdsForProductSpecs = [];

    if (productInstanceWithData.productId === 104) { //HINT door slab species, grade, finish
      productPropertyIdsForProductSpecs.push(...[137, 169, 170]);
    }
    else if (productInstanceWithData.productId === 105) { //HINT cabinet front species, grade, finish, veneer profile
      productPropertyIdsForProductSpecs.push(...[146, 147, 153, 160]);
    }

    return _.chain(productInstanceWithData.sortedPropertiesData)
      .filter((propertyData) => _.includes(productPropertyIdsForProductSpecs, propertyData.productProperty.id))
      .map((propertyData) => propertyData.selectedProductOption.title)
      .join(' | ')
      .value();
  }
  else {
    var invoiceInlineProperties = filterInstancePropertiesFor({productInstance: productInstanceWithData}, {invoiceVisibility: 'inline', isOrderLevel: 0, type: 'singleSelect'});

    return getInvoiceInlinePropertiesString({invoiceInlineProperties, productInstance: productInstanceWithData});
  }
}
