import { useContext, useEffect } from 'react';
import { toCanvas } from '~/helpers/canvas/canvas-helpers';
import { Line } from 'react-konva';
import { connect } from '@symbolic/redux';
import { resourceActions } from '~/redux';

import lib from '~/lib';
import CanvasDataContext from '~/contexts/canvas-data-context';
import ArchDataContext from '~/contexts/arch-data-context';
import PathTool from '~/components/canvas/tools/path/path-tool';

function CalicoCanvasMismatchLine({calicoMismatchLine, ...props}) {
  var canvasData = useContext(CanvasDataContext);
  var archData = useContext(ArchDataContext);

  var {from, to} = calicoMismatchLine.data;

  var isSelected = archData.selectedEntityId === calicoMismatchLine.id && archData.selectedEntityResourceKey === 'calicoMismatchLine';

  var points = [];

  if (_.size(from)) {
    from = {x: from.x, y: from.y};

    points.push(from);
  }
  if (_.size(to)) {
    to = {x: to.x, y: to.y};

    points.push(to);
  }

  var linePoints;

  if (_.size(from) && _.size(to)) {
    linePoints = _.flatMap(_.mapValues(points, (point) => _.values(toCanvas(point, canvasData))));
  }

  var onSelect = () => archData.setSelectedEntity(calicoMismatchLine.id, 'calicoMismatchLine');

  useEffect(() => {
    var handleDestroyEntity = (event) => {
      if (event.code === 'Backspace' && isSelected) {
        props.destroyEntity({id: calicoMismatchLine.id});

        archData.setSelectedEntity(undefined, undefined);
      }
    };

    document.addEventListener('keydown', handleDestroyEntity);

    return () => {
      document.removeEventListener('keydown', handleDestroyEntity);
    };
  });

  return (
    <>
      {(!to || isSelected) && (
        <PathTool
          isSelected={isSelected}
          strokeWidth={1}
          color={'transparent'}
          isInAddMode={!to}
          onSelect={onSelect}
          points={points}
          onTransform={({points, transformEnd}) => {
            var fromDelta, toDelta;

            if (points[0]) {
              fromDelta = lib.object.difference(points[0], from);

              from = lib.object.sum(fromDelta, calicoMismatchLine.data.from);
              from = {x: from.x, y: from.y, z: 0};
            }

            if (points[1]) {
              toDelta = lib.object.difference(points[1], to);

              to = lib.object.sum(toDelta, calicoMismatchLine.data.to);
              to = {x: to.x, y: to.y, z: 0};
            }

            if (!calicoMismatchLine.data.to) {
              archData.updateTentativeCalicoMismatchLine({tentativeCalicoMismatchLine: {...calicoMismatchLine, data: {...calicoMismatchLine.data, from, to}}});
            }
            else {
              props.updateEntity({id: calicoMismatchLine.id, props: {data: {...calicoMismatchLine.data, from, to}}, hitApi: !!transformEnd});
            }
          }}
        />
      )}
      <Line
        points={linePoints}
        stroke={'red'}
        strokeWidth={1}
        onMouseDown={onSelect}
      />
    </>
  );
}

export default connect({mapDispatch: {
  ..._.pick(resourceActions.entities, ['updateEntity', 'destroyEntity'])
}})(CalicoCanvasMismatchLine);
