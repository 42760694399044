import meljacLogo from '~/assets/meljac-logo.png';
import meljacLogoBlack from '~/assets/meljac-logo-black.png';
import maderaLogo from '~/assets/madera/madera-logo.png';
import maderaLogoBlack from '~/assets/madera/madera-logo-black.png';
import fireclayTileLogo from '~/assets/fireclay-tile-logo.png';
import deskmakersLogo from '~/assets/deskmakers/deskmakers-logo.png';
import demoLogo from '~/assets/demo/demo-logo.png';
import calicoLogo from '~/assets/calico/calico-logo.png';

var orgIdLogoMapByColor = {
  850: {white: meljacLogo, black: 'https://symbolic-public.s3.amazonaws.com/org-logos/light-background/850.png'},
  1004: {white: fireclayTileLogo, black: fireclayTileLogo},
  1053: {white: maderaLogo, black: maderaLogoBlack},
  1392: {white: demoLogo, black: demoLogo},
  1450: {white: deskmakersLogo, black: deskmakersLogo},
  1798: {white: calicoLogo, black: calicoLogo}
};

function getLogoForOrg({orgId, colorVariant}) {
  return _.get(orgIdLogoMapByColor, `${orgId}.${colorVariant}`);
}
export {orgIdLogoMapByColor, getLogoForOrg};
