import { isValidElement } from 'react';
import { Text } from '@symbolic/rn-lib';
import { View } from 'react-native';

import K from '~/k';

export default function DrawingsPageHeader({title, subtitle}) {
  return (
    <View style={{position: 'absolute', zIndex: 1, flexDirection: 'row', alignItems: 'flex-end', width: '100%', top: 0, left: 0, padding: K.spacing * 2}}>
      <View style={{flex: 1}}>
        <Text style={{fontWeight: 'bold', fontSize: 20, marginBottom: 3}}>{title}</Text>
        {isValidElement(subtitle) ? subtitle : <Text style={{fontSize: 13}}>{subtitle}</Text>}
      </View>
      <View style={{flexDirection: 'row', marginTop: 10}}>
        <Text style={{fontSize: 13}}>Initials</Text>
        <View style={{borderBottomWidth: 1, width: 40, marginLeft: 5}}/>
      </View>
    </View>
  );
}
