import { useContext, useEffect } from 'react';
import { getPlaneForRect, threeDToTwoD, toCanvas } from '~/helpers/canvas/canvas-helpers';
import { toRadians } from '~/helpers/trig/trig-helpers';
import { connect } from '@symbolic/redux';
import { resourceActions } from '~/redux';
import { Rect } from 'react-konva';

import ArchDataContext from '~/contexts/arch-data-context';
import CanvasDataContext from '~/contexts/canvas-data-context';
import RectTool from '~/components/canvas/tools/rect/rect-tool';

function CalicoCanvasMuralArea({calicoMuralArea, ...props}) {
  var archData = useContext(ArchDataContext);
  var canvasData = useContext(CanvasDataContext);

  var {position, size, xzRotation, yRotation} = calicoMuralArea.data;

  var isTwoDPosition = !position.z;

  //HINT: calicoMuralarea for archCustomView with {x, y} only. so applying z: 0
  if (isTwoDPosition) position = {...position, z: 0};

  var points = getPlaneForRect({position, size, rotation: {xz: -toRadians(xzRotation), y: -toRadians(yRotation)}});

  var isSelected = archData.selectedEntityId === calicoMuralArea.id;

  useEffect(() => {
    var handleDestroyEntity = (event) => {
      if (event.code === 'Backspace' && isSelected) {
        props.destroyEntity({id: calicoMuralArea.id});

        archData.setSelectedEntity(undefined, undefined);
      }
    };

    document.addEventListener('keydown', handleDestroyEntity);

    return () => {
      document.removeEventListener('keydown', handleDestroyEntity);
    };
  });

  return (
    <>
      <Rect
        {...toCanvas(position, canvasData)}
        {..._.mapValues(size, (value) => value * canvasData.scale)}
        fill={`#A3B3D6${calicoMuralArea.data.isReturn ? '4D' : ''}`}
        stroke='black'
        strokeWidth={0.5}
        rotation={yRotation}
      />
      <RectTool
        {...{isSelected}}
        rotation={yRotation}
        onSelect={() => archData.setSelectedEntity(calicoMuralArea.id, 'calicoMuralArea')}
        onTransform={({position, rotation = 0, size}) => {
          var newPosition;

          if (isTwoDPosition) {
            newPosition = position;
          }

          props.updateEntity({id: calicoMuralArea.id, props: {data: {...calicoMuralArea.data, position: newPosition, ...(size ? {size} : {}), yRotation: rotation}}, hitApi: false});
        }}
        onTransformEnd={() => {
          props.updateEntity({id: calicoMuralArea.id, props: {data: calicoMuralArea.data}});
        }}
        position={toCanvas(position, canvasData)}
        {...{size}}
      />
    </>
  );
}

export default connect({
  mapDispatch: {
    ..._.pick(resourceActions.entities, ['updateEntity', 'destroyEntity'])
  }
})(CalicoCanvasMuralArea);
