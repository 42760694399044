import { PureComponent } from 'react';
import { Text, CopilotStepView, TextInput, Label, CurrencyInput } from '@symbolic/rn-lib';
import { View, TouchableOpacity, Image } from 'react-native';
import { connect } from '@symbolic/redux';
import { resourceActions } from '~/redux';
import { products } from '@symbolic/lib';

import { getNameFor } from '~/helpers/product-order-helper';
import { formatPrice } from '~/helpers/price-helper';
import getPricingIsHidden from '~/helpers/get-pricing-is-hidden/get-pricing-is-hidden';

import HudElement from '~/components/hud-element';

import upArrowIcon from '~/assets/up-arrow-black.png';
import leftArrowIcon from '~/assets/left-arrow.png';

import K from '~/k';
import _ from 'lodash';

var { priceFor, productPricingRuleVersionFor } = products;

class ProductInstanceControlsHudElement extends PureComponent {
  handleProductInstanceUpdate = ({props}) => this.props.updateProductInstance({id: this.props.activeProductInstance.id, props});

  handleProductInstanceTitleChange = ({value}) => {
    var product = this.props.productsById[this.props.activeProductInstance.productId];

    if (product.instanceTitleIsRequired && _.isEmpty(value)) {
      alert('This item must have an identifier/description.');
    }
    else {
      this.handleProductInstanceUpdate({props: {title: value}});
    }
  };

  pricesFor = ({activeProductInstance}) => {
    var {productOptionsById, productPricingRulesById, productOptionClassifiersById, productsById, productPropertiesById, productRulesById, productInstancesById, product, productOrder} = this.props;

    var product = _.find(productsById, {id: activeProductInstance.productId});

    var unitPrice = 0;
    var subtotalPrice = 0;

    if (activeProductInstance.productId === -1) {
      unitPrice = priceFor({}, {productInstance: activeProductInstance, shouldApplyQuantity: false}, {productOrder});

      subtotalPrice = priceFor({}, {productInstance: activeProductInstance, shouldApplyQuantity: true}, {productOrder});
    }
    else {
      var {expressions} = productPricingRuleVersionFor({productInstance: activeProductInstance, productOrder}, {productsById, productPricingRulesById});

      unitPrice = priceFor(expressions, {productInstance: activeProductInstance, shouldRoundPrice: product.shouldRoundPrice, shouldApplyQuantity: false}, {productOptionsById, productPricingRulesById, productOptionClassifiersById, productsById, productPropertiesById, productRulesById, productInstancesById, productOrder});

      subtotalPrice = priceFor(expressions, {productInstance: activeProductInstance, shouldRoundPrice: product.shouldRoundPrice, shouldApplyQuantity: true}, {productOptionsById, productPricingRulesById, productOptionClassifiersById, productsById, productPropertiesById, productRulesById, productInstancesById, productOrder});
    }

    return {unitPrice, subtotalPrice};
  };

  render() {
    var {activeProductInstance, productInstances, productsById, productOrder} = this.props;
    var {id: orgId} = this.props.session.activeOrg;
    var pricingIsHidden = getPricingIsHidden({session: this.props.session, productOrder});

    var {unitPrice, subtotalPrice} = this.pricesFor({activeProductInstance});

    return (
      <HudElement x='center' y='bottom'>
        <View style={{flexDirection: 'row', alignItems: 'center', justifyContent: 'center'}}>
          {/* <Tooltip text='View photos' style={{zIndex: 1}} yPosition='top'>
            <Button icon={cameraIcon} style={{backgroundColor: 'transparent', paddingRight: K.spacing * 2}} onPress={() => this.props.showProductInstanceNotesPopup()}/>
          </Tooltip> */}
          <View style={{flexDirection: 'column', alignItems: 'center'}}>
            {activeProductInstance && (<>
              <CopilotStepView
                order={7}
                key={true}
                name='product title'
                text={getNameFor({productOrder, orgId, textToTransform: 'Identifiers/Descriptions can be added to items in your order. These can be helpful with project coordination. Click the arrows to go to the next/previous item in your order.'})}
              >
                <View style={{flexDirection: 'column', width: 'auto', alignItems: 'flex-end'}}>
                  <View style={{flexDirection: 'row', alignItems: 'center', marginBottom: K.margin}}>
                    {(productInstances.length > 1 && productInstances.indexOf(activeProductInstance) > 0) ? (
                      <TouchableOpacity
                        style={{marginRight: K.spacing}}
                        onPress={()=> {
                          var currentInstanceIndex = productInstances.indexOf(activeProductInstance);

                          this.props.setActiveProductInstanceId({activeProductInstanceId: productInstances[currentInstanceIndex - 1].id});
                        }}
                      >
                        <Image
                          source={leftArrowIcon}
                          style={{width: K.calc(30), height: K.calc(30), opacity: 0.5}}
                        />
                      </TouchableOpacity>
                    ) : (
                      <View style={{width: K.calc(30) + K.spacing}}/>
                    )}
                    <TextInput
                      nativeID={'OrderPageProductInstanceTitleInput'}
                      autoWidth
                      value={activeProductInstance.title || ''}
                      maxLength={40}
                      onChange={this.handleProductInstanceTitleChange}
                      placeholder={'Identifier/Description'}
                      style={{...K.fonts.standard, fontSize: K.calcFont(16), fontWeight: 'bold', justifyContent: 'center', textAlign: 'right', backgroundColor: 'transparent', borderRadius: 0, paddingRight: 0, paddingLeft: 0, marginRight: 0}}
                    />
                    <Text style={{...K.fonts.standard, fontSize: K.calcFont(16), fontWeight: 'bold', backgroundColor: 'transparent', borderRadius: 0, alignSelf: 'center', justifyContent: 'flex-end', textAlign: 'center'}}>
                      {` - ${productsById[activeProductInstance.productId].title}`}
                    </Text>
                    {(productInstances.length > 1 && activeProductInstance !== productInstances[productInstances.length - 1]) ? (
                      <TouchableOpacity
                        style={{marginLeft: K.spacing}}
                        onPress={()=> {
                          var currentInstanceIndex = productInstances.indexOf(activeProductInstance);

                          this.props.setActiveProductInstanceId({activeProductInstanceId: productInstances[currentInstanceIndex + 1].id});
                        }}
                      >
                        <Image
                          source={leftArrowIcon}
                          style={{width: K.calc(30), height: K.calc(30), opacity: 0.5, transform: [{rotate: '180deg'}]}}
                        />
                      </TouchableOpacity>
                    ) : (
                      <View style={{width: K.calc(30) + K.spacing}}/>
                    )}
                  </View>
                </View>
              </CopilotStepView>
            </>)}
            <CopilotStepView
              order={8}
              key={true}
              name='product qty and notes'
              text={'Edit quantity here. Click Notes & More to add or view additional information.'}
            >
              <View style={{flexDirection: 'row', alignItems: 'center'}}>
                <View style={{marginRight: K.margin}}>
                  {!pricingIsHidden && (<View style={{flexDirection: 'row', marginRight: K.spacing}}>

                    {activeProductInstance.productId === -1 ? (
                      <View style={{flexDirection: 'row', alignItems: 'center', justifyContent: 'baseline'}}>
                        {activeProductInstance.quantity > 1 ? (
                          <>
                            <Text style={{fontWeight: 'bold', marginRight: K.margin}}>{'$' + formatPrice(subtotalPrice, true)}</Text>
                            <Text style={{...K.fonts.label, textTransform: 'none', opacity: 0.5}}>($</Text>
                          </>
                        ) : (
                          <Text style={{fontWeight: 'bold'}}>$</Text>
                        )}
                        <CurrencyInput
                          placeholder={'0.00'}
                          autoWidth
                          value={formatPrice(unitPrice)}
                          onChange={({value}) => this.props.handleCustomPriceInput({value})}
                          style={{backgroundColor: 'transparent', borderRadius: 0, textAlign: 'left', paddingHorizontal: 0, height: 12, minWidth: K.calc(15), ...(activeProductInstance.quantity > 1 ? {opacity: 0.5} : {fontWeight: 'bold'})}}
                          selectTextOnFocus
                        />
                        {activeProductInstance.quantity > 1 && (<Text style={{...K.fonts.label, textTransform: 'none', opacity: 0.5}}>each)</Text>)}
                      </View>
                    ) : (
                      <>
                        <Text style={{fontWeight: 'bold', marginRight: K.margin}}>{'$' + formatPrice(subtotalPrice, true)}</Text>
                        {activeProductInstance.quantity > 1 && (
                          <Text style={{...K.fonts.label, textTransform: 'none', opacity: 0.5}}>{'($' + formatPrice(unitPrice, true) + ' each)'}</Text>
                        )}
                      </>
                    )}
                  </View>)}
                </View>
                <Text style={{...K.fonts.standard}}>QTY: </Text>
                <TextInput
                  value={activeProductInstance.quantity}
                  onChange={({value}) => (value < 1 || isNaN(value)) ? this.handleProductInstanceUpdate({props: {quantity: 1}}) : this.handleProductInstanceUpdate({props: {quantity: parseInt(value)}})}
                  style={{backgroundColor: 'transparent', paddingHorizontal: 0, width: K.calc(40)}}
                  placeholder={'1'}
                  selectTextOnFocus
                />
                <TouchableOpacity nativeID={'OrderPageProductInstanceNotesPopupButton'} style={{flexDirection: 'row', alignItems: 'center'}} onPress={() => this.props.showProductInstanceNotesPopup()}>
                  <Label style={{...K.fonts.label, textTransform: 'capitalize', paddingHorizontal: K.margin}}>Notes</Label>
                  <Image source={upArrowIcon} style={{width: K.calc(12), height: K.calc(12), transform: [{rotate: '90deg'}]}} />
                </TouchableOpacity>
              </View>
            </CopilotStepView>
          </View>
        </View>
      </HudElement>
    );
  }
}

export default connect({
  mapState: (state, ownProps) => {
    var productOrderId = ownProps.productOrder.id;
    var productOrder = _.get(state, `resources.productOrders.byId.${productOrderId}`);
    var productInstances = _.sortBy(_.filter(state.resources.productInstances.byId, {productOrderId}), 'rank');
    var productsById = state.resources.products.byId;
    var productPricingRulesById = state.resources.productPricingRules.byId;
    var productPropertiesById = state.resources.productProperties.byId;
    var productOptionClassifiersById = state.resources.productOptionClassifiers.byId;
    var productOptionsById = state.resources.productOptions.byId;
    var productRulesById = state.resources.productRules.byId;
    var productInstancesById = _.get(state.resources.productInstances, `byFieldKeyIndex.productOrderId.${productOrderId}`);

    return {
      productInstances,
      productsById,
      productOrder,
      productOptionsById,
      productPricingRulesById,
      productOptionClassifiersById,
      productPropertiesById,
      productRulesById,
      productInstancesById
    };
  },
  mapDispatch: {
    ..._.pick(resourceActions.productInstances, ['updateProductInstance']),
  }
})(ProductInstanceControlsHudElement);
