import _ from 'lodash';
import {fifteenAmpReceptacle, twentyAmpReceptacle, usbA, usbC, hdmi, rj45, rj11, speakerPorts, coax, cablePassThrough, pushButton} from '~/helpers/meljac/port-modules';

export default function getFloorOutletGraphicNodes({deps, group, rect, circle, path, dimension}) {
  var {productInstance, getArcPathArrayWithCenterFromAngles} = deps;
  var {propertiesDataById} = productInstance;

  var plateFinishColor = _.get(propertiesDataById, '5.selectedProductOption.data.fill', 'transparent');
  var selectedFloorOutletSizeId = _.get(propertiesDataById, '14.selectedProductOption.id');
  var floorOutletSizesShapeById = {99: 'ROUND', 101: 'RECTANGULAR', 100: 'SQUARE'};
  var floorOutletSizesData = {
    ROUND: {value: 'ROUND', dims: {height: 100, width: 100}},
    RECTANGULAR: {value: 'RECTANGULAR', dims: {height: 100, width: 180}},
    SQUARE: {value: 'SQUARE', dims: {height: 100, width: 100}}
  };

  var floorOutletWidth = 71.5;
  var floorOutletHeight = 100;
  var floorOutletSizes = _.mapValues(floorOutletSizesData, 'value');
  var floorOutletSize = floorOutletSizesData[floorOutletSizesShapeById[selectedFloorOutletSizeId]]['value'];
  var floorOutletDims = floorOutletSizesData[floorOutletSizesShapeById[selectedFloorOutletSizeId]]['dims'];

  var firstPortLayoutData = _.get(propertiesDataById, '50.selectedProductOption.data');
  var secondPortLayoutData = _.has(propertiesDataById, '121') ? _.get(propertiesDataById, '121.selectedProductOption.data') : '';

  var firstPortElements = [];
  var secondPortElements = [];
  var isSinglePort = true;
  if (_.get(propertiesDataById, '14.selectedProductOption.id') === 101) isSinglePort = false;

  var spaceBetweenOutlets = (180 - (71.5 * 2)) / 3;
  var offset = (spaceBetweenOutlets / 2) + (71.5 / 2);
  var stackedPortOffsetFromCenter = 12;

  function pushElementsToPort ({portData, elementArray, isSinglePort, portIndex}) {
    var layoutString = _.split(_.get(portData, 'layoutString'), ' ');
    var xPosition;
    var mechanismIsStacked = layoutString.length > 1 ? true : false;

    if (isSinglePort === true) xPosition = 50;
    else if (isSinglePort === false && portIndex === 0) xPosition = 90 - offset;
    else if (isSinglePort === false && portIndex === 1) xPosition = 90 + offset;

    _.forEach(layoutString, (mechanismString, mechanismIndex) => {
      if (mechanismString === 'RB') elementArray.push(fifteenAmpReceptacle({xPosition: mechanismIsStacked ? xPosition + (stackedPortOffsetFromCenter * (mechanismIndex === 0 ? -1 : 1)) : xPosition, yPosition: floorOutletHeight / 2, group, rect, path, receptacleTypeColor: {backgroundColor: '#000000', socketInletColor: '#474747'}}));
      if (mechanismString === 'RW') elementArray.push(fifteenAmpReceptacle({xPosition: mechanismIsStacked ? xPosition + (stackedPortOffsetFromCenter * (mechanismIndex === 0 ? -1 : 1)) : xPosition, yPosition: floorOutletHeight / 2, group, rect, path, receptacleTypeColor: {backgroundColor: '#FFFFFF', socketInletColor: '#474747'}}));
      if (mechanismString === '2RB') elementArray.push(twentyAmpReceptacle({xPosition: mechanismIsStacked ? xPosition + (stackedPortOffsetFromCenter * (mechanismIndex === 0 ? -1 : 1)) : xPosition, yPosition: floorOutletHeight / 2, group, rect, path, receptacleTypeColor: {backgroundColor: '#000000', socketInletColor: '#474747'}}));
      if (mechanismString === '2RW') elementArray.push(twentyAmpReceptacle({xPosition: mechanismIsStacked ? xPosition + (stackedPortOffsetFromCenter * (mechanismIndex === 0 ? -1 : 1)) : xPosition, yPosition: floorOutletHeight / 2, group, rect, path, receptacleTypeColor: {backgroundColor: '#FFFFFF', socketInletColor: '#474747'}}));
      if (mechanismString === 'RJ4') elementArray.push(rj45({xPosition: mechanismIsStacked ? xPosition + (stackedPortOffsetFromCenter * (mechanismIndex === 0 ? -1 : 1)) : xPosition, yPosition: floorOutletHeight / 2, group, rect, path}));
      if (mechanismString === 'B') elementArray.push(pushButton({xPosition: mechanismIsStacked ? xPosition + (stackedPortOffsetFromCenter * (mechanismIndex === 0 ? -1 : 1)) : xPosition, yPosition: floorOutletHeight / 2, group, rect, path, circle}));
      if (mechanismString === 'H') elementArray.push(hdmi({xPosition: mechanismIsStacked ? xPosition + (stackedPortOffsetFromCenter * (mechanismIndex === 0 ? -1 : 1)) : xPosition, yPosition: floorOutletHeight / 2, group, rect, path}));
      if (mechanismString === 'RJ1') elementArray.push(rj11({xPosition: mechanismIsStacked ? xPosition + (stackedPortOffsetFromCenter * (mechanismIndex === 0 ? -1 : 1)) : xPosition, yPosition: floorOutletHeight / 2, group, rect, path}));
      if (mechanismString === 'C') elementArray.push(coax({xPosition: mechanismIsStacked ? xPosition + (stackedPortOffsetFromCenter * (mechanismIndex === 0 ? -1 : 1)) : xPosition, yPosition: floorOutletHeight / 2, group, rect, path, circle}));
      if (mechanismString === 'UA') elementArray.push(usbA({xPosition: mechanismIsStacked ? xPosition + (stackedPortOffsetFromCenter * (mechanismIndex === 0 ? -1 : 1)) : xPosition, yPosition: floorOutletHeight / 2, group, rect, path}));
      if (mechanismString === 'UC') elementArray.push(usbC({xPosition: mechanismIsStacked ? xPosition + (stackedPortOffsetFromCenter * (mechanismIndex === 0 ? -1 : 1)) : xPosition, yPosition: floorOutletHeight / 2, group, rect, path}));
      if (mechanismString === 'CP') elementArray.push(cablePassThrough({xPosition: mechanismIsStacked ? xPosition + (stackedPortOffsetFromCenter * (mechanismIndex === 0 ? -1 : 1)) : xPosition, yPosition: floorOutletHeight / 2, group, rect, path, circle}));
      if (mechanismString === 'S') elementArray.push(speakerPorts({xPosition: mechanismIsStacked ? xPosition + (stackedPortOffsetFromCenter * (mechanismIndex === 0 ? -1 : 1)) : xPosition, yPosition: floorOutletHeight / 2, group, rect, path, circle}));
    });
  }

  pushElementsToPort({portData: firstPortLayoutData, elementArray: firstPortElements, isSinglePort, portIndex: 0});
  pushElementsToPort({portData: secondPortLayoutData, elementArray: secondPortElements, isSinglePort, portIndex: 1});

  var singleFloorOutlet = ({height, width, x, y}) => group({height, width, x, y}, [
    // the hinge
    path({d: [
      {command: 'M', x: 21.375, y: 15},
      {command: 'l', x: 0, y: -5},
      {command: 'a', rx: 3, ry: 3, xAxisRotation: 0, largeArcFlag: 0, sweepFlag: 1, x: 2, y: -3},
      {command: 'l', x: 25, y: 0},
      {command: 'a', rx: 3, ry: 3, xAxisRotation: 0, largeArcFlag: 0, sweepFlag: 1, x: 2, y: 3},
      {command: 'l', x: 0, y: 5}
    ], stroke: 'black', fill: 'transparent', strokeWidth: 1}),

    // round arc that connects with the hinge or circular cover
    path({d: getArcPathArrayWithCenterFromAngles({cx: 35.75, cy: 50, radius: 35.75, startAngle: 25, endAngle: 334}), stroke: 'black', fill: 'transparent', strokeWidth: 1}),

    // left arc connecting cover with hinge
    path({d: [
      {command: 'M', x: 21.375, y: 15},
      {command: 'A', rx: 3, ry: 3, xAxisRotation: 0, largeArcFlag: 0, sweepFlag: 1, x: 20.07823150229047, y: 17.868112844804784}
    ], stroke: 'black', fill: 'transparent', strokeWidth: 1}),

    // right arc connecting cover with hinge
    path({d: [
      {command: 'M', x: 50.858602857230004, y: 17.59949661343976},
      {command: 'A', rx: 3, ry: 3, xAxisRotation: 0, largeArcFlag: 0, sweepFlag: 1, x: 50.420, y: 15}
    ], stroke: 'black', fill: 'transparent', strokeWidth: 1}),

    // handle arc
    path({d: getArcPathArrayWithCenterFromAngles({cx: 35.75, cy: 50, radius: 28.75, startAngle: 90, endAngle: 270}), stroke: 'black', strokeWidth: 1, fill: 'transparent'}),

    // handle left hinge
    path({d: [{command: 'M', x: 7, y: 50}, {command: 'l', x: -1, y: 0}, {command: 'l', x: 0, y: -2.5}, {command: 'l', x: -4.5, y: 0}, {command: 'l', x: 0, y: 2.5}, {command: 'l', x: -1.5, y: 0}], stroke: 'black', fill: 'transparent', strokeWidth: 1}),

    // handle right hinge
    path({d: [{command: 'M', x: 64.5, y: 50}, {command: 'l', x: 1, y: 0}, {command: 'l', x: 0, y: -2.5}, {command: 'l', x: 4.5, y: 0}, {command: 'l', x: 0, y: 2.5}, {command: 'l', x: 1.5, y: 0}], stroke: 'black', fill: 'transparent', strokeWidth: 1}),

    //bottom thumb space bezier curve
    path({d: [{command: 'M', x: 26, y: 84.5}, {command: 'q', x1: 10, y1: 12, x: 20, y: 0}], stroke: 'black', fill: 'transparent', strokeWidth: 1})
  ]);

  var widthDimensionProps = [{x: floorOutletDims.width, y: floorOutletDims.height}, {x: 0, y: floorOutletDims.height}];
  var heightDimensionProps = [{x: 0, y: floorOutletDims.height}, {x: 0, y: 0}];

  return group({height: floorOutletDims.height, width: floorOutletDims.width, padding: 30}, [
    ...(floorOutletSize === floorOutletSizes.ROUND ? [circle({cx: 50, cy: 50, r: 50, stroke: 'black', strokeWidth: 1, fill: plateFinishColor})] : []),

    ...(floorOutletSize === floorOutletSizes.RECTANGULAR || floorOutletSize === floorOutletSizes.SQUARE ? [rect({width: floorOutletDims.width, height: floorOutletDims.height, fill: plateFinishColor, stroke: 'black'})] : []),

    ...(floorOutletSize === floorOutletSizes.ROUND || floorOutletSize === floorOutletSizes.SQUARE ? [singleFloorOutlet({height: 100, width: floorOutletWidth, x: 14.25, y: 0})] : []),

    ...(floorOutletSize === floorOutletSizes.RECTANGULAR ? [singleFloorOutlet({height: 100, width: floorOutletWidth, x: spaceBetweenOutlets, y: 0}), singleFloorOutlet({height: 100, width: floorOutletWidth, x: 90 + (spaceBetweenOutlets / 2), y: 0})] : []),

    ...firstPortElements,
    ...secondPortElements,

    dimension({props: {positions: widthDimensionProps, extrudeDistance: 30}}),
    dimension({props: {positions: heightDimensionProps, extrudeDistance: 30}})
  ]);
}
