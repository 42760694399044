import { lamellaData, grainDirectionSymbol, unitNumberSymbol } from './madera-script-helpers';
import getMaderaUnitNumberForProductInstance from '~/helpers/madera/get-madera-unit-number-for-product-instance';

import randomWithSeed from 'random-seed';
import lib from '~/lib';
import { get } from 'lodash';

// var getRandomValues = ({count, total, target, tolerance, seed}) => {
//   var array = [];
//   var length = count;
//   var number;
//   var totalProcessed;
//   var i;
//   var rand = randomWithSeed.create(seed);

//   for (i = 0; i < length; i++) {
//     // FIRST ONE
//     if (i == 0) {
//       number = rand.intBetween(target - tolerance, target + tolerance);
//       totalProcessed = number;
//       array.push(number);
//     }

//     //  MIDDLE SECTION
//     if (i > 0 && i < length - 1) {
//       if (length > 2) {
//         number = rand.intBetween(target - tolerance, target + tolerance);
//         totalProcessed = totalProcessed + number;

//         array.push(number);
//       }
//     }

//     // LAST ONE
//     if (i == length - 1) {
//       var lastOne = total - totalProcessed;
//       array.push(lastOne);
//     }
//   }

//   return array;
// };

export default function getSlabDoorGraphicNodes({deps, group, rect, path, text, circle}) {
  var {productInstance, productOrderProductInstances} = deps;
  var {properties, propertiesDataById} = productInstance;

  var slabWidth = _.get(properties, '141.value', 600);
  var slabHeight = _.get(properties, '142.value', 2000);
  var slabThickness = _.get(properties, '140.value', 67);

  var unitNumber = getMaderaUnitNumberForProductInstance({productInstance, productOrderProductInstances});
  var annotation = _.get(properties, '171.value', '');
  var hardware = _.get(properties, '163.value', '');
  var notes = _.get(properties, '162.value', '');

  var veneerProfileOption = _.get(propertiesDataById, '159.selectedProductOption');
  var grainDirectionOption = _.get(propertiesDataById, '138.selectedProductOption');

  var grainDirectionVerticalOptionId = 1077;
  var grainDirectionIsVertical = grainDirectionOption.id === grainDirectionVerticalOptionId;

  var hingeSideIsLeft = _.get(propertiesDataById, '144.selectedProductOption').id === 1091;

  var doorActionOption = _.get(propertiesDataById, '145.selectedProductOption');
  var doorActionKey = {
    1093: 'swing',
    1094: 'slide'
  }[doorActionOption.id];

  var hasHinges = doorActionKey === 'swing' && propertiesDataById['164'].selectedProductOption.id !== 1211;
  var hasPivotHinges = propertiesDataById['164'].selectedProductOption.id === 1212;
  var hingeQuantity = parseInt(propertiesDataById['164'].selectedProductOption.title);
  var hasHandle = _.includes([1207, 1209], propertiesDataById['165'].selectedProductOption.id);
  var hasLock = _.includes([1209], propertiesDataById['165'].selectedProductOption.id);

  var handleGraphic = ({hasHandle, hasLock, doorActionKey, hingeSideIsLeft}) => {
    var handleGraphic = [];

    if (hasHandle) {
      var hardwareGraphic = [
        circle({radius: 1 * scale, stroke: '#000', strokeWidth: 0.5}),
        rect({width: 5 * scale, height: 1 * scale, stroke: '#000', strokeWidth: 0.5, x: (!hingeSideIsLeft ? -0.5 : -5 + 0.5) * scale, y: -0.5 * scale, fill: 'white'})
      ];

      var lockGraphic = [];

      if (doorActionKey === 'slide') {
        hardwareGraphic = [
          rect({strokeWidth: 0.5, stroke: '#000', width: 2 * scale, height: 6 * scale, y: -0.5 * scale, x: (!hingeSideIsLeft ? -0.5 : -5 + 0.5)})
        ];
      }

      if (hasLock) {
        lockGraphic = [circle({radius: 1 * scale, stroke: '#000', strokeWidth: 0.5, y: -6 * scale})];
      }

      handleGraphic = [group({x: (!hingeSideIsLeft ? (0 + 4 * scale) : (slabWidth - 4 * scale)), y: slabHeight - 42 * scale}, [
        ...hardwareGraphic,
        ...lockGraphic
      ])];
    }

    return handleGraphic;
  };

  var determinedBy = _.get(propertiesDataById, '174.selectedProductOption.id') === 1249 ? 'quantity' : 'width';

  var numberOfLamellasOption = _.get(propertiesDataById, '143.selectedProductOption');
  var lamellaQuantity = _.has(numberOfLamellasOption, 'title') ? parseInt(numberOfLamellasOption.title) : null;

  var lamellaWidthValue = parseFloat(_.get(properties, '167.value', ''));

  var lamellaAlignment = 'center';

  if (determinedBy === 'width') {
    lamellaAlignment = _.lowerCase(_.get(propertiesDataById, '168.selectedProductOption.title'));
  }

  var {lamellaLinesGraphic, lamellaWidthInfoString} = lamellaData({boundingBox: {width: slabWidth, height: slabHeight}, determinedBy, path, lamellaQuantity, lamellaWidthValue, lamellaAlignment, productInstance});

  var doorActionLine = ({doorActionKey, hingeSideIsLeft}) => {
    if (doorActionKey === 'swing') {
      return swingLine({hingeSideIsLeft});
    }
    else if (doorActionKey === 'slide') {
      return slideArrow({hingeSideIsLeft});
    }
  };

  var swingLine = ({hingeSideIsLeft}) => {
    return group({}, [
      path({
        d: hingeSideIsLeft ? ([
          {command: 'M', x: slabWidth, y: 0},
          {command: 'L', x: 0, y: slabHeight / 2},
          {command: 'L', x: slabWidth, y: slabHeight}
        ]) : ([
          {command: 'M', x: 0, y: 0},
          {command: 'L', x: slabWidth, y: slabHeight / 2},
          {command: 'L', x: 0, y: slabHeight}
        ]),
        strokeWidth: 0.5,
        stroke: '#000',
        fill: 'transparent',
        strokeDasharray: '40 15'
      })
    ]);
  };

  var slideArrow = ({hingeSideIsLeft}) => {
    var symbolWidth = 300;
    var symbolDistanceFromHingeSide = !hingeSideIsLeft ? slabWidth * 0.15 : slabWidth * 0.85;
    var symbolDistanceFromTop = slabHeight * 0.6;

    var arrowWidth = 40;
    var arrowHeight = 14;

    return group({}, [
      path({
        d: !hingeSideIsLeft ? ([
          {command: 'M', x: symbolDistanceFromHingeSide, y: symbolDistanceFromTop},
          {command: 'L', x: symbolDistanceFromHingeSide + symbolWidth, y: symbolDistanceFromTop},
          {command: 'L', x: symbolDistanceFromHingeSide + symbolWidth - arrowWidth, y: symbolDistanceFromTop + (arrowHeight / 2)},
          {command: 'L', x: symbolDistanceFromHingeSide + symbolWidth - arrowWidth, y: symbolDistanceFromTop - (arrowHeight / 2)},
          {command: 'L', x: symbolDistanceFromHingeSide + symbolWidth, y: symbolDistanceFromTop},
        ]) : ([
          {command: 'M', x: symbolDistanceFromHingeSide, y: symbolDistanceFromTop},
          {command: 'L', x: symbolDistanceFromHingeSide - symbolWidth, y: symbolDistanceFromTop},
          {command: 'L', x: symbolDistanceFromHingeSide - symbolWidth + arrowWidth, y: symbolDistanceFromTop - (arrowHeight / 2)},
          {command: 'L', x: symbolDistanceFromHingeSide - symbolWidth + arrowWidth, y: symbolDistanceFromTop + (arrowHeight / 2)},
          {command: 'L', x: symbolDistanceFromHingeSide - symbolWidth, y: symbolDistanceFromTop},
        ]),
        strokeWidth: 0.5,
        stroke: '#000',
        fill: '#000'
      })
    ]);
  };

  var annotationSymbol = () => {
    var lineDrop = 5 * scale;
    var lineOverhang = 3 * scale;

    return group({y: slabHeight / 4, x: slabWidth / 2}, [
      path({
        d: [
          {command: 'M', x: (-slabWidth / 2) - lineOverhang, y: 0 + lineDrop},
          {command: 'L', x: (-slabWidth / 2) - lineOverhang, y: 0},
          {command: 'L', x: (slabWidth / 2) + lineOverhang, y: 0},
          {command: 'L', x: (slabWidth / 2) + lineOverhang, y: 0 + lineDrop},
        ],
        strokeWidth: 0.5,
        stroke: '#888',
        fill: 'transparent',
      }),
      circle({radius: 4 * scale, fill: '#888'}),
      text({fontSize: 4, x: 0, y: 0, radius: 8 * scale, fill: 'white', align: 'center', alignVertical: 'center'}, annotation)
    ]);
  };

  var scale = 24.5;

  return group({width: slabWidth, height: slabHeight, padding: 30}, [
    rect({width: slabWidth, height: slabHeight, strokeWidth: 1, stroke: '#000', rx: 0, ry: 0, fill: '#FFF'}),
    ...lamellaLinesGraphic,
    doorActionLine({doorActionKey, hingeSideIsLeft}),
    grainDirectionSymbol({grainDirectionIsVertical, boundingBox: {width: slabWidth, height: slabHeight}}, {group, path}), //HINT should come last so it is drawn on top
    ...(hasHinges ? (
      hasPivotHinges ? (
        [group({x: (hingeSideIsLeft ? 6 * scale : (slabWidth - 6 * scale))}, [
          rect({x: -1.5 * scale, y: -0.75 * scale, height: 1.5 * scale, width: 3 * scale, stroke: '#000', strokeWidth: 0.5}),
          rect({x: -1.5 * scale, y: -0.75 * scale + slabHeight, height: 1.5 * scale, width: 3 * scale, stroke: '#000', strokeWidth: 0.5}),
        ])]
      ) : (
        _.times(hingeQuantity, hingeIndex => rect({
          x: (hingeSideIsLeft ? 0 : slabWidth) + -0.75 * scale,
          y: 6 * scale + hingeIndex * (slabHeight - 4 * scale - 6 * scale - 10 * scale) / (hingeQuantity - 1),
          width: 1.5 * scale,
          height: 4 * scale,
          stroke: '#000',
          strokeWidth: 0.5
        }))
      )
    ) : []),
    ...handleGraphic({hasHandle, hasLock, doorActionKey, hingeSideIsLeft}),
    ...(annotation && [annotationSymbol()]),
    group({y: slabHeight + 6 * scale}, [
      unitNumberSymbol({scale, group, circle, text, unitNumber}),
      text({fontSize: 7, x: 0 * scale, y: 0, width: 75 * scale, fill: '#999'},
        `Lamella Width: ${lamellaWidthInfoString}
        Veneer profile: ${veneerProfileOption.title}
        Thick: ${lib.number.toFraction(lib.number.round(slabThickness / 25.4, {toNearest: 1 / 32}), {normalscript: true, delimiter: '-'})}” [${slabThickness}MM]${productInstance.quantity > 1 ? `\nQty: ${productInstance.quantity}` : ''}${hardware ? `\nHardware: ${hardware}` : ''}${notes ? `\nNotes: ${notes}` : ''}`
      ),
    ])
  ]);
}
