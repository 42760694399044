import { PureComponent } from 'react';
import NumberInput from './number-input';

import K from '~/k';
import _ from 'lodash';

class DimensionInput extends PureComponent {
  state = {

  };

  handleDimensionChange = ({value}) => {
    var {maxDimension, minDimension, dimensionInterval} = this.props;
    var roundValue = value;

    roundValue = Math.round(roundValue / dimensionInterval) * dimensionInterval;

    if (roundValue > maxDimension) roundValue = maxDimension;
    if (value < minDimension) roundValue = minDimension;

    this.setState({displayValue: roundValue});

    value = roundValue;

    this.props.onChange({value});
  };

  render() {
    return (
      <NumberInput
        autoWidth
        value={this.props.value}
        maxLength={40}
        onChange={({value}) => this.handleDimensionChange({value})}
        placeholder={this.props.placeholder}
        style={{backgroundColor: 'transparent', textAlign: 'right', height: 'auto', paddingVertical: 0, paddingHorizontal: 0, borderRadius: 0}}
      />
    );
  }
}

export default DimensionInput;
