import getKeypadGraphicNodes from '~/product-graphic-scripts/meljac/meljac-keypad-script';
import getDecoraGraphicNodes from '~/product-graphic-scripts/meljac/meljac-decora-script';
import getFloorOutletGraphicNodes from '~/product-graphic-scripts/meljac/meljac-floor-outlet-script';
import getWallOutletGraphicNodes from '~/product-graphic-scripts/meljac/meljac-wall-outlet-script';
import getLampGraphicNodes from '~/product-graphic-scripts/meljac/meljac-lamp-script';

import getFlooringGraphicNodes from '~/product-graphic-scripts/madera/madera-flooring-script';
import getSlabDoorGraphicNodes from '~/product-graphic-scripts/madera/madera-slab-door-script';
import getCabinetFrontGraphicNodes from '~/product-graphic-scripts/madera/madera-cabinet-front-script';

import getDemoLightGraphicNodes from '~/product-graphic-scripts/demo/demo-light-script';
import getDemoDoorGraphicNodes from '~/product-graphic-scripts/demo/demo-door-script';

function productGraphicScriptFor({productId}) {
  if (_.includes([8, 15, 9, 87], productId)) return getLampGraphicNodes;
  if (_.includes([1, 2, 11, 5, 10], productId)) return getKeypadGraphicNodes;
  if (_.includes([72, 89, 98], productId)) return getDecoraGraphicNodes;
  if (productId === 7) return getFloorOutletGraphicNodes;
  if (productId === 6) return getWallOutletGraphicNodes;

  //madera
  if (_.includes([86, 77, 88, 90], productId)) return getFlooringGraphicNodes;
  if (productId === 104) return getSlabDoorGraphicNodes;
  if (productId === 105) return getCabinetFrontGraphicNodes;

  //demo
  if (productId === 73) return getDemoLightGraphicNodes;
  if (productId === 75) return getDemoDoorGraphicNodes;
}

export { productGraphicScriptFor };
