import Papa from 'papaparse';
import K from '~/k';

const downloadCsv = async ({csv, filename}) => {
  var csv = Papa.unparse(csv);

  if (K.isWeb) {
    filename = `${filename}.csv`;

    var blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });

    if (navigator.msSaveBlob) { // IE 10+
      navigator.msSaveBlob(blob, filename);
    }
    else {
      var link = document.createElement('a');

      if (link.download !== undefined) { // feature detection
        // Browsers that support HTML5 download attribute
        var url = URL.createObjectURL(blob);
        link.setAttribute('href', url);
        link.setAttribute('download', filename);
        link.style.visibility = 'hidden';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      }
    }
  }
  else {
    var newFileLocation = `${FileSystem.cacheDirectory}${filename}.csv`;

    await FileSystem.writeAsStringAsync(newFileLocation, csv);

    this.share({url: newFileLocation, message: 'Share CSV'});
  }
};

export { downloadCsv };