import lib from '~/lib';

var getMuralMaskingPolygons = ({calicoMuralPanels}) => {
  var muralMaskingPolygons = _.map(calicoMuralPanels, ({data: {position: {x, y}, size: {width, height}, bleedLeft = 1, bleedRight = 1, bleedTop = 1, bleedBottom = 1}}) => {
    x -= bleedLeft;
    y -= bleedTop;
    width += bleedLeft + bleedRight;
    height += bleedTop + bleedBottom;

    return [
      {x: x, y: y},
      {x: x + width, y: y},
      {x: x + width, y: y + height},
      {x: x, y: y + height},
      {x: x, y: y}
    ];
  });

  // var done = false;

  // while (!done) {
  //   var mergeablePolygons = null;

  //   _.find(muralMaskingPolygons, (p1) => {
  //     var p2 = _.find(muralMaskingPolygons, p2 => {
  //       return p1 !== p2 && lib.polygon.polygonsOverlap(p1, p2, true);
  //     });

  //     if (p2) {
  //       mergeablePolygons = [p1, p2];

  //       return true;
  //     }
  //   });

  //   if (false && mergeablePolygons) {
  //     var mergedPolygon = lib.polygon.union({p1: mergeablePolygons[0], p2: mergeablePolygons[1]});

  //     muralMaskingPolygons = _.filter(muralMaskingPolygons, polygon => !_.includes(mergeablePolygons, polygon));

  //     muralMaskingPolygons.push(mergedPolygon);
  //   }
  //   else {
  //     done = true;
  //   }
  // }
  // console.log(muralMaskingPolygons)

  return muralMaskingPolygons;
};

var getCalicoMuralTextureOption = ({productOptionsById, calicoMuralTexture}) => {
  var {muralProductOptionId} = calicoMuralTexture.data;

  return productOptionsById[muralProductOptionId] || productOptionsById[990];
};

var getCalicoMuralTextureImageUrl = ({productOptionsById, calicoMuralTexture}) => {
  var option = getCalicoMuralTextureOption({productOptionsById, calicoMuralTexture});

  return option.thumbnailUrl;
};

var getCalicoMuralTextureTransformProps = ({calicoMuralTexture, scale = 1}) => {
  var {data} = calicoMuralTexture;

  var transformProps = {};

  if (data.flipX) transformProps = {...transformProps, scaleX: -1, offsetX: data.size.width * scale};
  if (data.flipY) transformProps = {...transformProps, scaleY: -1, offsetY: data.size.height * scale};
  if (data.yRotation) transformProps = {...transformProps, rotation: data.yRotation};

  return transformProps;
};

var getCalicoMuralSeamColor = ({thickness, allCalicoMuralSeamsInOrder}) => {
  var colors = ['#889CDB', '#C673B1', 'green', 'orange'];
  var color;

  if (thickness === 1) {
    color = '#F14D50';
  }
  else if (thickness === 2) {
    color = '#FCD145';
  }
  else {
    var thicknesses = _.pull(_.sortBy(_.uniq(_.map(allCalicoMuralSeamsInOrder, ({data}) => _.get(data, 'thickness', 1))), v => v), 1, 2);
    var thicknessIndex = _.indexOf(thicknesses, thickness);

    color = colors[thicknessIndex] || 'black';
  }

  return color;
};

var getCalicoMuralPanelPagesData = ({calicoMuralPanels, calicoWallLabels}) => {
  var calicoMuralPanelGroups = [{calicoMuralPanels}];
  var panelIndex = 0;

  calicoMuralPanels = _.reject(calicoMuralPanels, ['data.isDisabled', true]);

  if (calicoWallLabels.length) {
    var rectanglesOverlap = ({r1, r2}) => {
      var r1TopLeft = r1.position;
      var r1BottomRight = {x: r1.position.x + r1.size.width, y: r1.position.y + r1.size.height};

      var r2TopLeft = r2.position;
      var r2BottomRight = {x: r2.position.x + r2.size.width, y: r2.position.y + r2.size.height};

      return !(
        r2TopLeft.x >= r1BottomRight.x ||
        r2BottomRight.x <= r1TopLeft.x ||
        r2TopLeft.y >= r1BottomRight.y ||
        r2BottomRight.y <= r1TopLeft.y
      );
    };

    calicoMuralPanelGroups = _.chain(calicoMuralPanels)
      .groupBy(calicoMuralPanel => {
        var calicoWallLabel = _.find(calicoWallLabels, calicoWallLabel => {
          return rectanglesOverlap({
            r1: {position: calicoWallLabel.data.customViewPosition, size: calicoWallLabel.data.customViewSize},
            r2: calicoMuralPanel.data
          });
        });

        return _.get(calicoWallLabel, 'id', -1);
      })
      .map((calicoMuralPanels, calicoWallLabelId) => {
        return {calicoMuralPanels, calicoWallLabel: _.find(calicoWallLabels, ({id}) => id == calicoWallLabelId)};
      })
      .values()
      .sortBy(({calicoWallLabel}) => _.get(calicoWallLabel, 'data.customViewPosition.x', 10000000))
      .value();
  }

  var calicoMuralPanelPagesData = [];
  var xOffset = 0;

  _.forEach(calicoMuralPanelGroups, calicoMuralPanelGroup => {
    var calicoMuralPanelsData = _.map(calicoMuralPanelGroup.calicoMuralPanels, (calicoMuralPanel) => {
      var position = {...calicoMuralPanel.data.position};

      return {calicoMuralPanel, position};
    });

    calicoMuralPanelsData = _.sortBy(calicoMuralPanelsData, ['position.x', ({position}) => -position.y]); //TODO improve algorithm

    _.forEach(calicoMuralPanelsData, data => {
      data.panelIndex = panelIndex;

      panelIndex += 1;
    });

    calicoMuralPanelsData = _.sortBy(calicoMuralPanelsData, 'position.x');

    var minPanelWidth = 90;

    var uniq = (calicoMuralPanelsData, axisKey) => {
      var filteredData = [];
      var sizeKey = axisKey === 'x' ? 'width' : 'height';

      _.forEach(calicoMuralPanelsData, data => {
        var {calicoMuralPanel: p1} = data;

        var isUniq = !_.some(filteredData, ({calicoMuralPanel: p2}) => {
          return p1.data.position[axisKey] === p2.data.position[axisKey] && p1.data.size[sizeKey] === p2.data.size[sizeKey];
        });

        if (isUniq) {
          filteredData.push(data);
        }
      });

      return filteredData;
    };

    _.forEach(calicoMuralPanelsData, d1 => {
      var delta = 0;

      _.forEach(uniq(calicoMuralPanelsData, 'x'), d2 => {
        if (d1 !== d2 && d2.calicoMuralPanel.data.size.width < minPanelWidth && d2.calicoMuralPanel.data.position.x + d2.calicoMuralPanel.data.size.width <= d1.calicoMuralPanel.data.position.x) {
          delta += minPanelWidth - d2.calicoMuralPanel.data.size.width;
        }
      });

      d1.position = lib.object.sum(d1.position, {x: delta});
    });

    _.forEach(calicoMuralPanelsData, (data) => {
      var {calicoMuralPanel: p1, position} = data;

      var getRangesForPanels = (p1, p2, axisKey) => {
        var sizeKey = axisKey === 'x' ? 'width' : 'height';
        var pos1 = Math.round(p1.data.position[axisKey] * 10000) / 10000;
        var pos2 = Math.round(p2.data.position[axisKey] * 10000) / 10000;
        var size1 = Math.round(p1.data.size[sizeKey] * 10000) / 10000;
        var size2 = Math.round(p2.data.size[sizeKey] * 10000) / 10000;

        var a = {from: pos1, to: pos1 + size1}, b = {from: pos2, to: pos2 + size2};

        return {a, b};
      };

      var getIsOverlapping = (p1, p2, axisKey) => {
        var {a, b} = getRangesForPanels(p1, p2, axisKey);

        return (a.from < b.to && a.to > b.from) || (a.to === b.to && a.from === b.from);
      };

      //TODO can't just compare 1:1 - needs to be chained like HB container groups
      var getIsNearby = (p1, p2, axisKey) => {
        var {a, b} = getRangesForPanels(p1, p2, axisKey);

        return Math.min(Math.abs(a.from - b.to), Math.abs(b.from - a.to)) < 20;
      };

      //WARNING important that x and y are flipped here when calling getIsOverlapping
      var overlappingPanelsDataByAxisKey = {
        x: uniq(_.sortBy(_.filter(calicoMuralPanelsData, ({calicoMuralPanel: p2}) => getIsOverlapping(p1, p2, 'y')), 'calicoMuralPanel.data.position.x'), 'x'),
        y: uniq(_.sortBy(_.filter(calicoMuralPanelsData, ({calicoMuralPanel: p2}) => getIsOverlapping(p1, p2, 'x')), 'calicoMuralPanel.data.position.y'), 'y')
      };

      _.forEach(overlappingPanelsDataByAxisKey, (overlappingPanelsData, axisKey) => {
        if (axisKey === 'y') overlappingPanelsData = _.filter(overlappingPanelsData, ({calicoMuralPanel: p2}) => getIsNearby(p1, p2, 'y'));

        position[axisKey] += (_.indexOf(_.map(overlappingPanelsData, 'calicoMuralPanel'), p1) - overlappingPanelsData.length / 2) * 20;
      });

      data.flip = _.indexOf(_.reverse(_.map(overlappingPanelsDataByAxisKey.y, 'calicoMuralPanel')), p1) >= overlappingPanelsDataByAxisKey.y.length / 2;
    });

    calicoMuralPanelsData = _.map(calicoMuralPanelsData, ({calicoMuralPanel, ...data}) => {
      calicoMuralPanel = {...calicoMuralPanel, data: {
        ...calicoMuralPanel.data,
        originalSize: calicoMuralPanel.data.size,
        size: {
          width: calicoMuralPanel.data.size.width + _.get(calicoMuralPanel.data, 'bleedLeft', 1) + _.get(calicoMuralPanel.data, 'bleedRight', 1),
          height: calicoMuralPanel.data.size.height + _.get(calicoMuralPanel.data, 'bleedTop', 1) + _.get(calicoMuralPanel.data, 'bleedBottom', 1)
        }
      }};

      return {...data, noOffsetPosition: {...data.position}, calicoMuralPanel};
    });

    calicoMuralPanelGroup = {
      ...calicoMuralPanelGroup,
      calicoMuralPanelsData,
      originalWidth: _.max(_.map(calicoMuralPanelsData, ({calicoMuralPanel}) => calicoMuralPanel.data.position.x + calicoMuralPanel.data.size.width)) - _.min(_.map(calicoMuralPanelsData, 'calicoMuralPanel.data.position.x')),
      netWidth: _.max(_.map(calicoMuralPanelsData, ({calicoMuralPanel, position}) => position.x + + Math.max(minPanelWidth, calicoMuralPanel.data.size.width))) - _.min(_.map(calicoMuralPanelsData, 'position.x'))
    };

    var hasEnoughRoom = () => {
      var widths = [..._.map(_.last(calicoMuralPanelPagesData).calicoMuralPanelGroups, 'netWidth'), calicoMuralPanelGroup.netWidth];
      var netWidth = _.sum(widths) + (widths.length - 1) * 50;

      return netWidth < 1400;
    };

    var fitsOnExistingPage = calicoMuralPanelPagesData.length > 0 && hasEnoughRoom();

    //<offset
    var {originalWidth, netWidth} = calicoMuralPanelGroup;
    var minOriginalX = _.min(_.map(calicoMuralPanelsData, 'calicoMuralPanel.data.position.x'));
    var minX = _.min(_.map(calicoMuralPanelsData, 'noOffsetPosition.x'));

    if (!fitsOnExistingPage) xOffset = 0;

    _.forEach(calicoMuralPanelGroup.calicoMuralPanelsData, data => data.position.x += (xOffset + (minOriginalX - minX)));

    xOffset += 50 + (netWidth - originalWidth);
    //offset>

    if (fitsOnExistingPage) {
      _.last(calicoMuralPanelPagesData).calicoMuralPanelGroups.push(calicoMuralPanelGroup);
    }
    else {
      calicoMuralPanelPagesData.push({calicoMuralPanelGroups: [calicoMuralPanelGroup]});
    }
  });

  return calicoMuralPanelPagesData;
};

export { getMuralMaskingPolygons, getCalicoMuralTextureImageUrl, getCalicoMuralTextureOption, getCalicoMuralTextureTransformProps, getCalicoMuralSeamColor, getCalicoMuralPanelPagesData };
