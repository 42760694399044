import { useState, useRef, useEffect, useContext } from 'react';
import { View, TouchableOpacity, Text, Image } from 'react-native';
import { TextInput, Label, Popup } from '@symbolic/rn-lib';
import { toXYLine, toXYPoint, ArchElevation } from '~/helpers/arch/arch-helpers';
import { getMuralMaskingPolygons } from '~/helpers/custom/calico/custom-calico-helpers';
import { getCalicoMuralPanelPagesData } from '~/helpers/custom/calico/custom-calico-helpers';
import { toCanvas } from '~/helpers/canvas/canvas-helpers';
import { getDimensionLines, getCenteredCanvasContentOffset } from '~/helpers/canvas/canvas-helpers';
import getProductSpecString from '~/helpers/get-product-spec-string';
import { api } from '@symbolic/lib';
import { Circle } from 'react-konva';

import _ from 'lodash';
import K from '~/k';
import lib from '~/lib';

import CanvasArchWall from '~/components/arch/wall/arch-wall';
import ArchDataContext from '~/contexts/arch-data-context';
import CanvasDataContext from '~/contexts/canvas-data-context';

import CanvasView from '~/components/canvas/view/canvas-view';
import CanvasRect from '~/components/canvas/rect/canvas-rect';
import CanvasLine from '~/components/canvas/line/canvas-line';

import CanvasDimensionGroup from '~/components/canvas/dimensions/canvas-dimension-group';
import CalicoCanvasMuralArea from '~/components/custom/calico/calico-canvas-mural-area';
import CalicoCanvasMuralTexture from '~/components/custom/calico/calico-canvas-mural-texture';
import CalicoCanvasMuralPanel from '~/components/custom/calico/calico-canvas-mural-panel';
import CalicoCanvasMuralSeam from '~/components/custom/calico/calico-canvas-mural-seam';
import CalicoCanvasMismatchLine from '~/components/custom/calico/calico-canvas-mismatch-line';
import CalicoCanvasPanelLayoutView from '~/components/custom/calico/calico-canvas-panel-layout-view.js';
import CalicoWallLabel from '~/components/custom/calico/calico-wall-label';
import ProductCanvasText from '~/components/canvas/product/product-canvas-text';
import ProductCanvasLine from '~/components/canvas/product/product-canvas-line';
import VisibilityLayersHudElement from '~/components/custom/calico/hud-elements/visibility-layers-hud-element';
import SpatialCanvasProductInstance from '~/components/canvas/spatial-canvas-product-instance';
import upArrowIcon from '~/assets/up-arrow-black.png';
import getSizeForProductInstance from '~/helpers/product/get-size-for-product-instance';
import useKeyDown from '~/helpers/useKeyDown';
import useKeyUp from '~/helpers/useKeyUp';

//TODO filter out shapes not in camera footprint

var visibilityLayersDefaults = {calicoMuralTextures: true, calicoMuralSeams: true, calicoMuralPanels: true, dimensions: true};

export default function SpatialCanvas({
  scale,
  canvasSize,
  views,
  activeView,
  activeViewIndex,
  setArchCustomViewDrawingNotesData,
  setActiveViewIndex,
  setActiveProductInstanceId,
  archWalls,
  layerRef,
  forceUpdateKey,
  productCanvasTexts,
  productCanvasLines,
  calicoMuralAreas,
  calicoMuralTextures,
  productOptionsById,
  calicoMuralPanels,
  calicoMuralSeams,
  calicoMismatchLines,
  calicoWallLabels,
  updateTentativeArchWall,
  updateTentativeArchElevation,
  updateTentativeCalicoMuralSeam,
  updateTentativeCalicoMismatchLine,
  updateTentativeProductCanvasLine,
  isInContextMode,
  selectedEntityId,
  selectedEntityResourceKey,
  tentativeArchElevation,
  tentativeArchWall,
  tentativeCalicoMuralSeam,
  tentativeCalicoMismatchLine,
  session,
  productInstances,
  trackEntities,
  trackProductInstances,
  productPropertiesById,
  productRulesById,
  updateProductInstance,
  ...props
}) {
  var [visibilityLayers, setVisibilityLayers] = useState(visibilityLayersDefaults);
  var [sessionStoreLoaded, setSessionStoreLoaded] = useState(false);
  var [archCustomViewNotesPopupIsVisible, setArchCustomViewNotesPopupIsVisible] = useState(false);
  var offsetRef = useRef();
  var clipboardData = useRef({});
  var activeEntity, activeProductInstance;

  var viewportSize = session.activeOrg.id === 1798 ? {width: 1006, height: 564} : {width: 1555, height: 834};
  var drawingsScale = session.activeOrg.id === 1798 ? (activeView.viewKey === 'custom' ? 2 : 1) : 0.08;

  useEffect(() => {
    (async () => {
      if (!sessionStoreLoaded) {
        setVisibilityLayers(_.defaults(await sessionStore.get('visibilityLayers'), visibilityLayersDefaults));
        setSessionStoreLoaded(true);
      }
      else {
        await sessionStore.set('visibilityLayers', visibilityLayers);
      }
    })();
  });

  const updateVisibilityLayers = ({key, isVisible}) => setVisibilityLayers({...visibilityLayers, [key]: isVisible});

  function updateOffset() {
    offsetRef.current = (offsetRef.current || 0) + 1;
  }

  if (!offsetRef.current) updateOffset();

  if (isInContextMode) {
    archWalls = _.map(archWalls, archWall => ({...archWall, thickness: 4}));
  }

  var setSelectedEntity = (id, resourceKey) => {
    if (!(tentativeArchElevation || tentativeArchWall || tentativeCalicoMuralSeam || tentativeCalicoMismatchLine)) {
      props.setSelectedEntity(id, resourceKey);
    }
  };

  var archData = {activeView, selectedEntityId, selectedEntityResourceKey, setSelectedEntity, updateTentativeArchWall, updateTentativeArchElevation, updateTentativeCalicoMuralSeam, updateTentativeCalicoMismatchLine, updateTentativeProductCanvasLine, setActiveProductInstanceId};

  if (activeView.archElevation) {
    archWalls = ArchElevation.getVisibleArchWalls({archWalls, archElevation: activeView.archElevation});

    //TODO filter walls by whether they're in footprint or not
    //TODO don't want to filter out end of peninsula walls - i.e. a T situation
    //TODO sort archWalls by distance from elevation line - footprint distance from line
  }

  if (activeView.viewKey === 'custom') {
    var visibleCalicoMuralAreas = _.filter(calicoMuralAreas, ['data.archCustomViewId', activeView.archCustomView.id]);
    var visibleCalicoMuralPanels = _.filter(calicoMuralPanels, ['data.archCustomViewId', activeView.archCustomView.id]);
    var visibleProductInstances = _.filter(productInstances, ['archCustomViewId', activeView.archCustomView.id]);
    var visibleCalicoMuralTextures = _.filter(calicoMuralTextures, ['data.archCustomViewId', activeView.archCustomView.id]);

    var muralMaskingPolygons = getMuralMaskingPolygons({calicoMuralPanels: visibleCalicoMuralPanels});
  }

  if (activeView.viewKey === 'custom') {
    if (selectedEntityId && _.includes(['calicoMuralArea', 'calicoMuralPanel', 'calicoMuralTexture', 'calicoMuralSeam', 'calicoWallLabel'], selectedEntityResourceKey)) {
      activeEntity = _.find(props.entities, {id: selectedEntityId});
    }
    else if (selectedEntityId && _.includes(['productInstance'], selectedEntityResourceKey)) {
      activeProductInstance = _.find(productInstances, {id: selectedEntityId});
    }
    else {
      activeEntity = tentativeCalicoMuralSeam || tentativeCalicoMismatchLine;
    }
  }

  var getSnapData = () => {
    var candidateSnapPositions = [], sourceSnapPositions = [];

    if (activeView.viewKey === 'top') {
      if (_.includes(['archWall', 'calicoWallLabel'], selectedEntityResourceKey) || tentativeArchWall) {
        var activeArchWall = tentativeArchWall || _.find(archWalls, {id: selectedEntityId});

        candidateSnapPositions = _.map(_.flatMap(_.reject(archWalls, archWall => archWall === activeArchWall || !archWall.to), ({from, to}) => [from, to]), toXYPoint);
      }
    }

    if (activeView.viewKey === 'custom') {
      if (activeEntity || activeProductInstance) {
        _.forEach(activeProductInstance ? visibleProductInstances : [...(activeEntity.type === 'calicoMuralPanel' ? visibleCalicoMuralPanels : _.map(visibleCalicoMuralPanels, calicoMuralPanel => {
          var {data: {position: {x, y}, size: {width, height}, ...restData}} = calicoMuralPanel;

          x -= _.get(restData, 'bleedLeft', 1);
          y -= _.get(restData, 'bleedTop', 1);
          width += _.get(restData, 'bleedLeft', 1) + _.get(restData, 'bleedRight', 1);
          height += _.get(restData, 'bleedTop', 1) + _.get(restData, 'bleedBottom', 1);

          return {...calicoMuralPanel, data: {position: {x, y}, size: {width, height}, ...restData}};
        })), ...visibleCalicoMuralAreas], entity => {
          if (activeProductInstance) {
            var {x, y} = entity.properties.position;
            var {width, height} = getSizeForProductInstance({productInstance: entity});
          }
          else {
            var {data: {position: {x, y}, size: {width, height}}} = entity;
          }

          if (entity !== activeEntity && entity !== activeProductInstance) {
            candidateSnapPositions.push(
              {x: x, y: y},
              {x: x + width, y: y},
              {x: x + width, y: y + height},
              {x: x, y: y + height},
              {x: x + width / 2, y: y + height / 2}
            );
          }
        });

        if (activeProductInstance) {
          var {width, height} = getSizeForProductInstance({productInstance: activeProductInstance});
        }
        else {
          var {width, height} = _.defaults(_.get(activeEntity, 'data.size') ?? _.get(activeEntity, 'data.customViewSize'), {width: 0, height: 0});
        }

        var gridData = getGridData({session, viewportSize});

        if (gridData.enabled === true) { //HINT add snap points for grid
          var scaledOffset = {
            x: -(viewportSize.width / 2) / drawingsScale,
            y: -(viewportSize.height / 2) / drawingsScale
          };

          _.times((gridData.lineQty.vertical + 1), (n) => {
            var x = (n * gridData.gridSize) / drawingsScale;

            _.times((gridData.lineQty.horizontal + 1), (n) => {
              var y = (n * gridData.gridSize) / drawingsScale;

              candidateSnapPositions.push(lib.object.sum(scaledOffset, {x, y}));
            });
          });
        }

        sourceSnapPositions = [
          {x: 0, y: 0},
          {x: width, y: 0},
          {x: width, y: height},
          {x: width / 2, y: height / 2},
          {x: 0, y: height},
        ];
      }
    }

    var candidateSnapAngles = [0, 90, 180, 270];

    return {candidateSnapPositions, sourceSnapPositions, candidateSnapAngles};
  };

  var activeEntityWasPastedRef = useRef();

  useKeyDown(async event => {
    if (lib.event.keyPressed(event, 'ctrlcmd') && !activeEntityWasPastedRef.current) {
      if (event.key === 'c') {
        clipboardData.current = {activeEntity, activeProductInstance};

        event.preventDefault();
      }
      else if (event.key === 'v') {
        if (clipboardData.current.activeEntity) {
          var entity = await api.create('entity', {
            ..._.omit(clipboardData.current.activeEntity, ['id', 'created', 'lastUpdated', 'deleted', 'lastUpdatedBy', 'data']),
            data: {
              ...clipboardData.current.activeEntity.data,
              customViewPosition: { //HINT offset down and right 10in
                x: clipboardData.current.activeEntity.data.customViewPosition.x + 10,
                y: clipboardData.current.activeEntity.data.customViewPosition.y + 10
              }
            }
          });

          trackEntities({entities: [entity]});

          setTimeout(() => setSelectedEntity(entity.id, entity.type));
        }
        else if (clipboardData.current.activeProductInstance) {
          var activeCustomViewId = _.sortBy(props.archCustomViews, 'rank')[activeViewIndex].id;

          var productInstance = await api.create('productInstance', {
            ..._.omit(clipboardData.current.activeProductInstance, ['id', 'created', 'lastUpdated', 'deleted', 'lastUpdatedBy', 'properties', 'archCustomViewId']),
            archCustomViewId: activeCustomViewId,
            properties: {
              ...clipboardData.current.activeProductInstance.properties,
              position: { //HINT offset down and right 250mm
                x: clipboardData.current.activeProductInstance.properties.position.x + 250,
                y: clipboardData.current.activeProductInstance.properties.position.y + 250
              }
            }
          });

          trackProductInstances({productInstances: [productInstance]});

          setTimeout(() => setSelectedEntity(productInstance.id, 'productInstance'));
        }

        event.preventDefault();

        activeEntityWasPastedRef.current = true;
      }
    }
  });

  useKeyUp(() => activeEntityWasPastedRef.current = false);

  if (!isInContextMode && visibilityLayers.dimensions) {
    var dimensionLines = [];
    var createdWalls = _.filter(archWalls, 'to');
    var roundPoint = (point) => ({x: Math.round(point.x / (1 / 64)) * (1 / 64), y: Math.round(point.y / (1 / 64)) * (1 / 64)});

    if (activeView.viewKey === 'custom') {
      dimensionLines = _.flatMap(visibilityLayers.calicoMuralPanels ? visibleCalicoMuralPanels : visibleCalicoMuralAreas, ({data: {position: {x, y}, size: {width, height}, ...restData}}) => {
        var lineProps = {};

        if (visibilityLayers.calicoMuralPanels) {
          lineProps = {isStandalone: true, useSquareTicks: true};
          x -= _.get(restData, 'bleedLeft', 1);
          y -= _.get(restData, 'bleedTop', 1);
          width += _.get(restData, 'bleedLeft', 1) + _.get(restData, 'bleedRight', 1);
          height += _.get(restData, 'bleedTop', 1) + _.get(restData, 'bleedBottom', 1);
        }

        return [
          {from: {x: x + width, y: height + y}, to: {x: x, y: height + y}, ...lineProps},
          {from: {x: x, y: height + y}, to: {x: x, y: y}, ...lineProps}
        ];
      });
    }
    else if (activeView.viewKey === 'top') {
      dimensionLines = _.map(createdWalls, toXYLine);

      if (tentativeArchWall && tentativeArchWall.addModePoint) {
        dimensionLines.push(toXYLine({from: tentativeArchWall.from, to: tentativeArchWall.addModePoint}));
      }
    }

    dimensionLines = _.map(dimensionLines, ({from, to, ...rest}) => ({from: roundPoint(from), to: roundPoint(to), ...rest}));
  }

  if (activeView.viewKey !== 'top') {
    calicoWallLabels = _.filter(calicoWallLabels, entity => entity.data.archCustomViewId === activeView.archCustomView.id);
    calicoMuralPanels = _.filter(calicoMuralPanels, entity => entity.data.archCustomViewId === activeView.archCustomView.id);
  }

  return (
    <View style={{flex: 1}}>
      <CanvasView
        precision={props.precision}
        setPrecision={props.setPrecision}
        scale={scale || 2}
        textDimsScale={session?.activeOrg.id === 1053 ? 15 : 0.75}
        getOffset={activeView.getOffset}
        updateOffsetId={offsetRef.current}
        cameraXZAngle={activeView.cameraXZAngle}
        cameraYAngle={activeView.cameraYAngle}
        onCanvasClick={() => setTimeout(() => {setSelectedEntity(undefined, undefined); setActiveProductInstanceId(undefined); }, 50)} //WARNING important for properties in properties view type text (i.e. wall label title)
        getSnapData={getSnapData}
        {...{canvasSize, layerRef, forceUpdateKey}}
        cursor={tentativeArchElevation || tentativeArchWall || tentativeCalicoMuralSeam ? 'none' : 'default'}
        dimensionOptions={session?.activeOrg.id === 1053 ? {
          enableSecondaryLabel: true,
          primaryLabel: {offsetDistance: -6, getLabel: (mm) => `${lib.number.toFraction(lib.number.round(mm / 25.4, {toNearest: 0.5}), {normalscript: true, delimiter: '-'})}"`},
          secondaryLabel: {enabled: true, offsetDistance: 7, getLabel: (mm) => `[${mm}MM]`},
          rotateText: true,
          mainLine: {opacity: 1},
          offsetDistance: 20
        } : {}}
        activeOrgId={session?.activeOrg.id}
      >
        <ArchDataContext.Provider value={archData}>
          {_.includes(['calicoPanelLayout', 'custom'], activeView.viewKey) && (
            <ViewportBoundingBox
              {...{session, viewportSize, drawingsScale}}
              defaultScale={scale || 2}
            />
          )}
          {_.includes(['custom'], activeView.viewKey) && (<>
            {_.flatMap([
              {Component: CalicoCanvasMuralArea, entities: calicoMuralAreas, key: 'calicoMuralArea'},
              {Component: CalicoCanvasMuralTexture, entities: calicoMuralTextures, key: 'calicoMuralTexture', visibilityLayerKey: 'calicoMuralTextures'},
              {Component: CalicoCanvasMuralPanel, entities: calicoMuralPanels, key: 'calicoMuralPanel', visibilityLayerKey: 'calicoMuralPanels'},
              {Component: CalicoCanvasMuralSeam, entities: calicoMuralSeams, key: 'calicoMuralSeam', visibilityLayerKey: 'calicoMuralPanels'},
              {Component: CalicoCanvasMismatchLine, entities: calicoMismatchLines, key: 'calicoMismatchLine'}
            ], ({entities, key, visibilityLayerKey, Component}) => _.map(entities, entity => {
              if (visibilityLayers[visibilityLayerKey] !== false && entity.data.archCustomViewId) {
                if (entity.data.archCustomViewId === activeView.archCustomView.id) {
                  var props = {...{[key]: entity, muralMaskingPolygons}};

                  if (key === 'calicoMuralPanel' && visibilityLayers.calicoMuralPanels) {
                    props.showBleed = true;
                  }

                  return <Component {...props}/>;
                }
              }
            }))}
            {_.map(productCanvasTexts, (productCanvasText) => productCanvasText.customViewId === activeView.archCustomView.id ? <ProductCanvasText productCanvasText={productCanvasText}/> : null)}
            {_.map(productCanvasLines, (productCanvasLine) => productCanvasLine.customViewId === activeView.archCustomView.id ? <ProductCanvasLine productCanvasLine={productCanvasLine}/> : null)}
            {_.map(productInstances, productInstance => productInstance.archCustomViewId === activeView.archCustomView.id && (
              <SpatialCanvasProductInstance {...{productInstance}} product={props.productsById[productInstance.productId]}/>
            ))}
            {_.map(productInstances, productInstance => productInstance.archCustomViewId === activeView.archCustomView.id && (
              <CanvasDimensionGroup
                lines={getDimensionLines({entities: [{data: {
                  position: productInstance.properties.position,
                  size: getSizeForProductInstance({productInstance})
                }}], sides: ['left', 'top']})}
              />
            ))}
          </>)}
          {activeView.viewKey === 'top' && (<>
            {_.map(archWalls, archWall => (
              <CanvasArchWall key={archWall.id} {...{archWall, archWalls}}/>
            ))}
          </>)}
          {_.includes(['custom', 'top'], activeView.viewKey) && (<>
            {_.map(calicoWallLabels, (calicoWallLabel) => (
              <CalicoWallLabel calicoWallLabel={calicoWallLabel} key={calicoWallLabel.id} shouldRenderRectTool={activeView.viewKey === 'custom'} shouldRenderPathTool={activeView.viewKey === 'top'}/>
            ))}
          </>)}
          {activeView.viewKey === 'calicoPanelLayout' && (
            <CalicoCanvasPanelLayoutView
              {...{calicoMuralPanels, calicoWallLabels, calicoMuralTextures, productOptionsById}}
            />
          )}
          {dimensionLines && (
            <CanvasDimensionGroup lines={dimensionLines}/>
          )}
          {/* HINT uncomment block below to show snap points */}
          {/* <CanvasDataContext.Consumer>
            {canvasData => (
              <>
                {_.map([{x: 0, y: 0}], (point, index) => <Circle key={`candidate-snap-point-${index}`} {...{...toCanvas(point, canvasData)}} fill='red' radius={2.5} />)}
                {_.map(getSnapData().candidateSnapPositions, (point, index) => <Circle key={`candidate-snap-point-${index}`} {...{...toCanvas(point, canvasData)}} fill='red' radius={2.5} />)}
              </>
            )}
          </CanvasDataContext.Consumer> */}
        </ArchDataContext.Provider>
      </CanvasView>
      {props.showControls && (<>
        {session?.activeOrg.id === 1798 && (
          <VisibilityLayersHudElement {...{visibilityLayers, updateVisibilityLayers}}/>
        )}
        <View style={{position: 'absolute', bottom: K.spacing * 2, width: '100%', alignItems: 'center'}}>
          {selectedEntityResourceKey === 'productInstance' && (
            <Text style={{marginBottom: K.margin, fontWeight: 'bold'}}>{props.productsById[_.find(productInstances, {id: selectedEntityId}).productId].title}</Text>
          )}
          <View style={{flexDirection: 'row', alignItems: 'flex-end', marginBottom: K.spacing}}>
            <TouchableOpacity onPress={() => {
              if (activeViewIndex > 0) {
                updateOffset();
                setActiveViewIndex(activeViewIndex - 1);
              }
            }}>
              <Label>Prev</Label>
            </TouchableOpacity>
            <Text style={{marginLeft: K.spacing * 2, marginRight: K.spacing * 2, position: 'relative', top: 2, ...K.fonts.pageHeader}}>
              {activeViewIndex + 1}. {activeView.title}
            </Text>
            <TouchableOpacity nativeID={'OrderPageNextViewButton'}onPress={() => {
              if (activeViewIndex < views.length - 1) {
                updateOffset();
                setActiveViewIndex(activeViewIndex + 1);
              }
            }}>
              <Label>Next</Label>
            </TouchableOpacity>
          </View>
          <View style={{flexDirection: 'row'}}>
            {activeView.viewKey === 'calicoPanelLayout' && (
              <TouchableOpacity style={{marginRight: K.spacing * 2}} onPress={() => {
                var calicoMuralPanelPagesData = getCalicoMuralPanelPagesData({calicoMuralPanels, calicoWallLabels, scale});
                var calicoMuralPanelGroups = _.flatMap(calicoMuralPanelPagesData, 'calicoMuralPanelGroups');
                var calicoMuralPanelData = _.flatMap(calicoMuralPanelGroups, 'calicoMuralPanelsData');

                var updates = _.map(calicoMuralPanelData, ({calicoMuralPanel, position, flip, panelIndex}) => {
                  return {where: {id: calicoMuralPanel.id}, props: {data: {
                    ..._.find(calicoMuralPanels, {id: calicoMuralPanel.id}).data, //WARNING intentionally using an unmutated mural panel (this one has been modified)
                    drawingsPanelLayoutPosition: position,
                    drawingsShowNotesAbovePanel: flip,
                    panelNumber: panelIndex + 1
                  }}};
                });

                var positions = [];

                positions.push(..._.flatMap(updates, ({props: {data}}) => {
                  var {x, y} = data.drawingsPanelLayoutPosition || {x: 0, y: 0}, {width, height} = data.size;

                  return [{x, y: y - (_.some(updates, 'props.data.drawingsShowNotesAbovePanel') ? 155 : 0)}, {x: x + Math.max(width, 90), y: y + height + 155}];
                }));

                var offset = getCenteredCanvasContentOffset({positions, invert: true, scale: 1});

                _.forEach(updates, update => update.props.data.drawingsPanelLayoutPosition = lib.object.difference(update.props.data.drawingsPanelLayoutPosition, offset));

                props.updateEntities({updates});
              }}>
                <Label>Autogenerate</Label>
              </TouchableOpacity>
            )}
            {activeView.archCustomView && (
              <div style={{display: 'flex', flexDirection: 'row', gap: K.spacing * 2}}>
                <div>
                  {activeProductInstance && (<>
                    <Text style={{...K.fonts.standard}}>QTY: </Text>
                    <TextInput
                      value={activeProductInstance.quantity}
                      onChange={({value}) => (value < 1 || isNaN(value)) ? updateProductInstance({id: activeProductInstance.id, props: {quantity: 1}}) : updateProductInstance({id: activeProductInstance.id, props: {quantity: parseInt(value)}})}
                      style={{backgroundColor: 'transparent', paddingHorizontal: 0, width: K.calc(40)}}
                      placeholder={'1'}
                      selectTextOnFocus
                    />
                  </>)}
                </div>
                <TouchableOpacity style={{flexDirection: 'row', alignItems: 'center'}} onPress={() => setArchCustomViewNotesPopupIsVisible(true)}>
                  <Label style={{...K.fonts.label}}>Notes</Label>
                  <Image source={upArrowIcon} style={{width: K.calc(12), height: K.calc(12), position: 'relative', top: -1, marginLeft: 5, transform: [{rotate: '90deg'}]}} />
                </TouchableOpacity>
              </div>
            )}
          </View>
          {activeView.archCustomView && archCustomViewNotesPopupIsVisible && (
            <Popup onClose={() => setArchCustomViewNotesPopupIsVisible(false)}>
              {_.map([
                {key: 'general', title: 'Notes'},
                {
                  key: 'productSpec',
                  title: 'Product Spec',
                  placeholder: getProductSpecString({
                    productInstance: _.first(_.sortBy(_.filter(productInstances, {archCustomViewId: activeView.archCustomView.id}), ['id'])),
                    activeOrgId: session?.activeOrg.id,
                    productsById: props.productsById,
                    productPropertiesById, productRulesById, productOptionsById
                  }),
                  orgIds: [1053]
                },
                {key: 'calicoMuralArea', title: 'Elevation Layout Notes', orgIds: [1798]},
                {key: 'calicoMuralTexture', title: 'Mural Rendering Notes', orgIds: [1798]},
                {key: 'calicoMuralPanel', title: 'Print Panel Layout Notes', orgIds: [1798]},
                {key: 'calicoMuralSeam', title: 'Installer Layout Notes', orgIds: [1798]},
                {key: 'criticalAlignmentPanel', orgIds: [1798]},
                {key: 'mismatch', orgIds: [1798]}
              ], (notesInput) => {
                if (notesInput.key === 'general' || _.includes(notesInput.orgIds, session?.activeOrg.id)) {
                  if (session?.activeOrg.id === 1798 && notesInput.key === 'general') notesInput.title = 'General Notes';

                  return (
                    <TextInput
                      key={notesInput.key}
                      grayLabelledView
                      multiline
                      standardAutoheightStyles
                      label={notesInput.title ? notesInput.title : `${_.startCase(notesInput.key)} Notes`}
                      labelledViewStyles={{outerView: {marginBottom: K.margin}}}
                      placeholder={notesInput.placeholder || ''}
                      value={_.get(activeView, `archCustomView.drawingNotesData[${notesInput.key}]`)}
                      onChange={({value}) => setArchCustomViewDrawingNotesData({archCustomViewId: activeView.archCustomView.id, drawingNotesData: {...activeView.archCustomView.drawingNotesData, [notesInput.key]: value}})}
                    />
                  );
                }
              })}
            </Popup>
          )}
        </View>
      </>)}
    </View>
  );
}

var getGridData = ({session, viewportSize}) => {
  var gridData = {enabled: false};

  if (session.activeOrg.id === 1053) {
    var gridSize = 50; //HINT 50mm

    var lineQty = {
      vertical: Math.floor(viewportSize.width / gridSize),
      horizontal: Math.floor(viewportSize.height / gridSize)
    };

    gridData = {enabled: true, gridSize, lineQty, stroke: 'black', strokeWidth: 0.25, opacity: 0.2};
  }

  return gridData;
};

var ViewportBoundingBox = ({session, viewportSize, drawingsScale}) => {
  var canvasData = useContext(CanvasDataContext);

  var width = viewportSize.width / drawingsScale;
  var height = viewportSize.height / drawingsScale;
  var position = toCanvas({x: -width / 2, y: -height / 2}, canvasData);

  var gridData = getGridData({session, viewportSize, drawingsScale, canvasData});

  var scaledWidth = width * canvasData.scale;
  var scaledHeight = height * canvasData.scale;
  var scaledGridSize = (gridData.gridSize / drawingsScale) * canvasData.scale;

  return (
    <>
      <CanvasRect
        {...position}
        width={scaledWidth}
        height={scaledHeight}
        stroke='black'
        opacity={0.2}
        strokeWidth={0.5}
        listening={false}
      />
      {gridData.enabled === true && _.times(gridData.lineQty.vertical, (index) => {
        return (
          <CanvasLine key={`vertical-grid-line-${index}`} x={position.x} y={position.y}
            from={{x: scaledGridSize * (index + 1), y: 0}}
            to={{x: scaledGridSize * (index + 1), y: scaledHeight}}
            {..._.pick(gridData, ['stroke', 'strokeWidth', 'opacity'])}
          />
        );
      })}
      {gridData.enabled === true && _.times(gridData.lineQty.horizontal, (index) => {
        return (
          <CanvasLine key={`horizontal-grid-line-${index}`} x={position.x} y={position.y}
            from={{x: 0, y: scaledGridSize * (index + 1)}}
            to={{x: scaledWidth, y: scaledGridSize * (index + 1)}}
            {..._.pick(gridData, ['stroke', 'strokeWidth', 'opacity'])}
          />
        );
      })}
    </>
  );
};
