import { reducerAndActionsFor } from '@symbolic/redux';

const {reducer, actions} = reducerAndActionsFor('activeView', {
  setActiveView: ({data = {}, getState}) => {
    return {data: {...getState().activeView.data, ...data}};
  }
});

const {setActiveView} = actions;

export default reducer;
export {setActiveView};