import _ from 'lodash';

export default function getDemoDoorGraphicNodes({deps, group, rect, circle, dimension}) {
  var {propertiesDataById} = deps.productInstance;

  var rectWidth = _.get(propertiesDataById, '96.selectedProductOption.data.width');
  var rectHeight = _.get(propertiesDataById, '97.selectedProductOption.data.height');
  var doorMaterialColor = _.get(propertiesDataById, '94.selectedProductOption.data.color');
  var hingeSideOption = _.get(propertiesDataById, '95.selectedProductOption');
  var hingeLeftOptionId = 562;
  var hingeIsLeft = hingeSideOption.id === hingeLeftOptionId;
  var handleDiameter = 1.5;

  return group({width: rectWidth, height: rectHeight, padding: 30}, [
    rect({height: rectHeight, width: rectWidth, strokeWidth: 1, stroke: '#000', rx: 0, ry: 0, fill: doorMaterialColor}),
    rect({stroke: '#000', strokeWidth: 1, width: rectWidth - 10, fill: 'transparent', x: 5, height: 40, y: 5}),
    rect({stroke: '#000', strokeWidth: 1, width: rectWidth - 10, fill: 'transparent', x: 5, y: (10 + 40), height: rectHeight - (10 + 40 + 5)}),
    rect({fill: '#B7A17D', strokeWidth: 1, stroke: '#000', width: 7, height: 1.25, x: hingeIsLeft ? (rectWidth - 9.5 + handleDiameter / 2) : (2.5 - handleDiameter / 2), y: rectHeight - 37}),
    circle({fill: '#B7A17D', strokeWidth: 1, stroke: '#000', r: handleDiameter / 2, cx: hingeIsLeft ? (rectWidth - 2.5) : (2.5), cy: rectHeight - 42}),
    ..._.times(3, h => {
      return rect({y: (rectHeight - 1.5) / 2 * h, x: hingeIsLeft ? 0 : rectWidth - 1, fill: '#000', width: 1, height: 1.5, strokeWidth: 0});
    }),
    dimension({
      props: {
        positions: [
          {x: 0, y: rectHeight},
          {x: 0, y: 0}
        ],
        extrudeDistance: 20
      }
    }),
    dimension({
      props: {
        positions: [
          {x: 0, y: 0},
          {x: rectWidth, y: 0}
        ],
        extrudeDistance: 20
      }
    }),
  ]);
}
