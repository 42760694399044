import React, { useContext } from 'react';

import K from '~/k';
import _ from 'lodash';
import lib from '~/lib';
import CanvasLine from '~/components/canvas/line/canvas-line';
import CanvasText from '~/components/canvas/text/canvas-text';
import Distance from './canvas-dimension-line-distance';
import CanvasDataContext from '~/contexts/canvas-data-context';

import { toCanvas } from '~/helpers/canvas/canvas-helpers';

class CanvasDimensionLine extends React.PureComponent {
  distancesFor({positions}) {
    var distances = [];

    if (positions.length > 1) {
      var {angle} = this;

      //normalize positions onto x-axis and use the left-most one
      var sourcePosition = _.minBy(positions, position => lib.trig.rotate({position, byDegrees: -angle}).x);

      //source line to base all distances on
      var line = lib.trig.extend({line: {
        from: sourcePosition,
        to: lib.trig.translate({
          point: sourcePosition,
          by: 10, //arbitrary value >= 1
          alpha: lib.trig.degreesToRadians(angle)
        })
      }});

      var processedDistances = lib.waterfall(positions, [
        //project points onto line
        [_.map, (position) => {
          var positionOnLine = lib.trig.nearestPoint({point: position, onLine: line});

          positionOnLine = this.roundPosition(positionOnLine);

          return {
            position, positionOnLine,
            total: lib.trig.distance({fromPoint: sourcePosition, toPoint: positionOnLine}),
            positions: [position],
            // id: position.props.id //TODO
          };
        }],

        //sort by distance
        [_.sortBy, 'total'],

        //round and normalize
        [_.map, (distance, d, distances) => {
          var delta = distance.total - _.get(distances[d - 1], 'total', 0);

          return {...distance, delta: lib.round(delta, {toNearest: K.minPrecision})};
        }]
      ]);

      var enabledDistances = _.reject(processedDistances, 'positionIsDisabled');

      //calculate deltas such that disabled points amount to merging two distances
      _.forEach(enabledDistances, (distance, d) => {
        var delta = distance.total - _.get(enabledDistances[d - 1], 'total', 0);

        distance.delta = lib.round(delta, {toNearest: K.minPrecision});
      });

      _.forEach(processedDistances, (distance, d) => {
        var prevDistance = _.last(distances);

        //HINT keep d = 0 and delta != 0, if not keeping, at least keep position
        if (d === 0 || distance.delta !== 0) {
          distances.push(distance);

          //HINT allow change handlers to verify the change happened to their line
          distance.matchesAbsoluteLine = ({from: f1, to: t1}) => {
            var f2s = prevDistance.positions;
            var t2s = distance.positions;

            //HINT try both directions
            return _.some([[f1, t1], [t1, f1]], ([f1, t1]) => {
              var fromMatch = _.some(f2s, f2 => _.isEqual(f1, f2));
              var toMatch = _.some(t2s, t2 => _.isEqual(t1, t2));

              return fromMatch && toMatch;
            });
          };
        }
        else {
          //WARNING commented out because requiring unique positions filters out onChange candidates
          // var {position} = distance;
          // var positions = prevDistance.positions;
          // var positionIsUnique = !_.some(positions, p2 => _.isEqual(p2.absolute, position.absolute));

          // if (positionIsUnique) positions.push(distance.position);

          prevDistance.positions.push(distance.position);
        }
      });
    }

    return distances;
  }

  transformPosition({position, positions}) {
    if (this.props.transformData) {
      var {angle} = this;
      var alpha = lib.trig.degreesToRadians(angle);
      var {fixedTransform = 0, pushOutside, perpendicularAlpha} = this.props.transformData;
      var magnitude = fixedTransform;

      if (pushOutside) {
        var p1 = position;

        var transformDistanceCandidates = lib.waterfall(positions, [
          //draw lines from origin to extended other lines
          [_.map, (position) => {
            var line = lib.trig.extendedLineFrom({point: position, alpha});

            return ({p1, p2: lib.trig.nearestPoint({point: p1, onLine: line})});
          }],

          //eliminate pairs that push inside
          [_.filter, ({p1, p2}) => {
            var a1 = lib.trig.normalize({radians: perpendicularAlpha});
            var a2 = lib.trig.normalize({radians: lib.trig.alpha({p1, p2})});

            return a1 === a2 || _.isEqual(p1, p2);
          }],

          //convert point pairs to distances
          [_.map, ({p1, p2}) => {
            return lib.trig.distance({fromPoint: p1, toPoint: p2});
          }]
        ]);

        magnitude += _.max(transformDistanceCandidates) || 0;
      }

      var transform = lib.trig.rotate({
        point: {x: magnitude, y: 0}, byRadians: (pushOutside ? 0 : Math.PI) + perpendicularAlpha
      });

      position = lib.object.sum(position, transform);
    }

    return position;
  }

  roundPosition(position) {
    return {x: lib.number.round(position.x, {toNearest: K.minPrecision}), y: lib.number.round(position.y, {toNearest: K.minPrecision})};
  }

  get angle() {
    var {angle} = this.props;

    angle = lib.trig.normalize({degrees: angle});

    return angle;
  }

  get scale() {
    return this.props.canvasData.scale;
  }

  render() {
    var {positions, canvasData} = this.props;
    var labelOffsetFactor = -1;
    var distances = this.distancesFor({positions});

    if (distances.length > 0) {
      var {scale, angle} = this;
      var position = distances[0].position;
      var fontSize = scale * 8;

      position = this.transformPosition({position, positions});

      var netDistance = _.sumBy(distances, 'delta');
      var origin = position;

      var canvasPositionFor = ({distance, perpendicularOffsetDistance}) => {
        var position = lib.trig.translate({point: origin, by: distance, alpha: lib.trig.degreesToRadians(angle)});

        if (perpendicularOffsetDistance) {
          position = lib.trig.translate({point: position, by: perpendicularOffsetDistance, alpha: lib.trig.degreesToRadians(angle - 90)});
        }

        return position;
      };

      var summarize = this.props.summarize && distances.length > 2;

      if (summarize) {
        var summaryLine = {
          from: toCanvas(canvasPositionFor({distance: 0, perpendicularOffsetDistance: 15}), canvasData),
          to: toCanvas(canvasPositionFor({distance: netDistance, perpendicularOffsetDistance: 15}), canvasData)
        };
      }

      var defaultOpacity = 0.3;

      //summary line
      var multipleDistancesVisible = distances.length > 2;

      var renderSummaryLines = summarize && multipleDistancesVisible;
    }

    return distances.length > 0 && (<>
      {renderSummaryLines && (<>
        <CanvasLine {...summaryLine} strokeWidth={0.5} opacity={defaultOpacity}/>
        <CanvasLine from={summaryLine.from} to={toCanvas(_.first(distances).position, canvasData)} strokeWidth={0.5} opacity={defaultOpacity}/>
        <CanvasLine from={summaryLine.to} to={toCanvas(_.last(distances).position, canvasData)} strokeWidth={0.5} opacity={defaultOpacity}/>
        <CanvasText
          text={lib.number.toFraction(Math.abs(netDistance), {normalscript: true})}
          fill={'black'}
          backgroundColor='rgba(255, 255, 255, 0.5)'
          align='center'
          alignVertical='center'
          fontSize={fontSize}
          onMouseDown={this.handleSummaryLabelMouseDown}
          {...lib.object.sum(toCanvas(canvasPositionFor({distance: netDistance / 2, perpendicularOffsetDistance: 15}), canvasData))}
        />
      </>)}
      {_.map(distances, (distance, d) => {
        var prevDistance = distances[d - 1];
        var labelY = 0;

        if (distance.delta > 0) {
          var absDelta = Math.abs(distance.delta);
          var isOnXAxis = angle % 180 === 0;
          var offsetThreshold = isOnXAxis && Math.round(absDelta) !== absDelta ? 12 : 4;
          var shouldOffsetLabel = absDelta < offsetThreshold && absDelta > 0;

          if (shouldOffsetLabel) {
            labelY = 18 * labelOffsetFactor;

            labelOffsetFactor *= -1;
          }
          else if (labelOffsetFactor === 1) {
            labelOffsetFactor = -1;
          }
        }

        var distanceComponent = (
          <Distance
            key={d}
            {...{
              labelY, d, distance, canvasPositionFor, angle, prevDistance,
              fontSize, defaultOpacity
            }}
            {..._.pick(this.props, ['canvasData', 'transformData'])}
          />
        );

        return distanceComponent;
      })}
    </>);
  }
}

function CanvasDimensionLineWithContext(props) {
  let canvasData = useContext(CanvasDataContext);

  return (
    <CanvasDimensionLine {...props} {...{canvasData}}/>
  );
}

export default CanvasDimensionLineWithContext;
