import { Component } from 'react';
import { Text, CopilotStepView } from '@symbolic/rn-lib';
import { View, TouchableOpacity, Image } from 'react-native';
import { connect } from '@symbolic/redux';
import { resourceActions } from '~/redux';

import ProductProperty from '~/components/product-property';
import HudElement from '~/components/hud-element';

import { sortedProductPropertiesForProductInstance, productOptionsForProperty, getProductInstanceIsInvalid } from '~/helpers/product-order-helper';
import getIsBacklitEngravingCompatible from '~/helpers/meljac/get-is-backlit-engraving-compatible';

import upArrowIcon from '~/assets/up-arrow-black.png';

import K from '~/k';
import _ from 'lodash';

class PropertiesHudElement extends Component {
  state = {
    expandedPropertyId: null,
    expandedPropertyGroupId: null
  };

  componentDidMount() {
    var {propertyGroups} = this;

    if (propertyGroups.length) {
      var localStorageExpandedPropertyGroupId = parseInt(localStorage.getItem('expandedPropertyGroupId'));

      var expandedPropertyGroupId = _.get(_.find(propertyGroups, {id: localStorageExpandedPropertyGroupId}), 'id', propertyGroups[0].id);

      this.setState({expandedPropertyGroupId});
    }
  }

  setExpandedPropertyGroupId = ({id}) => {
    var expandedPropertyGroupId = this.state.expandedPropertyGroupId === id ? null : id;

    this.setState({expandedPropertyGroupId});

    localStorage.setItem('expandedPropertyGroupId', id);
  };

  handleOrderLevelPropertyChange = ({propertyId, optionId}) => {
    var {productInstances, productPropertiesById, productRulesById, productsById, productOptionsById, activeProductInstanceWithData} = this.props;

    var productInstance = _.first(productInstances);
    var productProperty = _.get(productPropertiesById, propertyId);

    var updates = [];

    var oldProperties = _.cloneDeep(_.get(productInstance, 'properties', {}));
    var newProperties = _.set(_.cloneDeep(oldProperties), productProperty.id, {optionId});

    var clonedProductInstance = _.set(_.cloneDeep(productInstance), 'properties', newProperties);

    var emptyProductProperty;

    var allPropertiesWillStillHaveOptionsAvailable = _.every(_.map(newProperties, (_value, productPropertyId) => {
      var productProperty = productPropertiesById[productPropertyId];

      if (productProperty) {
        var availablePropertyOptions = productOptionsForProperty({productProperty, productInstance: clonedProductInstance}, {productRulesById, productOptionsById, productsById});

        if (productProperty.id === 26) { //HINT special rules for MNA backlit engraving type
          var isBacklitEngravingCompatible = getIsBacklitEngravingCompatible({activeProductInstanceWithData});

          if (!isBacklitEngravingCompatible) {
            availablePropertyOptions = _.reject(availablePropertyOptions, {id: 139});
          }
        }

        if (_.isEmpty(availablePropertyOptions)) {
          emptyProductProperty = productProperty;

          return false;
        }
        else {
          return true;
        }
      }
      else {
        return true; //TODO fix some edge case where productProperty is undefined on a new order
      }
    }));

    if (allPropertiesWillStillHaveOptionsAvailable) {
      _.forEach(productInstances, ({id, properties}) => {
        updates.push({where: {id}, props: {properties: {...properties, [productProperty.id]: {optionId: optionId}}}});
      });

      setTimeout(() => {
        this.props.updateProductInstances({updates});
      });
    }
    else {
      alert(`Oops selecting this option means  ${_.get(emptyProductProperty, 'title')}  will not have any options. Please select another option or update your other options.`);
    }
  };

  updateDependentProductProperties({optionId}) {
    var {productInstance, productOptionsById} = this.props;

    if (this.props.session.activeOrg.id === 850) { //HINT Meljac NA
      var productOption = productOptionsById[optionId];

      if (productOption.productPropertyId === 5) { //HINT user changed plate finish
        var oldProperties = _.cloneDeep(_.get(productInstance, 'properties', {}));
        var newProperties = _.cloneDeep(oldProperties);

        var warmPlateFinishOptionIds = [
          36, //black stone chelsea
          39, //polished brass
          38, //brushed brass
          44, //burnished brass
          41, //golden brushed brass
          43, //antique brass
          42, //aged brass
          45, //light bronze
          46, //light bronze matte
          47, //medium bronze
          48, //dark bronze
          40 //unlacquered polished brass
        ];
        var coolPlateFinishOptionIds = [
          13, //sandblasted nickel
          12, //polished nickel
          11, //brushed nickel
          37, //dark polished nickel
          34, //black matte nickel
          27, //sandblasted chrome
          14, //brushed chrome
          20, //polished chrome
          28, //TODO medium pewter?
          30, //gray gunmetal
          31, //sanded gunmetal
          33, //sandblasted gunmetal
          32, //belgian gunmetal
          29, //dark brushed silver
          35, //ebony
          49, //satin copper
          50, //dark brushed copper
          51, //aged burnished copper
          52 //antique copper
        ];

        //HINT change default mech finish to match selected plate finish
        if (_.includes(warmPlateFinishOptionIds, optionId)) {
          newProperties = _.set(newProperties, 7, {optionId: 22}); //golden brass mech finish
        }
        else if (_.includes(coolPlateFinishOptionIds, optionId)) {
          newProperties = _.set(newProperties, 7, {optionId: 23}); //chrome mech finish
        }

        this.props.updateProductInstance({id: productInstance.id, props: {properties: newProperties}});
      }
    }
  }

  get propertyGroups() {
    var {productInstance, productsById, productPropertiesById, productRulesById, productOptionsById, productInstancesById, productPropertyGroups, selectedEntityPropertiesData} = this.props;

    var sortedProductProperties = productInstance && sortedProductPropertiesForProductInstance({productInstance}, {productsById, productPropertiesById, productRulesById, productOptionsById, productInstancesById});

    var propertyGroups = _.sortBy(_.map(_.get(selectedEntityPropertiesData, 'propertyGroups') || _.filter(productPropertyGroups, ({id}) => _.some(sortedProductProperties, {productPropertyGroupId: id})), propertyGroup => {

      return {
        ...propertyGroup,
        id: propertyGroup.id || 0,
        properties: this.props.selectedEntityPropertiesData ? propertyGroup.properties : _.filter(sortedProductProperties, {productPropertyGroupId: propertyGroup.id})};
    }), 'rank');

    return propertyGroups;
  }

  render() {
    var {productInstance, activeProductInstanceWithData, selectedEntityPropertiesData} = this.props;
    var {expandedPropertyGroupId} = this.state;
    var {propertyGroups} = this;

    var renderProperties = (expandedPropertyGroupId, id) => (expandedPropertyGroupId === id || id.length === 1);

    return (
      <HudElement x='right' y='bottom'>
        <CopilotStepView
          order={9}
          key={1}
          name={'options'}
          text={'Select product options here.'}
          style={{}}
        >
          {_.map(propertyGroups, ({id, properties, ...propertyGroup}, index) => {
            var somePropertyIsInvalid = productInstance && getProductInstanceIsInvalid({productInstance, productProperties: properties}, this.props);

            return (
              <View key={`product-property-group-${index}`}>
                {propertyGroups.length > 1 && (
                  <TouchableOpacity style={{flexDirection: 'row-reverse', paddingBottom: K.margin, marginBottom: K.spacing, marginRight: K.spacing}}
                    dataSet={{fadeOnHover: 1}}
                    nativeID={`OrderPageProductPropertyGroup-${_.kebabCase(propertyGroup.title)}`}
                    onPress={() => {
                      this.setExpandedPropertyGroupId({id});
                      this.setState({showingOrderLevelOptions: false, expandedPropertyId: null})
                    }}
                  >
                    <Image source={upArrowIcon} style={{...K.defaultIconSize, marginRight: 13, transform: expandedPropertyGroupId === id ? [] : [{rotate: '-180deg'}], backgroundColor: 'transparent'}}/>
                    <Text style={{...K.fonts.standard, fontSize: K.calcFont(16), ...(somePropertyIsInvalid ? {color: 'red'} : {}), ...(expandedPropertyGroupId === id ? {fontWeight: 'bold'} : {opacity: 0.5}), marginLeft: K.margin, marginRight: 22}}>{`${index + 1}. ${_.get(propertyGroup, 'title', 'Other Properties')}`}</Text>
                  </TouchableOpacity>
                )}
                {}
                {renderProperties(expandedPropertyGroupId, id) && (
                  <View>
                    <View style={{alignItems: 'flex-end'}}>
                      {_.map(properties, (productProperty, index) => (
                        <View nativeID={`OrderPageProductProperty-${_.kebabCase(productProperty.title)}`}>
                          <ProductProperty
                            selectedEntityPropertiesData={selectedEntityPropertiesData}
                            key={`property-${productProperty.id}`}
                            {...{productProperty, mode: 'order', index, productInstance, activeProductInstanceWithData}}
                            setExpandedPropertyId={(propertyId) => this.setState({expandedPropertyId: propertyId})}
                            expandedPropertyId={this.state.expandedPropertyId}
                            handleOrderLevelPropertyChange={(propertyId, optionId) => this.handleOrderLevelPropertyChange(propertyId, optionId)}
                            handleApplyAll={({productProperty, productOption, activeProductInstanceWithData}) => this.props.handleApplyAll({productProperty, productOption, activeProductInstanceWithData})}
                            showMediaPopup={this.props.showMediaPopup}
                            updateDependentProductProperties={({optionId}) => this.updateDependentProductProperties({optionId})}
                          />
                        </View>
                      ))}
                    </View>
                  </View>
                )}
              </View>
            );
          })}
        </CopilotStepView>
      </HudElement>
    );
  }
}

export default connect({
  mapState: (state, ownProps) => {
    var productOrderId = ownProps.productOrderId;
    var productInstances = _.filter(state.resources.productInstances.byId, {productOrderId});
    var productsById = state.resources.products.byId;
    var productPropertiesById = state.resources.productProperties.byId;
    var productRulesById = state.resources.productRules.byId;
    var productOptionsById = state.resources.productOptions.byId;
    var productInstancesById = _.get(state.resources.productInstances, `byFieldKeyIndex.productOrderId.${productOrderId}`);
    var productPropertyGroups = state.resources.productPropertyGroups.byId;

    return {
      productInstances,
      productsById,
      productPropertiesById,
      productRulesById,
      productOptionsById,
      productInstancesById,
      productPropertyGroups
    };
  },
  mapDispatch: {
    ..._.pick(resourceActions.productInstances, ['updateProductInstances', 'updateProductInstance'])
  }
})(PropertiesHudElement);
