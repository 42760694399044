import _ from 'lodash';
import getTextSize from '~/helpers/get-text-size';

export default function getCustomMeljacEngravingProductGraphicNodes({
  engravingsData, verticalOffset, horizontalOffset, text, image
}) {
  const {engravingArrays, fontFamily, fontSize, index, fontColor, isMechanicalStyleEngraving} = engravingsData;
  const {engravings} = engravingArrays;
  const realFontSize = (1.4 * fontSize);
  const mechanismToFirstEngravingSpacing = 3;
  const engravingToEngravingSpacing = 2;
  const textHeight = fontSize;
  const mechanismHeight = verticalOffset * 2;

  var aboveData = {
    engravings: _.get(engravings[index], 'above', []),
    hasSymbol: false,
    hasTwoSymbols: false,
    hasText: false,
    graphicNodes: [],
    lineCount: 0,
    totalEngravingWidth: 0,
    totalEngravings: 0,
    maxTextWidth: 0
  }

  var belowData ={
    engravings: _.get(engravings[index], 'below', []),
    hasSymbol: false,
    hasTwoSymbols: false,
    hasText: false,
    graphicNodes: [],
    lineCount: 0,
    totalEngravingWidth: 0,
    totalEngravings: 0,
    maxTextWidth: 0
  }

  var getHasNonEmptyEngraving = ({engravingObject, type}) => {
    return (
      _.has(engravingObject, type)
      && _.includes(Object.keys(engravingObject), type)
      && (engravingObject[type] !== '' && engravingObject[type] !== undefined)
    );
  }

  _.forEach(aboveData.engravings, engravingObject => {
    if (getHasNonEmptyEngraving({engravingObject, type: 'symbol'})) {
      if (aboveData.hasSymbol) aboveData.hasTwoSymbols = true;
      aboveData.hasSymbol = true;
      aboveData.lineCount = 1;
      if (aboveData.totalEngravings < 2) aboveData.totalEngravings++;
    }
    if (getHasNonEmptyEngraving({engravingObject, type: 'text'})) {
      aboveData.hasText = true;
      if (aboveData.lineCount < 2 && !aboveData.hasSymbol) aboveData.lineCount++;
      if (aboveData.totalEngravings < 2) aboveData.totalEngravings++;

      var textWidth = getTextSize({text: Object.values(engravingObject)[0], fontSize: realFontSize, fontFamily: `${fontFamily}`, fontWeight: fontFamily === 'Helvetica' ? 'normal' : 'bold'}).width - 2

      if (textWidth > aboveData.maxTextWidth) {
        aboveData.maxTextWidth = textWidth;
      }
    }
  });

  _.forEach(belowData.engravings, engravingObject => {
    if (getHasNonEmptyEngraving({engravingObject, type: 'symbol'})) {
      if (belowData.hasSymbol) belowData.hasTwoSymbols = true;
      belowData.hasSymbol = true;
      belowData.lineCount = 1;
      if (belowData.totalEngravings < 2) belowData.totalEngravings++;
    }
    if (getHasNonEmptyEngraving({engravingObject, type: 'text'})) {
      belowData.hasText = true;
      if (belowData.lineCount < 2 && !belowData.hasSymbol) belowData.lineCount++;
      if (belowData.totalEngravings < 2) belowData.totalEngravings++;

      var textWidth = getTextSize({text: Object.values(engravingObject)[0], fontSize: realFontSize, fontFamily: `${fontFamily}`, fontWeight: fontFamily === 'Helvetica' ? 'normal' : 'bold'}).width - 2

      if (textWidth > belowData.maxTextWidth) {
        belowData.maxTextWidth = textWidth;
      }
    }
  });

  if (aboveData.lineCount === 1) { // same line text text or text + symbols
    _.forEach(aboveData.engravings, engravingObject => {
      if (getHasNonEmptyEngraving({type: Object.keys(engravingObject)[0], engravingObject})) {
        if (Object.keys(engravingObject)[0] === 'text') {
          aboveData.totalEngravingWidth += getTextSize({text: Object.values(engravingObject)[0], fontSize: realFontSize, fontFamily: `${fontFamily}`, fontWeight: fontFamily === 'Helvetica' ? 'normal' : 'bold'}).width - 2
        }

        if (Object.keys(engravingObject)[0] === 'symbol') {
          aboveData.totalEngravingWidth += 8
        }
      }
    })
  }

  if (belowData.lineCount === 1) { // same line text text or text + symbols
    _.forEach(belowData.engravings, engravingObject => {
      if (getHasNonEmptyEngraving({type: Object.keys(engravingObject)[0], engravingObject})) {
        if (Object.keys(engravingObject)[0] === 'text') {
          belowData.totalEngravingWidth += getTextSize({text: Object.values(engravingObject)[0], fontSize: realFontSize, fontFamily: `${fontFamily}`, fontWeight: fontFamily === 'Helvetica' ? 'normal' : 'bold'}).width - 2
        }

        if (Object.keys(engravingObject)[0] === 'symbol') {
          belowData.totalEngravingWidth += 8
        }
      }
    })
  }

  //ABOVE ENGRAVINGS
  if (aboveData.lineCount === 1) {
    var xPosition = -(aboveData.totalEngravingWidth / 2) + (horizontalOffset);

    var yPositions = {
      text: -(mechanismToFirstEngravingSpacing + textHeight),
      symbol: -(mechanismToFirstEngravingSpacing + 5.25)
    }

    var symbolAndTextOffset = (aboveData.hasSymbol && aboveData.hasText) ? 0.5 : 0;

    _.forEach(aboveData.engravings, (engravingObject, engravingIndex) => {
      if (getHasNonEmptyEngraving({engravingObject, type: 'symbol'})) {
        var leftOfTextOffset = (engravingIndex === 0 && aboveData.hasText) ? -1.5 : 0;

        aboveData.graphicNodes.push(image({
          href: require(`../../assets/meljac/symbols/${engravingObject.symbol}.png`),
          width: 6, height: 6,
          x: xPosition + symbolAndTextOffset + leftOfTextOffset + (aboveData.hasTwoSymbols ? 2 : 1),
          y: yPositions.symbol - mechanismToFirstEngravingSpacing + 3
        }));

        xPosition += (6 + leftOfTextOffset);
      }

      if (getHasNonEmptyEngraving({engravingObject, type: 'text'})) {
        var textWidth = getTextSize({text: Object.values(engravingObject)[0], fontSize: realFontSize, fontFamily: `${fontFamily}`, fontWeight: fontFamily === 'Helvetica' ? 'normal' : 'bold'}).width - 2;
        var rightOfSymbolOffset = (engravingIndex === 1 && aboveData.hasSymbol) ? 2 : 0;
        var leftOfSymbolOffset = (engravingIndex === 0 && aboveData.hasSymbol) ? 1 : 0;

        aboveData.graphicNodes.push(
          text({
            fontSize, fontFamily,
            x: xPosition + (textWidth / 2) + symbolAndTextOffset + leftOfSymbolOffset + rightOfSymbolOffset,
            y: yPositions.text + textHeight,
            fontWeight: fontFamily === 'Helvetica' ? 'normal' : 'bold',
            textAnchor: 'middle',
            fill: fontColor,
            opacity: isMechanicalStyleEngraving !== 0 ? 0.5 : 1
          }, engravingObject.text)
        )

        xPosition += textWidth + leftOfSymbolOffset + rightOfSymbolOffset;
      }
    })
  }

  if (aboveData.lineCount === 2) {
    var filteredEngravings = _.filter(aboveData.engravings, engravingObject => (getHasNonEmptyEngraving({engravingObject, type: 'text'})));

    _.forEach(filteredEngravings, (engravingObject, engravingIndex) => {
      aboveData.graphicNodes.push(
        text({
          fontSize, fontFamily,
          x: horizontalOffset,
          y: engravingIndex === 0
            ? -(mechanismToFirstEngravingSpacing)
            : -(mechanismToFirstEngravingSpacing + textHeight + engravingToEngravingSpacing),
          fontWeight: fontFamily === 'Helvetica' ? 'normal' : 'bold',
          textAnchor: 'middle',
          fill: fontColor,
          opacity: isMechanicalStyleEngraving !== 0 ? 0.5 : 1
        }, engravingObject.text)
      )
    })
  }

  //BELOW ENGRAVINGS
  if (belowData.lineCount === 1) {
    var xPosition = -(belowData.totalEngravingWidth / 2) + (horizontalOffset);

    var yPositions = {
      text: mechanismHeight + mechanismToFirstEngravingSpacing,
      symbol: (mechanismHeight + textHeight + 0.75 - mechanismToFirstEngravingSpacing)
    }

    var symbolAndTextOffset = (belowData.hasSymbol && belowData.hasText) ? 2 : 0;

    _.forEach(belowData.engravings, (engravingObject, engravingIndex) => {
      if (getHasNonEmptyEngraving({engravingObject, type: 'symbol'})) {
        var leftOfTextOffset = (engravingIndex === 0 && belowData.hasText) ? -2.5 : 0;

        belowData.graphicNodes.push(image({
          href: require(`../../assets/meljac/symbols/${engravingObject.symbol}.png`),
          width: 6, height: 6,
          x: xPosition + symbolAndTextOffset + leftOfTextOffset + (belowData.hasTwoSymbols ? 2 : 1),
          y: yPositions.symbol
        }));

        xPosition += (6 + leftOfTextOffset);
      }

      if (getHasNonEmptyEngraving({engravingObject, type: 'text'})) {
        var textWidth = getTextSize({text: Object.values(engravingObject)[0], fontSize: realFontSize, fontFamily: `${fontFamily}`, fontWeight: fontFamily === 'Helvetica' ? 'normal' : 'bold'}).width - 2;
        var rightOfSymbolOffset = (engravingIndex === 1 && belowData.hasSymbol) ? 1 : 0;
        var leftOfSymbolOffset = (engravingIndex === 0 && belowData.hasSymbol) ? -1 : 0;

        belowData.graphicNodes.push(
          text({
            fontSize, fontFamily,
            x: xPosition + (textWidth / 2) + symbolAndTextOffset + rightOfSymbolOffset + leftOfSymbolOffset,
            y: yPositions.text + textHeight,
            fontWeight: fontFamily === 'Helvetica' ? 'normal' : 'bold',
            textAnchor: 'middle',
            fill: fontColor,
            opacity: isMechanicalStyleEngraving !== 0 ? 0.5 : 1
          }, engravingObject.text)
        )

        xPosition += textWidth + leftOfSymbolOffset;
      }
    })
  }

  if (belowData.lineCount === 2) {
    var filteredEngravings = _.filter(belowData.engravings, engravingObject => (getHasNonEmptyEngraving({engravingObject, type: 'text'}))); //if only one input has text then force onto one line

    _.forEach(filteredEngravings, (engravingObject, engravingIndex) => {
      belowData.graphicNodes.push(
        text({
          fontSize, fontFamily,
          x: horizontalOffset,
          y: engravingIndex === 0
            ? mechanismHeight + mechanismToFirstEngravingSpacing + textHeight
            : mechanismHeight + mechanismToFirstEngravingSpacing + (textHeight * 2) + engravingToEngravingSpacing,
          fontWeight: fontFamily === 'Helvetica' ? 'normal' : 'bold',
          textAnchor: 'middle',
          fill: fontColor,
          opacity: isMechanicalStyleEngraving !== 0 ? 0.5 : 1
        }, engravingObject.text)
      )
    })
  }

  return [...aboveData.graphicNodes, ...belowData.graphicNodes];
}
