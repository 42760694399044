import { Group, Rect } from 'react-konva';

import uuid from 'uuid';

export default function CanvasMask({maskingPolygons, children, ...props}) {
  var {isRenderingSvg} = global;

  if (isRenderingSvg) {
    var clipPathId = uuid();
  }

  var maskingPolygon = _.flatten(maskingPolygons);

  return (
    <Group {...props} clipFunc={maskingPolygon ? ctx => {
      if (isRenderingSvg) global.svgClipPathMap[clipPathId] = _.flatten(maskingPolygons);

      _.forEach(maskingPolygons, maskingPolygon => {
        _.forEach(maskingPolygon, (point, index) => {
          ctx[index === 0 ? 'moveTo' : 'lineTo'](point.x, point.y);
        });
      });
    } : null}>
      {isRenderingSvg && <Rect fill={clipPathId}/>}
      {children}
    </Group>
  );
}
