import _ from 'lodash';
import React from 'react';

import { Line } from 'react-konva';

export default function CanvasLine({from, to, x1, x2, y1, y2, closed, ...props}) {
  if (from || to) props.points = [from.x, from.y, to.x, to.y];
  if ((x1 || x1 === 0) && (x2 || x2 === 0) && (y1 || y1 === 0) && (y2 || y2 === 0)) props.points = [x1, y1, x2, y2];

  var defaultProps = {
    strokeWidth: 1,
    stroke: 'black'
  };

  props = _.defaults(props, defaultProps);

  return <Line shadowForStrokeEnabled={false} perfectDrawingEnabled={false} {...props}/>;
}
