import React, { useContext } from 'react';
import { Circle, Group, Line } from 'react-konva';

import _ from 'lodash';
import CanvasDataContext from '~/contexts/canvas-data-context';

const CursorCanvasObject = ({mousePosition}) => {
  var positionInCanvas = mousePosition ? mousePosition : {x: 0, y: 0};
  var linePositions = [positionInCanvas.x, positionInCanvas.y, positionInCanvas.x, positionInCanvas.y];

  var linePositionTransformations = {
    top: [0, -20, 0, -5],
    bottom: [0, 5, 0, 20],
    left: [-20, 0, -5, 0],
    right: [5, 0, 20, 0],
  };

  return (
    <Group opacity={0.75}>
      {_.map(_.values(linePositionTransformations), (transform, key) => (
        <Line key={`cursor-line-${key}`} stroke={'black'} strokeWidth={1} points={_.map(linePositions, (value, index) => value + transform[index])} />
      ))}
      {mousePosition && (<Circle x={mousePosition.x} y={mousePosition.y} radius={1} fill={'black'} />)}
    </Group>
  );
};

export default function CursorCanvasObjectWithContext(props) {
  let canvasData = useContext(CanvasDataContext);

  return <CursorCanvasObject {...props} {...{canvasData}} />;
}
