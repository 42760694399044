import React, { useContext, useRef, useState } from 'react';
import K from '~/k';
import _ from 'lodash';
import lib from '~/lib';

import { Transformer, Rect } from 'react-konva';
import { toCanvas, toReal, snap } from '~/helpers/canvas/canvas-helpers';

import CanvasDataContext from '~/contexts/canvas-data-context';
// import NumericInputContext from 'contexts/numeric-input-context';

const CanvasTransformer = ({
  shapeProps,
  transformerProps: customProps,
  isSelected,
  snapToLines, // Array<{from, to}>[]
  disabledAnchors,
  onTransform,
  onTransformEnd,
  customAnchorDragBoundFunc,
  customDragBoundFunc,
  onClick,
  transformerOffset,
  includeOriginalPosition = false,
  isRotatable = false,
  isScalable = true,
  isDraggable = true
}) => {
  const canvasData = useContext(CanvasDataContext);

  const shouldCalculateSnapAngles = canvasData.scale && canvasData.getSnapData;
  const candidateSnapAngles = shouldCalculateSnapAngles ? canvasData.getSnapData().candidateSnapAngles : [0, 90, 180, 270];

  const [isRotating, setRotating] = useState();
  const [isScaling, setScaling] = useState();
  const [isMoving, setMoving] = useState();
  const [initialPosition, setInitialPosition] = useState();
  const [cachedPointPositions, cachePositions] = useState();
  const [snapToRotation, setSnapToRotation] = useState();
  const [arrowUpdateType, setArrowUpdateType] = useState();

  const shapeRef = useRef();
  const transformerRef = useRef();
  const firstAnchorDragPosition = useRef();
  const storeArrowKeyEventRef = useRef();

  React.useEffect(() => {
    if (isSelected) {
      //HINT we need to attach transformer manually
      transformerRef.current.nodes([shapeRef.current]);
      transformerRef.current.getLayer().batchDraw();
      transformerRef.current.moveToTop();
    }
  }, [isSelected]);

  //HINT pulled out because we need to trigger whenever shape props change
  React.useEffect(() => {
    if (isSelected) {
      document.addEventListener('keydown', handleKeyDown);

      return () => {
        document.removeEventListener('keydown', handleKeyDown);
      };
    }
  }, [isSelected, shapeProps, canvasData]);

  const handleMouseMove = (event) => {
    if (isSelected && isDraggable) {
      const container = event.target.getStage().container();

      if (container.style.cursor !== 'move') container.style.cursor = 'move';
    }
  };

  const handleMouseLeave = (event) => {
    if (isSelected && isDraggable) {
      const container = event.target.getStage().container();

      if (container.style.cursor !== 'default') container.style.cursor = 'default';
    }
  };

  const handleDragMove = (event) => {
    // HINT only drag on left mouse click
    if (event.evt.which !== 1) {
      event.target.stopDrag();

      return;
    }

    if (!isRotating && !isScaling) {
      var position = _.mapValues(toReal({x: event.target.x(), y: event.target.y()}, canvasData), value => lib.number.round(value, {toNearest: K.minPrecision}));

      let transformations = {
        ...shapeProps,
        position
      };

      if (snapToRotation) {
        transformations.rotation = snapToRotation;
      }

      if (includeOriginalPosition && transformerRef?.current) {
        const widthOffset = lib.trig.rotate({point: {x: shapeProps.size.width / 2, y: 0}, byDegrees: shapeProps.rotation});

        let cursorPosition = lib.object.difference(transformerRef?.current.getStage().getPointerPosition(), lib.object.multiply(widthOffset, canvasData.scale));

        transformations.mousePosition = toReal(cursorPosition, canvasData);
      }

      onTransform(transformations);
    }
  };

  const handleScaleAndRotate = () => {
    if (!isMoving) {
      /**HINT
       * Transformer is changing scale of the node and NOT its width or height
       * but in the data model we have only size. To match the data better we will
       * reset Konva.scale.
       */
      const node = shapeRef.current;
      const scaleX = node.scaleX();
      const scaleY = node.scaleY();
      let position = _.mapValues(toReal({x: node.x(), y: node.y()}, canvasData), value => lib.number.round(value, {toNearest: K.minPrecision}));

      // Scale logic
      let attemptedSize = {
        width: shapeProps.size.width * scaleX,
        height: shapeProps.size.height * scaleY,
      };

      attemptedSize = _.mapValues(attemptedSize, value => lib.number.round(value, {toNearest: K.minPrecision}));

      if (!_.isEqual(attemptedSize, shapeProps.size) && !isScaling) {
        setScaling(true);
      }

      // Rotation logic
      const attemptedRotation = lib.trig.normalize({degrees: node.rotation()});

      if (attemptedRotation !== shapeProps.rotation && !isRotating) {
        setRotating(true);
      }

      // we will reset it back
      node.scaleX(1);
      node.scaleY(1);

      let mousePosition;
      if (includeOriginalPosition && transformerRef?.current) {
        const widthOffset = lib.trig.rotate({point: {x: attemptedSize.width / 2, y: 0}, byDegrees: attemptedRotation});

        let cursorPosition = lib.object.difference(transformerRef?.current.getStage().getPointerPosition(), lib.object.multiply(widthOffset, canvasData.scale));

        mousePosition = toReal(cursorPosition, canvasData);
      }

      onTransform({
        ...shapeProps,
        position,
        rotation: attemptedRotation,
        size: attemptedSize,
        mousePosition
      });
    }
  };

  //HINT scaling
  const anchorDragBoundFunc = (oldPosition, newPosition, event) => {
    if (customAnchorDragBoundFunc) {
      return customAnchorDragBoundFunc(oldPosition, newPosition, event);
    }
    else {
      //HINT do not snap rotating point
      if (transformerRef?.current.getActiveAnchor() === 'rotater') {
        return newPosition;
      }

      const {
        candidateSnapPositions = [],
      } = canvasData.getSnapData();

      const pointPosition = toReal(newPosition, canvasData);
      const oldPositionInReal = toReal(oldPosition, canvasData);

      if (!firstAnchorDragPosition.current) {
        firstAnchorDragPosition.current = oldPositionInReal;
      }

      let deltaInReal = lib.object.difference(pointPosition, oldPositionInReal);

      //HINT round to current precision setting
      if (Math.abs(deltaInReal.x) > Number.EPSILON) {
        const totalXDelta = pointPosition.x - firstAnchorDragPosition.current.x;
        const difference = lib.number.round(totalXDelta, {toNearest: canvasData.precision}) - totalXDelta;

        deltaInReal.x += difference;
      }

      if (Math.abs(deltaInReal.y) > Number.EPSILON) {
        const totalYDelta = pointPosition.y - firstAnchorDragPosition.current.y;
        const difference = lib.number.round(totalYDelta, {toNearest: canvasData.precision}) - totalYDelta;

        deltaInReal.y += difference;
      }

      // Snap logic
      const lastPosition = lib.object.sum(deltaInReal, oldPositionInReal);
      const {position: snappedPosition, wasSnapped} = snap(lib.object.sum(deltaInReal, oldPositionInReal), candidateSnapPositions, canvasData);
      const snappedPointDelta = lib.object.difference(snappedPosition, lastPosition);

      //HINT It's important that x and y are snapped independently because some points might cause difference snaps than others
      if (wasSnapped.x !== undefined) {
        deltaInReal.x += snappedPointDelta.x;
      }
      if (wasSnapped.y !== undefined) {
        deltaInReal.y += snappedPointDelta.y;
      }

      const position = lib.object.sum(oldPositionInReal, deltaInReal);

      return toCanvas(position, canvasData);
    }
  };

  const handleTransformEnd = () => {
    setMoving(false);
    setRotating(false);
    setScaling(false);
    setSnapToRotation(null);

    firstAnchorDragPosition.current = null;

    if (onTransformEnd) onTransformEnd();
  };

  const handleKeyDown = (arrowKeyEvent) => {
    if (document.activeElement.tagName === 'BODY') {
      let transformAmount = canvasData.precision;
      const arrowKeyPressed = (
        lib.event.keyPressed(arrowKeyEvent, 'right') ||
        lib.event.keyPressed(arrowKeyEvent, 'left') ||
        lib.event.keyPressed(arrowKeyEvent, 'up') ||
        lib.event.keyPressed(arrowKeyEvent, 'down')
      );

      // if (lib.event.keyPressed(arrowKeyEvent, 'alt') && arrowKeyPressed) {
      //   numericInputData.toggleNumericInputVisibility(true);
      //   storeArrowKeyEventRef.current = arrowKeyEvent;
      //   setArrowUpdateType('scale');
      // }
      // else if (canvasData.isShifting && arrowKeyPressed) {
      //   numericInputData.toggleNumericInputVisibility(true);
      //   storeArrowKeyEventRef.current = arrowKeyEvent;
      //   setArrowUpdateType('transform');
      // }
      // else {
        handleArrowKeyTransform({arrowKeyEvent, transformAmount});
      // }
    }
  };

  const handleArrowKeyTransform = ({arrowKeyEvent, transformAmount}) => {
    let transform;
    if (lib.event.keyPressed(arrowKeyEvent, 'right')) transform = {x: 1, y: 0};
    else if (lib.event.keyPressed(arrowKeyEvent, 'left')) transform = {x: -1, y: 0};
    else if (lib.event.keyPressed(arrowKeyEvent, 'up')) transform = {x: 0, y: -1};
    else if (lib.event.keyPressed(arrowKeyEvent, 'down')) transform = {x: 0, y: 1};

    if (transform) {
      arrowKeyEvent.preventDefault();

      transform = lib.object.multiply(transform, transformAmount);
      transform = lib.object.round(transform, {toNearest: K.minPrecision});

      let position;

      if (customDragBoundFunc) {
        position = customDragBoundFunc(toCanvas(lib.object.sum(shapeProps.position, transform), canvasData), canvasData);
        position = toReal(position, canvasData);
      }
      else {
        position = lib.object.sum(shapeProps.position, transform);
      }

      if (!_.isEqual(position, shapeProps.position)) onTransformEnd({...shapeProps, position});
    }
  };

  const handleArrowKeyScale = ({arrowKeyEvent, transformAmount}) => {
    let transform;
    var sideKey;
    if (lib.event.keyPressed(arrowKeyEvent, 'right')) sideKey = 'right';
    else if (lib.event.keyPressed(arrowKeyEvent, 'left')) sideKey = 'left';
    else if (lib.event.keyPressed(arrowKeyEvent, 'up')) sideKey = 'top';
    else if (lib.event.keyPressed(arrowKeyEvent, 'down')) sideKey = 'bottom';

    if (sideKey) {
      arrowKeyEvent.preventDefault();

      var size = {...shapeProps.size};
      var {position} = shapeProps;

      var sideKeys = ['bottom', 'left', 'top', 'right'];
      var sideKeyIndex = _.indexOf(sideKeys, sideKey);
      let effectiveRotation = shapeProps.rotation;
      //hint not 100% sure why necessary
      if (_.includes([270, 90], shapeProps.rotation)) effectiveRotation = effectiveRotation === 90 ? 270 : 90;
      var effectiveSideKey = sideKeys[(sideKeyIndex + Math.floor(effectiveRotation / 90)) % 4];
      let sizeKey = _.includes(['left', 'right'], effectiveSideKey) ? 'width' : 'height';

      size[sizeKey] += transformAmount;

      var sizeChanged = !_.isEqual(size, shapeProps.size);

      if (sizeChanged) {
        var willModifyPosition = false;

        if (_.includes(['left', 'top'], effectiveSideKey)) {
          willModifyPosition = true;
        }

        if (willModifyPosition) {
          if (sideKey === 'right') transform = {x: 1, y: 0};
          else if (sideKey === 'left') transform = {x: -1, y: 0};
          else if (sideKey === 'top') transform = {x: 0, y: -1};
          else if (sideKey === 'bottom') transform = {x: 0, y: 1};

          var constrainedPosition = lib.object.sum(position, lib.object.multiply(transform, transformAmount));

          //check if position was constrained to something smaller than the attempted size change
          if (!_.isEqual(constrainedPosition, lib.object.sum(position, lib.object.multiply(transform, transformAmount)))) {
            let axisKey = sizeKey === 'width' ? 'x' : 'y';

            size[sizeKey] = size[sizeKey] - Math.abs((constrainedPosition[axisKey] - lib.object.sum(position, lib.object.multiply(transform, transformAmount))[axisKey]));
          }

          position = constrainedPosition;
        }
      }

      if (sizeChanged || !_.isEqual(position, shapeProps.position)) onTransformEnd({...shapeProps, size, position});
    }
  };

  // if (numericInputData.isNumericInputSubmitted) {
  //   if (storeArrowKeyEventRef.current) {
  //     (arrowUpdateType === 'scale' ? handleArrowKeyScale : handleArrowKeyTransform)({arrowKeyEvent: storeArrowKeyEventRef.current, transformAmount: numericInputData.numericInputValue});
  //   }
  //   numericInputData.disableIsNumericInputSubmitted();
  //   numericInputData.toggleNumericInputVisibility(false);
  //   storeArrowKeyEventRef.current = null;
  // }

  //HINT moving
  const dragBoundFunc = (newPosition) => {
    if (customDragBoundFunc) {
      return customDragBoundFunc(newPosition, canvasData);
    }
    else {
      const {position: origin, rotation} = shapeProps;
      const {
        candidateSnapPositions = [],
        sourceSnapPositions = [],
      } = canvasData.getSnapData();

      let initial = initialPosition;
      let cachedPositions = cachedPointPositions;

      const pointPositions = _.cloneDeep(sourceSnapPositions);

      if (!isMoving) {
        setInitialPosition(origin);

        initial = origin;

        cachePositions(pointPositions.map(position => {
          return {...position, ...lib.trig.rotate({point: lib.object.sum(position, initial), byDegrees: rotation, aroundOrigin: origin})};
        }));

        cachedPositions = pointPositions.map(position => {
          return {...position, ...lib.trig.rotate({point: lib.object.sum(position, initial), byDegrees: rotation, aroundOrigin: origin})};
        });

        setMoving(true);
      }

      let newPositionInReal = toReal(newPosition, canvasData);

      if (snapToLines?.length > 0) {
        if (transformerRef?.current) {
          let cursorPosition = transformerRef.current.getStage().getPointerPosition();

          //HINT offset by the half the width
          const widthOffset = lib.trig.rotate({point: {x: shapeProps.size.width / 2, y: 0}, byDegrees: rotation});

          cursorPosition = lib.object.difference(cursorPosition, lib.object.multiply(widthOffset, canvasData.scale));

          newPositionInReal = toReal(cursorPosition, canvasData);
        }

        var nearestLine = _.minBy(_.values(snapToLines), line => lib.trig.distance({fromPoint: newPositionInReal, toLine: line}));

        let nearestPoint = lib.trig.nearestPoint({point: newPositionInReal, onLine: nearestLine});

        //hint fixes axis swapping bug for front view, arch elements in top view still inconsistent
        nearestPoint = lib.object.sum(nearestPoint, lib.trig.rotate({point: {x: 0, y: -shapeProps.size.height}, byDegrees: rotation}));
        setSnapToRotation(lib.trig.radiansToDegrees(lib.trig.normalize({radians: lib.trig.alpha({p1: nearestLine.from, p2: nearestLine.to})})));

        newPositionInReal = nearestPoint;
      }

      const deltaInReal = lib.object.difference(newPositionInReal, initial);

      //HINT round to current precision setting
      if (Math.abs(deltaInReal.x) > Number.EPSILON) {
        deltaInReal.x = lib.number.round(deltaInReal.x, {toNearest: canvasData.precision});
      }

      if (Math.abs(deltaInReal.y) > Number.EPSILON) {
        deltaInReal.y = lib.number.round(deltaInReal.y, {toNearest: canvasData.precision});
      }
      const snappedDelta = deltaInReal;
      const leastSnapDistance = {x: Number.MAX_SAFE_INTEGER, y: Number.MAX_SAFE_INTEGER};
      //Iterate through points and check if any of them snap
      //If so, update the transform to be the snapped transform
      cachedPositions.forEach(pointPosition => {
        const lastPosition = _.clone(pointPosition);

        pointPosition = lib.object.sum(pointPosition, deltaInReal);

        const {position: snappedPosition, wasSnapped, snapData} = snap(pointPosition, candidateSnapPositions, canvasData);
        const snappedPointDelta = lib.object.difference(snappedPosition, lastPosition);

        //HINT It's important that x and y are snapped independently because some points might cause difference snaps than others
        if (wasSnapped.x !== undefined && (leastSnapDistance.x === undefined || snapData.x.candidateData?.distance < leastSnapDistance.x)) {
          leastSnapDistance.x = snapData.x.candidateData.distance;
          snappedDelta.x = snappedPointDelta.x;
        }
        if (wasSnapped.y !== undefined && (leastSnapDistance.y === undefined || snapData.y.candidateData?.distance < leastSnapDistance.y)) {
          leastSnapDistance.y = snapData.y.candidateData.distance;
          snappedDelta.y = snappedPointDelta.y;
        }
      });

      let position = lib.object.sum(initial, snappedDelta);

      return toCanvas(position, canvasData);
    }
  };

  //HINT used to prevent canvas objects from being flipped while scaling, additionally manages constraintsFor logic
  const boundBoxFunc = (oldBoundBox, newBoundBox) => {
    // "boundBox" is an object with
    // x, y, width, height and rotation properties
    // transformer tool will try to fit nodes into that box

    //HINT don't need to constrain if the shape was only rotated
    if (oldBoundBox.width === newBoundBox.width && oldBoundBox.height === newBoundBox.height) return newBoundBox;

    //HINT restrict shapes from being flipped while scaling
    if (newBoundBox.width <= 0 || newBoundBox.height <= 0) return oldBoundBox;

    return newBoundBox;
  };

  const appearanceProps = {
    anchorStroke: 'black',
    anchorCornerRadius: 5,
    anchorSize: 10,
    borderStroke: '#58aeed',
  };

  const transformerProps = {
    rotationSnaps: candidateSnapAngles,
    ignoreStroke: true,
    resizeEnabled: isScalable,
    rotateEnabled: isRotatable,
    enabledAnchors: _.difference(['top-center', 'bottom-center', 'middle-left', 'middle-right'], disabledAnchors),
    boundBoxFunc,
    anchorDragBoundFunc,
    ...appearanceProps,
    ...customProps,
  };

  const canvasShapeProps = {
    ...(shapeProps.size ? lib.object.multiply(shapeProps.size, canvasData.scale) : {width: shapeProps.width, height: shapeProps.height}),
    ...(shapeProps.position ? toCanvas(lib.object.sum(shapeProps.position, transformerOffset), canvasData) : {x: shapeProps.x, y: shapeProps.y}),
    rotation: shapeProps.rotation,
  };

  const enabledRectProps = {
    draggable: isDraggable,
    onTransform: handleScaleAndRotate,
    onTransformEnd: handleTransformEnd,
    onDragMove: handleDragMove,
    onDragEnd: handleTransformEnd,
    dragBoundFunc,
    onMouseLeave: handleMouseLeave,
    onMouseMove: handleMouseMove,
  };

  const rectProps = {
    ...canvasShapeProps,
    ...(isSelected ? enabledRectProps : {}),
    onClick,
    stroke: 'transparent',
    fill: 'transparent'
  };

  return (<>
    <Rect ref={shapeRef} {...rectProps}/>
    {isSelected && (<Transformer ref={transformerRef} {...transformerProps} />)}
  </>);
};

export default function RectTool(props) {
  const canvasData = useContext(CanvasDataContext);
  const lastClickTimestamp = useRef(0);

  var handleClick = () => {
    if (!props.doubleClickToSelect || Date.now() - lastClickTimestamp.current < 300) {
      props.onSelect();
    }

    lastClickTimestamp.current = Date.now();
  };

  return <CanvasTransformer
    shapeProps={{..._.mapValues(props.size, (value) => value * canvasData.scale), size: props.size, ...props.position, rotation: props.rotation}}
    onClick={props.onSelect && handleClick}
    {...props}
  />;
}
