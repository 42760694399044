import { Component } from 'react';
import { Text, TextInput } from '@symbolic/rn-lib';
import { View } from 'react-native';
import { connect } from '@symbolic/redux';
import { resourceActions } from '~/redux';
import { getNameFor } from '~/helpers/product-order-helper';

import K from '~/k';

class ProductOrderTitleHudElement extends Component {
  render() {
    var {productOrder, lastCompletedProductOrderStatus} = this.props;
    var {id: orgId} = this.props.session.activeOrg;

    var titleIsEditable = true;

    var dataForlastCompletedProductOrderStatus = _.get(productOrder, `statusData.${lastCompletedProductOrderStatus.key}`);
    var awaitingPaymentMethod = _.get(dataForlastCompletedProductOrderStatus, 'awaitingPaymentMethod');

    return (
      <View style={{...this.props.style}}>
        {titleIsEditable ? (
          <TextInput
            multiline
            blurOnSubmit
            returnKeyType='done'
            style={{...K.fonts.pageHeader, width: 300, backgroundColor: 'transparent', borderRadius: 0, paddingHorizontal: 0, height: 'auto', top: K.isWeb ? 0 : -3}}
            value={productOrder.title}
            onChange={({value}) => this.props.updateProductOrder({id: productOrder.id, props: {['title']: value}})}
            selectTextOnFocus={this.props.autoFocusTitleInput}
            disabled={!!awaitingPaymentMethod}
          />
        ) : (
          <Text style={{...K.fonts.pageHeader}}>{this.props.title}</Text>
        )}
      </View>
    );
  }
}

export default connect({
  mapState: (state, ownProps) => {
    var productOrderId = ownProps.productOrder.id;
    var productOrder = _.get(state, `resources.productOrders.byId.${productOrderId}`);

    return {
      productOrder
    };
  },
  mapDispatch: {
    ..._.pick(resourceActions.productOrders, ['updateProductOrder']),
  }
})(ProductOrderTitleHudElement);
