import { createStore, resourcesReducerAndActionsFor } from '@symbolic/redux';
import activeViewReducer, {setActiveView} from './active-view';

var {actions: resourceActions, reducer: resourcesReducer} = resourcesReducerAndActionsFor({
  archWalls: {indexedFieldKeys: ['productOrderId']},
  archElevations: {indexedFieldKeys: ['productOrderId']},
  archCustomViews: {indexedFieldKeys: ['productOrderId']},
  comments: {},
  entities: {indexedFieldKeys: ['parentId']},
  guests: {},
  media: {},
  notifications: {},
  products: {},
  productCategories: {},
  productGraphics: {},
  productPricingRules: {},
  productOptions: {},
  productOptionClassifiers: {},
  productOptionClassifierGroups: {},
  productProperties: {},
  productRules: {},
  productPropertyGroups: {},
  productInstances: {indexedFieldKeys: ['productOrderId']},
  productOrders: {indexedFieldKeys: ['orgSpecificId']},
  productCanvasTexts: {indexedFieldKeys: ['productOrderId']},
  productCanvasLines: {indexedFieldKeys: ['productOrderId']},
  users: {},
  userGroups: {}
});

var store = createStore({
  reducers: {resources: resourcesReducer, activeView: activeViewReducer, /*event: eventReducer*/},
  initialState: {activeView: {data: {}}, /*event: {}*/}
});

export { resourceActions, store, setActiveView };
