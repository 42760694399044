import { Component } from 'react';
import { TextInput } from '@symbolic/rn-lib';

class NumberInput extends Component {
  constructor(props) {
    super(props);

    this.handleChange = this.handleChange.bind(this);
  }

  handleChange({value}) {
    var value = (this.props.emptyStringIsNull && value === '') ? null : Number(this.constrain(value));

    this.props.onChange({value});
  }

  constrain(value) {
    var numericValue = 0;

    if (typeof value === 'number') {
      numericValue = value;
    }
    else if (typeof value === 'string') {
      numericValue = value.replace(/[^\d.]+/g, '');
    }

    return numericValue;
  }

  render() {
    return (
      <TextInput
        {...this.props}
        value={this.props.value || ''}
        onChange={this.handleChange}
      />
    );
  }
}

export default NumberInput;
