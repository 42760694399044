import { products } from '@symbolic/lib';

var { productPricingRuleVersionFor, priceFor } = products;

function formatPrice(price, trimTrailingZeroes) {
  var n = 2;
  var x = 3;
  var re = '\\d(?=(\\d{' + (x || 3) + '})+' + (n > 0 ? '\\.' : '$') + ')';

  price /= 100;

  price = price.toFixed(Math.max(0, ~~n)).replace(new RegExp(re, 'g'), '$&,');

  if (trimTrailingZeroes) {
    const trimmedPrice = price.replace(/(\.\d*?[1-9]?)0+$/g, '$1');

    if (trimmedPrice.endsWith('.')) price = trimmedPrice.replace('.', '');
  }

  return price;
}

function totalPriceFor({productInstances}, dependencies) {
  var {productsById, productPricingRulesById, productPropertiesById, productOptionsById, productOptionClassifiersById, productRulesById, productInstancesById, productOrder} = dependencies;
  var total = 0;

  _.forEach(productInstances, (productInstance) => {
    var {expressions} = productPricingRuleVersionFor({productInstance, productOrder}, {productsById, productPricingRulesById});
    var product = productsById[productInstance.productId];

    var priceForProductInstance = priceFor(expressions, {productInstance, shouldRoundPrice: product.shouldRoundPrice}, {product, productOptionsById, productPricingRulesById, productOptionClassifiersById, productsById, productPropertiesById, productRulesById, productInstancesById, productOrder});

    total += priceForProductInstance;
  });

  return total;
}

export { formatPrice, totalPriceFor };
