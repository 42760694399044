import { PureComponent } from 'react';
import { TouchableOpacity, Image, View } from 'react-native';
import { confirm, Text, TextInput, CopilotStepView } from '@symbolic/rn-lib';
import { products } from '@symbolic/lib';
import { connect } from '@symbolic/redux';

import { formatPrice } from '~/helpers/price-helper';
import { getTitleFor } from '~/helpers/product-helper';
import { getNameFor } from '~/helpers/product-order-helper';

import leftArrowIcon from '~/assets/left-arrow.png';
import K from '~/k';
import trashIcon from '~/assets/trash-icon.png';
import _ from 'lodash';

var { calculatePriceForInstance } = products;

class CartDataRow extends PureComponent {
  state = {
    isHoveringQuantity: false,
    isHoveringRow: false
  };

  handleDeletePress = async () => {
    var {productInstance, onDelete} = this.props;

    if ( await confirm('Are you sure?', 'Are you sure you want to delete this product instance?')) onDelete({productInstance});
  };

  render() {
    var {isInvalid, setActiveInstanceId, productInstance, productInstanceWithData, productsById, productPricingRulesById, productOptionsById, productOptionClassifiersById, productOrder, productPropertiesById, productRulesById, productInstancesById, activeProductInstanceId, indexInProductInstancesForOrder, session} = this.props;

    var orgId = session.activeOrg.id;

    var product = productsById[productInstance.productId];
    var priceForProductInstance = calculatePriceForInstance({productInstance, productOrder}, {productOptionsById, productPricingRulesById, productOptionClassifiersById, productsById, productPropertiesById, productRulesById, productInstancesById});
    var customTitle = productInstance.title || '';
    var sortedProductProperties = productInstanceWithData.sortedPropertiesData;
    var nonOrderLevelProperties = [];
    var isCustomProduct = productInstance.productId === -1;
    var isActiveProductInstance = activeProductInstanceId === productInstance.id;

    _.map(sortedProductProperties, sortedProductProperty => {
      if (sortedProductProperty.productProperty.isOrderLevel === 0) nonOrderLevelProperties = [...nonOrderLevelProperties, sortedProductProperty];
    });

    return (
      <CopilotStepView
        order={10}
        key={1}
        name={'cart data row'}
        text={getNameFor({productOrder, orgId, textToTransform: 'Each item in your order will appear as a row in the cart and includes selected options.\n\nThe number left of the title can be modified to change the rank of each item in your order.\n\nThe number right of the title represents quantity and can be modified here as well.'})}
        style={{}}
      >
        <View
          style={{padding: K.spacing, paddingRight: K.spacing + 3, ...(isActiveProductInstance && {backgroundColor: K.colors.doubleGray})}}
          dataSet={{'conditional-opacity-parent': 1}}
        >
          <View style={{flexDirection: 'row', alignContent: 'center', paddingTop: 5, paddingBottom: 5}}>
            <View style={{flex: 2, overflow: 'visible', flexDirection: 'row'}}>
              <TextInput
                value={indexInProductInstancesForOrder + 1}
                onChange={({value})=> this.props.updateProductInstanceRank({value, productInstance})}
                style={{backgroundColor: 'transparent', width: 25, opacity: 0.5, paddingHorizontal: 0, height: 14, ...K.fonts.standard}}
                selectTextOnFocus
              />
              <TouchableOpacity
                onPress={() => setActiveInstanceId({id: productInstance.id})}
                style={{flexDirection: 'column', alignSelf: 'flex-start', flexShrink: 1}}
                dataSet={{hoverUnderline: 1}}
              >
                <Text style={{marginBottom: K.margin, ...(isInvalid && {color: 'red'})}}>
                  {getTitleFor(customTitle, product.title, productOrder)}
                </Text>
                <Text style={{ opacity: 0.7, fontSize: 11, flexShrink: 1}}>
                  {!isCustomProduct ? _.map(nonOrderLevelProperties, (nonOrderLevelProperty) => {
                    return `${_.get(nonOrderLevelProperty.productProperty, 'title', 'ERROR')}: ${_.get(nonOrderLevelProperty.selectedProductOption, 'title', 'ERROR')}`;
                  }).join(', ') : `Type: Custom Product, Notes: ${productInstance.notes}`}
                </Text>
              </TouchableOpacity>
            </View>
            <View style={{flex: 1, flexDirection: 'row', justifyContent: 'space-evenly'}}>
              <View
                style={{ opacity: 0.7, flexDirection: 'row', width: '50%', justifyContent: 'space-evenly'}}
                dataSet={{'conditional-opacity-parent': 1}}
              >
                <View
                  style={{width: K.margin * 4, alignItems: 'flex-start'}}
                  dataSet={{'conditional-opacity': 1}}
                >
                  {productInstance.quantity > 1 && (
                    <TouchableOpacity onPress={()=>this.props.updateProductInstance({id: productInstance.id, props: {quantity: productInstance.quantity - 1}})}>
                      <Image source={leftArrowIcon} style={{...K.defaultIconSize, opacity: 0.5, transform: [{rotate: '-90deg'}]}} />
                    </TouchableOpacity>
                  )}
                </View>
                <View style={{alignItems: 'flex-start'}}>
                  <Text style={{alignSelf: 'center', pointerEvents: 'none'}}>{productInstance.quantity}</Text>
                </View>
                <View
                  style={{width: K.margin * 4, alignSelf: 'flex-start'}}
                  dataSet={{'conditional-opacity': 1}}
                >
                  <TouchableOpacity
                    onPress={()=>this.props.updateProductInstance({id: productInstance.id, props: {quantity: productInstance.quantity + 1}})}
                  >
                    <Image
                      source={leftArrowIcon}
                      style={{...K.defaultIconSize, opacity: 0.5, transform: [{rotate: '90deg'}]}}
                    />
                  </TouchableOpacity>
                </View>
              </View>
              <View style={{flexDirection: 'column', justifyContent: 'space-between', minWidth: '50px'}}>
                <View>
                  {!this.props.pricingIsHidden && (
                    <Text style={{opacity: 0.7, textAlign: 'right'}}>${formatPrice(priceForProductInstance, true)}</Text>
                  )}
                </View>
                <View style={{alignItems: 'flex-end'}}>
                  <TouchableOpacity
                    dataSet={{'conditional-opacity': 1}}
                    onPress={()=>this.handleDeletePress(productInstance.id)}
                  >
                    <Image source={trashIcon} style={{...K.defaultIconSize, opacity: 0.7}} />
                  </TouchableOpacity>
                </View>
              </View>
            </View>
          </View>
        </View>
      </CopilotStepView>
    );
  }
}

// export default CartDataRow;

export default connect({
  mapState: (state, ownProps) => {},
  mapDispatch: {}
})(CartDataRow);
