import { PureComponent } from 'react';
import { View, ScrollView, Image } from 'react-native';
import { Tooltip, Text, PickerInput, Button, Popup, TextInput, DocumentTitle, CheckboxInput, LabelledView} from '@symbolic/rn-lib';
import { connect } from '@symbolic/redux';
import { resourceActions } from '~/redux';
import { api } from '@symbolic/lib';
import moment from 'moment';

import K from '~/k';
import AdminMenu from '~/components/admin-menu';
import SecondaryHeader from '~/components/secondary-header';
import ProductProperty from '~/components/product-property';
import ProductPropertiesPopup from '~/components/popups/product-properties-popup';
import FileInput from '~/components/file-input';

import settingsIcon from '~/assets/settings-icon.png';
import thumbnailPlaceholder from '~/assets/thumbnail-placeholder.png';

import React from 'react';
import _ from 'lodash';
import handleFileUpload from '~/helpers/upload-file-helper';

import AdminSection from '~/components/admin-section';
import 'ace-builds/src-noconflict/mode-java';
import 'ace-builds/src-noconflict/theme-github';
import 'ace-builds/src-noconflict/ext-language_tools';

import downloadIcon from '~/assets/download-icon-black.png';

import { products } from '@symbolic/lib';
// import { priceFor, productPricingRuleVersionFor } from '@symbolic/lib';
import { formatPrice } from '~/helpers/price-helper';
import { productOptionsForProperty } from '~/helpers/product-order-helper';
import { productOptionsByIdFor } from '~/helpers/product-property-helper';
import { downloadCsv } from '~/helpers/csv-helper';
import { publishLevelOptions } from '~/helpers/publish-level-options';
import { getProductInstanceWithData } from '~/helpers/product-order-helper';

var { priceFor, productPricingRuleVersionFor } = products;

require(`ace-builds/src-noconflict/theme-github`); //eslint-disable-line
require(`ace-builds/src-noconflict/mode-javascript`); //eslint-disable-line
require(`ace-builds/src-noconflict/snippets/javascript`); //eslint-disable-line

class AdminProductPage extends PureComponent {
  state = {
    productPropertiesPopupIsVisible: false,
    productSettingsPopupIsVisible: false,
    image: null,
    uploading: false,
    uploadingWasSuccessful: false,
    uploadingHasFailed: false
  };

  archive = async () => {
    this.props.updateProduct({id: this.props.product.id, props: {archived: this.props.product.archived ? 0 : 1}});
  };

  handleProductPropsChange = ({propKey, value}) => this.props.updateProduct({id: this.props.product.id, props: {[propKey]: value}});

  handleSettingsPopupChange = (value) => this.setState({productSettingsPopupIsVisible: !value});

  handleReferenceCodeChange = ({value, key}) => {
    var referenceCodes = _.cloneDeep(_.get(this.props.product, 'referenceCodes', {}));

    referenceCodes = _.set(referenceCodes, key, value);

    this.props.updateProduct({id: this.props.product.id, props: {referenceCodes}});
  };

  toggleProductOptionDisabled = ({productOptionId, productPropertyId}) => {
    var associations = _.cloneDeep(_.get(this.props.product, 'associations', {}));

    var disabledProductOptionIds = _.get(associations, `productProperties.${productPropertyId}.disabledProductOptionIds`, []);

    //add or remove id from disabledProductOptionIds
    if (!_.includes(disabledProductOptionIds, productOptionId)) {
      disabledProductOptionIds.push(productOptionId);
    }
    else {
      disabledProductOptionIds = _.pull(disabledProductOptionIds, productOptionId);
    }

    associations = _.set(associations, `productProperties.${productPropertyId}.disabledProductOptionIds`, disabledProductOptionIds);

    this.props.updateProduct({id: this.props.product.id, props: {associations}});
  };

  handleExportPricesButtonPress = () => {
    var {product, productsById, productPricingRulesById, productOptionClassifiersById, productOptionsById, productsById, productRulesById, productOptionsById, productPropertiesById, productInstancesById, productOrder} = this.props;
    var {exportPriceData} = product;

    var {groupDataBy, rowsData, columnsData, fixedProperties} = exportPriceData;

    var productInstanceFor = ({groupByProductOptionId, rowProductOptionId, columnProductOptionId, columnData}) => {
      var productInstance = getProductInstanceWithData({productInstance: {
        created: moment().format('YYYY-MM-DD hh:mm:ss'),
        orgId: this.props.session.activeOrg.id,
        productId: product.id,
        properties: {
          [groupDataBy.productPropertyId]: {optionId: groupByProductOptionId},
          [rowsData.productPropertyId]: {optionId: rowProductOptionId},
          [columnData.productPropertyId]: {optionId: columnProductOptionId},
          ...fixedProperties
        },
        quantity: 1
      }}, {productsById, productPropertiesById, productRulesById, productOptionsById});

      return productInstance;
    };

    var columnProductOptionIdFor = ({columnProductOptionClassifierId, columnData}) => {
      var columnProductOptionClassifier = _.find(productOptionClassifiersById, {id: columnProductOptionClassifierId});
      var columnProductOptionClassifierGroupId = columnProductOptionClassifier.productOptionClassifierGroupId;

      var productOptionsForColumn = _.chain(productOptionsById)
        .reject('archived')
        .filter({productPropertyId: columnData.productPropertyId})
        .filter(productOption => {
          if (productOption.classifications) {
            var productOptionClassifierIdForGroupId = _.get(productOption.classifications, columnProductOptionClassifierGroupId);

            if (parseInt(productOptionClassifierIdForGroupId) === columnProductOptionClassifierId) {
              return productOption;
            }
          }
        })
        .sortBy('rank')
        .value();

      return productOptionsForColumn[0].id;
    };

    var pricesFor = ({rowProductInstances}) => {
      return _.map(rowProductInstances, productInstance => {
        var {expressions} = productPricingRuleVersionFor({productInstance}, {productsById, productPricingRulesById});

        var priceForProductInstance = priceFor(expressions, {productInstance, shouldRoundPrice: product.shouldRoundPrice}, {productOptionsById, productPricingRulesById, productOptionClassifiersById, productsById, productPropertiesById, productRulesById, productInstancesById});

        return formatPrice(priceForProductInstance);
      });
    };

    var filename = `${_.kebabCase(product.title)}-price-export-${moment().format('YYYY-MM-DD')}`;

    //HINT create headers
    var csv = [[
      _.find(productPropertiesById, {id: groupDataBy.productPropertyId}).title,
      _.find(productPropertiesById, {id: rowsData.productPropertyId}).title,
      ..._.flatMap(columnsData, columnData => {
        return _.map(columnData.productOptionClassifierIds, (productOptionClassifierId) => {
          return _.find(productOptionClassifiersById, {id: productOptionClassifierId}).title;
        });
      })
    ]];

    //HINT create groups
    _.forEach(groupDataBy.productOptionsIds, (groupByProductOptionId) => {
      var groupByProductOptionTitle = _.find(productOptionsById, {id: groupByProductOptionId}).title;

      //HINT create rows by productOptionIds
      if (rowsData.productOptionIds) {
        var rowProductOptionIds = rowsData.productOptionIds;

        //HINT get all productOptionIds for productPropertyId if empty
        if (_.isEmpty(rowsData.productOptionIds)) {
          rowProductOptionIds = _.map(productOptionsByIdFor({productPropertyId: rowsData.productPropertyId}, {productOptionsById}), ({id}) => id);
        }

        _.forEach(rowProductOptionIds, rowProductOptionId => {
          if (csv.length < 10000) { //HINT limit to 10,000 rows
            var rowProductInstances = [];
            var rowProductOption = _.get(productOptionsById, rowProductOptionId);

            //HINT create columns for row
            _.forEach(columnsData, columnData => {
              _.forEach(columnData.productOptionClassifierIds, columnProductOptionClassifierId => {
                var productInstance = productInstanceFor({
                  groupByProductOptionId,
                  rowProductOptionId,
                  columnProductOptionId: columnProductOptionIdFor({columnProductOptionClassifierId, columnData}),
                  columnData
                });

                var rowProductProperty = _.get(productPropertiesById, rowsData.productPropertyId);
                var validRowProductOptionIdsForRow = _.map(productOptionsForProperty({productProperty: rowProductProperty, productInstance}, {productRulesById, productOptionsById, productsById}), ({id}) => id);

                var isValidProductInstance = _.includes(validRowProductOptionIdsForRow, rowProductOptionId);

                if (isValidProductInstance) rowProductInstances.push(productInstance);
              });
            });

            if (rowProductInstances.length > 0) {
              csv.push([groupByProductOptionTitle, rowProductOption.title, ...pricesFor({rowProductInstances})]);
            }
          }
        });
      }
      //HINT create rows by productOptionClassifierIds
      else if (rowsData.productOptionClassifierIds) {
        _.forEach(rowsData.productOptionClassifierIds, rowProductOptionClassifierId => {
          if (csv.length < 10000) { //HINT limit to 10,000 rows
            var rowProductInstances = [];

            var rowProductOptionClassifier = _.find(productOptionClassifiersById, {id: rowProductOptionClassifierId});
            var rowProductOptionClassifierGroupId = rowProductOptionClassifier.productOptionClassifierGroupId;

            var productOptionsForRow = _.chain(productOptionsById)
              .reject('archived')
              .filter({productPropertyId: rowsData.productPropertyId})
              .filter(productOption => {
                if (productOption.classifications) {
                  var productOptionClassifierIdForGroupId = _.get(productOption.classifications, rowProductOptionClassifierGroupId);

                  if (parseInt(productOptionClassifierIdForGroupId) === rowProductOptionClassifierId) {
                    return productOption;
                  }
                }
              })
              .sortBy('rank')
              .value();

            if (productOptionsForRow.length > 0) {
              //HINT create columns
              _.forEach(columnsData, columnData => {
                _.forEach(columnData.productOptionClassifierIds, columnProductOptionClassifierId => {
                  var productInstance = productInstanceFor({
                    groupByProductOptionId,
                    rowProductOptionId: productOptionsForRow[0].id,
                    columnProductOptionId: columnProductOptionIdFor({columnProductOptionClassifierId, columnData}),
                    columnData
                  });

                  var rowProductProperty = _.get(this.props.productPropertiesById, rowsData.productPropertyId);
                  var validRowProductOptionIdsForRow = _.map(productOptionsForProperty({productProperty: rowProductProperty, productInstance}, {productRulesById, productOptionsById, productsById}), ({id}) => id);

                  var isValidProductInstance = _.some(_.map(productOptionsForRow, productOption => {
                    return _.includes(validRowProductOptionIdsForRow, productOption.id);
                  }));

                  if (isValidProductInstance) rowProductInstances.push(productInstance);
                });
              });
            }

            if (rowProductInstances.length > 0) {
              csv.push([groupByProductOptionTitle, rowProductOptionClassifier.title, ...pricesFor({rowProductInstances})]);
            }
          }
        });
      }

      //HINT create empty row to seperate groups
      csv.push([]);
    });

    downloadCsv({csv, filename});
  };

  duplicateProduct = async () => {
    var {product} = this.props;

    var newProduct = await api.create('product', {
      ..._.omit(product, ['id', 'created', 'lastUpdated', 'lastUpdatedBy', 'deleted', 'archived', 'mediumId', 'rank']),
      title: `${product.title} (copy)`
    });

    this.props.trackProducts({products: [newProduct]});

    setTimeout(() => {
      if (confirm('This product has been successfully duplicated. Would you like to be redirect to the new product?')) {
        this.props.history.push(`/admin/products/${newProduct.id}`);
      }
    });
  };

  render() {
    var {product, productPropertiesById, productCategoriesById} = this.props;

    var productPropertyAssociations = _.get(product, 'associations.productProperties');
    var sortedProductProperties = _.chain(productPropertyAssociations)
      .map((_value, productPropertyId) => productPropertiesById[productPropertyId])
      .filter(productProperty => !!productProperty)
      .sortBy('rank')
      .value();

    var productCategoryOptions = _.map(productCategoriesById, (category) => ({title: category.title, value: category.id}));

    var referenceCodeTypes = [
      {title: 'Catalog', key: 'catalog'},
      {title: 'Factory', key: 'factory'},
      {title: 'Machining', key: 'machining'}
    ];

    return (
      <DocumentTitle title={`Admin: ${product.title} - Product`}>
        <View style={{flex: 1, flexDirection: 'row'}}>
          <AdminMenu activeOrg={this.props.session.activeOrg} />
          <View style={{flex: 1}}>
            <SecondaryHeader
              title={product.title}
              titleIsEditable
              onTitleChange={({value}) => this.handleProductPropsChange({propKey: 'title', value})}
              subTitle={`ID: ${product.id}`}
              rightComponent={(
                <View style={{position: 'relative', marginRight: K.margin, flexDirection: 'row', alignItems: 'end'}}>
                  <Button dark label='Duplicate' onPress={this.duplicateProduct} style={{marginRight: K.spacing}}/>
                  {!!this.props.product.isStocked &&
                    <LabelledView label='Amount in stock' styles={{outerView: {marginRight: K.spacing}}}>
                      <TextInput
                        style={{height: K.inputHeight, width: 135, backgroundColor: K.colors.doubleGray}}
                        value={this.props.product.currentInventory}
                        onChange={({value}) => this.props.updateProduct({id: this.props.product.id, props: {currentInventory: parseInt(value)}})}
                      />
                    </LabelledView>
                  }
                  {this.props.session.activeOrg.role === 'owner' &&
                    <LabelledView gray label={'Product Visibility Level'} styles={{outerView: {marginBottom: 0}, innerView: {paddingTop: K.spacing}}}>
                      <PickerInput
                        inline
                        basic
                        style={{height: K.inputHeight, width: 200}}
                        buttonStyle={{backgroundColor: K.colors.gray}}
                        options={publishLevelOptions}
                        value={product.publishLevel}
                        onChange={({value}) => this.handleProductPropsChange({propKey: 'publishLevel', value})}
                      />
                    </LabelledView>
                  }
                </View>
              )}
              belowHeaderComponent={(
                <View style={{marginTop: K.spacing * 2, flexDirection: 'column'}}>
                  <View style={{flexDirection: 'row'}}>
                    <Button
                      icon={settingsIcon}
                      onPress={() => this.handleSettingsPopupChange(this.state.productSettingsPopupIsVisible)}
                      style={{backgroundColor: 'transparent', margin: K.margin, ...K.defaultIconSize}}
                    />
                    {product.exportPriceData && (
                      <Tooltip left text='Export Prices'>
                        <Button
                          style={{backgroundColor: K.color, margin: K.margin, ...K.defaultIconSize}}
                          onPress={() => this.handleExportPricesButtonPress()}
                          icon={downloadIcon}
                        />
                      </Tooltip>
                    )}
                  </View>
                </View>
              )}
            ></SecondaryHeader>
            <ScrollView style={{flex: 1}} contentContainerStyle={{padding: K.spacing, flex: 1}}>
              <AdminSection title={'SKU'} contentStyle={{flexDirection: 'row'}}>
                {_.map(referenceCodeTypes, ({title, key}) => (
                  <TextInput
                    key={key}
                    label={title}
                    labelledViewStyles={{outerView: {marginBottom: K.margin, marginRight: K.margin, width: 200}}}
                    onChange={({value}) => this.handleReferenceCodeChange({value, key})}
                    value={product.referenceCodes[key] || ''}
                  />
                ))}
              </AdminSection>
              <AdminSection title={'Properties'}>
                <View style={{flexDirection: 'row', marginBottom: K.spacing}}>
                  {!_.isEmpty(productPropertyAssociations) ? (
                    <View style={{flexDirection: 'row', flexWrap: 'wrap', width: '100%'}}>
                      {_.map(sortedProductProperties, (productProperty) => {
                        var disabledProductOptionIds = _.get(productPropertyAssociations, `${productProperty.id}.disabledProductOptionIds`, []);

                        return (
                          <ProductProperty
                            key={`property-${productProperty.id}`}
                            toggleProductOptionDisabled={this.toggleProductOptionDisabled}
                            {...{productProperty, mode: 'admin', disabledProductOptionIds}}
                          />
                        );
                      })}
                    </View>
                  ) : (
                    <View style={{paddingVertical: K.spacing * 1, paddingHorizontal: K.spacing, opacity: 0.5, textAlign: 'center'}}>
                      <Text>This product has no properties</Text>
                    </View>
                  )}
                </View>
                <Button label='Select Properties' style={{width: 200, padding: K.spacing, borderRadius: 20}} onPress={() => this.setState({productPropertiesPopupIsVisible: true})}/>
              </AdminSection>
            </ScrollView>
          </View>
          {this.state.productPropertiesPopupIsVisible && (
            <ProductPropertiesPopup
              onClose={() => this.setState({productPropertiesPopupIsVisible: false})}
              {...{product}}
            />
          )}
          {this.state.productSettingsPopupIsVisible && (
            <Popup onClose={() => {
              this.setState({uploading: false, uploadingWasSuccessful: false, uploadingHasFailed: false});
              this.handleSettingsPopupChange(this.state.productSettingsPopupIsVisible);
            }}>
              <TextInput
                style={{...K.fonts.pageHeader, paddingHorizontal: K.spacing, paddingVertical: 0, backgroundColor: 'white', marginTop: K.spacing, marginBottom: K.spacing}}
                value={product.title}
                onChange={({value}) => this.handleProductPropsChange({propKey: 'title', value})}
                blurOnEnter
                blurOnSubmit
                multiline
                standardAutoheightStyles
                returnKeyType='done'
              />
              <View style={{marginBottom: K.spacing, flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center'}}>
                <FileInput
                  containerStyle ={{height: 64}}
                  uploading={this.state.uploading}
                  uploadingWasSuccessful={this.state.uploadingWasSuccessful}
                  uploadingHasFailed = {this.state.uploadingHasFailed}
                  handleFilesPicked={(files) => handleFileUpload({files,
                    setState: (newState) => this.setState(newState),
                    api,
                    resource: this.props.product,
                    session: this.props.session,
                    resourceKey: 'product',
                    trackResource: ({products}) => this.props.trackProducts({products})
                  })}
                >
                  <Image source={this.props.product.thumbnailUrl ? {uri: this.props.product.thumbnailUrl} : thumbnailPlaceholder} style={{height: 40, width: 40, border: '1px solid black', borderRadius: 25}}/>
                </FileInput>
              </View>
              <LabelledView gray label={'Product Description'} styles={{outerView: {marginBottom: K.margin}}}>
                <TextInput
                  value={_.get(product, 'description', '')}
                  multiline
                  blurOnSubmit
                  standardAutoheightStyles
                  onChange={({value}) => this.handleProductPropsChange({propKey: 'description', value})}
                  labelledViewStyles={{outerView: {marginBottom: K.margin}}}
                />
              </LabelledView>
              <LabelledView gray label={'Links'} styles={{outerView: {marginBottom: K.margin}}}>
                {console.log(product)}
                <TextInput
                  value={_.get(product, 'links', '')}
                  multiline
                  blurOnSubmit
                  standardAutoheightStyles
                  onChange={({value}) => this.handleProductPropsChange({propKey: 'links', value})}
                  labelledViewStyles={{outerView: {marginBottom: K.margin}}}
                />
              </LabelledView>
              <CheckboxInput
                label={'Require custom label'}
                value={product.instanceTitleIsRequired}
                onChange={({value}) => this.props.updateProduct({id: this.props.product.id, props: {instanceTitleIsRequired: value}})}
                style={{marginVertical: K.margin}}
              />
              <CheckboxInput
                label={'Should round price'}
                value={product.shouldRoundPrice}
                onChange={({value}) => this.props.updateProduct({id: this.props.product.id, props: {shouldRoundPrice: value}})}
                style={{marginVertical: K.margin}}
              />
              <LabelledView gray label={'Product Category'} styles={{outerView: {marginBottom: (K.margin * 2) + 10, marginTop: K.margin}}}>
                <PickerInput
                  showDownArrow
                  style={{height: K.inputHeight}}
                  buttonStyle={{backgroundColor: K.colors.gray}}
                  options={[{title: 'Unassigned', value: -1}, ...productCategoryOptions]}
                  value={product.productCategoryId}
                  onChange={({value}) => this.handleProductPropsChange({propKey: 'productCategoryId', value})}
                />
              </LabelledView>
              <CheckboxInput
                label={'Is Stocked'}
                value={product.isStocked}
                onChange={({value}) => this.props.updateProduct({id: this.props.product.id, props: {isStocked: value}})}
                style={{marginVertical: K.margin}}
              />
              <CheckboxInput
                label={'Enable Dimensions'}
                value={product.hasScript}
                onChange={({value}) => this.props.updateProduct({id: this.props.product.id, props: {hasScript: value}})}
              />
              <Button
                light
                onPress={this.archive}
                label={this.props.product.archived === 0 ? 'ARCHIVE' : 'UNARCHIVE'}
                style={{marginVertical: K.margin * 3}}
              />
            </Popup>
          )}
          {this.state.productPropertiesPopupIsVisible && (
            <ProductPropertiesPopup
              onClose={() => this.setState({productPropertiesPopupIsVisible: false})}
              {...{product}}
            />
          )}
        </View>
      </DocumentTitle>
    );
  }
}

export default connect({
  mapState: (state, ownProps) => {
    var productId = parseInt(ownProps.match.params.productId);
    var product = _.get(state.resources.products.byId, productId);
    var productCategoriesById = _.get(state, 'resources.productCategories.byId');
    var productOrderId = 1;
    var productInstances = _.filter(state.resources.productInstances.byId, {productOrderId});
    var productsById = state.resources.products.byId;
    var productPricingRulesById = state.resources.productPricingRules.byId;
    var productPropertiesById = state.resources.productProperties.byId;
    var productOptionClassifiersById = state.resources.productOptionClassifiers.byId;
    var productOptionsById = state.resources.productOptions.byId;
    var productRulesById = state.resources.productRules.byId;
    var productInstancesById = state.resources.productInstances.byId;

    if (!product) {
      if (ownProps.history) ownProps.history.push('/404/product');
    }
    else {
      var productPropertiesById = state.resources.productProperties.byId;
    }

    return {
      product,
      productPropertiesById,
      productCategoriesById,
      products: state.resources.products.byId,
      productInstances,
      productsById,
      productPricingRulesById,
      productOptionClassifiersById,
      productOptionsById,
      productRulesById,
      productInstancesById
    };
  },
  mapDispatch: {
    ..._.pick(resourceActions.products, ['updateProduct', 'destroyProduct', 'trackProducts'])
  }
})(AdminProductPage);
