import { useContext, useEffect } from 'react';
import { toCanvas } from '~/helpers/canvas/canvas-helpers';
import { connect } from '@symbolic/redux';
import { resourceActions } from '~/redux';

import lib from '~/lib';
import K from '~/k';
import CanvasDataContext from '~/contexts/canvas-data-context';
import ArchDataContext from '~/contexts/arch-data-context';

import CanvasLine from '~/components/canvas/line/canvas-line';
import CanvasPath from '~/components/canvas/path/canvas-path';
import PathTool from '~/components/canvas/tools/path/path-tool';

function ProductCanvasLine({productCanvasLine, ...props}) {
  const canvasData = useContext(CanvasDataContext);
  const archData = useContext(ArchDataContext);
  const isSelected = archData.selectedEntityId === productCanvasLine.id && archData.selectedEntityResourceKey === 'productCanvasLine';

  useEffect(() => {
    const handleDestroyProductCanvasLine = (event) => {
      if (event.code === 'Backspace' && isSelected) {
        props.destroyProductCanvasLine({id: productCanvasLine.id});

        archData.setSelectedEntity(undefined, undefined);
      }
    };

    document.addEventListener('keydown', handleDestroyProductCanvasLine);

    return () => {
      document.removeEventListener('keydown', handleDestroyProductCanvasLine);
    };
  });

  const points = [];

  if (productCanvasLine.from) points.push(productCanvasLine.from);
  if (productCanvasLine.to) points.push(productCanvasLine.to);

  return (
    <>
      {!props.isStatic && (
        <PathTool
          isSelected={isSelected}
          isInAddMode={!productCanvasLine.to}
          onSelect={() => archData.setSelectedEntity(productCanvasLine.id, 'productCanvasLine')}
          onTransform={({points, transformEnd}) => {
            var from, to;

            if (points[0]) from = points[0];
            if (points[1]) to = points[1];

            if (!productCanvasLine.to) {
              archData.updateTentativeProductCanvasLine({tentativeProductCanvasLine: {...productCanvasLine, from, to}});
            }
            else {
              props.updateProductCanvasLine({id: productCanvasLine.id, props: {from, to}, hitApi: !!transformEnd});
            }
          }}
          points={points}
          color={K.colors.accent}
        />
      )}
      {productCanvasLine.to && (
        <>
          <CanvasLine from={toCanvas(productCanvasLine.from, canvasData)} to={toCanvas(productCanvasLine.to, canvasData)} strokeWidth={productCanvasLine.strokeWidth} onClick={() => archData.setSelectedEntity(productCanvasLine.id, 'productCanvasLine')}/>
          {productCanvasLine.toSymbol !== 'none' && (
            <CanvasPath {...{
              ...toCanvas(productCanvasLine.to, canvasData),
              width: canvasData.scale,
              height: canvasData.scale,
              data: 'M 0 0 L 4 -3 L 4 3 Z',
              fill: 'black',
              stroke: 'black',
              strokeWidth: productCanvasLine.strokeWidth,
              closed: true,
              opacity: 1,
              listening: false,
              rotation: lib.trig.radiansToDegrees(lib.trig.alpha({p1: productCanvasLine.to, p2: productCanvasLine.from})),
            }}/>
          )}
          {productCanvasLine.fromSymbol !== 'none' && (
            <CanvasPath {...{
              ...toCanvas(productCanvasLine.from, canvasData),
              width: canvasData.scale,
              height: canvasData.scale,
              data: 'M 0 0 L 4 -3 L 4 3 Z',
              fill: 'black',
              stroke: 'black',
              strokeWidth: productCanvasLine.strokeWidth,
              closed: true,
              opacity: 1,
              listening: false,
              rotation: lib.trig.radiansToDegrees(lib.trig.alpha({p1: productCanvasLine.from, p2: productCanvasLine.to})),
            }}/>
          )}
        </>
      )}
    </>
  );
}

export default connect({
  mapDispatch: {
    ..._.pick(resourceActions.productCanvasLines, ['updateProductCanvasLine', 'destroyProductCanvasLine'])
  }
})(ProductCanvasLine);
