import { PureComponent } from 'react';
import { View } from 'react-native';
import { Popup } from '@symbolic/rn-lib';

import K from '~/k';
import Medium from '~/components/medium';

class MediaPopup extends PureComponent {
  state = {
    fullScreenMode: false,
  };

  render() {
    var {relevantMedia} = this.props;

    //HINT popup width, Medium padding, Medium margin
    var size = (K.standardColumnWidth / 2) - (K.margin * 4) - (K.margin * 2);

    return (
      <Popup onClose={() => this.props.onClose()}>
        <View style={{flexWrap: 'wrap', flexDirection: 'row'}}>
          {_.map(relevantMedia, medium => (
            <Medium
              key={medium.id}
              style={{width: size, height: size, marginBottom: K.margin, marginRight: K.margin}}
              {...{medium}}
            />
          ))}
        </View>
      </Popup>
    );
  }
}

export default MediaPopup;
