import { useContext, PureComponent } from 'react';
import { Line } from 'react-konva';
import { roundPoint } from '~/helpers/arch/arch-helpers';
import { toReal, toCanvas, snap } from '~/helpers/canvas/canvas-helpers';

import _ from 'lodash';

import Grip from '~/components/canvas/tools/grip/grip';
import SnapDataContext from '~/contexts/snap-data-context';
import CanvasDataContext from '~/contexts/canvas-data-context';
import CanvasCursorObject from '~//components/canvas/cursor/canvas-cursor';

class PathTool extends PureComponent {
  state = {
    movingGripIndex: undefined
  };

  componentDidMount = () => {
    document.addEventListener('click', this.handleClick);
    document.addEventListener('keydown', this.handleKeyDown);
    document.addEventListener('keyup', this.handleKeyUp);
    document.addEventListener('mousemove', this.handleMouseMove);
  };

  componentWillUnmount = () => {
    document.removeEventListener('click', this.handleClick);
    document.removeEventListener('keydown', this.handleKeyDown);
    document.removeEventListener('keyup', this.handleKeyUp);
    document.removeEventListener('mousemove', this.handleMouseMove);
  };

  handleClick = (event) => {
    if (this.props.isInAddMode) {
      var point = roundPoint(toReal(this.snapPoint({x: event.layerX, y: event.layerY}), this.props.canvasData), this.props.canvasData.precision);
      var points = [...this.props.points, point];

      this.props.onTransform({points});
    }
  };

  handleMouseMove = (event) => {
    if (this.props.isInAddMode) {
      if (this.props.onAddModeMouseMove) this.props.onAddModeMouseMove({point: roundPoint(toReal(this.snapPoint({x: event.layerX, y: event.layerY}), this.props.canvasData), this.props.canvasData.precision)});

      this.setState({mousePosition: {x: event.layerX, y: event.layerY}});
    }
  };

  handleGripMoveStart = ({gripIndex}) => {
    this.setState({movingGripIndex: gripIndex});
  };

  handleGripMove = ({position, gripIndex}) => {
    var points = [...this.props.points];
    console.log(position)
    points[gripIndex] = position;

    this.props.onTransform({points});
  };

  handleGripMoveEnd = () => {
    this.setState({movingGripIndex: undefined});

    this.props.onTransform({points: this.props.points, transformEnd: true});
  };

  snapPoint = (point, {mode = 'canvas'} = {}) => {
    point = snap(point, this.candidateSnapPositions, this.props.canvasData, {mode}).position;

    if (this.props.points.length && this.props.canvasData.isShifting) {
      var prevPoint = toCanvas(_.last(this.props.points), this.props.canvasData);
      var snappedAxisKey = Math.abs(point.x - prevPoint.x) > Math.abs(point.y - prevPoint.y) ? 'y' : 'x';

      point = {...point, [snappedAxisKey]: prevPoint[snappedAxisKey]};
    }

    return point;
  };

  get candidateSnapPositions() {
    return [
      ...this.props.canvasData.getSnapData().candidateSnapPositions,
      ..._.filter(this.props.points, (point, index) => index !== this.state.movingGripIndex)
    ];
  }

  render() {
    var {points, isSelected, isInAddMode, canvasData, strokeWidth = 1} = this.props;
    var {candidateSnapPositions} = this;

    return (<>
      {!this.props.hideLine && points.length > 1 && (
        _.map(_.take(points), (point, index) => {
          var fromCanvasPosition = toCanvas(point, canvasData);
          var toCanvasPosition = toCanvas(points[index + 1], canvasData);

          return (
            <Line
              key={index}
              stroke={this.props.color || 'black'}
              strokeWidth={strokeWidth}
              hitStrokeWidth={5}
              onMouseDown={this.props.onSelect}
              points={[
                fromCanvasPosition.x,
                fromCanvasPosition.y,
                toCanvasPosition.x,
                toCanvasPosition.y
              ]}
            />
          );
        })
      )}
      {this.props.isInAddMode && points[0] && this.state.mousePosition && (
        <Line
          points={[
            ..._.values(toCanvas(points[0], this.props.canvasData)),
            ..._.values(this.snapPoint(this.state.mousePosition))
          ]}
          strokeWidth={strokeWidth}
          stroke={'black'}
          dash={[2, 2]}
          opacity={0.5}
        />
      )}
      {(isSelected || isInAddMode) && (_.map(points, (point, gripIndex) => {
        return (
          <Grip
            {...{gripIndex, candidateSnapPositions}}
            position={point}
            key={gripIndex}
            canvasData={this.props.canvasData}
            onMove={this.handleGripMove}
            onMoveStart={this.handleGripMoveStart}
            onMoveEnd={this.handleGripMoveEnd}
          />
        );
      }))}
      {isInAddMode && this.state.mousePosition && (
        <CanvasCursorObject {...{canvasData}} mousePosition={this.snapPoint(this.state.mousePosition)} />
      )}
    </>);
  }
}

export default function PathToolWithContext(props) {
  var canvasData = useContext(CanvasDataContext);
  var snapData = useContext(SnapDataContext);

  return <PathTool {...props} {...{canvasData, snapData}}/>;
}
