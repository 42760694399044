import K from '~/k';

const handleExportPanelsAsSvgs = async ({calicoMuralPanels, filename, activeCustomViewId}) => {
  var calicoMuralPanelsInCustomView = _.filter(calicoMuralPanels, ['data.archCustomViewId', activeCustomViewId]);

  var pages = {
    printPanelLayout: {
      texture: 'test',
      filename: `${filename}.svg`,
      panels: _.map(calicoMuralPanelsInCustomView, (calicoMuralPanel) => {
        var rect = `\n<rect x="${calicoMuralPanel.data.position.x}" y="${calicoMuralPanel.data.position.y}" width="${calicoMuralPanel.data.size.width}" height="${calicoMuralPanel.data.size.height}"/>`;

        return rect;
      })
    },
  };

  _.forEach(pages, (page) => {
    var svg = `<svg>${_.join(page.panels, '')}\n</svg>`;

    if (K.isWeb) {
      var blob = new Blob([svg], { type: 'image/svg+xml'});
      if (navigator.msSaveBlob) { // IE 10+
        navigator.msSaveBlob(blob, page.filename);
      }
      else {
        var link = document.createElement('a');
        if (link.download !== undefined) { // feature detection
          // Browsers that support HTML5 download attribute
          var url = URL.createObjectURL(blob);
          link.setAttribute('href', url);
          link.setAttribute('download', page.filename);
          link.style.visibility = 'hidden';
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        }
      }
    }
  });
};

export {handleExportPanelsAsSvgs};
