var getSizeForProductInstance = ({productInstance}) => {
  var productIdToSizeProductPropertyIdsMap = {
    104: {width: 141, height: 142},
    105: {width: 151, height: 152}
  };

  var sizeProductPropertyIds = productIdToSizeProductPropertyIdsMap[productInstance.productId];

  //TODO default size
  var size = {width: productInstance.properties[sizeProductPropertyIds.width]?.value || 600, height: productInstance.properties[sizeProductPropertyIds.height]?.value || 2000};

  return size;
};

export default getSizeForProductInstance;
