import { PureComponent } from 'react';
import { View, Image, ScrollView } from 'react-native';
import { connect } from '@symbolic/redux';
import { resourceActions } from '~/redux';
import K from '~/k';
import _ from 'lodash';
import { TouchableOpacity } from 'react-native-web';
import upArrowIcon from '~/assets/up-arrow-black.png';

class SymbolsDropdown extends PureComponent {
  render () {
    var {engravingLocationKey, symbolFileNames, mechanismIndex, inputIndex, showDropdown, activeSymbolFileName} = this.props;

    var styles = {
      dropdownArrowIcon: {
        ...(showDropdown ? {transform: 'rotate(180deg)'} : {}),
        width: 20,
        height: 20,
        margin: K.spacing / 2
      },
      symbolIcon: {
        maxWidth: 20, maxHeight: 20,
        margin: K.spacing / 2,
      },
    };

    return (
      <View style={{borderRadius: 5, backgroundColor: K.colors.gray, display: 'flex', flexDirection: 'column', alignItems: 'center', flex: 1}}>
        <TouchableOpacity
          style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between', width: '100%'}}
          onPress={() => this.props.handleUpdateShowDropdown({value: !showDropdown})}
        >
          <View style={{width: 20, height: 20, margin: K.spacing / 2}}>
            {activeSymbolFileName !== '' && (
              <Image source={require(`../../assets/meljac/symbols/${String(activeSymbolFileName)}.png`)} style={{width: 20, height: 20, margin: 0, padding: 0}} />
            )}
          </View>
          <Image source={upArrowIcon} style={{...styles.dropdownArrowIcon, opacity: 0.7, marginRight: K.spacing}} />
        </TouchableOpacity>
        {showDropdown && (<>
          <ScrollView contentContainerStyle={{width: '100%', display: 'flex', flexDirection: 'row', flexWrap: 'wrap', position: 'relative'}}>
            {_.map(symbolFileNames, (symbolFileName, index) => {
              return (
                <TouchableOpacity
                  key={`symbol-${engravingLocationKey}-${symbolFileNames[index]}`}
                  onPress={() => this.props.handleUpdateSymbol({mechanismIndex, engravingLocationKey, inputIndex, type: 'symbol', value: symbolFileName})}
                  style={styles.symbolIcon}
                >
                  <Image
                    source={require(`../../assets/meljac/symbols/${symbolFileName}.png`)}
                    style={{width: 20, height: 20}}
                  />
                </TouchableOpacity>
              );
            })}
          </ScrollView>
        </>)}
      </View>
    );
  }
}

class EngravingSymbolsPicker extends PureComponent {
  state = {
    showDropdown: false
  };

  handleUpdateShowDropdown = ({value}) => {
    this.setState({showDropdown: value});
  };

  handleUpdateSymbol = ({mechanismIndex, engravingLocationKey, inputIndex, type, value}) => {
    this.props.handleUpdateActiveSymbolFileName({value});

    this.props.handleUpdateEngravings({mechanismIndex, engravingLocationKey, inputIndex, type, value});
  };

  render() {
    var {inputIndex, mechanismIndex, engravingLocationKey} = this.props;

    var symbolFileNames = [18009, 18010, 18015, 18021, 18023, 18025, 18026, 18027, 18034, 18036, 18038, 18039, 18040, 18046, 18103, 18111, 18114, 18133, 18138, 18139, 18197, 18210, 18220, 18224, 18227, 18228, 18234, 18235, 18236, 18237, 18253, 18302, 18313, 18319, 18328, 18357, 18376, 18381, 18384, 18390, 18494, 18523, 18524, 18526, 18537, 18541, 18595, 18596, 18597, 18598, 18599];

    return (<>
      <View style={{flex: 1, flexDirection: 'row', flexWrap: 'wrap', justifyContent: 'space-between', maxHeight: 200, gap: K.spacing / 2}}>
        <SymbolsDropdown
          {...{symbolFileNames, engravingLocationKey, mechanismIndex, inputIndex}}
          handleUpdateShowDropdown={this.handleUpdateShowDropdown}
          showDropdown={this.state.showDropdown}
          activeSymbolFileName={this.props.activeSymbolFileName}
          handleUpdateSymbol={this.handleUpdateSymbol}
        />
      </View>
    </>);
  }
}

export default connect({
  mapDispatch: {
    ..._.pick(resourceActions.productInstances, ['updateProductInstance']),
  },
})(EngravingSymbolsPicker);
