import { Component } from 'react';
import { View } from 'react-native';
import { Text } from '@symbolic/rn-lib';
import { formatPrice } from '~/helpers/price-helper';
import _ from 'lodash';
import K from '~/k';
import moment from 'moment';

class OrderCostsTable extends Component {
  render() {
    var {orderCosts, paymentAmount, productOrder, productInstances, styles = {}, hideInvoiceDiscount, payInFull, showAmountPaid = false, showAmountDue = false, shouldApplyUpcharge = false, upchargePercentage, upchargeAmount, showPayments = false, completedPaymentStatuses} = this.props;
    var totalProductItems = _.sumBy(productInstances, 'quantity');
    var discountMode = _.get(productOrder, 'discountMode');

    var showDeposit = !productOrder.isStocked && !payInFull && paymentAmount > 0 && productOrder.amountPaidInCents < paymentAmount;
    var depositPercentage = productOrder.isStocked ? ' (100%)' : orderCosts.depositPercentage === 100 ? '' : ` (${orderCosts.depositPercentage}%)`;

    var amountDueAmount = paymentAmount >= 0 ? paymentAmount : 0;

    return (
      <View style={{...styles.outerView}}>
        <View style={{flexDirection: 'row', justifyContent: 'space-between', ...styles.row}}>
          <Text style={styles.title}>{`Subtotal (${totalProductItems} items)`} </Text>
          <Text style={styles.value}>${formatPrice(orderCosts.orderSubTotal)}</Text>
        </View>
        {((orderCosts.discount !== 0) && !hideInvoiceDiscount && (
          <View style={{flexDirection: 'row', justifyContent: 'space-between', ...styles.row}}>
            <Text style={styles.title}>{`Discount ${discountMode === 'amount' ? '' : '(' + productOrder.discountPercentage + '%)'}`}</Text>
            <Text style={styles.value}>{`-$${formatPrice(orderCosts.discount)}`}</Text>
          </View>
        ))}
        <View style={{flexDirection: 'row', justifyContent: 'space-between', ...styles.row}}>
          <Text style={styles.title}>Shipping & Handling</Text>
          <Text style={styles.value}>{orderCosts.shippingIsTbd ? 'TBD' : '$' + formatPrice(orderCosts.shipping)}</Text>
        </View>
        {(!productOrder.disableSalesTax && orderCosts.tax !== 0) && (
          <View style={{flexDirection: 'row', justifyContent: 'space-between', ...styles.row}}>
            <Text style={styles.title}>Tax</Text>
            <Text style={styles.value}>${formatPrice(orderCosts.tax)}</Text>
          </View>
        )}
        <View style={{flexDirection: 'row', justifyContent: 'space-between', ...styles.row}}>
          <Text style={{...(styles.totalTitle || styles.title)}}>Total</Text>
          <Text style={styles.value}>${formatPrice(orderCosts.orderTotal)}</Text>
        </View>
        {(showDeposit && (
          <View style={{flexDirection: 'row', justifyContent: 'space-between', ...styles.row}}>
            <Text style={styles.title}>{`Deposit${depositPercentage}`}</Text>
            <Text style={styles.value}>{`$${formatPrice(paymentAmount)}`}</Text>
          </View>
        ))}
        {((productOrder.amountPaidInCents !== 0 || showAmountPaid) && (
          <View style={{flexDirection: 'row', justifyContent: 'space-between', marginTop: K.spacing, ...styles.row}}>
            <Text style={styles.title}>Amount Paid</Text>
            <Text style={styles.value}>{`$${formatPrice(productOrder.amountPaidInCents)}`}</Text>
          </View>
        ))}
        {(showPayments) && (
          _.map(_.filter(completedPaymentStatuses, 'paymentAmountInCents'), (statusData, index) => {
            var {date, paymentAmountInCents, upchargeAmountInCents, paymentAmountBeforeUpchargeInCents} = statusData;

            return (
              <View key={index} style={{marginTop: K.spacing / 2}}>
                <View style={{
                  flexDirection: 'row', justifyContent: 'space-between', opacity: 0.5, ...styles.row
                }}>
                  <Text style={{...styles.title, fontWeight: 'normal'}}>{`${moment(date).format('M/D/YYYY')}`}</Text>
                </View>
                {(upchargeAmountInCents && upchargeAmountInCents > 0) ? (
                  <View>
                    <View style={{
                      flexDirection: 'row', justifyContent: 'space-between', opacity: 0.5, ...styles.row
                    }}>
                      <Text style={styles.title}>{'Amount Paid'}</Text>
                      <Text style={styles.value}>{`$${formatPrice(paymentAmountBeforeUpchargeInCents)}`}</Text>
                    </View>
                    <View style={{
                      flexDirection: 'row', justifyContent: 'space-between', opacity: 0.5, ...styles.row
                    }}>
                      <Text style={styles.title}>{`Processing fee (${parseFloat(upchargePercentage / 10, 3)}%)`}</Text>
                      <Text style={styles.value}>{`$${formatPrice(upchargeAmountInCents)}`}</Text>
                    </View>
                    <View style={{
                      flexDirection: 'row', justifyContent: 'space-between', opacity: 0.5, ...styles.row
                    }}>
                      <Text style={styles.title}>{'Payment Total'}</Text>
                      <Text style={styles.value}>{`$${formatPrice(paymentAmountInCents)}`}</Text>
                    </View>
                  </View>
                ) : (
                  <View style={{
                    flexDirection: 'row', justifyContent: 'space-between', opacity: 0.5, ...styles.row
                  }}>
                    <Text style={styles.title}>{'Amount Paid'}</Text>
                    <Text style={styles.value}>{`$${formatPrice(paymentAmountInCents)}`}</Text>
                  </View>
                )}
              </View>
            );
          })
        )}
        {(showAmountDue) && (
          <View style={{flexDirection: 'row', justifyContent: 'space-between', marginTop: K.spacing, ...styles.row}}>
            <Text style={styles.title}>Amount Due</Text>
            <Text style={styles.value}>{`$${formatPrice(amountDueAmount)}`}</Text>
          </View>
        )}
        {(shouldApplyUpcharge) && (
          <View style={{flexDirection: 'row', justifyContent: 'space-between', ...styles.row}}>
            <Text style={styles.title}>{`Processing fee (${parseFloat(upchargePercentage / 10, 3)}%)`}</Text>
            <Text style={styles.value}>{`$${formatPrice(upchargeAmount)}`}</Text>
          </View>
        )}
      </View>
    );
  }
}

export default OrderCostsTable;
