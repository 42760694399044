export default function getDemoLightGraphicNodes({deps, group, rect, circle, path, dimension}) {
  var {productOptionsByIdData, productOptionsById, productInstance, productsById, productPropertiesById, productRulesById, productInstancesById, getTextHeight, defaultProductOptionsForInstanceProperties} = deps;
  var {propertiesDataById} = productInstance;

  var hardwareFill = _.get(propertiesDataById, '88.selectedProductOption.data.fill');
  var overallHeight = _.get(propertiesDataById, '90.selectedProductOption.data.height') * 3;
  var isWarm = _.get(propertiesDataById, '89.selectedProductOption.id') === 530;
  var glowFill = _.get(propertiesDataById, '91.selectedProductOption.data.color');
  var overallWidth = 50;
  var heightOffset = overallHeight - 50;

  return group({width: overallWidth, height: overallHeight}, [

    //glows
    radialGradient({id: 'glow'}, [{offset: '0%', color: glowFill}, {offset: '100%', color: 'rgba(255, 255, 255, 0)'}]),
    ellipse({rx: 30, ry: 20, cx: 10, cy: heightOffset + 20, fill: 'url(#glow)', opacity: 0.4}),
    ellipse({rx: 30, ry: 20, cx: 20, cy: heightOffset + 50, fill: 'url(#glow)', opacity: 0.4}),
    ellipse({rx: 30, ry: 20, cx: 40, cy: heightOffset + 10, fill: 'url(#glow)', opacity: 0.4}),

    //light 1
    group({x: 10, y: 8}, [

      //ceiling mount
      ellipse({rx: 2, ry: 1, fill: hardwareFill}),

      //cord
      rect({y: 0, x: -0.25 / 2, width: 0.25, height: heightOffset, fill: hardwareFill}),

      group({y: heightOffset - 1}, [

        //cone
        linearGradient({id: 'cone1', gradientTransform: 'rotate(90)'}, [{offset: '0%', color: isWarm ? '#E94747' : '#47C2E9'}, {offset: '100%', color: isWarm ? '#F1DFAF' : '#AFF1C2'}]),
        path({fill: 'url(#cone1)', opacity: 0.8, x: 0, y: 0, d: [
          {command: 'M', x: 0, y: 0},
          {command: 'L', x: -6, y: 12},
          {command: 'A', rx: 5, ry: 1, xAxisRotation: 0, largeArcFlag: 0, sweepFlag: 0, x: 6, y: 12}
        ]}),

        //bulb
        dropShadow({id: 'bulb1', color: '#fff', opacity: 1, blur: 1}),
        circle({cx: 0, cy: 3.3, r: 1, fill: '#fff', opacity: 0.6, filter: 'url(#bulb1)'}),

        //black attachment
        path({fill: hardwareFill, opacity: 0.8, x: 0, y: 0, d: [
          {command: 'M', x: 0, y: 0},
          {command: 'L', x: -1.5, y: 3},
          {command: 'L', x: 1.5, y: 3},
          {command: 'A', rx: 5, ry: 5, xAxisRotation: 0, largeArcFlag: 0, sweepFlag: 0, x: 1.5, y: 3}
        ]}),
      ])
    ]),

    //light 2
    group({x: 20, y: 2}, [

      //ceiling mount
      ellipse({rx: 2, ry: 1, fill: hardwareFill}),

      //cord
      rect({y: 0, x: -0.5 / 2, width: 0.5, height: heightOffset + 30, fill: hardwareFill}),

      group({y: heightOffset + 30}, [

        //cone
        linearGradient({id: 'cone2', gradientTransform: 'rotate(90)'}, [{offset: '0%', color: isWarm ? '#F0E3B6' : '#839DF8'}, {offset: '100%', color: isWarm ? '#FFB966' : '#B6DFF0'}]),
        path({fill: 'url(#cone2)', opacity: 0.8, x: 0, y: 2, d: [
          {command: 'M', x: 4, y: 0},
          {command: 'A', rx: 5, ry: 5, xAxisRotation: 0, largeArcFlag: 0, sweepFlag: 0, x: -4, y: 0},
          {command: 'L', x: -4, y: 12},
          {command: 'A', rx: 5, ry: 1.5, xAxisRotation: 0, largeArcFlag: 0, sweepFlag: 0, x: 4, y: 12}
        ]}),

        //bulb
        dropShadow({id: 'bulb2', color: '#fff', opacity: 1, blur: 1}),
        circle({cx: 0, cy: 2.5, r: 2, fill: '#fff', opacity: 0.6, filter: 'url(#bulb2)'}),

        //black attachment
        path({fill: hardwareFill, x: 0, y: 2, d: [
          {command: 'M', x: 4, y: 0},
          {command: 'A', rx: 5, ry: 5, xAxisRotation: 0, largeArcFlag: 0, sweepFlag: 0, x: -4, y: 0},
          {command: 'A', rx: 5, ry: 1.5, xAxisRotation: 0, largeArcFlag: 0, sweepFlag: 0, x: 4, y: 0}
        ]})
      ])
    ]),

    //light 3
    group({x: 40, y: 6, padding: 30}, [

      //ceiling mount
      ellipse({rx: 2, ry: 1, fill: hardwareFill}),

      //cord
      rect({y: 0, x: -0.75 / 2, width: 0.75, height: heightOffset - 10, fill: hardwareFill}),

      group({y: heightOffset - 10}, [

        //cone
        radialGradient({id: 'cone3'}, [{offset: '0%', color: '#FFDADA'}, {offset: '100%', color: isWarm ? '#FF8F8F' : '#8291DF'}]),
        circle({cx: 0, cy: 10, r: 10, opacity: 0.8, fill: 'url(#cone3)'}),

        //bulb
        dropShadow({id: 'bulb3', color: '#fff', opacity: 1, blur: 5}),
        circle({cx: 0, cy: 6, r: 5, fill: '#fff', opacity: 0.6, filter: 'url(#bulb3)'}),

        //black attachment
        path({fill: hardwareFill, x: 0, y: 4, d: [
          {command: 'M', x: 8, y: 0},
          {command: 'A', rx: 10, ry: 10, xAxisRotation: 0, largeArcFlag: 0, sweepFlag: 0, x: -8, y: 0},
          {command: 'A', rx: 10, ry: 2, xAxisRotation: 0, largeArcFlag: 0, sweepFlag: 0, x: 8, y: 0}
        ]})
      ])
    ])

    // linearGradient({}, []),
    // path({d: [
    //   {command: 'M', x: 11.66, y: 22.24},
    //   {command: 'l', x: 0, y: -2.32},
    //   {command: 'a', rx: 2, ry: 2.5, xAxisRotation: 0, largeArcFlag:0, sweepFlag: 1, x: 4.54, y: 0},
    // ]})
  ]);
}
