import { Component } from 'react';
import { View } from 'react-native';
import { CheckboxInput } from '@symbolic/rn-lib';

import _ from 'lodash';
import K from '~/k';
import HudElement from '~/components/hud-element';

class VisibilityLayersHudElement extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    var {visibilityLayers, viewMode, updateVisibilityLayers} = this.props;
    var layers = [
      {key: 'calicoMuralTextures', title: 'Murals'},
      {key: 'calicoMuralPanels', title: 'Panels & Seams'},
      {key: 'dimensions', title: 'Dimensions'}
    ];

    return (
      <HudElement x='right' y='top'>
        <View style={{marginTop: viewMode === 'front' ? 150 : 0, marginRight: K.spacing, marginTop: K.spacing}}>
          {_.map(layers, layer => (
            <View key={layer.key} style={{marginBottom: 2, flexDirection: 'row', alignItems: 'center'}}>
              <CheckboxInput
                label={layer.title}
                value={visibilityLayers[layer.key]}
                onChange={() => updateVisibilityLayers({key: layer.key, isVisible: !visibilityLayers[layer.key]})}
                style={{backgroundColor: 'none', width: '100%', marginBottom: -5}}
                labelStyles={{textAlign: 'right', top: -3, position: 'relative'}}
                checkboxStyle={{borderTopRightRadius: undefined, borderBottomRightRadius: undefined, borderRadius: 100, width: 24, height: 24}}
              />
            </View>
          ))}
        </View>
      </HudElement>
    );
  }
}

export default VisibilityLayersHudElement;
