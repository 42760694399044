export default function getFlooringGraphicNodes({deps, group, path, dimension, shortDimension}) {
  var {productInstance} = deps;
  var {propertiesDataById} = productInstance;

  var boardLengthData = _.get(propertiesDataById, '60.selectedProductOption.data');
  var boardLength = _.has(boardLengthData, 'mm') ? boardLengthData.mm : boardLengthData.in * 25.4;
  var customLengthTitle = _.get(propertiesDataById, '60.selectedProductOption.title');

  var boardWidthData = _.get(propertiesDataById, '48.selectedProductOption.data');
  var boardWidth = _.has(boardWidthData, 'mm') ? boardWidthData.mm : boardWidthData.in * 25.4;

  var boardThicknessData = _.get(propertiesDataById, '47.selectedProductOption.data');
  var boardThickness = _.has(boardThicknessData, 'mm') ? boardThicknessData.mm : boardThicknessData.in * 25.4;

  var tongueLength = .3;

  var viewWidth = 200;
  if (boardLength >= 0 && boardLength <= 5999) viewWidth = 210;
  if (boardLength >= 6000 && boardLength <= 7999) viewWidth = 220;
  if (boardLength >= 8000 && boardLength <= 9999) viewWidth = 230;
  if (boardLength >= 10000) viewWidth = 240;

  var scaleFactor = viewWidth / boardLength;

  boardWidth = boardWidth * scaleFactor;
  boardLength = boardLength * scaleFactor;
  boardThickness = boardThickness * scaleFactor;

  var boardTop = group({width: boardLength + (boardWidth / Math.sqrt(2)), height: boardWidth / Math.sqrt(2), strokeWidth: 0, stroke: 'black', fill: '#7C4F00', fillOpacity: .25, x: 0, y: 0}, [
    path({d: [
      {command: 'M', x: 0, y: 0},
      {command: 'H', x: boardLength},
      {command: 'L', x: (boardWidth / Math.sqrt(2)) + boardLength, y: boardWidth / Math.sqrt(2)},
      {command: 'H', x: boardWidth / Math.sqrt(2)},
      {command: 'Z'}
    ]}),
  ]);

  var forwardFacingSide = group({width: boardLength, height: boardThickness, x: boardWidth / Math.sqrt(2), y: boardWidth / Math.sqrt(2), strokeWidth: 0, stroke: 'black', fill: 'transparent'}, [
    //Top groove
    path({d: [
      {command: 'M', x: 0, y: 0},
      {command: 'V', y: boardThickness * .33},
      {command: 'H', x: boardLength},
      {command: 'V', y: 0},
      {command: 'Z'}
    ], fill: '#7C4F00', fillOpacity: .45}),
    //Tongue
    path({d: [
      {command: 'M', x: 0, y: boardThickness * .33},
      {command: 'V', y: boardThickness * .66},
      {command: 'H', x: boardLength},
      {command: 'L', x: boardLength - (tongueLength / Math.sqrt(2)), y: (boardThickness * .66) - (tongueLength / Math.sqrt(2))},
      {command: 'V', y: boardThickness * .33},
      {command: 'Z'}
    ], fill: '#7C4F00', fillOpacity: .75}),
    //Bottom groove
    path({d: [
      {command: 'M', x: 0, y: boardThickness * .66},
      {command: 'H', x: boardLength},
      {command: 'V', y: boardThickness},
      {command: 'H', x: 0},
      {command: 'Z'}
    ], fill: '#7C4F00', fillOpacity: .45})
  ]);

  var outerFacingSide = group({width: (boardWidth / Math.sqrt(2)) + (tongueLength / Math.sqrt(2)), height: boardWidth / Math.sqrt(2), x: 0, y: 0, strokeWidth: 0, stroke: 'black', fill: 'transparent'}, [
    path({d: [
      {command: 'M', x: 0, y: 0},
      {command: 'V', y: boardThickness * .33},
      {command: 'L', x: - (tongueLength / Math.sqrt(2)), y: (boardThickness * .33) - (tongueLength / Math.sqrt(2))},
      {command: 'V', y: (boardThickness * .66) - (tongueLength / Math.sqrt(2))},
      {command: 'L', x: 0, y: boardThickness * .66},
      {command: 'V', y: boardThickness},
      {command: 'L', x: boardWidth / Math.sqrt(2), y: (boardWidth / Math.sqrt(2)) + boardThickness},
      {command: 'V', y: (boardWidth / Math.sqrt(2)) + (boardThickness * .66)},
      {command: 'L', x: (boardWidth / Math.sqrt(2)) - (tongueLength / Math.sqrt(2)), y: ((boardWidth / Math.sqrt(2)) + (boardThickness * .33)) - (tongueLength / Math.sqrt(2))},
      {command: 'V', y: ((boardWidth / Math.sqrt(2)) + (boardThickness * .33)) - (tongueLength / Math.sqrt(2))},
      {command: 'L', x: boardWidth / Math.sqrt(2), y: (boardWidth / Math.sqrt(2)) + (boardThickness * .33)},
      {command: 'V', y: boardWidth / Math.sqrt(2)},
      {command: 'Z'}
    ], fill: '#7C4F00', fillOpacity: .6}),
    //topside of groove
    path({d: [
      {command: 'M', x: 0, y: boardThickness * .33},
      {command: 'L', x: - (tongueLength / Math.sqrt(2)), y: (boardThickness * .33) - (tongueLength / Math.sqrt(2))},
      {command: 'H', x: 0},
      {command: 'Z'}
    ], fill: '#7C4F00', fillOpacity: .25}),
    //tongue
    path({d: [
      {command: 'M', x: boardWidth / Math.sqrt(2), y: (boardWidth / Math.sqrt(2)) + (boardThickness * .33)},
      {command: 'L', x: (boardWidth / Math.sqrt(2)) - (tongueLength / Math.sqrt(2)), y: (boardWidth / Math.sqrt(2)) + (boardThickness * .33) - (tongueLength / Math.sqrt(2))},
      {command: 'V', y: (boardWidth / Math.sqrt(2)) + (boardThickness * .66) - (tongueLength / Math.sqrt(2))},
      {command: 'L', x: boardWidth / Math.sqrt(2), y: (boardWidth / Math.sqrt(2)) + (boardThickness * .66)},
      {command: 'Z'}
    ], fill: '#7C4F00', fillOpacity: .75})
  ]);

  var board = group({ width: boardLength, height: (boardWidth / Math.sqrt(2) + boardThickness), strokeWidth: 1, stroke: 'black', fill: 'transparent', x: - (boardLength / 2), y: boardLength / 2}, [
    boardTop,
    forwardFacingSide,
    outerFacingSide
  ]);

  var dimensionWidth = {
    positions: [{x: boardLength + (boardWidth / Math.sqrt(2)), y: (boardWidth / Math.sqrt(2) + boardThickness)}, {x: 0 + (boardWidth / Math.sqrt(2)), y: (boardWidth / Math.sqrt(2) + boardThickness)}],
    extrudeDistance: 30,
    customTitles: [customLengthTitle]
  };

  var dimensionHeight = {
    positions: [{x: boardLength, y: 0}, {x: boardLength + (boardWidth / Math.sqrt(2) + boardThickness), y: (boardWidth / Math.sqrt(2))}],
    extrudeDistance: 30
  };

  var thicknessDimension = {
    positions: [{x: 0, y: boardThickness}, {x: 0, y: 0}],
    extrudeDistance: 5
  };

  return group({width: boardWidth, height: boardLength, padding: 30}, [
    board,
    group({x: - (boardLength / 2), y: boardLength / 2}, [
      dimension({props: dimensionWidth}),
      dimension({props: dimensionHeight}),
      shortDimension({props: thicknessDimension})
    ])
  ]);
}
