function fifteenAmpReceptacle ({xPosition, yPosition, group, rect, path, receptacleTypeColor}) {
  var mech = group({width: 28, height: 28, x: -14 + xPosition, y: -14 + yPosition}, [
    rect({height: 28, width: 28, strokeWidth: 1, stroke: 'black', fill: receptacleTypeColor.backgroundColor, rx: 1.5, ry: 1.5}),
    rect({height: 6.24, width: 1.94, x: 7.56, y: 6.88, strokeWidth: 1, stroke: 'black', fill: receptacleTypeColor.socketInletColor}),
    rect({height: 7.66, width: 1.94, x: 18.36, y: 6.17, strokeWidth: 1, stroke: 'black', fill: receptacleTypeColor.socketInletColor}),
    path({d: [
      {command: 'M', x: 11.66, y: 22.24},
      {command: 'l', x: 0, y: -2.32},
      {command: 'a', rx: 2, ry: 2.5, xAxisRotation: 0, largeArcFlag: 0, sweepFlag: 1, x: 4.54, y: 0},
      {command: 'l', x: 0, y: 2.32},
      {command: 'l', x: - 4.54, y: 0}
    ], stroke: 'black', fill: receptacleTypeColor.socketInletColor, strokeWidth: 1})
  ]);
  return mech;
}

function twentyAmpReceptacle ({xPosition, yPosition, group, rect, path, receptacleTypeColor}) {
  var mech = group({width: 30, height: 30, x: -15 + xPosition, y: -15 + yPosition}, [
    rect({height: 30, width: 30, strokeWidth: 1, stroke: 'black', fill: receptacleTypeColor.backgroundColor, rx: 1.5, ry: 1.5}),
    rect({height: 6.24, width: 1.94, x: 18.36 + 2, y: 6.88, strokeWidth: 1, stroke: 'black', fill: receptacleTypeColor.socketInletColor}),
    rect({height: 7.66, width: 1.94, x: 7.56 + 2, y: 6.17, strokeWidth: 1, stroke: 'black', fill: receptacleTypeColor.socketInletColor}),
    rect({height: 1.5, width: 4, x: 6.56, y: 6.88 + (6.24 / 2) - 1, strokeWidth: 0.75, stroke: 'transparent', fill: receptacleTypeColor.socketInletColor}),
    path({d: [
      {command: 'M', x: 11.66, y: 22.24},
      {command: 'l', x: 0, y: -2.32},
      {command: 'a', rx: 2, ry: 2.5, xAxisRotation: 0, largeArcFlag: 0, sweepFlag: 1, x: 4.54, y: 0},
      {command: 'l', x: 0, y: 2.32},
      {command: 'l', x: - 4.54, y: 0}
    ], stroke: 'black', fill: receptacleTypeColor.socketInletColor, strokeWidth: 1, transform: 'translate(7)'})
  ]);
  return mech;
}

function usbA ({xPosition, yPosition, group, rect, path}) {
  var mech = group({width: 12.5, height: 5.12, x: -6.25 + xPosition, y: -2.56 + yPosition}, [
    rect({height: 6.12, width: 13.5, strokeWidth: 1, stroke: 'black', fill: 'gray', rx: 0.3, ry: 0.3}),
    rect({height: 5.12, width: 12.5, strokeWidth: 1, stroke: 'black', fill: 'black', rx: 0.1, ry: 0.1, x: 0.5, y: 0.5}),
    rect({height: 1, width: 9, strokeWidth: 1, stroke: 'black', fill: 'white', x: 1.75 + 0.5, y: 1 + 0.5})
  ]);
  return mech;
}

function usbC ({xPosition, yPosition, group, rect, path}) {
  var mech = group({width: 9.34, height: 3.56, x: -4.17 + xPosition, y: -1.28 + yPosition}, [
    rect({height: 3.56, width: 9.34, strokeWidth: 1, stroke: 'black', fill: 'gray', rx: 1, ry: 1}),
    rect({height: 2.56, width: 8.34, strokeWidth: 1, stroke: 'black', fill: 'black', rx: 1, ry: 1, x: 0.5, y: 0.5}),
    rect({height: 1.2, width: 6.69, strokeWidth: 1, stroke: 'black', fill: 'white', rx: .5, ry: .5, x: .825 + 0.5, y: .68 + 0.5})
  ]);
  return mech;
}

function hdmi ({xPosition, yPosition, group, rect, path}) {
  var mech = group({width: 14, height: 4.55, x: -7 + xPosition, y: -2.275 + yPosition}, ([
    path({d: [
      {command: 'M', x: 0, y: 0},
      {command: 'L', x: 14, y: 0},
      {command: 'L', x: 14, y: 2.6},
      {command: 'L', x: 13, y: 3.1},
      {command: 'L', x: 12, y: 4.55},
      {command: 'H', x: 2},
      {command: 'L', x: 1, y: 3.1},
      {command: 'L', x: 0, y: 2.6},
      {command: 'Z'}
    ], strokeWidth: 1, stroke: 'black', fill: 'black'}),
    path({d: [
      {command: 'M', x: 2.5, y: 1.525},
      {command: 'L', x: 11.5, y: 1.525},
      {command: 'L', x: 11.5, y: 2.525},
      {command: 'L', x: 10.5, y: 3.025},
      {command: 'H', x: 3.5},
      {command: 'L', x: 2.5, y: 2.525},
      {command: 'Z'}
    ], strokeWidth: 1, stroke: 'black', fill: 'white'})
  ]));
  return mech;
}

function rj45 ({xPosition, yPosition, group, rect, path}) {
  var mech = group({x: -5.95 + xPosition, y: -4.8 + yPosition}, ([
    path({d: [
      {command: 'M', x: 0, y: 0},
      {command: 'H', x: 11.9},
      {command: 'V', y: 6.9},
      {command: 'H', x: 11.9 - 1.8},
      {command: 'V', y: 6.9 + 1.7},
      {command: 'H', x: 11.9 - 1.8 - 1.1},
      {command: 'V', y: 6.9 + 1.7 + 1.3},
      {command: 'H', x: 1.8 + 1.1},
      {command: 'V', y: 6.9 + 1.7},
      {command: 'H', x: 1.8},
      {command: 'V', y: 6.9},
      {command: 'H', x: 0},
      {command: 'Z'}
    ], strokeWidth: 1, stroke: 'black', fill: 'black'}),
    rect({x: 1.7, y: 0, width: 0.5, height: 3, stroke: 'black', strokeWidth: 0.5, fill: 'white'}),
    rect({x: 2.7, y: 0, width: 0.5, height: 3, stroke: 'black', strokeWidth: 0.5, fill: 'white'}),
    rect({x: 3.7, y: 0, width: 0.5, height: 3, stroke: 'black', strokeWidth: 0.5, fill: 'white'}),
    rect({x: 4.7, y: 0, width: 0.5, height: 3, stroke: 'black', strokeWidth: 0.5, fill: 'white'}),
    rect({x: 5.7, y: 0, width: 0.5, height: 3, stroke: 'black', strokeWidth: 0.5, fill: 'white'}),
    rect({x: 6.7, y: 0, width: 0.5, height: 3, stroke: 'black', strokeWidth: 0.5, fill: 'white'}),
    rect({x: 7.7, y: 0, width: 0.5, height: 3, stroke: 'black', strokeWidth: 0.5, fill: 'white'}),
    rect({x: 8.7, y: 0, width: 0.5, height: 3, stroke: 'black', strokeWidth: 0.5, fill: 'white'}),
    rect({x: 9.7, y: 0, width: 0.5, height: 3, stroke: 'black', strokeWidth: 0.5, fill: 'white'}),
  ]));
  return mech;
}

function rj11 ({xPosition, yPosition, group, rect, path}) {
  var mech = group({width: 9.5, height: 7, x: -4.75 + xPosition, y: -3.5 + yPosition}, ([
    path({d: [
      {command: 'M', x: 0, y: 0},
      {command: 'H', x: 9.5},
      {command: 'V', y: 6},
      {command: 'L', x: 7, y: 6},
      {command: 'H', x: 7},
      {command: 'V', y: 7},
      {command: 'H', x: 2.5},
      {command: 'V', y: 6},
      {command: 'H', x: 0},
      {command: 'Z'}
    ], strokeWidth: 1, stroke: 'black', fill: 'black'}),
    rect({x: 0.2 + 2, y: 0, width: 0.5, height: 2, stroke: 'white', strokeWidth: 0.5, fill: 'white'}),
    rect({x: 0.2 + 3.5, y: 0, width: 0.5, height: 2, stroke: 'white', strokeWidth: 0.5, fill: 'white'}),
    rect({x: 0.2 + 5, y: 0, width: 0.5, height: 2, stroke: 'white', strokeWidth: 0.5, fill: 'white'}),
    rect({x: 0.2 + 6.5, y: 0, width: 0.5, height: 2, stroke: 'white', strokeWidth: 0.5, fill: 'white'}),

  ]));
  return mech;
}

function speakerPorts ({xPosition, yPosition, group, rect, path}) {
  var mech = group({x: -12.5 + xPosition, y: -10 + yPosition}, [
    rect({width: 25, height: 20, strokeWidth: 1, stroke: 'black', fill: 'gray'}),
    rect({x: 1, y: 1, width: 11, height: 18, strokeWidth: 1, stroke: 'black', fill: 'red'}),
    rect({x: 13, y: 1, width: 11, height: 18, strokeWidth: 1, stroke: 'black', fill: 'black'}),
  ]);
  return mech;
}

function coax ({xPosition, yPosition, group, rect, path, circle}) {
  var mech = group({width: 6.9, height: 6.9, x: -3.45 + xPosition, y: -3.45 + yPosition}, ([
    circle({cx: 3.45, cy: 3.45, r: 3.45, strokeWidth: 1, stroke: 'black', fill: '#E2B547'}),
    circle({cx: 3.45, cy: 3.45, r: 2.45, strokeWidth: 1, stroke: 'black', fill: 'black'}),
    circle({cx: 3.45, cy: 3.45, r: 2, strokeWidth: 1.5, stroke: 'gray', fill: 'transparent'}),
    circle({cx: 3.45, cy: 3.45, r: 1, strokeWidth: 1.5, stroke: '#E2B547', fill: 'transparent'})
  ]));
  return mech;
}

function cablePassThrough ({xPosition, yPosition, group, rect, path, circle}) {
  var mech = group({width: 12, height: 12, cx: -6, cy: -6, x: xPosition, y: yPosition}, ([
    circle({r: 6, strokeWidth: 1, stroke: 'black', fill: 'black'}),
    path({d: [
      {command: 'M', x: -7, y: 0},
      {command: 'H', x: 7}
    ], strokeWidth: 1, stroke: 'white'}),
    path({d: [
      {command: 'M', x: 0, y: 7},
      {command: 'V', y: -7}
    ], strokeWidth: 1, stroke: 'white'}),
    path({d: [
      {command: 'M', x: -4.94974746831, y: 4.94974746831},
      {command: 'L', x: 4.94974746831, y: -4.94974746831}
    ], strokeWidth: 1, stroke: 'white'}),
    path({d: [
      {command: 'M', x: 4.94974746831, y: 4.94974746831},
      {command: 'L', x: -4.94974746831, y: -4.94974746831}
    ], strokeWidth: 1, stroke: 'white'}),
  ]));
  return mech;
}

function pushButton ({xPosition, yPosition, group, rect, path, circle}) {
  var mech = group({x: xPosition - 6, y: yPosition - 6}, ([
    circle({
      cx: 6,
      cy: 6,
      fill: 'black',
      strokeWidth: 1,
      stroke: 'gray',
      r: 6,
    }),
    circle({
      cx: 6,
      cy: 6,
      fill: 'black',
      strokeWidth: 1,
      stroke: 'gray',
      r: 4,
    }),
  ]));
  return mech;
}

module.exports = {fifteenAmpReceptacle, twentyAmpReceptacle, usbA, usbC, hdmi, rj45, rj11, speakerPorts, coax, cablePassThrough, pushButton};
