import { createRef, useContext, useEffect, useRef } from 'react';
import { threeDToTwoD, toCanvas } from '~/helpers/canvas/canvas-helpers';
import { Path } from 'react-konva';

import _ from 'lodash';
import CanvasDataContext from '~/contexts/canvas-data-context';

export default function Renderer({nodes}) {
  var canvasData = useContext(CanvasDataContext);
  var nodesRef = useRef(null);

  return (
    _.map(nodes, (shape, index) => {
      if (shape.type === 'plane') {
        var points = _.map(shape.points, point => threeDToTwoD(point, canvasData));

        var isPerpendicularToView = _.every(points, point => point.x === points[0].x) || _.every(points, point => point.y === points[0].y);

        if (isPerpendicularToView) return null;

        var d = 'M ' + _.join(_.map([...points, points[0]], point => {
          var canvasPoint = toCanvas(point, canvasData);

          return _.join([canvasPoint.x, canvasPoint.y], ' ');
        }), ' L ');

        return <Path key={index} ref={nodesRef} data={d} {..._.omit(shape, ['points', 'x', 'y', 'z'])}/>;
      }
    })
  );
}
