// eslint-disable-next-line quotes
import _ from 'lodash';

export default function getDecoraGraphicNodes({deps, group, rect, circle, mask, path, dimension}) {
  var {propertiesDataById, properties} = deps.productInstance;

  var visibleScrews;
  var rectData;

  if (deps.productInstance.productId === 98 ) {
    rectData = _.get(propertiesDataById, '86');

    if (_.get(propertiesDataById, '3.selectedProductOption.id') === 103) {
      rectData = _.get(propertiesDataById, '115');
    }
  }
  else {
    visibleScrews = deps.productInstance.productId === 72;
    rectData = _.get(propertiesDataById, visibleScrews ? 86 : 115);
  }

  var rectHeight = _.get(rectData, 'selectedProductOption.data.height');
  var rectWidth = _.get(rectData, 'selectedProductOption.data.width');
  var gangNumber = _.get(rectData, 'selectedProductOption.data.gang');
  var spacing = 34 + 12;
  var cutoutTypeOptionId = _.get(propertiesDataById, '87.selectedProductOption.id');

  var plateFinishColor = _.get(propertiesDataById, '5.selectedProductOption.data.fill', '#BEA57B');

  function calcStarPoints(centerX, centerY, innerCirclePoints, innerRadius, outerRadius) {
    var angle = (Math.PI / innerCirclePoints);
    var angleOffsetToCenterStar = 0;

    var totalPoints = innerCirclePoints * 2; // 10 in a 5-points star

    var points = [];
    for (let i = 0; i < totalPoints; i++) {
      var isEvenIndex = i % 2 == 0;
      var r = isEvenIndex ? outerRadius : innerRadius;
      var currX = centerX + Math.cos(i * angle + angleOffsetToCenterStar ) * r;
      var currY = centerY + Math.sin(i * angle + angleOffsetToCenterStar) * r;

      if (i === 0) {
        points.push({command: 'M', x: currX, y: currY});
      }
      else {
        points.push({command: 'L', x: currX, y: currY});
      }
    }

    var firstPoint = _.first(points);
    points.push({command: 'L', x: firstPoint.x, y: firstPoint.y});

    return points;
  }

  function calculateLineXYOnCircle({cx, cy, angle, radius}) {
    angle = angle * (Math.PI / 180);
    return {
      x: cx + (radius * Math.cos(angle)),
      y: cy + (radius * Math.sin(angle))
    };
  }

  var screwCircleRadius = 3.30;

  var screws = {
    torxScrew: ({x, y}) => group({width: screwCircleRadius * 2, height: screwCircleRadius * 2, x, y}, ([
      circle({cx: screwCircleRadius, cy: screwCircleRadius, r: screwCircleRadius, stroke: 'black', strokeWidth: 1, fill: 'transparent'}),
      path({d: calcStarPoints(screwCircleRadius, screwCircleRadius, 6, 1, 1.7), strokeWidth: 1, stroke: 'black', fill: 'transparent'})
    ])),

    slotHeadScrew: ({x, y}) => {
      var firstPoint = calculateLineXYOnCircle({cx: screwCircleRadius, cy: screwCircleRadius, angle: 278, radius: screwCircleRadius});
      var secondPoint = calculateLineXYOnCircle({cx: screwCircleRadius, cy: screwCircleRadius, angle: 82, radius: screwCircleRadius});
      var thirdPoint = calculateLineXYOnCircle({cx: screwCircleRadius, cy: screwCircleRadius, angle: 264, radius: screwCircleRadius});
      var fourthPoint = calculateLineXYOnCircle({cx: screwCircleRadius, cy: screwCircleRadius, angle: 96, radius: screwCircleRadius});

      return group({width: 8, height: 8, x, y}, ([
        circle({cx: screwCircleRadius, cy: screwCircleRadius, r: screwCircleRadius, stroke: 'black', strokeWidth: 1, fill: 'transparent'}),
        path({d: [
          {command: 'M', x: firstPoint.x, y: firstPoint.y},
          {command: 'L', x: secondPoint.x, y: secondPoint.y},
        ], strokeWidth: 1, stroke: 'black', fill: 'transparent'}),
        path({d: [
          {command: 'M', x: thirdPoint.x, y: thirdPoint.y},
          {command: 'L', x: fourthPoint.x, y: fourthPoint.y},
        ], strokeWidth: 1, stroke: 'black', fill: 'transparent'})
      ]));
    }
  };

  var edgeTypeId = _.get(propertiesDataById, '2.selectedProductOption.id');
  var isBeveled = {240: true, 241: false}[edgeTypeId];
  var bevel = group({x: 0, y: 0, width: rectWidth, height: rectHeight}, [
    path({d: [
      {command: 'M', x: 0, y: 0},
      {command: 'H', x: rectWidth},
      {command: 'L', x: rectWidth - 2, y: 2},
      {command: 'H', x: 2},
      {command: 'Z'}
    ], opacity: 0.2, fill: 'black'}),
    path({d: [
      {command: 'M', x: 0, y: 0},
      {command: 'V', y: rectHeight},
      {command: 'L', x: 2, y: rectHeight - 2},
      {command: 'V', y: 2},
      {command: 'Z'}
    ], opacity: 0.2, fill: 'black'}),
    path({d: [
      {command: 'M', x: 0, y: rectHeight},
      {command: 'H', x: rectWidth},
      {command: 'L', x: rectWidth - 2, y: rectHeight - 2},
      {command: 'H', x: 2},
      {command: 'Z'}
    ], opacity: 0.5, fill: 'black'}),
    path({d: [
      {command: 'M', x: rectWidth, y: 0},
      {command: 'V', y: rectHeight},
      {command: 'L', x: rectWidth - 2, y: rectHeight - 2},
      {command: 'V', y: 2},
      {command: 'Z'}
    ], opacity: 0.5, fill: 'black'})
  ]);

  var screwTypeOptionId = _.get(propertiesDataById, '3.selectedProductOption.id');
  var screwBasedOnType = {5: 'torxScrew', 6: 'slotHeadScrew'};
  var isScrewVisible = {5: true, 6: true, 103: false}[screwTypeOptionId];

  var widthDimensionProps = [{x: rectWidth, y: rectHeight}, {x: 0, y: rectHeight}];
  var heightDimensionProps = [{x: 0, y: rectHeight}, {x: 0, y: 0}];

  var switchGapXSpacingProps = [{x: rectWidth, y: rectHeight}, {x: 0, y: rectHeight}];

  var switchGap = ({xSpacing}) => {
    var mech = group({x: xSpacing - 17, y: (rectHeight / 2) - 33.7}, [
      rect({width: 34, height: 67.4, fill: 'white', rx: cutoutTypeOptionId === 525 ? 3 : 0, ry: cutoutTypeOptionId === 525 ? 3 : 0, strokeWidth: 0.5, stroke: 'black'}),
      ...(isScrewVisible ? [
        screws[screwBasedOnType[screwTypeOptionId]]({x: 17 - 3, y: -14.8 - 3}),
        screws[screwBasedOnType[screwTypeOptionId]]({x: 17 - 3, y: 67 + 14.8 - 3})
      ] : []),
    ]);
    return mech;
  };

  var switchGaps = [];
  var switchGroupXPosition;

  if (gangNumber > 1) {
    switchGroupXPosition = (rectWidth / 2) - (((spacing * (gangNumber - 1))) / 2);
  }
  else {
    switchGroupXPosition = rectWidth / 2;
  }

  if (gangNumber > 1) {
    _.times(gangNumber, (gangIndex) => {
      switchGaps.push(switchGap({xSpacing: (spacing * gangIndex)}));
      switchGapXSpacingProps.splice(gangIndex + 1, 0, {x: switchGroupXPosition + (gangIndex * spacing), y: rectHeight});
    });
  }
  else {
    switchGaps.push(switchGap({xSpacing: 0}));
    switchGapXSpacingProps.splice(1, 0, {x: rectWidth / 2, y: rectHeight});
  }

  return group({width: rectWidth, height: rectHeight, fill: plateFinishColor, padding: 30}, [
    rect({width: rectWidth, height: rectHeight, fill: plateFinishColor, x: 0, y: 0}),
    group({x: switchGroupXPosition, y: 0}, [
      ...switchGaps
    ]),
    ...(isBeveled ? [bevel] : []),

    dimension({props: {positions: switchGapXSpacingProps, extrudeDistance: 30}}),
    dimension({props: {positions: widthDimensionProps, extrudeDistance: 60}}),
    dimension({props: {positions: heightDimensionProps, extrudeDistance: 60}})
  ]);
}