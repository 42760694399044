import { Component } from 'react';
import { TextInput, LabelledView, DateInput, Button, Label } from '@symbolic/rn-lib';
import { View } from 'react-native';
import { resourceActions } from '~/redux';
import { connect } from '@symbolic/redux';
import { api } from '@symbolic/lib';
import { getNameFor } from '~/helpers/product-order-helper';

import AddressInput from '~/components/address-input';

import moment from 'moment';
import K from '~/k';

class ProjectInfoForm extends Component {
  handleInputChange = ({propKey, value}) => this.props.updateProductOrder({id: this.props.productOrder.id, props: {[propKey]: value}});

  handleAddressChange = ({value, type}) => {
    if (type === 'billing') {
      this.props.updateProductOrder({id: this.props.productOrder.id, props: {billingAddress: value}});
    }
    else if (type === 'delivery') {
      this.props.updateProductOrder({id: this.props.productOrder.id, props: {deliveryAddress: value}});

      this.considerUpdatingTaxPercentage({deliveryAddress: value});
    }
  };

  handleEstimatedShippingDateChange = async ({value}) => {
    var {data: {estimatedShippingDate}} = await api.request({
      uri: '/configurator/product-order/update-estimated-shipping-date',
      body: {
        productOrderId: this.props.productOrder.id,
        estimatedShippingDate: value
      }
    });

    this.props.updateProductOrder({id: this.props.productOrder.id, props: {estimatedShippingDate}, hitApi: false});
  };

  considerUpdatingTaxPercentage = async ({deliveryAddress}) => {
    var {productOrder} = this.props;
    var state = deliveryAddress.state.toUpperCase();

    var shouldCalculateSalesTax = true;

    if (productOrder.orgId === 850 && !_.includes(['CA', 'NY'], state)) shouldCalculateSalesTax = false;

    if (shouldCalculateSalesTax) {
      var previousTaxPercentage = _.get(productOrder, 'taxPercentage', 0);

      var {data} = await api.request({
        uri: '/configurator/get-tax-rate',
        body: {deliveryAddress}
      });

      var taxPercentage = data.taxRate;

      if (taxPercentage !== 0 && (previousTaxPercentage !== taxPercentage)) {
        this.props.updateProductOrder({id: productOrder.id, props: {taxPercentage}});
      }
    }
  };

  formatDate = (value) => {
    if (value) value = moment.utc(value);

    return value ? value.format('YYYY-MM-DD HH:mm:ss') : value;
  };

  render() {
    var {productOrder, session} = this.props;
    var {activeOrg: {id: orgId}} = session;

    return (
      <View>
        <TextInput
          nativeID={'OrderPageSettingsPopupOrderNameInput'}
          grayLabelledView
          label={getNameFor({productOrder, orgId, textToTransform: 'Order name'})}
          labelledViewStyles={{outerView: {marginBottom: K.margin}}}
          onChange={({value}) => this.handleInputChange({propKey: 'title', value})}
          value={getNameFor({productOrder, orgId, textToTransform: _.get(productOrder, 'title', '')})}
        />
        {orgId !== 1053 ? (
          <TextInput
            grayLabelledView
            label={orgId === 1798 ? 'Invoice #' : 'Purchase Order Number (optional)'}
            labelledViewStyles={{outerView: {marginBottom: K.margin}}}
            onChange={({value}) => this.handleInputChange({propKey: 'purchaseOrderNumber', value})}
            value={_.get(productOrder, 'purchaseOrderNumber', '')}
          />
        ) : (
          <TextInput
            grayLabelledView
            label={'Estimate Number'}
            labelledViewStyles={{outerView: {marginBottom: K.margin}}}
            onChange={({value}) => this.handleInputChange({propKey: 'estimateNumber', value})}
            value={_.get(productOrder, 'estimateNumber', '')}
          />
        )}
        {orgId !== 1798 && (
          <TextInput
            grayLabelledView
            label={'Company'}
            labelledViewStyles={{outerView: {marginBottom: K.margin}}}
            onChange={({value}) => this.handleInputChange({propKey: 'firmName', value})}
            value={_.get(productOrder, 'firmName', '')}
          />
        )}
        <TextInput
          grayLabelledView
          label={orgId === 1798 ? 'Account / Client' : 'Contact name'}
          labelledViewStyles={{outerView: {marginBottom: K.margin}}}
          onChange={({value}) => this.handleInputChange({propKey: 'contactName', value})}
          value={_.get(productOrder, 'contactName', '')}
        />
        {_.includes([850, 1053], orgId) && (<>
          <TextInput
            grayLabelledView
            label={'Contact Phone'}
            labelledViewStyles={{outerView: {marginBottom: K.margin}}}
            onChange={({value}) => this.handleInputChange({propKey: 'phone', value})}
            value={_.get(productOrder, 'phone', '')}
          />
          <AddressInput
            value={productOrder.deliveryAddress}
            type='deliveryAddress'
            onChange={({value}) => this.handleAddressChange({value, type: 'delivery'})}
            labelledViewStyles={{outerView: {marginBottom: K.margin}}}
          />
        </>)}
        {orgId === 850 && (<>
          <Button
            label={'Billing address same as delivery'}
            style={{marginBottom: K.margin}}
            textStyle={{opacity: 0.7}}
            onPress={() => this.handleAddressChange({value: productOrder.deliveryAddress, type: 'billing'})}
          />
          <AddressInput
            value={productOrder.billingAddress}
            type='billingAddress'
            onChange={({value}) => this.handleAddressChange({value, type: 'billing'})}
            labelledViewStyles={{outerView: {marginBottom: K.margin}}}
          />
          {/* <LabelledView gray label={'Target delivery date (optional)'} styles={{outerView: {marginBottom: K.margin}}}>
            <DateInput
              placeholder={'MM/DD'}
              onChange={({value}) => this.handleInputChange({propKey: 'targetDeliveryDate', value: this.formatDate(value)})}
              value={productOrder['targetDeliveryDate'] && moment.utc(productOrder['targetDeliveryDate'])}
            />
          </LabelledView> */}
          <LabelledView gray label={'Subscribe to Status Updates (optional)'} styles={{outerView: {marginBottom: K.margin}}}>
            <TextInput
              placeholder={'jane@email.com,joe@email.com'}
              onChange={({value}) => this.handleInputChange({propKey: 'clientEmails', value})}
              value={_.defaultTo(_.get(productOrder, 'clientEmails', ''), '')}
            />
          </LabelledView>
        </>)}
        {orgId === 1798 && (
          <TextInput
            grayLabelledView
            label={'Salesforce Link'}
            labelledViewStyles={{outerView: {marginBottom: K.margin}}}
            onChange={({value}) => this.handleInputChange({propKey: 'externalLink1', value})}
            value={_.get(productOrder, 'externalLink1', '')}
          />
        )}
        {(orgId === 850 && (session.activeOrg.role === 'member' || session.activeOrg.role === 'owner')) && (<>
          <Label style={{marginLeft: K.spacing, marginTop: K.spacing, opacity: 0.5}}>Visible to employees only</Label>
          <LabelledView gray label={'Estimated Shipping Date'} styles={{outerView: {marginBottom: K.margin}}}>
            <DateInput
              placeholder={'MM/DD'}
              onChange={({value}) => this.handleEstimatedShippingDateChange({value: this.formatDate(value)})}
              value={productOrder['estimatedShippingDate'] && moment.utc(productOrder['estimatedShippingDate'])}
            />
          </LabelledView>
          <TextInput
            grayLabelledView
            label={'Tracking Number'}
            labelledViewStyles={{outerView: {marginBottom: K.margin}}}
            onChange={({value}) => this.handleInputChange({propKey: 'trackingNumber', value})}
            value={_.get(productOrder, 'trackingNumber', '')}
          />
        </>)}
      </View>
    );
  }
}

export default connect({
  mapDispatch: {
    ..._.pick(resourceActions.productOrders, ['updateProductOrder'])
  }
})(ProjectInfoForm);
