import _ from 'lodash';

export default function getMaderaUnitNumberForProductInstance({productInstance, productOrderProductInstances}) {
  var {productId, properties} = productInstance;
  var unitNumber;

  var doorNumberOverridePropertyValue = _.get(properties, '161.value');
  var cabinetFrontNumberOverridePropertyValue = _.get(properties, '195.value');

  if (productId === 104 && doorNumberOverridePropertyValue) { //HINT door slab
    unitNumber = doorNumberOverridePropertyValue;
  }
  else if (productId === 105 && cabinetFrontNumberOverridePropertyValue) { //HINT cabinet front
    unitNumber = cabinetFrontNumberOverridePropertyValue;
  }
  else {
    //TODO move up to order page
    var unitNumber = _.findIndex(_.sortBy(productOrderProductInstances, [
      'archCustomViewId',
      'id'
    ]), {id: productInstance.id}) + 1;
  }

  return unitNumber.toString().padStart(3, '0'); //TODO store this value somewhere on the productInstance?
}
