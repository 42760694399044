import getScrews from '~/helpers/meljac/get-screws';

export default function getLampGraphicNodes({deps, group, rect, circle, path, dimension}) {
  var {propertiesDataById} = deps.productInstance;

  var productId = deps.productInstance.productId;
  var mechanismFinishData = _.get(propertiesDataById, '7.selectedProductOption.data');
  var mechanismFinishColor = _.get(mechanismFinishData, 'fill') || '#FFA64E';
  var plateFinishColor = _.get(propertiesDataById, '5.selectedProductOption.data.fill', '#BEA57B');
  var plateSizeData = _.get(propertiesDataById, '15.selectedProductOption.data');
  var rectWidth = _.get(plateSizeData, 'width', 0);
  var rectHeight = _.get(plateSizeData, 'height', 0);
  var edgeTypeId = _.get(propertiesDataById, '2.selectedProductOption.id');
  var isBeveled = {240: true, 241: false}[edgeTypeId];
  var tulipeShadeIsBeveled = _.get(propertiesDataById, '17.selectedProductOption.id') === 110 ? false : true;
  var lampNeckBent = _.get(propertiesDataById, '18.selectedProductOption.id') === 111 ? false : true;
  var mechanismsData;
  if (productId === 8 || productId === 9) mechanismsData = _.get(propertiesDataById, '20.selectedProductOption.data');
  if (productId === 15 || productId === 87) mechanismsData = _.get(propertiesDataById, '53.selectedProductOption.data');
  var hasMechanism = _.has(mechanismsData, 'layoutString') ? true : false;

  var classiquePushButton = () => ([
    circle({
      cx: 6,
      cy: 6,
      fill: mechanismFinishColor,
      strokeWidth: 1,
      stroke: 'black',
      r: 6,
    }),
    circle({
      cx: 6,
      cy: 6,
      fill: mechanismFinishColor,
      strokeWidth: 1,
      stroke: 'black',
      r: 4,
    }),
  ]);

  var classiqueMomentaryToggle = () => ([
    circle({
      cx: 6,
      cy: 6,
      fill: mechanismFinishColor,
      strokeWidth: 1,
      stroke: 'black',
      r: 6,
    }),
    circle({
      cx: 6,
      cy: 6,
      fill: 'black',
      fillOpacity: 0.2,
      strokeWidth: 0.5,
      stroke: 'black',
      r: 4,
    }),
    // path({d: [
    //   {command: 'M', x: 0, y: 0},
    //   {command: 'C', x1: -2, y1: 0.65, x2: 0.6, y2: -0.45, x: 0, y: 2},
    //   {command: 'C', x1: 0, y1: 3.3, x2: 2, y2: 3.3, x: 2, y: 2},
    //   {command: 'C', x1: 1.45, y1: -0.45, x2: 4, y2: 0.65, x: 2, y: 0},
    //   {command: 'Z'}
    // ], fill: 'black', fillOpacity: 0.3, stroke: 'black', strokeWidth: 0.5, x: 5, y: 6}),
    circle({
      cx: 6,
      cy: 6,
      fill: 'black',
      fillOpacity: 0.5,
      strokeWidth: 0.5,
      stroke: 'black',
      r: 3,
    }),
    circle({
      cx: 6,
      cy: 4,
      fill: mechanismFinishColor,
      strokeWidth: 1,
      stroke: 'black',
      r: 3,
    }),
    circle({
      cx: 6,
      cy: 4,
      fill: mechanismFinishColor,
      strokeWidth: 0.5,
      stroke: 'black',
      opacity: 0.5,
      r: 2.5
    }),
  ]);

  var classiqueToggle = () => ([
    circle({
      cx: 6,
      cy: 6,
      fill: mechanismFinishColor,
      strokeWidth: 1,
      stroke: 'black',
      r: 6,
    }),
    circle({
      cx: 6,
      cy: 6,
      fill: 'black',
      fillOpacity: 0.2,
      strokeWidth: 0.5,
      stroke: 'black',
      r: 4,
    }),
    path({d: [
      {command: 'M', x: -0.4, y: -1},
      {command: 'C', x1: -0.2, y1: -0.6, x2: 0, y2: 0, x: 0, y: 2},
      {command: 'C', x1: 0, y1: 3.3, x2: 2, y2: 3.3, x: 2, y: 2},
      {command: 'C', x1: 2, y1: 0, x2: 2.2, y2: -0.6, x: 2.4, y: -1},
      {command: 'Z'}
    ], fill: 'black', fillOpacity: 0.3, stroke: 'black', strokeWidth: 0.5, x: 5, y: 5}),
    circle({
      cx: 6,
      cy: 6,
      fill: 'black',
      fillOpacity: 0.5,
      strokeWidth: 0.5,
      stroke: 'black',
      r: 3,
    }),
    circle({
      cx: 6,
      cy: 1,
      fill: mechanismFinishColor,
      strokeWidth: 1,
      stroke: 'black',
      r: 3,
    }),
    circle({
      cx: 6,
      cy: 1,
      fill: mechanismFinishColor,
      strokeWidth: 0.5,
      stroke: 'black',
      opacity: 0.5,
      r: 2.5
    }),
  ]);

  var toggleStyleData = _.get(propertiesDataById, '21.selectedProductOption');
  var toggleIsJMA = _.get(toggleStyleData, 'id') === 120 ? true : false;

  var ellipsePushButton = () => ([
    path({d: [
      {command: 'M', x: 0, y: 4.5},
      {command: 'C', x1: 0, y1: -1.5, x2: 9, y2: -1.5, x: 9, y: 4.5},
      {command: 'L', x: 9, y: 9.5},
      {command: 'C', x1: 9, y1: 15.5, x2: 0, y2: 15.5, x: 0, y: 9.5},
      {command: 'Z'}
    ], fill: mechanismFinishColor, stroke: 'black', strokeWidth: 1, x: 1.5, y: -1}),
    path ({d: [
      {command: 'M', x: 1, y: 4.5},
      {command: 'C', x1: 1, y1: -0.5, x2: 8, y2: -0.5, x: 8, y: 4.5},
      {command: 'L', x: 8, y: 9.5},
      {command: 'C', x1: 8, y1: 14.5, x2: 1, y2: 14.5, x: 1, y: 9.5},
      {command: 'Z'}
    ], fill: mechanismFinishColor, stroke: 'black', strokeWidth: 1, x: 1.5, y: -1})
  ]);

  var ellipseToggleButton = () => ([
    path({d: [
      {command: 'M', x: 0, y: 4.5},
      {command: 'C', x1: 0, y1: -1.5, x2: 9, y2: -1.5, x: 9, y: 4.5},
      {command: 'L', x: 9, y: 9.5},
      {command: 'C', x1: 9, y1: 15.5, x2: 0, y2: 15.5, x: 0, y: 9.5},
      {command: 'Z'}
    ], fill: mechanismFinishColor, stroke: 'black', strokeWidth: 1, x: 1.5, y: -1}),
    path ({d: [
      {command: 'M', x: 1, y: 4.5},
      {command: 'C', x1: 1, y1: -0.5, x2: 8, y2: -0.5, x: 8, y: 4.5},
      {command: 'L', x: 8, y: 9.5},
      {command: 'C', x1: 8, y1: 14.5, x2: 1, y2: 14.5, x: 1, y: 9.5},
      {command: 'Z'}
    ], fill: mechanismFinishColor, stroke: 'black', strokeWidth: 1, x: 1.5, y: -1}),
    ...(toggleIsJMA
      ? [path({d: [
        {command: 'M', x: 1, y: 3},
        {command: 'C', x1: 1, y1: -1, x2: 5, y2: -1, x: 5, y: 3},
        {command: 'C', x1: 5, y1: 7, x2: 1, y2: 7, x: 1, y: 3}
      ], fill: mechanismFinishColor, stroke: 'black', strokeWidth: 1, x: 3, y: 3})]
      : [circle({
        cx: 6,
        cy: 6,
        fill: mechanismFinishColor,
        strokeWidth: 1,
        stroke: 'black',
        r: 3,
      })]),
  ]);

  var lampNeck = group({x: (rectWidth / 2) - 4.5, y: (rectHeight / 2) - (hasMechanism ? 18 : 0), width: 100, height: 100}, [
    ...(lampNeckBent ? [
      path({d: [
        {command: 'M', x: 0, y: 0},
        {command: 'C', x1: 2.5, y1: -17, x2: 22.25, y2: -58, x: 44, y: -58},
        {command: 'C', x1: 60.5, y1: -58, x2: 70.25, y2: -41.5, x: 75, y: -25},
        {command: 'H', x: 67},
        {command: 'C', x1: 63, y1: -35.5, x2: 57, y2: -50, x: 44, y: -50},
        {command: 'C', x1: 28.5, y1: -51, x2: 12, y2: -20.5, x: 8, y: 0},
        {command: 'A', rx: 1, ry: 1, xAxisRotation: 0, largeArcFlag: 0, sweepFlag: 1, x: 0, y: 0},
      ], strokeWidth: 1, stroke: 'black', fill: plateFinishColor}),
      path({d: [
        {command: 'M', x: -1.5, y: 0},
        {command: 'L', x: 2.5, y: -14},
        {command: 'L', x: 5.395, y: -17.4},
        {command: 'C', x1: 7, y1: -16, x2: 10, y2: -14.5, x: 12.5, y: -14.5},
        {command: 'L', x: 12.3, y: -11},
        {command: 'L', x: 9, y: 0},
        {command: 'A', rx: 1, ry: 1, xAxisRotation: 0, largeArcFlag: 0, sweepFlag: 1, x: -1.5, y: 0}
      ], strokeWidth: 1, stroke: 'black', fill: plateFinishColor})
    ] : [
      circle({
        cx: 4, cy: 0, fill: 'transparent', strokeWidth: 1, stroke: 'black', r: 5.5
      }),
      // offset x:67 y: -25
      path({d: [
        {command: 'M', x: 0, y: 0},
        {command: 'C', x1: 2.5, y1: -17, x2: 22.25, y2: -58, x: 44, y: -58},
        {command: 'C', x1: 60.5, y1: -58, x2: 70.25, y2: -41.5, x: 75, y: -25},
        {command: 'H', x: 67},
        {command: 'C', x1: 63, y1: -35.5, x2: 57, y2: -50, x: 44, y: -50},
        {command: 'C', x1: 28.5, y1: -51, x2: 12, y2: -20.5, x: 8, y: 0},
        {command: 'A', rx: 1, ry: 1, xAxisRotation: 0, largeArcFlag: 0, sweepFlag: 1, x: 0, y: 0},
      ], strokeWidth: 1, stroke: 'black', fill: plateFinishColor})
    ]),
  ]);

  var lampSocket = path({d: [
    {command: 'M', x: 0, y: 0},
    {command: 'C', x1: 2.4, y1: -0.5, x2: 5.6, y2: -0.5, x: 8, y: 0},
    {command: 'L', x: 9, y: 6},
    {command: 'V', y: 15},
    {command: 'C', x1: 6, y1: 16, x2: 2, y2: 16, x: -1, y: 15},
    {command: 'V', y: 6},
    {command: 'Z'}
  ], strokeWidth: 1, stroke: 'black', fill: plateFinishColor});

  var roseau = group({x: ((rectWidth / 2) - 4.5) + 67, y: ((rectHeight / 2) - (hasMechanism ? 18 : 0)) - 25, width: 0, height: 0}, [
    path({d: [
      {command: 'M', x: -1, y: 15},
      {command: 'L', x: -1, y: 40},
      {command: 'C', x1: 2, y1: 42, x2: 6, y2: 42, x: 9, y: 40},
      {command: 'V', y: 15}
    ], strokeWidth: 1, stroke: 'black', fill: plateFinishColor}),
    lampSocket
  ]);

  var tulipe = group({x: ((rectWidth / 2) - 4.5) + 67, y: ((rectHeight / 2) - (hasMechanism ? 18 : 0)) - 25, width: 0, height: 0}, [
    ...(tulipeShadeIsBeveled ? [
      path({d: [
        {command: 'M', x: 9.75, y: 14.5},
        {command: 'L', x: 23.75, y: 54.5},
        {command: 'C', x1: 19, y1: 55.75, x2: -7.25, y2: 52.5, x: -14.25, y: 48.25},
        {command: 'L', x: -1.75, y: 14.5}
      ], strokeWidth: 1, stroke: 'black', fill: plateFinishColor})
    ] : [
      path({d: [
        {command: 'M', x: 9.75, y: 14.5},
        {command: 'L', x: 23.75, y: 54.5},
        {command: 'C', x1: 20, y1: 58, x2: -11, y2: 58, x: -15, y: 54},
        {command: 'L', x: -1.75, y: 14.5}
      ], strokeWidth: 1, stroke: 'black', fill: plateFinishColor})
    ]),
    path({d: [
      {command: 'M', x: -1, y: 14},
      {command: 'C', x1: -2, y1: 14.25, x2: -2, y2: 14.75, x: -1, y: 15}
    ], strokeWidth: 1, stroke: 'black', fill: 'gray'}),
    path({d: [
      {command: 'M', x: 9, y: 15},
      {command: 'C', x1: 10, y1: 14.75, x2: 10, y2: 14.25, x: 9, y: 14}
    ], strokeWidth: 1, stroke: 'black', fill: 'gray'}),
    lampSocket
  ]);

  var bevel = group({x: 0, y: 0, width: rectWidth, height: rectHeight}, [
    path({d: [
      {command: 'M', x: 0, y: 0},
      {command: 'H', x: rectWidth},
      {command: 'L', x: rectWidth - 2, y: 2},
      {command: 'H', x: 2},
      {command: 'Z'}
    ], opacity: 0.2}),
    path({d: [
      {command: 'M', x: 0, y: 0},
      {command: 'V', y: rectHeight},
      {command: 'L', x: 2, y: rectHeight - 2},
      {command: 'V', y: 2},
      {command: 'Z'}
    ], opacity: 0.2}),
    path({d: [
      {command: 'M', x: 0, y: rectHeight},
      {command: 'H', x: rectWidth},
      {command: 'L', x: rectWidth - 2, y: rectHeight - 2},
      {command: 'H', x: 2},
      {command: 'Z'}
    ], opacity: 0.5}),
    path({d: [
      {command: 'M', x: rectWidth, y: 0},
      {command: 'V', y: rectHeight},
      {command: 'L', x: rectWidth - 2, y: rectHeight - 2},
      {command: 'V', y: 2},
      {command: 'Z'}
    ], opacity: 0.5})
  ]);

  var screwTypeOptionId = _.get(propertiesDataById, '3.selectedProductOption.id');
  var screws = getScrews({group, path, circle, screwTypeOptionId, rectWidth, rectHeight, productIsLamp: true});
  var isScrewVisible = {5: true, 6: true, 103: false}[screwTypeOptionId];
  var widthDimensionProps = [{x: rectWidth, y: rectHeight}, {x: 0, y: rectHeight}];
  var heightDimensionProps = [{x: 0, y: rectHeight}, {x: 0, y: 0}];

  var mechanismsLayout = _.split(_.replace(_.get(mechanismsData, 'layoutString', ''), ' ', ''), '-');
  var mechanisms = [];

  if (mechanismsLayout !== 0) {
    if (deps.productInstance.productId === 8 || deps.productInstance.productId === 9) {
      _.forEach(mechanismsLayout, (mechanismString, index) => {
        if (mechanismString === 'MB' || mechanismString === 'B') mechanisms.push(group({x: (rectWidth / 2) - 6, y: (18 * (index + 1)) - (mechanismsLayout.length > 1 ? 10 : 0)}, classiquePushButton()));
        if (mechanismString === 'MT') mechanisms.push(group({x: (rectWidth / 2) - 6, y: (18 * (index + 1)) - (mechanismsLayout.length > 1 ? 10 : 0)}, classiqueMomentaryToggle()));
        if (mechanismString === 'T') mechanisms.push(group({x: (rectWidth / 2) - 6, y: (18 * (index + 1)) - (mechanismsLayout.length > 1 ? 10 : 0)}, classiqueToggle()));
      });
    }
    if (deps.productInstance.productId === 15 || deps.productInstance.productId === 87) {
      _.forEach(mechanismsLayout, (mechanismString, index) => {
        if (mechanismString === 'B') mechanisms.push(group({x: (rectWidth / 2) - 6, y: (18 * (index + 1)) - (mechanismsLayout.length > 1 ? 10 : 0)}, ellipsePushButton()));
        if (mechanismString === 'T') mechanisms.push(group({x: (rectWidth / 2) - 6, y: (18 * (index + 1)) - (mechanismsLayout.length > 1 ? 10 : 0)}, ellipseToggleButton()));
      });
    }
  }

  return group({width: rectWidth + 50, height: rectHeight + 50, y: 50, padding: 30}, [
    rect({width: rectWidth, height: rectHeight, fill: plateFinishColor, x: 0, y: 0, strokeWidth: 1, stroke: '#000000'}),
    ...(isBeveled ? [bevel] : []),
    ...(isScrewVisible ? [screws] : []),
    lampNeck,
    ...(productId === 9 || productId === 87 ? [roseau] : [tulipe]),
    group({x: 0, y: (rectHeight / 2) - 6}, [
      ...mechanisms
    ]),
    dimension({props: {positions: widthDimensionProps, extrudeDistance: 60}}),
    dimension({props: {positions: heightDimensionProps, extrudeDistance: 60}})
  ]);
}
