import { PureComponent } from 'react';
import { View, ScrollView, Text } from 'react-native';
import { products } from '@symbolic/lib';
import { getProductInstancesWithData, filterInstancePropertiesFor, getProductInstanceIsInvalid } from '~/helpers/product-order-helper';
import { connect } from '@symbolic/redux';
import { resourceActions } from '~/redux';
import { withKeyEvents } from '@symbolic/rn-lib';
import CartDataRow from '~/components/cart-data-row';
import OrderCostsTable from '~/components/order-costs-table';

import getPricingIsHidden from '~/helpers/get-pricing-is-hidden/get-pricing-is-hidden';
import GetDisclaimer from '~/helpers/get-disclaimer';

import K from '~/k';
import _ from '@symbolic/lodash';

var { getCostsForOrder, getPaymentAmountForOrder, getActiveProductOrderStatus, getFilteredProductOrderStatuses, getProductOrderStatusesFor } = products;

class Cart extends PureComponent {
  handleProductInstanceDelete = ({productInstance}) => {
    var {activeProductInstanceId, productInstances, activeProductInstance, productInstancesById, setActiveInstanceId} = this.props;

    if (productInstances.length === 1) {
      setActiveInstanceId({id: -1});
    }
    else {
      var activeProductInstance = _.get(productInstancesById, activeProductInstanceId);

      if (productInstance.id === activeProductInstanceId) {
        var indexOfActiveProductInstance = _.indexOf(productInstances, activeProductInstance);

        if (indexOfActiveProductInstance === 0) {
          setTimeout(() => setActiveInstanceId({id: productInstances[1].id}));
        }
        else {
          setTimeout(() => setActiveInstanceId({id: productInstances[indexOfActiveProductInstance - 1].id}));
        }
      }
    }

    this.props.destroyProductInstance({id: productInstance.id});
  };

  updateProductInstanceRank = ({value, productInstance}) => {
    var updates = [];
    var newRank = _.parseInt(value) - 1;
    var oldRank = _.findIndex(_.sortBy(this.props.productInstances, 'rank'), {id: productInstance.id});
    var oldIds = _.map(_.sortBy(this.props.productInstances, 'rank'), 'id');

    if (newRank > oldIds.length - 1) newRank = oldIds.length - 1;
    if (newRank < 0) newRank = 0;

    if (oldRank !== newRank && !isNaN(newRank)) {
      var newIds = _.arrayMove([...oldIds], oldRank, newRank);

      _.forEach(newIds, (id, rank) => updates.push({where: {id}, props: {rank: rank + 1}}));

      this.props.updateProductInstances({updates});
    }
  };

  render() {
    var {productOrder, activeProductInstanceId, setActiveInstanceId, onClose, roundPrice, productInstances, productInstancesById, productPricingRulesById, productOptionsById, productOptionClassifiersById, productRulesById, productPropertiesById, productsById, session} = this.props;
    var {activeOrg} = session;
    var {role} = activeOrg;
    var orderLevelProperties = [];

    var productInstancesWithData = _.sortBy(getProductInstancesWithData({productInstances: productInstancesById}, {
      productPropertiesById, productOptionsById, productsById, productRulesById, productInstancesById
    }), 'rank');

    var orderCosts = getCostsForOrder({productOrder, productInstances}, {productOptionsById, productPricingRulesById, productOptionClassifiersById, productsById, productPropertiesById, productRulesById, productInstancesById});

    var productOrderStatuses = getProductOrderStatusesFor({productOrder, productOrderStatuses: getFilteredProductOrderStatuses({productOrderStatuses: _.get(session, 'activeOrg.appData.designEngine.productOrderStatuses'), userRole: role})});
    var statusData = productOrder.statusData;
    var activeProductOrderStatus = getActiveProductOrderStatus({productOrderStatuses, statusData});
    var {paymentAmount} = getPaymentAmountForOrder({productOrder, orderCosts}, {productOrderStatuses, activeProductOrderStatus, activeOrg});

    if (productInstancesWithData.length > 0) {
      orderLevelProperties = filterInstancePropertiesFor({productInstance: productInstancesWithData[0]}, {isOrderLevel: 1});
    }

    var pricingIsHidden = getPricingIsHidden(this.props);

    return (
      <View style={{borderLeftWidth: 1, borderLeftColor: K.colors.grayBorder, backgroundColor: K.colors.gray, width: '25%'}}>
        {productInstances.length === 0 && (
          <View style={{ ...K.fonts.standard, flexGrow: 1, alignContent: 'center', justifyContent: 'center', textAlign: 'center'}}>
            <Text style={{padding: K.spacing}}>
              There are no items in the cart
            </Text>
          </View>
        )}
        {productInstancesWithData.length > 0 && (
          <View style={{flexShrink: 1, padding: 0}}>
            <ScrollView style={{flexGrow: 1, margin: 0}}>
              {_.map(productInstancesWithData, (productInstanceWithData, index) => {
                var firstProductInstanceId = productInstancesWithData[0].id;
                var productInstance = _.find(productInstances, {id: productInstanceWithData.id});
                var isInvalid = getProductInstanceIsInvalid({productInstance}, _.pick(this.props, ['productsById', 'productPropertiesById', 'productRulesById', 'productOptionsById']));

                return (
                  <CartDataRow
                    key={productInstance.id}
                    updateProductInstance = {this.props.updateProductInstance}
                    productInstance={productInstance}
                    productPropertiesById={productPropertiesById}
                    productsById={productsById}
                    indexInProductInstancesForOrder={index}
                    updateProductInstanceRank={this.updateProductInstanceRank}
                    {...{productInstanceWithData, pricingIsHidden, isInvalid, setActiveInstanceId, onClose, productPricingRulesById, productOptionsById, productOptionClassifiersById, roundPrice, productInstancesById, firstProductInstanceId, productOrder, productPropertiesById, productRulesById, activeProductInstanceId, activeOrg}}
                    onDelete= {({productInstance}) => this.handleProductInstanceDelete({productInstance})}
                  />
                );
              })}
            </ScrollView>
          </View>
        )}
        <View style={{ paddingTop: K.spacing * 2, paddingBottom: K.spacing * 2, marginRight: K.spacing, marginLeft: K.spacing, flexGrow: 1, alignItems: 'stretch'}}>
          <View style={{alignSelf: 'flex-start'}}>
            {_.map(orderLevelProperties, (orderLevelPropertyData) => {
              return (
                <View
                  key={orderLevelPropertyData.productProperty.id}
                  style={{ flexDirection: 'row', justifyContent: 'start', borderTopLeftRadius: 5, borderBottomLeftRadius: 5 }}
                >
                  <Text style={{ ...K.fonts.standard, fontWeight: 'bold', marginBottom: 3 }}>{orderLevelPropertyData.productProperty.title + ':   '}</Text>
                  <Text style={{ ...K.fonts.standard }}>{orderLevelPropertyData.selectedProductOption.title}</Text>
                </View>
              );
            })}
          </View>
          {!pricingIsHidden && (
            <OrderCostsTable
              styles={{outerView: {width: '100%', justifyContent: 'space-between', marginTop: K.spacing * 2}, row: {paddingVertical: K.spacing / 4}, title: {...K.fonts.standard}, value: {...K.fonts.standard}, totalTitle: {fontWeight: 'bold'}}}
              {...{orderCosts, productOrder, productInstances, paymentAmount}}
            />
          )}
        </View>
        {activeOrg.id === 850 && _.includes([1, 2, 5, 10, 11, 6, 7, 8, 15, 9, 87, 72, 89], productOrder.productId) && (
          <View style={{bottom: 0, padding: K.spacing}}><GetDisclaimer orgId={activeOrg.id} disclaimerType={'backBoxDisclaimer'} /></View>
        )}
      </View>
    );
  }
}
export default connect({
  mapState: (state, ownProps) => {
    var productOrderId = ownProps.productOrder.id;
    var productInstances = _.sortBy(_.filter(state.resources.productInstances.byId, {productOrderId}), 'rank');
    var productInstancesById = _.get(state.resources.productInstances, `byFieldKeyIndex.productOrderId.${productOrderId}`);
    var productPricingRulesById = state.resources.productPricingRules.byId;
    var productOptionsById = state.resources.productOptions.byId;
    var productOptionClassifiersById = state.resources.productOptionClassifiers.byId;
    var productRulesById = state.resources.productRules.byId;
    var productPropertiesById = state.resources.productProperties.byId;
    var productsById = state.resources.products.byId;

    return {
      productInstances,
      productInstancesById,
      productPricingRulesById,
      productOptionsById,
      productOptionClassifiersById,
      productRulesById,
      productPropertiesById,
      productsById
    };
  },
  mapDispatch: {
    ..._.pick(resourceActions.productInstances, ['updateProductInstance', 'destroyProductInstance', 'updateProductInstances']),
    ..._.pick(resourceActions.productOrders, ['updateProductOrder'])
  }
})(withKeyEvents(Cart));
