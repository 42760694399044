import React from 'react';
import { PureComponent } from 'react';
import _ from '@symbolic/lodash';
import K from '~/k';

import createIconWhite from '~/assets/create-icon-white.png';

import AdminMenu from '~/components/admin-menu';
import SecondaryHeader from '~/components/secondary-header';

import { Text, PickerInput, Link, Label, TextInput, DocumentTitle, LabelledView } from '@symbolic/rn-lib';
import { View, ScrollView, TouchableOpacity, Image } from 'react-native';
import { connect } from '@symbolic/redux';
import { resourceActions } from '~/redux';
import { api } from '@symbolic/lib';
import { accentColorForOrg } from '~/helpers/org-helper';

class ProductPropertyRow extends PureComponent {
  updateProductPropertyRank = ({value, productProperty}) => {
    var updates = [];
    var newRank = _.parseInt(value) - 1;
    var oldRank = _.findIndex(_.sortBy(this.props.productProperties, 'rank'), {id: productProperty.id});
    var oldIds = _.map(_.sortBy(this.props.productProperties, 'rank'), 'id');

    if (newRank > oldIds.length - 1) newRank = oldIds.length - 1;
    if (newRank < 0) newRank = 0;

    if (oldRank !== newRank && !isNaN(newRank)) {
      var newIds = _.arrayMove([...oldIds], oldRank, newRank);

      _.forEach(newIds, (id, rank) => updates.push({where: {id}, props: {rank: rank + 1}}));

      this.props.updateProductProperties({updates});
    }
  };

  render() {
    var {productProperty, index} = this.props;

    var textCellStyle = {paddingHorizontal: K.spacing};

    return (
      <View
        dataSet={{'hover-background-double-gray': 1}}
        style={{flexDirection: 'row', alignItems: 'center', height: 30, borderRadius: K.borderRadius, backgroundColor: K.colors.gray, marginBottom: K.margin, marginHorizontal: K.spacing}}
      >
        <TextInput
          value={index + 1}
          onChange={({value})=> this.updateProductPropertyRank({value, productProperty, index})}
          style={{backgroundColor: 'transparent', alignSelf: 'center', width: 75, paddingHorizontal: K.spacing, textAlign: 'center', opacity: 0.5 }}
          selectTextOnFocus
        />
        <Link to={`/admin/product-properties/${productProperty.id}`} style={{paddingLeft: K.spacing, flexDirection: 'row', flex: 1, height: '100%', alignItems: 'center'}}>
          <Text style={{width: 75, opacity: 0.5, ...textCellStyle}}>{productProperty.id}</Text>
          <Text style={{flex: 1, ...textCellStyle}}>{productProperty.title}</Text>
          <Text style={{flex: 1, opacity: 0.5, ...textCellStyle}}>{productProperty.internalTitle}</Text>
        </Link>
      </View>
    );
  }
}

class AdminProductPropertiesPage extends React.PureComponent {
  state = {
    filterMode: 'active'
  };

  handleFilterModeChange = ({value}) => this.setState({filterMode: value});

  createProductProperty = async () => {
    var productProperty = await api.create('productProperty', {
      orgId: this.props.session.activeOrg.id,
      title: 'Untitled Product Property',
      rank: _.max(_.map(this.props.productProperties, ({rank}) => rank)) + 1
    });

    this.props.trackProductProperties({productProperties: [productProperty]});

    this.createProductOption({productPropertyId: productProperty.id});

    //HINT need the setTimeout after trackProductProperties
    setTimeout(() => this.props.history.push(`/admin/product-properties/${productProperty.id}`));
  };

  createProductOption = async ({productPropertyId}) => {
    var productOption = await api.create('productOption', {
      orgId: this.props.session.activeOrg.id,
      title: 'Untitled Option',
      productPropertyId,
      rank: 1,
    });

    this.props.trackProductOptions({productOptions: [productOption]});
  };

  render() {
    var {productProperties} = this.props;
    var {filterMode} = this.state;

    var productProperties = _.filter(productProperties, productProperty => {
      if (filterMode === 'active') return !productProperty.archived;
      if (filterMode === 'archived') return !!productProperty.archived;
      if (filterMode === 'all') return true;
    });

    return (
      <DocumentTitle title={'Admin: Product Properties'}>
        <View style={{flex: 1, flexDirection: 'row'}}>
          <AdminMenu activeOrg={this.props.session.activeOrg} />
          <View style={{flex: 1, position: 'relative'}}>
            <SecondaryHeader
              title={'Properties'}
              rightComponent={(
                <View style={{right: 20}}>
                  <LabelledView label={'Status'} styles={{paddingTop: K.spacing}}>
                    <PickerInput
                      style={{height: K.inputHeight}}
                      buttonStyle={{backgroundColor: K.colors.doubleGray}}
                      showDownArrow={true}
                      options={[
                        {value: 'active', title: 'Active'},
                        {value: 'archived', title: 'Archived'},
                        {value: 'all', title: 'All'},
                      ]}
                      value={this.state.filterMode}
                      onChange={this.handleFilterModeChange}
                    />
                  </LabelledView>
                </View>
              )}
            ></SecondaryHeader>
            <ScrollView style={{flex: 1}} contentContainerStyle={{paddingTop: K.spacing, paddingBottom: K.spacing * 4}}>
              <View style={{paddingLeft: K.spacing * 2, flexDirection: 'row', marginRight: K.spacing}}>
                {_.map([
                  {title: 'Rank', style: {width: 75}},
                  {title: 'ID', style: {width: 75}},
                  {title: 'Title', style: {flex: 1}},
                  {title: 'Internal Title', style: {flex: 1}}
                ], ({title, style}, h) => (
                  <View key={h} style={{...style, padding: K.spacing}}>
                    <Label>{title}</Label>
                  </View>
                ))}
              </View>
              <View>
                {_.map(_.sortBy(productProperties, ['rank', 'id']), (productProperty, index) => (
                  <ProductPropertyRow
                    key={productProperty.id}
                    {...{productProperty, productProperties, index, ..._.pick(this.props, ['updateProductProperties'])}}
                  />
                ))}
              </View>
              {/* TODO pagination */}
            </ScrollView>
            <TouchableOpacity
              onPress={this.createProductProperty}
              style={{position: 'fixed', bottom: K.spacing * 2, left: K.spacing * 2, backgroundColor: accentColorForOrg({org: this.props.session.activeOrg}), width: 70, height: 70, borderRadius: 70, alignItems: 'center', 'justifyContent': 'center'}}
            >
              <Image source={createIconWhite} style={{width: K.calc(35), height: K.calc(35)}}/>
            </TouchableOpacity>
          </View>
        </View>
      </DocumentTitle>
    );
  }
}

export default connect({
  mapState: (state) => {
    return {
      productProperties: state.resources.productProperties.byId
    };
  },
  mapDispatch: {
    ..._.pick(resourceActions.productProperties, ['updateProductProperties', 'trackProductProperties']),
    ..._.pick(resourceActions.productOptions, ['trackProductOptions'])
  }
})(AdminProductPropertiesPage);
