import _ from 'lodash';
import getTextSize from '~/helpers/get-text-size';
import { PureComponent, Fragment} from 'react';
import {Rect, G, Circle, Ellipse, Text as SvgText, Image, Mask, Defs, Path, RadialGradient, LinearGradient, Stop, Line } from 'react-native-svg';
import { Rect as CanvasRect, Circle as CanvasCircle, Group as CanvasGroup, Path as CanvasPath } from 'react-konva';
import CanvasText from '~/components/canvas/text/canvas-text';
import lib from '~/lib';
// import { Text as ThreeText } from '@react-three/drei';

class ProductGraphicNode extends PureComponent {
  render() {
    var {node, size, scale, mode, productInstance, dimensionsAreVisible, substrate, canvasData, position, measurementSystem} = this.props;
    var {type, props, children} = node;
    var deps = {size, scale, mode, substrate, canvasData, position};

    if (node.shouldExist) {
      var shouldExist = productInstance.propertiesDataById[node.shouldExist.productPropertyId].selectedProductOption.id === node.shouldExist.productOptionId;

      if (!shouldExist) return null;
    }

    var calc = (formula, {size = 0, scale = 1, productInstance}) => {
      if (typeof(formula) === 'string') {
        //Percentages
        //Match any number or decimal number or negative number preceding %
        var percentages = formula.match(/[-+]?\d+(\.\d+)?(?=%)/g);
        if (percentages) {
          percentages.forEach(percentage => {
            var value = ((parseFloat(percentage) || 0) / 100) * size;

            formula = formula.replace(`${percentage}%`, value);
          });
        }

        //Match any number or decimal number or negative number preceding mm
        // var pixels = formula.match(/[-+]?\d+(\.\d+)?(?=px)/g);

        // if (pixels) {
        //   pixels.forEach(pixel => {
        //     var value = (parseFloat(pixel) || 0);

        //     formula = formula.replace(`${pixel}px`, value);
        //   });
        // }
      }

      var value = 0;

      if (typeof(formula) === 'object') {
        value = _.get(productInstance, `properties.${formula.productPropertyId}.value`, 0);
      }
      else {
        try {
          value = eval(formula);
        }
        catch (err) {
          console.log(formula, err); // eslint-disable-line
        }
      }

      value = value * scale;

      return value;
    };

    props = {...props, fontSize: props.fontSize * scale * 1.4};

    if (type === 'rect') props = _.defaults(props, {x: 0, y: 0});

    props = _.mapValues(props, (prop, key) => {
      if (_.includes(['width', 'left', 'right', 'x1', 'x2', 'rx', 'x', 'cx', 'r', 'radius'], key)) {
        prop = calc(prop, {size: size.width, scale, key, productInstance});
      }
      if (_.includes(['height', 'top', 'bottom', 'y1', 'y2', 'y', 'cy', 'ry'], key)) {
        prop = calc(prop, {size: size.height, scale, key, productInstance});
      }
      if (_.includes(['depth', 'z'], key)) {
        prop = calc(prop, {size: size.depth, scale, key, productInstance});
      }

      if (_.includes(['d'], key)) {
        var pathCommands = _.map(prop, (command, key) => {
          return _.mapValues(command, (item, itemName) => {

            if (_.includes(['x1', 'x2', 'x', 'rx'], itemName)) {
              item = calc(item, {size: size.width, scale, key}) ;
            }
            if (_.includes(['y1', 'y2', 'y', 'ry'], itemName)) {
              item = calc(item, {size: size.height, scale, key}) ;
            }

            return item;
          });
        });

        prop = _.chain(pathCommands)
          .map((commandData) => `${commandData.command} ${_.join(_.values(_.omit(commandData, ['command'])), ' ')}`)
          .join(' ')
          .value();
      }

      return prop;
    });

    if (mode === '2d') {
      if (substrate === 'canvas') {
        if (type === 'rect') return <CanvasRect {...props}/>;
        if (type === 'circle') return <CanvasCircle {...props}/>;
        if (type === 'text') return <CanvasText {...props} text={children}/>;
      }
      else {
        if (type === 'rect') return <Rect {...props}/>;
        if (type === 'circle') return <Circle {...props}/>;
        if (type === 'text') return <SvgText {...props}>{children}</SvgText>;
      }

      if (type === 'ellipse') return <Ellipse {...props}/>;
      if (type === 'image') return <Image {...props}/>;

      if (type === 'dimension') {
        var {positions, extrudeDistance = 30, customTitles = [], lineColor = '#ccc', textColor = '#666'} = props.props; //TODO don't need props.props - should just have "props"
        var alpha = lib.trig.alpha({p1: {x: positions[0].x, y: positions[0].y}, p2: {x: positions[positions.length - 1].x, y: positions[positions.length - 1].y}});
        var orientation = Math.abs((alpha / Math.PI) - 1) < 0.1 ? 'vertical' : 'horizontal';
        var scaledPositions = _.map(positions, ({x, y}) => ({x: x * scale, y: y * scale}));
        var extrudedPositions = _.map(scaledPositions, ({x, y}) => lib.trig.translate({point: {x, y}, by: extrudeDistance, alpha: alpha - (Math.PI / 2)}));

        function decimalToFraction(decimal) {
          function gcd(a, b) {
              return b === 0 ? a : gcd(b, a % b);
          }

          let numerator = decimal * 1000000; // Consider a large number for precision
          let denominator = 1000000; // Same precision as the numerator

          const commonDivisor = gcd(numerator, denominator);

          numerator /= commonDivisor;
          denominator /= commonDivisor;

          return { numerator, denominator };
      }

        return (<>
          {dimensionsAreVisible === true && (<>
            <Line x1={(extrudedPositions[0].x)} y1={extrudedPositions[0].y} x2={extrudedPositions[positions.length - 1].x} y2={extrudedPositions[positions.length - 1].y} stroke={lineColor}/>
            {_.map(scaledPositions, (position, index) => {
              if (index > 0) {
                var textInt = Math.round(lib.trig.dist(positions[index - 1], positions[index]) * 100) / 100;
                var text = String(textInt);

                if (customTitles[index - 1]) text = customTitles[index - 1];

                var isSmallDimension = textInt <= 5;

                var textOffset = getTextSize({text, fontSize: 11, fontFamily: 'Arial'})[orientation === 'horizontal' ? 'width' : 'height'] / 2;

                if (measurementSystem === 'imperial') {
                  textInt = textInt * 0.0393701;

                  textInt = Math.round(textInt / (1/16)) * (1/16);

                  var integer = String(Math.floor(textInt));
                  var remainingFraction = decimalToFraction(textInt - Math.floor(textInt));

                  text = `${Math.floor(textInt) !== 0 ? integer : ''} ${remainingFraction.numerator}/${remainingFraction.denominator}"`;
                }

                var extrudedLabelPositions = _.map(scaledPositions, ({x, y}) => lib.trig.translate({
                  point: {x, y},
                  by: (isSmallDimension
                    ? extrudeDistance + (measurementSystem === 'imperial' ? 8 : 2) + textOffset
                    : extrudeDistance),
                  alpha: alpha - (Math.PI / 2)
                }));

                var dimensionTextPosition = {
                  x: ((extrudedLabelPositions[index].x + extrudedLabelPositions[index - 1].x) / 2),
                  y: ((extrudedLabelPositions[index].y + extrudedLabelPositions[index - 1].y) / 2)
                };
              }

              return (<Fragment key={(JSON.stringify(position))}>
                <Line x1={position.x} y1={position.y} x2={extrudedPositions[index].x} y2={extrudedPositions[index].y} stroke={lineColor}/>
                {(index > 0 && textInt !== 0) && (<>
                  {!isSmallDimension && (
                    <Rect
                      x={dimensionTextPosition.x - (getTextSize({text: `${text}`}).width / 2)}
                      y={dimensionTextPosition.y - (getTextSize({text: `${text}`}).height / 2)}
                      width={getTextSize({text: `${text}`}).width}
                      height={getTextSize({text: `${text}`}).height}
                      fill='white'
                    />
                  )}
                  {(!!text && index > 0) && (
                    <SvgText
                      x={dimensionTextPosition.x}
                      y={dimensionTextPosition.y + 3}
                      style={{fontSize: isSmallDimension ? 8 : 11, fontFamily: 'arial', backgroundColor: 'white', fill: textColor, textAnchor: 'middle'}}
                    >{`${text}`}</SvgText>
                  )}
                </>)}
              </Fragment>);
            })}
          </>)}
        </>);
      }

      if (type === 'shortDimension') {
        var {positions, extrudeDistance} = props.props;
        var alpha = lib.trig.alpha({p1: {x: positions[0].x, y: positions[0].y}, p2: {x: positions[1].x, y: positions[1].y}, perpendicular: 0});

        var title = Math.round(lib.trig.dist(positions[0], positions[1]) * 100) / 100;
        var scaledPositions = _.mapValues(positions, ({x, y}) =>{return {x: x * scale, y: y * scale};});

        var p1 = [lib.trig.translate({point: scaledPositions[0], by: extrudeDistance * scale, alpha: alpha + Math.PI}), lib.trig.translate({point: scaledPositions[0], by: 5, alpha: alpha + Math.PI + ((Math.PI / 7))}), lib.trig.translate({point: scaledPositions[0], by: 5, alpha: alpha + Math.PI - ((Math.PI / 7))})];

        var p2 = [lib.trig.translate({point: scaledPositions[1], by: extrudeDistance * scale, alpha}), lib.trig.translate({point: scaledPositions[1], by: 5, alpha: alpha + (Math.PI / 7)}), lib.trig.translate({point: scaledPositions[1], by: 5, alpha: alpha - (Math.PI / 7)})];
        var extrudedTextPosition = lib.trig.translate({point: scaledPositions[1], by: extrudeDistance, alpha});
        return (<>
          {dimensionsAreVisible === true && (<>
            return (<>
              {_.map(p1, (position, index) => {
                return (<>
                  <Line key={index} x1={scaledPositions[0].x} y1={scaledPositions[0].y} x2={position.x} y2={position.y} strokeWidth='1' stroke='black' opacity='0.5'/>
                </>);
              })}
              {_.map(p2, (position, index) => {
                return (<>
                  <Line key={index} x1={scaledPositions[1].x} y1={scaledPositions[1].y} x2={position.x} y2={position.y} strokeWidth='1' stroke='black' opacity='0.5'/>
                </>);
              })}
              <Rect
                x={extrudedTextPosition.x - (getTextSize({text: title}).width / 2)}
                y={extrudedTextPosition.y - (getTextSize({text: title}).height / 2)}
                width={getTextSize({text: title}).width}
                height={getTextSize({text: title}).height}
                fill='white' opacity='0.5'
              />
              <SvgText
                x={extrudedTextPosition.x}
                y={extrudedTextPosition.y + 3}
                style={{fontSize: 11, fontFamily: 'Arial', backgroundColor: 'white', color: 'black', textAnchor: 'middle'}}
              >
                {title}
              </SvgText>
            </>);
          </>)}
        </>);
      }

      if (type === 'group') {
        if (substrate === 'canvas') {
          return (
            <CanvasGroup {...props}>
              {_.map(children, (node) => (
                <ProductGraphicNode key={node.id} {...deps} {...{node, productInstance, dimensionsAreVisible, measurementSystem}}/>
              ))}
            </CanvasGroup>
          );
        }
        else {
          return (
            <G {...props}>
              {_.map(children, (node) => (
                <ProductGraphicNode key={node.id} {...deps} {...{node, productInstance, dimensionsAreVisible, measurementSystem}}/>
              ))}
            </G>
          );
        }
      }

      if (type === 'mask') {
        return (
          <Defs>
            <Mask {...props}>
              {_.map(children, (node) => (
                <ProductGraphicNode key={node.id} {...deps} {...{node, productInstance}}/>
              ))}
            </Mask>
          </Defs>
        );
      }

      if (type === 'radialGradient' || type === 'linearGradient') {
        var Gradient = {radialGradient: RadialGradient, linearGradient: LinearGradient}[type];

        return (
          <Defs>
            <Gradient {...props}>
              {_.map(children, ({color, offset}) => (
                <Stop offset={offset} stopColor={color}/>
              ))}
            </Gradient>
          </Defs>
        );
      }

      if (type === 'dropShadow') {
        return (
          <Defs>
            <filter {..._.pick(props, ['id'])} x={'-50%'} y={'-50%'} width={'200%'} height={'200%'}>
              <feDropShadow dx={props.x || 0} dy={props.y || 0} stdDeviation={props.blur || 1} floodColor={props.color || 'black'} floodOpacity={props.opacity || 1}/>
            </filter>
          </Defs>
        );
      }

      if (type === 'path') {
        if (props.x || props.y) props = {...props, transform: 'translate(' + props.x + ',' + props.y + ')'};

        if (substrate === 'canvas') {
          return <CanvasPath {...props} data={props.d}/>;
        }
        else {
          return <Path {...props}/>;
        }
      }
    }

    if (mode === '3d') {
      var origin = _.defaults(props.origin, {x: 'near', y: 'near', z: 'near'});

      if (props.origin === 'center') origin = {x: 'center', y: 'center', z: 'center'};
      if (props.origin === 'near') origin = {x: 'near', y: 'near', z: 'near'};

      var originMultipliers = _.mapValues(origin, value => type === 'cube' ? _.get({near: 0.5, center: 0, far: 0.5}, value, value || 0) : 0);

      var size = _.pick(props, ['width', 'height', 'depth']);
      var dimensions = [props.width || 0, props.height || 0, props.depth || 0];

      var position = [
        -((props.x || 0) + originMultipliers.x * dimensions[0]),
        ((props.y || 0) + originMultipliers.y * dimensions[1]),
        -((props.z || 0) + originMultipliers.z * dimensions[2])
      ];

      if (type === 'group') {
        return (
          <group {...{position}}>
            {_.map(children, (node) => (
              <ProductGraphicNode key={node.id} {...deps} {...{node, size, productInstance}}/>
            ))}
          </group>
        );
      }

      if (type === 'cube') {
        return (
          <mesh castShadow receiveShadow {...{position}}>
            <boxBufferGeometry attach="geometry" args={dimensions} />
            <meshStandardMaterial attach="material" color={props.fill || '#999999'} />
          </mesh>
        );
      }

      // if (type === 'cylinder') return <Cylinder {...props}/>;

      // if (type === 'text') {
      //   return <ThreeText
      //     scale={[10, 10, 10]}
      //     color="black" // default
      //     anchorX="center" // default
      //     anchorY="middle" // default
      //   ></ThreeText>;
      // }
    }

    return null;
  }
}

export default ProductGraphicNode;
