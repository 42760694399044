import { PureComponent } from 'react';
import { api } from '@symbolic/lib';
import { connect } from '@symbolic/redux';
import { resourceActions } from '~/redux';

import { Text, CheckboxInput } from '@symbolic/rn-lib';
import { PaymentElement } from '@stripe/react-stripe-js';

import K from '~/k';

import { loadStripe } from '@stripe/stripe-js';
import { Elements, ElementsConsumer } from '@stripe/react-stripe-js';

const stripeTest = loadStripe('pk_test_51MnnGgHVjs5jQE5v5IQJwTMnVoPmnOoGzLgPgb9kEGB67BJB6t92kcP1Fz4dGLHhLy3rWSnIgw2m3z2iVygfG0I900TXmj1FR5');
const stripeLive = loadStripe('pk_live_51MnnGgHVjs5jQE5vANyyiUo2YGj7owHY41S6P79azFgMGhrnfVe8QanjadPY6PP7s8gVgjpihco5LGdOjls963jz00xtZpD8rE');

class StripeCheckoutForm extends PureComponent {
  state = {
    paymentEnvironment: 'live',
    paymentElementIsLoading: true,
    clientSecret: null
  };

  intiallyLoadedPaymentTypes = {};

  componentDidMount = async () => {
    this.setState({paymentEnvironment: await sessionStore.get('paymentEnvironment') || 'live'}, async () => {
      var {productOrder, activeProductOrderStatus} = this.props;
      var {paymentEnvironment} = this.state;

      var paymentIntentId = _.get(productOrder, `integrationData.stripe.${paymentEnvironment}.${activeProductOrderStatus.key}.paymentIntentId`);

      this.setState({paymentElementIsLoading: true, clientSecret: null});

      if (paymentIntentId) {
        await this.getPaymentIntent({paymentIntentId});
      }
      else {
        await this.createPaymentIntent();
      }
    });
  };

  componentDidUpdate = async (prevProps) => {
    var {activePaymentMethod, activeOrgId, payInFull, productOrderId, isCardPayment, shippedWithoutFinalPayment} = this.props;

    var activePaymentMethodChanged = activePaymentMethod && prevProps.activePaymentMethod && (prevProps.activePaymentMethod !== activePaymentMethod);
    var payInFullChanged = prevProps.payInFull !== payInFull;

    if (activeOrgId === 850) {
      if ((activePaymentMethodChanged && this.props.paymentAmount > 300000) || payInFullChanged) {
        await api.request({uri: '/configurator/update-stripe-payment-intent', body: {
          paymentIntentId: this.state.paymentIntentId,
          paymentEnvironment: this.state.paymentEnvironment,
          productOrderId, payInFull, isCardPayment, shippedWithoutFinalPayment
        }});
      }
    }
  };

  getPaymentIntent = async ({paymentIntentId}) => {
    var {paymentEnvironment} = this.state;

    var {data: {paymentIntent}} = await api.request({uri: '/configurator/get-stripe-payment-intent', body: {paymentIntentId, paymentEnvironment}});

    this.considerUpdatingPaymentIntent({paymentIntentId, paymentIntent});

    this.setState({
      paymentIntentId,
      clientSecret: _.get(paymentIntent, 'client_secret'),
      paymentElementIsLoading: false
    });
  };

  considerUpdatingPaymentIntent = async ({paymentIntentId, paymentIntent}) => {
    var {paymentAmount, upchargeAmount, productOrderId, payInFull, isCardPayment, shippedWithoutFinalPayment} = this.props;
    var {paymentEnvironment} = this.state;

    if (
      paymentAmount !== parseInt(_.get(paymentIntent, 'amount')) ||
      upchargeAmount !== parseInt(_.get(paymentIntent, 'metadata.upcharge_amount_in_cents')) ||
      payInFull !== (_.get(paymentIntent, 'metadata.pay_in_full') === 'true')
    ) {
      await api.request({uri: '/configurator/update-stripe-payment-intent', body: {
        paymentIntentId,
        paymentEnvironment,
        paymentAmount, productOrderId, payInFull, isCardPayment, shippedWithoutFinalPayment
      }});
    }
  };

  createPaymentIntent = async () => {
    var {productOrderId, isCardPayment, payInFull, shippedWithoutFinalPayment} = this.props;
    var {paymentEnvironment} = this.state;

    var {data: {paymentIntentId, stripePaymentIntentClientSecret}} = await api.request({uri: '/configurator/create-stripe-payment-intent', body: {productOrderId, isCardPayment, paymentEnvironment, payInFull, shippedWithoutFinalPayment}});

    this.setState({
      paymentIntentId,
      clientSecret: stripePaymentIntentClientSecret,
      paymentElementIsLoading: false
    });
  };

  handlePaymentEnvironmentChange = async ({value}) => {
    var paymentEnvironment = value === 1 ? 'test' : 'live';

    sessionStore.set('paymentEnvironment', paymentEnvironment);

    window.location.reload();
  };

  handleSubmit = async ({event, stripe, elements}) => {
    event.preventDefault();

    if (!stripe || !elements) return;

    setTimeout(() => {
      this.props.updateProductOrder({id: this.props.productOrderId, props: {isProcessingPayment: 1}}); //TODO should be route that enforces permissions
    }, 100);

    // https://stripe.com/docs/js/payment_intents/confirm_payment
    const {error} = await stripe.confirmPayment({
      elements,
      confirmParams: {
        return_url: window.location.href
      }
    });

    if (error) {
      setTimeout(() => {
        this.props.updateProductOrder({id: this.props.productOrderId, props: {isProcessingPayment: 0}}); //TODO should be route that enforces permissions
      }, 100);

      alert(error.message);
    } else {
      // Your customer will be redirected to your `return_url`. For some payment
      // methods like iDEAL, your customer will be redirected to an intermediate
      // site first to authorize the payment, then redirected to the `return_url`.
    }
  };

  render() {
    var {formattedPaymentAmount, stripePaymentMethodOrder, isEmployee} = this.props;
    var {clientSecret, paymentEnvironment, paymentElementIsLoading} = this.state;

    var appearance = {
      theme: 'none',
      variables: { // https://stripe.com/docs/elements/appearance-api?platform=web#variables
        fontFamily: K.fontFamily,
        fontSizeBase: `${K.calcFont(14)}px`,
        spacingUnit: `${K.spacing / 2}px`,
        borderRadius: `${K.borderRadius}px`,
        colorPrimary: '#000',
        colorBackground: 'transparent',
        colorText: '#000'
      },
      rules: { //https://stripe.com/docs/elements/appearance-api?platform=web#rules
        '.Tab': {
          border: '1px solid',
          borderColor: K.colors.gray,
        },
        '.Tab--selected': {
          borderColor: '#000',
          color: '#000',
        },
        '.Label': {
          fontSize: `${K.calcFont(12)}px`,
          letterSpacing: '0.8px',
          textTransform: 'uppercase',
          opacity: 0.6,
          marginBottom: `${K.margin}px`,
          marginLeft: `${K.spacing}px`
        },
        '.Input': {
          fontSize: `${K.calcFont(14)}px`,
          lineHeight: '30px',
          padding: `${K.margin}px ${K.spacing}px`,
          backgroundColor: K.colors.gray
        }
      }
    };

    // https://stripe.com/docs/js/elements_object/create_payment_element
    var paymentElementOptions = {
      layout: 'tabs',
      wallets: 'never',
      paymentMethodOrder: stripePaymentMethodOrder
    };

    //TODO seperate payment methods
    // card? https://stripe.com/docs/payments/quickstart
    // eCheck/bank? https://stripe.com/docs/sources/ach-credit-transfer

    //HINT test payment details
    // https://stripe.com/docs/payments/accept-a-payment?platform=web&ui=elements#web-test-the-integration

    return (
      <>
        {(isEmployee || process.env.NODE_ENV === 'development') && (
          <CheckboxInput
            label={'Stripe Test Mode'}
            value={paymentEnvironment === 'test' ? 1 : 0}
            onChange={({value}) => this.handlePaymentEnvironmentChange({value})}
            style={{marginVertical: K.margin}}
          />
        )}
        {(!paymentElementIsLoading && clientSecret) && (
          <Elements stripe={this.state.paymentEnvironment === 'test' ? stripeTest : stripeLive} options={{clientSecret, appearance}}>
            <ElementsConsumer>
              {({stripe, elements}) => (
                <>
                  <form onSubmit={(event) => this.handleSubmit({event, stripe, elements})}>
                    <PaymentElement
                      options={{...paymentElementOptions}}
                      onChange={({value}) => {
                        if (this.intiallyLoadedPaymentTypes[value]) {
                          this.props.handleStripePaymentMethodChange({type: value.type});
                        }
                        else {
                          this.intiallyLoadedPaymentTypes[value] = 1;
                        }
                      }}
                    />
                    <br/>
                    {!paymentElementIsLoading ? (
                      <button
                        disabled={!stripe}
                        style={{...K.button, cursor: 'pointer', display: 'flex', position: 'relative', border: 'none', borderRadius: 0, backgroundColor: 'black', color: 'white', fontSize: `${K.calc(14)}px`, letterSpacing: '0.8px', height: '30px', width: 'auto', marginTop: K.spacing, padding: `${K.spacing / 2}px ${K.spacing * 1.5}px`}}
                      >
                        {`PAY $${formattedPaymentAmount} NOW`}
                      </button>
                    ) : (
                      <Text>Loading payment form...</Text>
                    )}
                  </form>
                </>
              )}
            </ElementsConsumer>
          </Elements>
        )}
      </>
    );
  }
}

export default connect({
  mapDispatch: {
    ..._.pick(resourceActions.productOrders, ['updateProductOrder'])
  }
})(StripeCheckoutForm);
