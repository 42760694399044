import { PureComponent } from 'react';
import { Text, Link, Label, DocumentTitle, TextInput, Tooltip } from '@symbolic/rn-lib';
import { View, ScrollView, TouchableOpacity, Image} from 'react-native';
import { connect } from '@symbolic/redux';
import { PickerInput, LabelledView } from '@symbolic/rn-lib';
import { resourceActions } from '~/redux';
import { api } from '@symbolic/lib';
import { accentColorForOrg } from '~/helpers/org-helper';
import { publishLevelOptions } from '~/helpers/publish-level-options';
import _ from '@symbolic/lodash';

import K from '~/k';
import AdminMenu from '~/components/admin-menu';
import SecondaryHeader from '~/components/secondary-header';

import createIconWhite from '~/assets/create-icon-white.png';

class ProductRow extends PureComponent {
  updateProductRank = ({value, product}) => {
    var updates = [];
    var newRank = _.parseInt(value) - 1;
    var oldRank = _.findIndex(_.sortBy(this.props.products, 'rank'), {id: product.id});
    var oldIds = _.map(_.sortBy(this.props.products, 'rank'), 'id');

    if (newRank > oldIds.length - 1) newRank = oldIds.length - 1;
    if (newRank < 0) newRank = 0;

    if (oldRank !== newRank && !isNaN(newRank)) {
      var newIds = _.arrayMove([...oldIds], oldRank, newRank);

      _.forEach(newIds, (id, rank) => updates.push({where: {id}, props: {rank: rank + 1}}));

      this.props.updateProducts({updates});
    }
  };

  render() {
    var {product, index} = this.props;

    var productCurrentInventory = _.get(product, 'currentInventory', '');
    var productVisibility = _.get(_.find(publishLevelOptions, {value: product.publishLevel}), 'title');

    var textCellStyle = {paddingHorizontal: K.spacing};

    return (
      <View
        dataSet={{'hover-background-double-gray': 1}}
        style={{flexDirection: 'row', alignItems: 'center', height: 30, borderRadius: K.borderRadius, marginBottom: K.margin, marginHorizontal: K.spacing, backgroundColor: productCurrentInventory < 0 ? 'rgba(255, 0, 0, 0.1)' : K.colors.gray}}
      >
        <TextInput
          value={index + 1}
          onChange={({value})=> this.updateProductRank({value, product, index})}
          style={{backgroundColor: 'transparent', alignSelf: 'center', width: 75, paddingHorizontal: K.spacing, textAlign: 'center', opacity: 0.5}}
          selectTextOnFocus
        />
        <Link to={`/admin/products/${product.id}`} style={{paddingLeft: K.spacing, flexDirection: 'row', flex: 1, height: '100%', alignItems: 'center'}}>
          <Text style={{width: 75, opacity: 0.5, ...textCellStyle}}>{product.id}</Text>
          <Text style={{flex: 1, ...textCellStyle}}>{product.title}</Text>
          <Text style={{width: 150, opacity: 0.5, ...textCellStyle}}>{productVisibility}</Text>
          <Text style={{width: 150, opacity: 0.5, ...textCellStyle, ...(productCurrentInventory < 0 && {color: 'red'})}}>{product.isStocked ? productCurrentInventory : 'N/A'}</Text>
        </Link>
      </View>
    );
  }
}

class AdminProductsPage extends PureComponent {
  state = {
    filterMode: 'active',
    stockedFilterMode: 'all'
  };

  handleFilterModeChange = ({value}) => this.setState({filterMode: value});

  handleStockedFilterModeChange = ({value}) => this.setState({stockedFilterMode: value});

  createProduct = async () => {
    var product = await api.create('product', {
      orgId: this.props.session.activeOrg.id,
      title: 'Untitled Product',
      rank: _.max(_.map(this.props.products, ({rank}) => rank)) + 1
    });

    this.props.trackProducts({products: [product]});

    //HINT need the setTimeout after trackProducts
    setTimeout(() => this.props.history.push(`/admin/products/${product.id}`));
  };

  render() {
    var {filterMode, stockedFilterMode} = this.state;

    var products = _.filter(this.props.products, product => {
      if (product.id === -1) return !product;
      if (filterMode === 'active') return !product.archived;
      if (filterMode === 'archived') return !!product.archived;
      if (filterMode === 'all') return true;
    });

    var filteredProducts = _.filter(products, product => {
      if (stockedFilterMode === 'isStocked' && product.isStocked !== 0) return !product.archived;
      if (stockedFilterMode === 'notStocked' && product.isStocked === 0) return !product.archived;
      if (stockedFilterMode === 'all') return true;
    });

    return (
      <DocumentTitle title={'Admin: Products'}>
        <View style={{flex: 1, flexDirection: 'row'}}>
          <AdminMenu activeOrg={this.props.session.activeOrg} />
          <View style={{flex: 1, position: 'relative'}}>
            <SecondaryHeader
              title={'Products'}
              rightComponent={(
                <View style={{flexDirection: 'row', width: K.spacing * 21, justifyContent: 'space-between'}}>
                  <LabelledView label='Stocked' styles={{paddingTop: K.spacing}}>
                    <PickerInput
                      style={{height: K.inputHeight, width: K.spacing * 10}}
                      buttonStyle={{backgroundColor: K.colors.doubleGray}}
                      showDownArrow={true}
                      options={[
                        {value: 'isStocked', title: 'Stocked'},
                        {value: 'notStocked', title: 'Not in Stock'},
                        {value: 'all', title: 'All'},
                      ]}
                      value={this.state.stockedFilterMode}
                      onChange={this.handleStockedFilterModeChange}
                    />
                  </LabelledView>
                  <LabelledView label='Status' styles={{paddingTop: K.spacing}}>
                    <PickerInput
                      style={{height: K.inputHeight, width: K.spacing * 10}}
                      buttonStyle={{backgroundColor: K.colors.doubleGray}}
                      showDownArrow={true}
                      options={[
                        {value: 'active', title: 'Active'},
                        {value: 'archived', title: 'Archived'},
                        {value: 'all', title: 'All'},
                      ]}
                      value={this.state.filterMode}
                      onChange={this.handleFilterModeChange}
                    />
                  </LabelledView>
                </View>
              )}
            ></SecondaryHeader>
            <ScrollView style={{flex: 1}} contentContainerStyle={{paddingTop: K.spacing, marginBottom: K.spacing * 8}}>
              <View style={{paddingLeft: K.spacing * 2, flexDirection: 'row', marginRight: K.spacing}}>
                {_.map([
                  {title: 'Rank', style: {width: 75}},
                  {title: 'ID', style: {width: 75}},
                  {title: 'Title', style: {flex: 1}},
                  {title: 'Visibility', style: {width: 150}},
                  {title: 'Inventory', style: {width: 150}}
                ], ({title, style}, h) => (
                  <View key={h}
                    style={{...style, padding: K.spacing}}
                  >
                    <Label>{title}</Label>
                  </View>
                ))}
              </View>
              <View>
                {_.map(_.sortBy(filteredProducts, ['rank', 'id']), (product, index) => (
                  <ProductRow
                    key={product.id}
                    {...{product, products, filteredProducts, index, ..._.pick(this.props, ['updateProducts'])}}
                  />
                ))}
              </View>
              {/* TODO pagination */}
            </ScrollView>
            <TouchableOpacity
              onPress={this.createProduct}
              style={{position: 'fixed', bottom: K.spacing * 2, left: K.spacing * 2, backgroundColor: accentColorForOrg({org: this.props.session.activeOrg}), width: 70, height: 70, borderRadius: 70, alignItems: 'center', 'justifyContent': 'center'}}
            >
              <Image source={createIconWhite} style={{width: K.calc(35), height: K.calc(35)}}/>
            </TouchableOpacity>
            {/*<Link
              to='/'
              style={{position: 'absolute', right: K.spacing * 2, top: '50%', marginTop: -35, width: 70, height: 70, borderRadius: 70, alignItems: 'center', justifyContent: 'center', borderWidth: 1, borderStyle: 'solid', borderColor: 'rgba(0, 0, 0, 0.2)'}}
            >
              <Image source={leftArrowIcon} style={{height: K.calc(23.54), width: K.calc(35.93)}}/>
            </Link>*/}
          </View>
        </View>
      </DocumentTitle>
    );
  }
}

export default connect({
  mapState: (state, ownProps) => {
    var productOrderId = 1;
    var productInstances = _.filter(state.resources.productInstances.byId, {productOrderId});
    var productsById = state.resources.products.byId;
    var productPricingRulesById = state.resources.productPricingRules.byId;
    var productPropertiesById = state.resources.productProperties.byId;
    var productOptionClassifiersById = state.resources.productOptionClassifiers.byId;
    var productOptionsById = state.resources.productOptions.byId;
    var productRulesById = state.resources.productRules.byId;
    return {
      // add dependies for price helper functions
      products: state.resources.products.byId,
      productInstances,
      productsById,
      productPricingRulesById,
      productPropertiesById,
      productOptionClassifiersById,
      productOptionsById,
      productRulesById
    };
  },
  mapDispatch: {
    ..._.pick(resourceActions.products, ['updateProducts', 'trackProducts']),
    ..._.pick(resourceActions.products, ['trackProducts'])
  }
})(AdminProductsPage);
