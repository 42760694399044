import useEffectUnscoped from '~/helpers/useEffectUnscoped';

var useKeyUp = (handleKeyUp, options) => {
  useEffectUnscoped((depsRef) => {
    var handleKeyUp = (event) => depsRef.current.handleKeyUp(event);

    document.addEventListener('keyup', handleKeyUp, options);

    return () => document.removeEventListener('keyup', handleKeyUp);
  }, [], {handleKeyUp});
};

export default useKeyUp;
