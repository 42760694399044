import { getLineAlpha } from '~/helpers/trig/trig-helpers';

function roundPoint(point, precision) {
  return {x: Math.round(point.x / precision) * precision, y: Math.round(point.y / precision) * precision};
}

function round(value, precision) {
  return Math.round(value / precision) * precision;
}

//converts an xz line to an xy line
function toXYLine({from, to}) {
  return {from: {x: from.x, y: from.z}, to: {x: to.x, y: to.z}};
}

//converts an xy line to an xz line
function toXZLine({from, to}) {
  return {from: {x: from.x, z: from.y}, to: {x: to.x, z: to.y}};
}

//converts an xz point to an xy point
function toXYPoint({x, z}) {
  return {x: x, y: z};
}

//converts an xy point to an xz point
function toXZPoint({x, y}) {
  return {x: x, z: y};
}

function getSizeForPoints(points) {
  var xs = _.map(points, 'x');
  var ys = _.map(points, 'y');
  var size = {width: (_.max(xs) - _.min(xs)) || 0, height: (_.max(ys) - _.min(ys)) || 0};

  return size;
}

function getOffsetForPoints(points) {
  var xs = _.map(points, 'x'), ys = _.map(points, 'y');
  var minX = _.min(xs), minY = _.min(ys);
  var maxX = _.max(xs), maxY = _.max(ys);
  var size = {width: maxX - minX, height: maxY - minY};

  return {x: -(-minX - size.width * 0.5) || 0, y: -(-minY - size.height * 0.5) || 0};
}

var ArchElevation = {
  getVisibleArchWalls({archElevation, archWalls}) {
    var elevationAlpha = getLineAlpha(toXYLine(archElevation));

    archWalls = _.filter(archWalls, archWall => {
      var wallAlpha = getLineAlpha(toXYLine(archWall));

      return wallAlpha === elevationAlpha;
    });

    return archWalls;
  },

  getWallPointsForView({view}) {
    return [];
  }
};

var ArchWall = {
  getAdjacentArchWalls({archWall, archWalls}) {
    return _.mapValues({from: {}, to: {}}, (__, rangeKey) => _.find(archWalls, adjacentArchWall => {
      var oppositeRangeKey = rangeKey === 'from' ? 'to' : 'from';

      return archWall.to && adjacentArchWall.to && _.isEqual(archWall[rangeKey], adjacentArchWall[oppositeRangeKey]);
    }));
  }
};

export {
  roundPoint,
  round,
  toXYLine, toXZLine, toXYPoint, toXZPoint,
  getSizeForPoints, getOffsetForPoints,
  ArchElevation, ArchWall
};
