import { PureComponent } from 'react';
import {Image, TouchableOpacity, View} from 'react-native';
import { connect } from '@symbolic/redux';
import { setActiveView } from '~/redux/active-view';
import { withRouter } from 'react-router-native';

import K from '~/k';

class Medium extends PureComponent {
  handleMediumPress = () => {
    this.props.setActiveView({data: {activeMediumId: this.props.medium.id}});
  };

  render () {
    return (
      <TouchableOpacity
        style={{borderRadius: K.borderRadius, padding: K.margin, backgroundColor: K.colors.gray, ...this.props.style}}
        onPress={this.handleMediumPress}
      >
        <Image
          source={this.props.medium.presignedUrl}
          style={{resizeMode: 'contain', alignSelf: 'center', borderRadius: K.borderRadius / 2, overflow: 'hidden', width: '100%', height: '100%'}}
        />
      </TouchableOpacity>
    );
  }
}

export default withRouter(connect({
  mapState: (state) => {
    return {
      activeMediumId: state.activeView.data.activeMediumId,
    };
  },
  mapDispatch: {
    setActiveView
  }
})(Medium));
