import { createRef, Component } from 'react';
import { View, TouchableOpacity, Image } from 'react-native';
import { Text } from '@symbolic/rn-lib';

import K from '~/k';

import uploadIconBlack from '~/assets/upload-icon-black.png';

class FileInput extends Component {
  state = {isDragging: false, isDropped: false};

  fileInputRef = createRef();

  drop = createRef();

  componentDidMount() {
    this.drop.current.addEventListener('dragover', this.handleDragOver);
    this.drop.current.addEventListener('drop', this.handleDrop);
  }

  componentWillUnmount() {
    this.drop.current.removeEventListener('dragover', this.handleDragOver);
    this.drop.current.removeEventListener('drop', this.handleDrop);
  }

  handleDragOver = (event) => {
    event.preventDefault();
    event.stopPropagation();

    if (!this.state.isDragging) {
      this.setState({isDragging: true});
    }
  };

  handleDrop = (event) => {
    event.preventDefault();
    event.stopPropagation();

    this.setState({isDragging: false, isDropped: true});

    const {files} = event.dataTransfer;

    if (files.length > 0 ) {
      this.props.handleFilesPicked(files);
    }
  };

  getLabel = () => {
    const { uploading, uploadingWasSuccessful, uploadingHasFailed} = this.props;

    const messages = {
      idle: 'Drag and Drop or click to browse',
      dragging: 'Drop here to upload.',
      uploading: 'Uploading ...',
      uploadingWasSuccessful: 'File uploaded successfuly!',
      uploadingHasFailed: 'Couldn\'t upload file. Check your internet connection or try again later.'
    };

    const settings = {
      dragging: this.state.isDragging,
      uploading,
      uploadingWasSuccessful,
      uploadingHasFailed
    };

    const key = _.findKey(settings, item => item);

    return key ? messages[key] : messages.idle;
  };

  render() {
    return (
      <View style={{width: '100%'}} ref={this.drop}>
        <TouchableOpacity onPress={() => this.fileInputRef.current.click()} style={{flexDirection: 'row', paddingVertical: K.spacing, alignItems: 'center', border: '1px dashed rgba(0, 0, 0, 0.5)', borderRadius: K.borderRadius, padding: K.spacing, ...this.props.containerStyle}}>
          {this.props.children}
          <Text style={{...K.fonts.label, marginLeft: K.spacing, textAlign: 'center'}}>{this.getLabel()}</Text>
          <input accept='image/jpeg, image/png' type='file' onInput={(e) => this.props.handleFilesPicked(e.target.files)} style={{display: 'none'}} ref={this.fileInputRef}/>
          {(!this.props.uploading && !this.props.uploadingWasSuccessful && !this.props.uploadingHasFailed) && <Image source={uploadIconBlack} style={{...K.defaultIconSize, marginLeft: K.spacing}}/>}
        </TouchableOpacity>
      </View>
    );
  }
}

export default FileInput;
