import React from 'react';
import _ from '@symbolic/lodash';
import K from '~/k';

import ProductOptionPopup from '~/components/popups/product-option-popup';
import DimensionInput from '~/components/dimension-input';

import settingsIcon from '~/assets/settings-icon.png';
import editIcon from '~/assets/edit-icon.png';
import checkIcon from '~/assets/check-icon-black.png';
import xIcon from '~/assets/x-icon.png';
import thumbnailPlaceholder from '~/assets/thumbnail-placeholder.png';

import { View, Image, TouchableOpacity } from 'react-native';
import { Label, Text, TextInput, Tooltip } from '@symbolic/rn-lib';
import { connect } from '@symbolic/redux';
import { resourceActions } from '~/redux';
import { publishLevelOptions } from '../helpers/publish-level-options';

function Thumbnail({nativeID, thumbnailUrl, tooltipText, onPress, isButton, isActive, isInvalid, style, imageSize, imageStyle}) {
  style = {height: 40, width: 40, backgroundColor: 'white', borderRadius: 20, justifyContent: 'center', alignItems: 'center', ...style};

  var thumbnail = (<>
    <Image source={thumbnailUrl ? (typeof(thumbnailUrl) === 'string' ? {uri: thumbnailUrl} : thumbnailUrl) : thumbnailPlaceholder} style={{width: '100%', height: '100%', borderRadius: imageSize ? 0 : 20, ...imageSize, ...imageStyle}}/>
    <View dataSet={isInvalid || !(onPress || isButton) ? {} : {borderBlackOnHover: 1}} style={{cursor: !(onPress || isButton) ? 'default' : 'pointer', borderColor: isInvalid ? 'red' : (isActive ? 'black' : 'rgba(0, 0, 0, 0.1)'), borderWidth: 1, width: '100%', height: '100%', borderRadius: '50%', position: 'absolute', top: 0, left: 0}}/>
  </>);

  if (onPress) {
    thumbnail = (<TouchableOpacity nativeID={nativeID} {...{style, onPress}} >{thumbnail}</TouchableOpacity>);
  }
  else {
    thumbnail = (<View nativeID={nativeID} {...{style}}>{thumbnail}</View>);
  }

  if (tooltipText) {
    thumbnail = <Tooltip text={tooltipText} style={{zIndex: 1}}>{thumbnail}</Tooltip>;
  }

  return thumbnail;
}

class ProductOption extends React.PureComponent {
  state = {
    productOptionPopupIsVisible: false,
    mediaPopupIsVisible: false
  };

  handleTitleChange = ({value}) => this.props.updateProductOption({id: this.props.productOption.id, props: {title: value}});

  archive = async () => {
    this.props.history.push('/admin/product-properties');
    this.props.updateProductOption({id: this.props.productOption.id, props: {archived: (this.props.productOption.archived + 1) % 2}});
  };

  handleProductOptionPress = async () => {
    var {mode, productProperty, productOption, expandedPropertyId} = this.props;

    if (this.props.mode === 'admin') {
      this.props.toggleProductOptionDisabled({productOptionId: productOption.id, productPropertyId: productProperty.id});
    }
    else if (mode === 'order') {
      if (productProperty.type === 'singleSelect') {
        if (this.props.propertyIsExpanded) {
          if (this.props.productProperty.onChange) {
            this.props.productProperty.onChange({value: _.get(productOption, 'value', productOption.id)});
          }
          else {
            if (productProperty.isOrderLevel === 1) {
              // if (await confirm(getNameFor({orgId, textToTransform: 'Are you sure? This will change this setting for the whole order.'}), '')) {
              this.props.handleOrderLevelPropertyChange({propertyId: productProperty.id, optionId: productOption.id});
              // }
            }
            else {
              this.props.setActiveProductOption({id: productOption.id});
            }
          }

          this.props.setExpandedPropertyId(null);
        }
        else if (expandedPropertyId === productProperty.id) { //collapse property
          this.props.setExpandedPropertyId(null);
        }
        else { //expand property
          this.props.setExpandedPropertyId(productProperty.id);
        }
      }
      else {
        this.setState({productOptionPopupIsVisible: true});
      }
    }
  };

  handleValueChange = ({value}) => {
    if (this.props.productProperty.type === 'number') value = parseInt(value) || 0;

    if (this.props.productProperty.onChange) {
      this.props.productProperty.onChange({value});
    }
    else {
      var properties = _.cloneDeep(_.get(this.props.productInstance, 'properties', {}));

      properties = _.set(properties, this.props.productProperty.id, {value});

      this.props.updateProductInstance({id: this.props.productInstance.id, props: {properties}});
    }
  };

  render() {
    var {productOption, isEditable, propertyIsExpanded, isActive, mode, productProperty, inputQuantity, productInstance, index, disabled, arrayInputProps, isInvalid, activeProductInstanceWithData} = this.props;
    var productOptionStyles = mode === 'order' ? {marginLeft: K.margin, marginBottom: K.spacing, display: 'flex', flexDirection: 'row'} : {};
    var labelStyles = mode === 'order' ? {width: 'fit-content'} : {fontSize: K.calcFont(12), width: 100};

    var filteredMedia = productOption && _.filter(this.props.media, medium => {
      if (_.has(medium.associations.productOptions, productOption.id)) {
        return medium;
      }
    });

    var borderColor = this.state.productOptionPopupIsVisible ? K.colors.doubleGray : '#FFF';

    if (isEditable) { //admin property page
      return (
        <View dataSet={{conditionalOpacityParent: 1, hoverOutlineDoubleGray: 1}} style={{...productOptionStyles, width: 300, borderRadius: K.borderRadius, borderWidth: '1px', borderStyle: 'solid', borderColor, paddingVertical: K.margin}}>
          <View style={{flexDirection: 'row', alignItems: 'center'}}>
            <TextInput
              value={index + 1}
              onChange={({value})=> this.props.updateProductOptionRank({value, productOption})}
              style={{top: -1, position: 'relative', backgroundColor: 'transparent', paddingLeft: 0, width: 36, textAlign: 'center', paddingRight: K.spacing, opacity: 0.5 }}
              selectTextOnFocus
            />
            <TextInput
              style={{paddingHorizontal: 0, backgroundColor: 'transparent', minHeight: 0, borderRadius: 0, ...labelStyles}}
              value={productOption.title}
              onChange={({value}) => this.handleTitleChange({value})}
              blurOnEnter
              blurOnSubmit
              multiline
              standardAutoheightStyles
              returnKeyType='done'
              selectTextOnFocus={_.includes(productOption.title, 'Untitled Option')}
            />
            {!productProperty.noOptionThumbnails && (
              <Thumbnail thumbnailUrl={productOption.thumbnailUrl}/>
            )}
            <Text style={{flex: 1, opacity: 0.5, fontSize: K.calc(12), marginLeft: K.spacing}}>
              {_.get(_.find(publishLevelOptions, {value: productOption.publishLevel}), 'title')}
            </Text>
            <TouchableOpacity style={{marginHorizontal: K.spacing}} dataSet={{conditionalOpacity: 1}} onPress={() => this.setState({productOptionPopupIsVisible: true})}>
              <Image source={settingsIcon} style={{...K.defaultIconSize, opacity: 0.5}} />
            </TouchableOpacity>
          </View>
          {this.state.productOptionPopupIsVisible && (
            <ProductOptionPopup
              onClose={() => this.setState({productOptionPopupIsVisible: false})}
              {...{productOption, mode, activeProductInstanceWithData}}
            />
          )}
        </View>
      );
    }
    else if (mode === 'order') { //main options popup
      if (propertyIsExpanded) {
        var showThumbnail = !productProperty.noOptionThumbnails;
        var visibilityText = '';

        if (productInstance && productOption.publishLevel !== 'all') {
          visibilityText = ` (${_.get(_.find(publishLevelOptions, {value: productOption.publishLevel}), 'title')})`;
        }

        return (
          <View style={{...productOptionStyles, alignItems: 'flex-end', position: 'relative'}} dataSet={{conditionalOpacityParent: 1}}>
            <TouchableOpacity
              nativeID={`OrderPageProductOption-${productOption.id}`}
              style={{flexDirection: 'row', alignItems: 'center', flex: 1}}
              onPress={this.handleProductOptionPress}
            >
              <View style={{flexDirection: 'column', flex: 1, alignItems: 'flex-end', marginRight: K.spacing}}>
                <Text style={{textAlign: 'right', ...(isActive && {fontWeight: 'bold'})}}>
                  {productOption.title + visibilityText}
                </Text>
                {filteredMedia.length > 0 && (
                  <TouchableOpacity
                    dataSet={{'conditional-opacity': 1}}
                    style={{position: 'absolute', top: '100%'}}
                    onPress={() => this.props.showMediaPopup({resourceId: productOption.id, resourceKey: 'productOptions', relevantMedia: filteredMedia})}
                  >
                    <Text style={{...K.fonts.standard, opacity: 0.5}}>see images</Text>
                  </TouchableOpacity>
                )}
              </View>
              {showThumbnail && (
                <Thumbnail
                  nativeID={`OrderPageProductOption-${productOption.id}`}
                  thumbnailUrl={productOption.thumbnailUrl} {...{isActive}}
                  isButton={true}
                />
              )}
            </TouchableOpacity>
          </View>
        );
      }
      else { //property (not expanded) on order page
        var ViewComponent = (productProperty.isArray || productProperty.type === 'singleSelect') ? TouchableOpacity : View;
        var viewProps = (productProperty.isArray || productProperty.type === 'singleSelect') ? {onPress: this.handleProductOptionPress} : {};

        if (_.includes(['checkbox', 'text', 'number', 'dimension'], productProperty.type)) {
          var value = _.get(this.props.selectedEntityPropertiesData, `propertyValues.${productProperty.id}`, _.get(productInstance, `properties.${productProperty.id}.value`, productProperty.type === 'text' ? '' : 0));
        }

        return (
          <View>
            <ViewComponent nativeID={`OrderPageProductOption-${productOption ? productOption.id : ''}`} {...viewProps} style={{...productOptionStyles, cursor: 'default', ...(this.props.isSubdued ? {opacity: 0.3} : {}), flexDirection: 'row', alignItems: 'center'}}>
              {!productProperty.isArray && (<>
                <View style={{alignItems: 'flex-end', marginRight: K.spacing}}>
                  <Text style={{fontWeight: 'bold', opacity: 1, ...labelStyles}}>{productProperty.title}</Text>
                  {productProperty.type === 'singleSelect' && (
                    <View style={{flexDirection: 'row'}} dataSet={{'conditional-opacity-parent': 1}}>
                      {productProperty.isOrderLevel === 0 && (
                        <TouchableOpacity
                          dataSet={{'conditional-opacity': 1}}
                          style={{justifyContent: 'flex-end', alignSelf: 'center', marginRight: K.spacing * 2, width: 'fit-content'}}
                          onPress={() => this.props.handleApplyAll({productProperty, productOption, activeProductInstanceWithData})}
                        >
                          <Text style={{...K.fonts.standard, opacity: 0.5}}>apply to all</Text>
                        </TouchableOpacity>
                      )}
                      <Text style={{textTransform: 'none', ...(isActive ? {opacity: 1} : {}), ...labelStyles}}>
                        {productOption.title}
                      </Text>
                    </View>
                  )}
                  {productProperty.type === 'dimensions' && (
                    <DimensionInput
                      {..._.pick(this.props.productProperty.data, ['dimensionInterval', 'minDimension', 'maxDimension'])}
                      placeholder={'Enter dimension'}
                      value={_.get(productInstance, `properties.${productProperty.id}.value`, 0)}
                      onChange={this.handleValueChange}
                    >
                    </DimensionInput>
                  )}
                  {productProperty.type === 'number' && (
                    <TextInput
                      dataSet={{backgroundOnlyWhenFocused: 1}}
                      placeholder={productProperty.placeHolder}
                      value={value}
                      onChange={this.handleValueChange}
                      style={{paddingHorizontal: 5, height: 20, width: 100, textAlign: 'right', marginTop: 2, position: 'relative', left: 5}}
                    />
                  )}
                  {productProperty.type === 'text' && (
                    <TextInput
                      placeholder={productProperty.placeHolder}
                      value={value}
                      onChange={this.handleValueChange}
                      style={{paddingHorizontal: 5, height: 20, width: productProperty.multiline ? 200 : 100, textAlign: 'right', marginTop: 2, position: 'relative', right: -5}}
                      multiline={!!productProperty.multiline}
                    />
                  )}
                </View>
                {productProperty.type === 'checkbox' && (
                  <Thumbnail
                    thumbnailUrl={value ? checkIcon : xIcon}
                    imageStyle={{opacity: value ? 1 : 0.3}}
                    imageSize={{width: 20, height: 20}}
                    onPress={() => this.handleValueChange({value: !value})}
                  />
                )}
                {productProperty.type !== 'checkbox' && (
                  <Thumbnail isButton={productProperty.isArray || productProperty.type === 'singleSelect'} thumbnailUrl={productProperty.type === 'singleSelect' ? (productProperty.icon || productOption.thumbnailUrl) : (productProperty.thumbnailUrl || productProperty.icon)} {...{isInvalid}}/>
                )}
              </>)}
              {!!productProperty.isArray && (<>
                <Text style={{fontWeight: 'bold', opacity: 1, marginRight: K.spacing, ...labelStyles}}>{productProperty.title}</Text>
                <Thumbnail {...{isInvalid}} thumbnailUrl={editIcon} imageSize={{width: K.calc(20), height: K.calc(20)}}/>
              </>)}
            </ViewComponent>
            {this.state.productOptionPopupIsVisible && (
              <ProductOptionPopup
                onClose={() => this.setState({productOptionPopupIsVisible: false})}
                {...{productProperty, productOption, mode, inputQuantity, arrayInputProps, productInstance, activeProductInstanceWithData}}
              />
            )}
          </View>
        );
      }
    }
    else { // admin-product-page - click to disable
      return (
        <TouchableOpacity
          style={{...productOptionStyles, flexDirection: 'row', alignItems: 'center', marginBottom: K.spacing, ...(disabled && {opacity: 0.3})}}
          onPress={this.handleProductOptionPress}
        >
          <Label style={{textTransform: 'none', ...(isActive ? {opacity: 1} : ''), ...labelStyles}}>
            {productOption.title}
          </Label>
          <Thumbnail thumbnailUrl={productOption.thumbnailUrl}/>
        </TouchableOpacity>
      );
    }
  }
}

export default connect({
  mapState: (state) => {
    return {
      media: state.resources.media.byId,
      productsById: state.resources.products.byId,
      productPropertiesById: state.resources.productProperties.byId,
      productOptionsById: state.resources.productOptions.byId,
      productPricingRulesById: state.resources.productPricingRules.byId,
      productOptionClassifiersById: state.resources.productOptionClassifiers.byId,
      productRulesById: state.resources.productRules.byId,
      productInstancesById: state.resources.productInstances.byId
    };
  },
  mapDispatch: {
    ..._.pick(resourceActions.productOptions, ['updateProductOption']),
    ..._.pick(resourceActions.productInstances, ['updateProductInstance'])
  }
})(ProductOption);
