import { PureComponent } from 'react';
import { View } from 'react-native';
import { connect } from '@symbolic/redux';

import K from '~/k';

class Page extends PureComponent {
  render() {
    var {height, width, children, isPrinting, style, pageSize} = this.props;
    var padding = this.props.session.activeOrg.id === 1798 ? K.spacing * 2 : K.spacing * 3;

    return (
      <View style={{height, width, ...pageSize, position: 'relative', backgroundColor: 'white', paddingHorizontal: padding, paddingVertical: padding, ...(isPrinting ? {alignSelf: 'center'} : {margin: 'auto', marginBottom: K.spacing, ...K.shadow}), ...style}}>
        {children}
      </View>
    );
  }
}

Page = connect({})(Page); //to get session

export default Page;
