import { PureComponent } from 'react';
import { connect } from '@symbolic/redux';
import { TextInput, Button } from '@symbolic/rn-lib';
import { View } from 'react-native';
import { resourceActions } from '~/redux';

import HudElement from '~/components/hud-element';
import deleteIcon from '~/assets/trash-icon.png';
import arrowIcon from '~/assets/arrow-icon.png';
import K from '~/k';
import _ from '@symbolic/lodash';

var updateRank = ({value, instance, instances, updateInstances}) => {
  var updates = [];
  var newRank = _.parseInt(value) - 1;
  var oldRank = _.findIndex(instances, {id: instance.id});
  var oldIds = _.map(instances, 'id');

  if (newRank > oldIds.length - 1) newRank = oldIds.length - 1;
  if (newRank < 0) newRank = 0;

  if (oldRank !== newRank && !isNaN(newRank)) {
    var newIds = _.arrayMove([...oldIds], oldRank, newRank);

    _.forEach(newIds, (id, rank) => updates.push({where: {id}, props: {rank: rank + 1}}));

    updateInstances({updates});
  }
};

class TreeHudElement extends PureComponent {
  updateArchCustomViewRank = ({value, archCustomView}) => {
    updateRank({
      value,
      instance: archCustomView,
      instances: _.sortBy(this.props.archCustomViews, 'rank'),
      updateInstances: this.props.updateArchCustomViews
    });
  };

  render() {
    return (
      <HudElement x='left' y='top' style={{marginTop: 100}}>
        <View>
          {_.map(_.sortBy(this.props.archCustomViews, 'rank'), (archCustomView, index) => {
            var computedIndex = this.props.session.activeOrg.id === 1798 ? index * 2 + 1 : index;

            return (
              <View
                key={archCustomView.id}
                style={{flexDirection: 'row'}}
                dataSet={{conditionalOpacityParent: 1}}
              >
                <TextInput
                  value={`${index + 1}`}
                  style={{backgroundColor: 'transparent', width: 30, opacity: 0.5, height: 25}}
                  onChange={({value}) => this.updateArchCustomViewRank({value, archCustomView})}
                  selectTextOnFocus
                />
                <TextInput
                  value={archCustomView.title || 'Untitled Viewport'}
                  style={{backgroundColor: 'transparent', paddingHorizontal: 0, height: 25, marginRight: K.spacing, fontWeight: computedIndex === this.props.activeViewIndex ? 'bold' : 'normal'}}
                  autoWidth
                  onChange={({value}) => this.props.updateArchCustomView({id: archCustomView.id, props: {title: value}})}
                />
                <Button
                  dataSet={{conditionalOpacity: 1}}
                  icon={deleteIcon}
                  style={{backgroundColor: 'transparent', height: 25, width: 30}}
                  onPress={() => confirm('Are you sure you want to delete this viewport?') && this.props.destroyArchCustomView({id: archCustomView.id})}
                />
                <Button
                  dataSet={{conditionalOpacity: 1}}
                  icon={arrowIcon}
                  style={{backgroundColor: 'transparent', height: 25, width: 30}}
                  onPress={() => this.props.setActiveViewIndex(computedIndex)}
                />
              </View>
            );
          })}
        </View>
      </HudElement>
    );
  }
}

export default connect({
  mapState: (state, ownProps) => {
    var {productOrder} = ownProps;

    if (productOrder && state.resources.archCustomViews.byFieldKeyIndex) {
      var archCustomViews = state.resources.archCustomViews.byFieldKeyIndex.productOrderId[productOrder.id];
    }

    return {
      archCustomViews
    };
  },
  mapDispatch: {
    ..._.pick(resourceActions.archCustomViews, ['updateArchCustomView', 'updateArchCustomViews', 'destroyArchCustomView']),
  }
})(TreeHudElement);
