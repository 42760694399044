import K from '~/k';

export default function getTextHeight({
  text,
  width,
  fontSize = 12.6,
  fontFamily = K.fontFamily,
  letterSpacing = 0.4
}) {
  var newEl = document.createElement('div');

  newEl.style.position = 'fixed';
  newEl.innerHTML = text;
  newEl.style['white-space'] = 'pre-line';
  newEl.style['font-size'] = fontSize + 'px';
  newEl.style['letter-spacing'] = letterSpacing + 'px';
  newEl.style['font-family'] = fontFamily;

  if (width) newEl.style.width = width + 'px';

  document.body.appendChild(newEl);

  var height = newEl.clientHeight;

  newEl.remove();

  return height;
}
