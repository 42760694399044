import React from 'react';

import { useContext } from 'react';
import { resourceActions } from '~/redux';
import { connect } from '@symbolic/redux';

import { toCanvas } from '~/helpers/canvas/canvas-helpers';
import { getCalicoMuralTextureImageUrl, getCalicoMuralPanelPagesData } from '~/helpers/custom/calico/custom-calico-helpers';

import _ from 'lodash';
import CanvasText from '~/components/canvas/text/canvas-text';
import CanvasDataContext from '~/contexts/canvas-data-context';
import CalicoCanvasPanelLayoutRenderer from '~/components/custom/calico/calico-canvas-panel-layout-renderer';
import useImage from 'use-image';

function CalicoCanvasPanelLayoutView(props) {
  var {calicoMuralTextures, calicoMuralPanels, calicoWallLabels} = props;
  var canvasData = useContext(CanvasDataContext);
  var {scale} = canvasData;

  _.forEach(calicoMuralPanels, calicoMuralPanel => {
    calicoMuralPanel.data.position.x = Math.round(calicoMuralPanel.data.position.x * 1000000) / 1000000;
    calicoMuralPanel.data.position.y = Math.round(calicoMuralPanel.data.position.y * 1000000) / 1000000;
  });

  var calicoMuralPanelPagesData = getCalicoMuralPanelPagesData({calicoMuralPanels, calicoWallLabels, scale});
  var calicoMuralPanelGroups = _.flatMap(calicoMuralPanelPagesData, 'calicoMuralPanelGroups');

  const [muralTextureImage] = useImage(getCalicoMuralTextureImageUrl({calicoMuralTexture: calicoMuralTextures[0], productOptionsById: props.productOptionsById}));

  return (<>
    <CalicoCanvasPanelLayoutRenderer
      {...{calicoMuralPanelGroups, calicoMuralTextures, muralTextureImage, calicoMuralPanels, canvasData, scale}}
      {..._.pick(props, ['updateEntity'])}
    />
  </>);
}

export default connect({mapDispatch: {
  ..._.pick(resourceActions.entities, ['updateEntities', 'updateEntity'])
}})(CalicoCanvasPanelLayoutView);
