import React, { PureComponent } from 'react';
import { View, ScrollView } from 'react-native';
import { Button, DocumentTitle, PickerInput, CheckboxInput } from '@symbolic/rn-lib';
import { api, event as LibEvent } from '@symbolic/lib';
import { connect } from '@symbolic/redux';
import { resourceActions } from '~/redux';
import { setActiveView } from '~/redux/active-view';
import { withRouter } from 'react-router-native';
import { drawingSetModes } from '~/helpers/ui-modes';
import { getProductInstanceWithData, filterInstancePropertiesFor, getIsSpatial } from '~/helpers/product-order-helper';
import { getShouldRenderElementForOrg } from '~/helpers/get-should-render-helpers';
import getProductSpecString from '~/helpers/get-product-spec-string';
import { getCalicoMuralPanelPagesData } from '~/helpers/custom/calico/custom-calico-helpers';

import K from '~/k';
import moment from 'moment';
import downloadIconBlack from '~/assets/download-icon-black.png';
import style from '~/helpers/document-style-tag';
import printIcon from '~/assets/print-icon.png';
import _ from 'lodash';
import stringifyCssRule from 'stringify-css-rule';
import ProductGraphic from '~/components/product-graphic';

import DrawingsStandalonePage from '~/pages/drawings/page-types/standalone/drawings-standalone-page';
import DrawingsSpatialCustomViewPage from '~/pages/drawings/page-types/spatial/drawings-spatial-custom-view-page';
import DrawingsCustomCalicoMuralAreaPage from '~/pages/drawings/page-types/custom/calico/1-drawings-custom-calico-mural-area-page';
import DrawingsCustomCalicoMuralTexturePage from '~/pages/drawings/page-types/custom/calico/2-drawings-custom-calico-mural-texture-page';
import DrawingsCustomCalicoMuralPanelPage from '~/pages/drawings/page-types/custom/calico/3-drawings-custom-calico-mural-panel-page.js';
import DrawingsCustomCalicoMuralSeamPage from '~/pages/drawings/page-types/custom/calico/4-drawings-custom-calico-mural-seam-page.js';

class DrawingsPage extends PureComponent {
  state = {isPrinting: false, isPdfing: false, dimensionsAreVisible: true, measurementSystem: 'metric'};

  async componentDidMount() {
    global.isRenderingSvg = true;

    if (_.size(this.props.productOrder)) {
      var productInstances = await api.get('productInstances', {where: {productOrderId: this.props.productOrder.id}});

      this.props.trackProductInstances({productInstances});
    }

    window.addEventListener('keydown', this.handleKeyboardEvents);

    var productOrder = await api.get('productOrder', {where: {id: this.props.productOrder.id}});

    var isSpatial = getIsSpatial({productOrder, session: this.props.session});

    await this.props.trackProductOrders({productOrders: [productOrder]});

    if (isSpatial) {
      var archElevations = await api.get('archElevations', {where: {productOrderId: this.props.productOrder.id}});
      var archWalls = await api.get('archWalls', {where: {productOrderId: this.props.productOrder.id}});
      var archCustomViews = await api.get('archCustomViews', {where: {productOrderId: productOrder.id}});
      var entities = await api.get('entities', {where: {productOrderId: productOrder.id}});
      var productCanvasTexts = await api.get('productCanvasTexts', {where: {productOrderId: productOrder.id}});
      var productCanvasLines = await api.get('productCanvasLines', {where: {productOrderId: productOrder.id}});

      this.props.trackArchElevations({archElevations});
      this.props.trackArchWalls({archWalls});
      this.props.trackEntities({entities});
      this.props.trackArchCustomViews({archCustomViews});
      this.props.trackProductCanvasTexts({productCanvasTexts});
      this.props.trackProductCanvasLines({productCanvasLines});
    }

    setTimeout(() => {
      this.productGraphicNodesByProductInstanceId = {};

      _.forEach(productInstances, productInstance => {
        var product = this.props.productsById[productInstance.productId];

        productInstance = getProductInstanceWithData({productInstance}, this.props);

        var nodes = [ProductGraphic.evalScript({product, productInstance, scale: this.props.drawingSetMode === drawingSetModes.CLIENT ? 3.7795275591 : 2.5})];

        this.productGraphicNodesByProductInstanceId[productInstance.id] = nodes;
      });

      this.setState({resourceStatus: 'loaded'});
    });
  }

  componentWillUnmount() {
    global.isRenderingSvg = false;

    window.removeEventListener('keydown', this.handleKeyboardEvents);
  }

  handleKeyboardEvents = (event) => {
    if (LibEvent.keyPressed(event, 'ctrlcmd') && event.key === 'p') {
      event.preventDefault();

      this.print();
    }
  };

  get pageSizeKey() {
    var {
      drawingSetMode = drawingSetModes.FACTORY,
      drawingsPageSize = 'ledger',
      productOrder,
      session: {activeOrg}
    } = this.props;

    if ((drawingSetMode === drawingSetModes.CLIENT && productOrder.type === 'standalone') || activeOrg.id === 1798) {
      drawingsPageSize = 'letter';
    }

    return drawingsPageSize;
  }

  get pageOrientation() {
    var {drawingSetMode = drawingSetModes.FACTORY, productOrder} = this.props;

    return drawingSetMode === drawingSetModes.CLIENT && productOrder.type === 'standalone' ? 'portrait' : 'landscape';
  }

  print = () => {
    this.props.setActiveView({data: {isPrinting: true}});

    document.querySelector('html').style.height = 'auto';
    document.querySelector('body').style.height = 'auto';

    var printViewCss = `@media print{ @page{ size: ${this.pageSizeKey} ${this.pageOrientation}; margin: 0px;}}`;
    style.textContent = style.textContent + printViewCss;

    setTimeout(() => {
      window.print();

      style.textContent = _.replace(style.textContent, printViewCss, '');

      document.querySelector('html').style.height = '100%';
      document.querySelector('body').style.height = '100%';

      this.props.setActiveView({data: {isPrinting: false}});
    });
  };

  handleActveViewDataChange = ({key, value}) => this.props.setActiveView({data: {[key]: value}});

  handleDimensionsAreVisibleChange = ({value}) => this.setState({dimensionsAreVisible: value === 1 ? true : false});

  // downloadPdf = async () => {
  //   // var {drawingSetMode = drawingSetModes.CLIENT, productOrder} = this.props;
  //   // var element = document.querySelector('div[data-drawings="1"]');
  //   // var filename = `#${productOrder.orgSpecificId} ${productOrder.title} ${moment().format('YMD')} - Drawings`;
  //   // var pageOrientation = drawingSetMode === drawingSetModes.CLIENT && productOrder.type === 'standalone' ? 'portrait' : 'landscape';
  //   // var pageSizeLabel = drawingSetMode === drawingSetModes.CLIENT && productOrder.type === 'standalone' ? 'letter' : 'ledger';

  //   // this.props.setActiveView({data: {isPrinting: true}});

  //   // setTimeout(async () => {
  //   //   await html2pdf(element, {filename, jsPDF: {unit: 'in', format: pageSizeLabel, orientation: pageOrientation}});

  //   //   this.props.setActiveView({data: {isPrinting: false}});
  //   // });
  // };

  generatePdf = () => {
    var {productOrder} = this.props;

    this.props.setActiveView({data: {isPrinting: true, isPdfing: true}});

    var fontFamily = this.props.session.activeOrg.id === 1798 ? 'HelveticaNeue' : 'Arial';

    setTimeout(async () => {
      var domain = process.env.NODE_ENV === 'production' ? 'https://api.symbolicframeworks.com' : 'http://localhost:3301';
      var url = `${domain}/download-pdf`;

      var styleString = _.join(_.map(document.styleSheets, styleSheet => {
        return styleSheet.cssRules
          ? Array.from(styleSheet.cssRules)
            .map(rule => stringifyCssRule(rule))
            .join('\n')
          : '';
      }), '\n');

      var fontStyle;

      if (this.props.session.activeOrg.id === 1798) {
        fontStyle = `@font-face {
          font-family: 'DIN';
          src: url('https://symbolic-public.s3.amazonaws.com/fonts/DIN/DIN-Medium.ttf') format('truetype'),
          font-weight: 400;
          font-style: normal;
        };`;
      }
      else {
        fontStyle = '@font-face { font-family: \'' + fontFamily + '\'; src: url(\'https://symbolic-public.s3.amazonaws.com/fonts/' + fontFamily + '.ttf\')}'
      }

      var html = '<html><head><style>' + fontStyle + '</style></head><body>' + document.querySelector('div[data-drawings="1"]').outerHTML + '<style>' + styleString + '</style></body></html>';

      var token = await sessionStore.getToken();

      var landscape = this.pageOrientation === 'landscape';
      var format = this.pageSizeKey === 'letter' ? 'Letter' : 'Tabloid';

      var options = JSON.stringify({landscape, format});

      var filename = `#${productOrder.orgSpecificId} - ${productOrder.title} - ${moment().format('YYMMDD')} - Drawings`;

      // HINT debugging tool - please keep
      // window.open('').document.write(html);
      setTimeout(() => {
        document.querySelector('form[name="generatePdfForm"]').action = url;
        document.querySelector('input[name="html"]').value = html;
        document.querySelector('input[name="token"]').value = token;
        document.querySelector('input[name="options"]').value = options;
        document.querySelector('input[name="filename"]').value = filename;
      });

      setTimeout(() => document.querySelector('form[name="generatePdfForm"]').submit());

      this.props.setActiveView({data: {isPrinting: false, isPdfing: false}});
    });
  };

  handleUpdateMeasurementSystem = ({value}) => {
    this.setState({measurementSystem: value});
  }

  render() {
    var {
      isPrinting, isPdfing, productOrder, drawingSetMode = drawingSetModes.FACTORY, session: {activeOrg},
      productsById, productOptionsById, productPropertiesById, productRulesById, productInstancesById,
      drawingsPageSize = this.pageSizeKey
    } = this.props;

    var pages = [];
    var pageSize = drawingsPageSize === 'ledger' ? {width: 1632, height: 1056} : (this.pageOrientation === 'landscape' ? {width: 1056, height: 816} : {width: 816, height: 1056});

    var isSpatial = getIsSpatial({productOrder, session: this.props.session});

    if (!isSpatial) {
      var productInstances = _.sortBy(_.values(productInstancesById), 'rank');
      var productInstanceCount = productInstances.length;

      var inClientMode = drawingSetMode === drawingSetModes.CLIENT;
      var inFactoryMode = drawingSetMode === drawingSetModes.FACTORY;

      if (productInstanceCount > 0 && this.productGraphicNodesByProductInstanceId) {
        var orderLevelProperties = filterInstancePropertiesFor({
          productInstance: getProductInstanceWithData({productInstance: _.first(productInstances)}, {productPropertiesById, productOptionsById, productsById, productRulesById, productInstancesById})
        }, {isOrderLevel: 1, type: 'singleSelect', drawingVisibility: 'visible'});

        var maxWidth = pageSize.width - K.spacing * 6;
        var netWidth = 0;

        var scale = inClientMode ? 3.7795275591 : 2.5;

        var page = {type: 'standalone', productInstancesData: []};
        var indexInProductOrder = 0;
        var maxProductInstancesPerPage = inFactoryMode ? 4 : 1;

        _.forEach(productInstances, productInstance => {
          if (inFactoryMode) {
            var nodes = this.productGraphicNodesByProductInstanceId[productInstance.id];

            var width = nodes[0] ? _.get(nodes, '0.props.width', 0) * scale + 150 : 360;

            if (netWidth + width > maxWidth || page.productInstancesData.length >= maxProductInstancesPerPage) {
              netWidth = 0;

              pages.push(page);

              page = {type: 'standalone', productInstancesData: []};
            }
          }

          page.productInstancesData.push({productInstance, indexInProductOrder});

          netWidth += width + 24;
          indexInProductOrder += 1;

          if (inClientMode) {
            pages.push(page);

            page = {type: 'standalone', productInstancesData: []};
          }
        });

        if (inFactoryMode && page.productInstancesData.length > 0) {
          pages.push(page);
        }
      }
    }
    else {
      var {entities} = this.props;
      var padding = this.props.session.activeOrg.id === 1798 ? K.spacing * 2 : K.spacing * 3;
      var canvasSize = {
        width: pageSize.width - padding * 2 - 5,
        height: activeOrg.id === 1053 ? pageSize.height - padding * 2 - 150 : pageSize.height - 102 - 31 - padding * 2 - 5 - 66
      };

      var allCalicoMuralAreas = _.filter(entities, ['type', 'calicoMuralArea']);
      var allCalicoMuralPanels = _.filter(entities, ['type', 'calicoMuralPanel']);
      var allCalicoMuralTextures = _.filter(entities, ['type', 'calicoMuralTexture']);
      var allCalicoMuralSeamsInOrder = _.filter(entities, ['type', 'calicoMuralSeam']);
      var allCalicoWallLabels = _.filter(entities, ['type', 'calicoWallLabel']);
      var allCalicoMismatchLines = _.filter(entities, ['type', 'calicoMismatchLine']);

      _.forEach(this.props.archCustomViews, archCustomView => {
        var scale = activeOrg.id === 1053 ? 0.08 : 1;

        var productCanvasTexts = _.filter(this.props.productCanvasTexts, {customViewId: archCustomView.id});
        var productCanvasLines = _.filter(this.props.productCanvasLines, {customViewId: archCustomView.id});

        if (activeOrg.id === 1798) {
          var calicoMuralAreas = _.filter(allCalicoMuralAreas, ['data.archCustomViewId', archCustomView.id]);
          var calicoMuralPanels = _.filter(allCalicoMuralPanels, ['data.archCustomViewId', archCustomView.id]);
          var calicoMuralTextures = _.filter(allCalicoMuralTextures, ['data.archCustomViewId', archCustomView.id]);
          var calicoMuralSeams = _.filter(allCalicoMuralSeamsInOrder, ['data.archCustomViewId', archCustomView.id]);
          var calicoWallLabels = _.filter(allCalicoWallLabels, ['data.archCustomViewId', archCustomView.id]);
          var calicoMismatchLines = _.filter(allCalicoMismatchLines, ['data.archCustomViewId', archCustomView.id]);
          var calicoMuralTexture = calicoMuralTextures[0];

          if (calicoMuralAreas.length) {
            scale = 2; //Math.min(2, Math.min(xScale, yScale));
          }
        }

        var sharedProps = {
          archWalls: this.props.archWalls,
          scale, productCanvasTexts, productCanvasLines,
        };

        if (activeOrg.id === 1798) {
          if (calicoMuralTexture) {
            sharedProps = {
              ...sharedProps,
              calicoMuralTexture, calicoMuralTextures, calicoMuralAreas, calicoWallLabels, calicoMuralPanels, calicoMismatchLines,
              archCustomView, allCalicoWallLabels
            };

            pages.push({...sharedProps, type: 'calicoMuralArea'});
            pages.push({...sharedProps, type: 'calicoMuralTexture'});

            _.forEach(calicoMuralPanels, calicoMuralPanel => {
              calicoMuralPanel.data.position.x = Math.round(calicoMuralPanel.data.position.x * 1000000) / 1000000;
              calicoMuralPanel.data.position.y = Math.round(calicoMuralPanel.data.position.y * 1000000) / 1000000;
            });

            var calicoMuralPanelPagesData = getCalicoMuralPanelPagesData({calicoMuralPanels, calicoWallLabels});

            _.forEach(calicoMuralPanelPagesData, ({calicoMuralPanelGroups}) => {
              pages.push({...sharedProps, type: 'calicoMuralPanel', calicoMuralPanelGroups});
            });

            pages.push({...sharedProps, type: 'calicoMuralSeam', calicoMuralSeams, allCalicoMuralSeamsInOrder});
          }
        }
        else {
          sharedProps.productOrderProductType = _.get(productsById[productOrder.productId], 'title');

          var productInstances = _.filter(this.props.productInstancesById, {archCustomViewId: archCustomView.id});

          var footerData = {notes: archCustomView.drawingNotesData.general};

          if (activeOrg.id === 1053) {
            footerData = _.set(footerData, 'productSpec', (archCustomView.drawingNotesData.productSpec || getProductSpecString({productInstance: productInstances[0], activeOrgId: activeOrg.id, productsById, productPropertiesById, productRulesById, productOptionsById})));
          }

          pages.push({
            ...sharedProps,
            type: 'spatialCustomView',
            productsById: this.props.productsById,
            productInstances,
            title: archCustomView.title,
            footerData
          });
        }
      });
    }

    var pageCount = _.size(pages);

    return (
      <DocumentTitle title={`#${productOrder.orgSpecificId} - ${productOrder.title} - ${moment().format('YYMMDD')} - Drawings`}>
        <View style={{flex: 1, backgroundColor: '#eeeeee', position: 'relative'}}>
          {!isPrinting && (
            <View style={{position: 'absolute', zIndex: 1, top: K.spacing, right: K.spacing, flexDirection: 'row', alignItems: 'center', alignSelf: 'flex-end', justifyContent: 'center'}}>
              {getShouldRenderElementForOrg({activeOrgId: activeOrg.id, visibleOrgIds: [850]}) && (
                <>
                  {inFactoryMode && (
                    <CheckboxInput
                      label={'Show Dimensions'}
                      value={this.state.dimensionsAreVisible === true ? 1 : 0}
                      onChange={({value}) => this.handleDimensionsAreVisibleChange({value})}
                      style={{height: K.buttonHeight, marginRight: K.spacing}}
                    />
                  )}
                  {this.state.dimensionsAreVisible && activeOrg.id === 850 && (
                    <PickerInput
                      buttonStyle={{marginRight: K.spacing}}
                      textStyle={{...K.fonts.label}}
                      options={[
                        {value: 'metric', title: 'UNITS: MILLIMETERS'},
                        {value: 'imperial', title: 'UNITS: INCHES'}
                      ]}
                      onChange={({value}) => this.handleUpdateMeasurementSystem({value})}
                      value={this.state.measurementSystem}
                    />
                  )}
                  <PickerInput
                    buttonStyle={{marginRight: K.spacing}}
                    textStyle={{...K.fonts.label}}
                    options={[
                      {value: drawingSetModes.FACTORY, title: 'MULTIPLE'},
                      {value: drawingSetModes.CLIENT, title: 'INDIVIDUAL'}
                    ]}
                    onChange={({value}) => this.handleActveViewDataChange({key: 'drawingSetMode', value})}
                    value={drawingSetMode}
                  />
                </>
              )}
              {/* {getShouldRenderElementForOrg({activeOrgId: activeOrg.id, visibleOrgIds: [1053]}) && (
                <View style={{marginRight: K.spacing}}>
                  <PickerInput
                    showDownArrow
                    textStyle={{...K.fonts.label}}
                    options={[
                      {value: 'ledger', title: 'Ledger'},
                      {value: 'letter', title: 'Letter'}
                    ]}
                    onChange={({value}) => this.handleActveViewDataChange({key: 'drawingsPageSize', value})}
                    value={drawingsPageSize}
                  />
                </View>
              )} */}
              <Button icon={printIcon} onPress={this.print} style={{...K.defaultIconSize, marginRight: K.spacing, backgroundColor: 'transparent'}}/>
              <Button icon={downloadIconBlack} onPress={this.generatePdf} style={{...K.defaultIconSize, marginRight: K.spacing, backgroundColor: 'transparent'}} />
              {/* hidden form to generate drawings pdf */}
              <form name='generatePdfForm' target='_blank' method='post'>
                <input type='hidden' name='html' />
                <input type='hidden' name='token' />
                <input type='hidden' name='productOrderId' />
                <input type='hidden' name='options' />
                <input type='hidden' name='filename' />
              </form>
            </View>
          )}
          <ScrollView style={{overflowX: 'auto', display: 'block', position: 'relative'}}>
            <View dataSet={{drawings: 1}}
              style={{
                paddingVertical: isPrinting ? 0 : K.spacing * 5, paddingHorizontal: isPrinting ? 0 : K.spacing * 3,
                ...(!isPrinting ? {position: 'absolute', minWidth: '100%'} : {}), //fontFamily: activeOrg.id === 1798 ? 'HelveticaNeue' : 'Arial'
              }}
            >
              {_.map(pages, (page, pageNumber) => {
                var PageComponent = {
                  standalone: DrawingsStandalonePage,
                  spatialCustomView: DrawingsSpatialCustomViewPage,
                  calicoMuralArea: DrawingsCustomCalicoMuralAreaPage,
                  calicoMuralTexture: DrawingsCustomCalicoMuralTexturePage,
                  calicoMuralPanel: DrawingsCustomCalicoMuralPanelPage,
                  calicoMuralSeam: DrawingsCustomCalicoMuralSeamPage
                }[page.type];

                var props = {
                  pageNumber: pageNumber,
                  page, pageSize, pageCount,
                  isPrinting, activeOrg, productOrder
                };

                if (!isSpatial) {
                  props = {
                    ...props,
                    productPropertiesById, productOptionsById, productsById, productRulesById, productInstancesById,
                    inFactoryMode, inClientMode, drawingSetMode, orderLevelProperties, isPdfing, dimensionsAreVisible: this.state.dimensionsAreVisible, measurementSystem: this.state.measurementSystem
                  };
                }
                else {
                  props = {...props, productOptionsById, canvasSize};
                }

                return (
                  <PageComponent key={pageNumber} {...props}/>
                );
              })}
              {!!isPrinting && (
                <style>{`
                  @page {
                    size: ${pageSize.width}px ${pageSize.height}px;
                    margin: 0px;
                  }
                  body, html {
                    margin: 0;
                    padding: 0;
                  }
                `}</style>
              )}
            </View>
          </ScrollView>
        </View>
      </DocumentTitle>
    );
  }
}

export default withRouter(connect({
  mapState: (state, ownProps) => {
    const productOrderOrgSpecificId = ownProps.match.params.productOrderOrgSpecificId;
    var productOrder = _.values(state.resources.productOrders.byFieldKeyIndex.orgSpecificId[productOrderOrgSpecificId])[0];
    const productInstancesById = _.pickBy(_.get(state, 'resources.productInstances.byId'), {productOrderId: parseInt(productOrder.id)});
    const productsById = _.get(state, 'resources.products.byId');
    const productCategoriesById = _.get(state, 'resources.productCategories.byId');
    const productOptionsById = _.get(state, 'resources.productOptions.byId');
    const productPropertiesById = _.get(state, 'resources.productProperties.byId');
    const productRulesById = _.get(state, 'resources.productRules.byId');

    //TODO not sure why these are empty to start
    if (productOrder && state.resources.archElevations.byFieldKeyIndex && state.resources.archWalls.byFieldKeyIndex && state.resources.entities.byFieldKeyIndex) {
      var archElevations = state.resources.archElevations.byFieldKeyIndex.productOrderId[productOrder.id];
      var archWalls = state.resources.archWalls.byFieldKeyIndex.productOrderId[productOrder.id];
    }

    if (productOrder && state.resources.archCustomViews.byFieldKeyIndex) {
      var archCustomViews = state.resources.archCustomViews.byFieldKeyIndex.productOrderId[productOrder.id];
    }

    if (productOrder && state.resources.entities.byFieldKeyIndex) {
      var entities = state.resources.entities.byFieldKeyIndex.parentId[productOrder.id];
    }

    if (productOrder && state.resources.archCustomViews.byFieldKeyIndex) {
      var archCustomViews = state.resources.archCustomViews.byFieldKeyIndex.productOrderId[productOrder.id];
    }

    if (productOrder && state.resources.productCanvasTexts.byFieldKeyIndex) {
      var productCanvasTexts = state.resources.productCanvasTexts.byFieldKeyIndex.productOrderId[productOrder.id];
    }

    if (productOrder && state.resources.productCanvasLines.byFieldKeyIndex) {
      var productCanvasLines = state.resources.productCanvasLines.byFieldKeyIndex.productOrderId[productOrder.id];
    }

    return {
      archCustomViews,
      archElevations,
      archWalls,
      entities,
      productOrder,
      productInstancesById,
      productsById,
      productCategoriesById,
      productOptionsById,
      productPropertiesById,
      productRulesById,
      productCanvasTexts,
      productCanvasLines,
      ..._.pick(state.activeView.data, ['drawingSetMode', 'isPrinting', 'isPdfing', 'drawingsPageSize'])
    };
  },
  mapDispatch: {
    setActiveView,
    ..._.pick(resourceActions.productInstances, ['trackProductInstances']),
    ..._.pick(resourceActions.productOrders, ['trackProductOrders']),
    ..._.pick(resourceActions.archElevations, ['trackArchElevations']),
    ..._.pick(resourceActions.archWalls, ['trackArchWalls']),
    ..._.pick(resourceActions.entities, ['trackEntities']),
    ..._.pick(resourceActions.archCustomViews, ['trackArchCustomViews']),
    ..._.pick(resourceActions.productCanvasTexts, ['trackProductCanvasTexts']),
    ..._.pick(resourceActions.productCanvasLines, ['trackProductCanvasLines']),
  }
})(DrawingsPage));
