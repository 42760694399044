import React from 'react';
import { PureComponent } from 'react';
import K from '~/k';

import { View, TouchableOpacity } from 'react-native';
import { Link, Text } from '@symbolic/rn-lib';

class AdminMenu extends PureComponent {
  render() {
    var {activeOrg} = this.props;

    var links = [
      {title: 'Products', to: '/admin/products'},
      {title: 'Properties', to: '/admin/product-properties'},
      {title: 'Property Groups', to: '/admin/product-property-groups'},
      {title: 'Product Categories', to: '/admin/product-categories'},
      {title: 'Media', to: '/admin/media'},
      {title: 'Guests', to: '/admin/guests'},
      {title: 'User Groups', to: '/admin/user-groups'},
    ];

    var enabledIntegrations = _.get(activeOrg, 'appData.designEngine.enabledIntegrations', []);

    if (enabledIntegrations.length > 0) links.push({title: 'Integrations', to: '/admin/integrations'});

    return (
      <View style={{width: 200, backgroundColor: '#f5f5f5', backgroundOpacity: 0.5, marginRight: 1}}>
        <View style={{padding: K.spacing * 2, marginBottom: K.spacing * 2 - 3}}>
          <Text style={{...K.fonts.pageHeader, pointerEvents: 'none'}}>
            Admin:
          </Text>
        </View>
        {_.map(links, (link, index) => (
          <TouchableOpacity
            key={index}
            dataSet={{'hover-background-double-gray': 1}}
            style={{...(_.includes(window.location.pathname, link.to) ? {opacity: 1, backgroundColor: K.colors.doubleGray} : {opacity: 0.5})}}
          >
            <Link
              to={link.to}
              style={{height: 30, justifyContent: 'center', paddingHorizontal: K.spacing * 2, marginBottom: K.margin}}
            >
              <Text style={{position: 'relative', top: 1}}>{link.title}</Text>
            </Link>
          </TouchableOpacity>
        ))}
      </View>
    );
  }
}

export default AdminMenu;
