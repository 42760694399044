function productOptionsByIdFor({productPropertyId}, dependencies) {
  var {productOptionsById} = dependencies;

  return _.chain(productOptionsById)
    .reject('archived')
    .filter({productPropertyId})
    .sortBy('rank')
    .value();
}

export { productOptionsByIdFor };
