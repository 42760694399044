import _ from 'lodash';
import getBevel from '~/helpers/meljac/get-bevel';
import getScrews from '~/helpers/meljac/get-screws';
import {fifteenAmpReceptacle, twentyAmpReceptacle, usbA, usbC, hdmi, rj45, rj11, speakerPorts, coax, cablePassThrough} from '~/helpers/meljac/port-modules';

export default function getWallOutletGraphicNodes({deps, group, rect, circle, path, dimension}) {
  var {propertiesDataById} = deps.productInstance;

  var spacingRules = [
    {axis: 'Y', height: 117, rows: 2, isScrewVisible: false, backBoxType: 'MUD RING', spacing: 36},
    {axis: 'Y', height: 117, rows: 2, isScrewVisible: true, backBoxType: 'MUD RING', spacing: 36},

    {axis: 'Y', height: 117, rows: 2, isScrewVisible: false, backBoxType: 'MELUS', spacing: 36},
    {axis: 'Y', height: 117, rows: 2, isScrewVisible: true, backBoxType: 'MELUS', spacing: 36},

    {axis: 'Y', height: 144, rows: 2, isScrewVisible: false, backBoxType: 'MELUS', spacing: 55},
    {axis: 'Y', height: 144, rows: 2, isScrewVisible: true, backBoxType: 'MELUS', spacing: 55},

    {axis: 'Y', height: 144, rows: 3, isScrewVisible: false, backBoxType: 'MELUS', spacing: 32},
    {axis: 'Y', height: 144, rows: 3, isScrewVisible: true, backBoxType: 'MELUS', spacing: 37},

    {axis: 'X', width: 117, columns: 2, isScrewVisible: false, backBoxType: 'MUD RING', spacing: 36},
    {axis: 'X', width: 117, columns: 2, isScrewVisible: true, backBoxType: 'MUD RING', spacing: 36},

    {axis: 'X', width: 117, columns: 2, isScrewVisible: false, backBoxType: 'MELUS', spacing: 36},
    {axis: 'X', width: 117, columns: 2, isScrewVisible: true, backBoxType: 'MELUS', spacing: 36},

    {axis: 'X', width: 144, columns: 2, isScrewVisible: false, backBoxType: 'MELUS', spacing: 55},
    {axis: 'X', width: 144, columns: 2, isScrewVisible: true, backBoxType: 'MELUS', spacing: 55},

    {axis: 'X', width: 144, columns: 3, isScrewVisible: false, backBoxType: 'MELUS', spacing: 32},
    {axis: 'X', width: 144, columns: 3, isScrewVisible: true, backBoxType: 'MELUS', spacing: 37},

    {axis: 'X', width: 207, columns: 3, isScrewVisible: false, backBoxType: 'MELUS', spacing: 59},
    {axis: 'X', width: 207, columns: 3, isScrewVisible: true, backBoxType: 'MELUS', spacing: 59},

    {axis: 'X', width: 207, columns: 4, isScrewVisible: false, backBoxType: 'MELUS', spacing: 40},
    {axis: 'X', width: 207, columns: 4, isScrewVisible: true, backBoxType: 'MELUS', spacing: 40},

    {axis: 'X', width: 269, columns: 3, isScrewVisible: false, backBoxType: 'MELUS', spacing: 72},
    {axis: 'X', width: 269, columns: 3, isScrewVisible: true, backBoxType: 'MELUS', spacing: 72},

    {axis: 'X', width: 269, columns: 4, isScrewVisible: false, backBoxType: 'MELUS', spacing: 59},
    {axis: 'X', width: 269, columns: 4, isScrewVisible: true, backBoxType: 'MELUS', spacing: 59},

    {axis: 'X', width: 269, columns: 5, isScrewVisible: false, backBoxType: 'MELUS', spacing: 46},
    {axis: 'X', width: 269, columns: 5, isScrewVisible: true, backBoxType: 'MELUS', spacing: 46}
  ];

  var rectWidth = _.get(propertiesDataById, '13.selectedProductOption.data.width');
  var rectHeight = _.get(propertiesDataById, '13.selectedProductOption.data.height');
  var orientation = rectWidth >= rectHeight ? 'X' : 'Y';

  var port1LayoutString = _.get(propertiesDataById, '43.selectedProductOption.data.layoutString');
  var port2LayoutString = _.get(propertiesDataById, '122.selectedProductOption.data.layoutString');
  var port3LayoutString = _.get(propertiesDataById, '123.selectedProductOption.data.layoutString');
  var port4LayoutString = _.get(propertiesDataById, '127.selectedProductOption.data.layoutString');
  var port5LayoutString = _.get(propertiesDataById, '173.selectedProductOption.data.layoutString');
  var layoutString = _.join([port1LayoutString, port2LayoutString, port3LayoutString, port4LayoutString, port5LayoutString], ' ');

  var totalRows = 1;
  var totalColumns = 1;

  if (port2LayoutString && orientation === 'Y') totalRows = 2;
  if (port2LayoutString && orientation === 'X') totalColumns = 2;

  if (port3LayoutString && orientation === 'Y') totalRows = 3;
  if (port3LayoutString && orientation === 'X') totalColumns = 3;

  if (port4LayoutString && orientation === 'Y') totalRows = 4;
  if (port4LayoutString && orientation === 'X') totalColumns = 4;

  if (port5LayoutString && orientation === 'Y') totalRows = 5;
  if (port5LayoutString && orientation === 'X') totalColumns = 5;

  if (rectHeight === 117 && rectWidth === 117) {
    totalRows = 2;
    totalColumns = 2;
  }

  //HINT modify spacing rules for "No Port" option
  if (!port1LayoutString && orientation === 'Y') totalRows -= 1;
  if (!port1LayoutString && orientation === 'X') totalColumns -= 1;

  var plateFinishStrokeColor = _.get(propertiesDataById, '5.selectedProductOption.data.strokeColor', '#000000');
  var plateFinishStrokeWidth = _.get(propertiesDataById, '5.selectedProductOption.data.strokeWidth', {240: 3, 241: 2}[edgeTypeId]);
  var plateFinishColor = _.get(propertiesDataById, '5.selectedProductOption.data.fill', 'transparent');

  var edgeTypeId = _.get(propertiesDataById, '2.selectedProductOption.id', 241);
  var isBeveled = {240: true, 241: false}[edgeTypeId];
  var bevel = getBevel({group, path, rectWidth, rectHeight});

  var backBoxData = _.get(propertiesDataById, '58.selectedProductOption.data');
  var backBoxType = _.get(propertiesDataById, '59.selectedProductOption.data.type');

  var mechanisms = [];
  var mechanismsLayout = _.remove(_.split(layoutString, ' '), (string) => string !== '');

  var screwTypeOptionId = _.get(propertiesDataById, '3.selectedProductOption.id');
  var isScrewVisible = {5: true, 6: true, 103: false}[screwTypeOptionId];
  var customInteraxis = (rectHeight === 117 || rectWidth === 117) && _.get(propertiesDataById, '29.selectedProductOption.id') === 144 ? 88 : 0;

  function getXYSpacing({width, height, backBoxType, isScrewVisible, columns = 0, rows = 0}) {
    var xSpacingRule = _.find(spacingRules, {axis: 'X', width, columns, isScrewVisible, backBoxType});
    var ySpacingRule = _.find(spacingRules, {axis: 'Y', height, rows, isScrewVisible, backBoxType});

    var xSpacing = columns === 1 ? 0 : _.get(xSpacingRule, 'spacing', 20);
    var ySpacing = rows === 1 ? 0 : _.get(ySpacingRule, 'spacing', 20);

    if (rectWidth === 117 && rectHeight === 117) {
      xSpacing = 46;
    }

    return {xSpacing, ySpacing};
  }

  var {xSpacing, ySpacing} = getXYSpacing({width: rectWidth, height: rectHeight, rows: totalRows, columns: totalColumns, backBoxType, isScrewVisible});

  if (mechanismsLayout.length === 1) {
    xSpacing = rectWidth / 2;
    ySpacing = rectHeight / 2;
  }

  var mechanismGroupDimensions = {
    width: 0,
    height: 0
  };

  var yOriented = orientation === 'Y' ? 1 : 0;
  var xOriented = orientation === 'X' ? 1 : 0;

  function pushMechanismToGroup ({mechString, index, dualPortYDiff = 0}) {
    var xPosition = xSpacing * index * xOriented;
    var yPosition = (ySpacing * index * yOriented) + dualPortYDiff;

    if (rectHeight === 117 && rectWidth === 117) {
      xPosition = index % 2 === 0 ? -(xSpacing / 2) : xSpacing / 2;
      yPosition = index === 0 || index === 1 ? -(ySpacing / 2) : ySpacing / 2;
    }

    if (mechString === 'RB') mechanisms.push(fifteenAmpReceptacle({xPosition, yPosition, group, rect, path, receptacleTypeColor: {backgroundColor: '#000000', socketInletColor: '#474747'}}));
    if (mechString === 'RW') mechanisms.push(fifteenAmpReceptacle({xPosition, yPosition, group, rect, path, receptacleTypeColor: {backgroundColor: '#FFFFFF', socketInletColor: '#474747'}}));
    if (mechString === '2RB') mechanisms.push(twentyAmpReceptacle({xPosition, yPosition, group, rect, path, receptacleTypeColor: {backgroundColor: '#000000', socketInletColor: '#474747'}}));
    if (mechString === '2RW') mechanisms.push(twentyAmpReceptacle({xPosition, yPosition, group, rect, path, receptacleTypeColor: {backgroundColor: '#FFFFFF', socketInletColor: '#474747'}}));
    if (mechString === 'UA') mechanisms.push(usbA({xPosition, yPosition, group, rect, path}));
    if (mechString === 'RJ4') mechanisms.push(rj45({xPosition, yPosition, group, rect, path}));
    if (mechString === 'RJ1') mechanisms.push(rj11({xPosition, yPosition, group, rect, path}));
    if (mechString === 'UC') mechanisms.push(usbC({xPosition, yPosition, group, rect, path}));
    if (mechString === 'C') mechanisms.push(coax({xPosition, yPosition, group, rect, path, circle}));
    if (mechString === 'H') mechanisms.push(hdmi({xPosition, yPosition, group, rect, path}));
    if (mechString === 'CP') mechanisms.push(cablePassThrough({xPosition, yPosition, group, rect, path}));
    if (mechString === 'S') mechanisms.push(speakerPorts({xPosition, yPosition, group, rect, path}));

    _.set(mechanismGroupDimensions, 'width', xSpacing * index * xOriented);
    _.set(mechanismGroupDimensions, 'height', ySpacing * index * yOriented);
  }

  _.forEach(mechanismsLayout, (mechanismString, index) => {
    pushMechanismToGroup({mechString: mechanismString, index});
  });

  if (rectHeight === 117 && rectWidth === 117) {
    _.set(mechanismGroupDimensions, 'width', 0);
    _.set(mechanismGroupDimensions, 'height', 0);
  }

  var mechanismGroupPositions = {
    x: (rectWidth / 2) - (mechanismGroupDimensions.width / 2),
    y: (rectHeight / 2) - (mechanismGroupDimensions.height / 2)
  };

  var mechanismGroup = group({width: mechanismGroupDimensions.width, height: mechanismGroupDimensions.height, x: mechanismGroupPositions.x, y: mechanismGroupPositions.y, strokeWidth: 1, stroke: 'red'}, [
    ...mechanisms
  ]);

  var screws = getScrews({group, path, circle, screwTypeOptionId, rectWidth, rectHeight, customInteraxis});

  var coverData = _.get(propertiesDataById, '12.selectedProductOption');
  coverData = coverData.data;
  var covers = [];
  var spacing = (rectWidth - (coverData.width * coverData.quantity)) / (coverData.quantity + 1);

  var coverGroupDimensions = {
    width: coverData.quantity === 1 ? coverData.width : ((coverData.width + spacing) * coverData.quantity) - spacing,
    height: coverData.height
  };

  function cover ({width, height, x, y}) {
    var mech = group({width, height, x, y}, ([
      path({d: [
        {command: 'M', x: 0, y: 0},
        {command: 'H', x: width},
        {command: 'V', y: height},
        {command: 'H', x: 0},
        {command: 'Z'}
      ], strokeWidth: 1, stroke: 'black', fill: 'transparent'}),
      path({d: [
        {command: 'M', x: (width * .15), y: 0},
        {command: 'V', y: 2},
        {command: 'H', x: (width * .85)},
        {command: 'V', y: 0}
      ], strokeWidth: 1, stroke: 'black', fill: 'transparent'})
    ]));
    return mech;
  }

  _.times(coverData.quantity, i => {
    covers.push(cover({width: coverData.width, height: coverData.height, x: (coverData.width + spacing) * i}));
  });

  var coverGroup = group({x: (rectWidth / 2) - (coverGroupDimensions.width / 2), y: (rectHeight / 2) - (coverGroupDimensions.height / 2)}, [
    ...covers
  ]);

  var widthDimensionProps = [{x: rectWidth, y: rectHeight}, {x: 0, y: rectHeight}];
  var heightDimensionProps = [{x: 0, y: rectHeight}, {x: 0, y: 0}];

  var xSpacingInnerDimensionPositions = [];
  var ySpacingInnerDimensionPositions = [];

  if (rectWidth === 117 && rectHeight === 117) {
    xSpacingInnerDimensionPositions = [{x: (rectWidth / 2) - (xSpacing / 2), y: rectHeight}, {x: (rectWidth / 2) + (xSpacing / 2), y: rectHeight}];
    ySpacingInnerDimensionPositions = [{x: 0, y: (rectHeight / 2) - (ySpacing / 2)}, {x: 0, y: (rectHeight / 2) + (ySpacing / 2)}];
  }
  else if (mechanismsLayout.length === 1) {
    xSpacingInnerDimensionPositions = [{x: rectWidth / 2, y: rectHeight}];
    ySpacingInnerDimensionPositions = [{x: 0, y: rectHeight / 2}];
  }
  else if (mechanismsLayout.length > 1) {
    if (yOriented) {
      xSpacingInnerDimensionPositions = [{x: rectWidth / 2, y: rectHeight}];
      ySpacingInnerDimensionPositions = _.times(mechanismsLayout.length, index => ({x: 0, y: mechanismGroupPositions.y + (index * ySpacing)}));
    }
    if (xOriented) {
      xSpacingInnerDimensionPositions = _.times(mechanismsLayout.length, index => ({x: mechanismGroupPositions.x + (index * xSpacing), y: rectHeight}));
      ySpacingInnerDimensionPositions = [{x: 0, y: rectHeight / 2}];
    }
  }

  var xSpacingDimensionPositions = [{x: rectWidth, y: rectHeight}, ...xSpacingInnerDimensionPositions.reverse(), {x: 0, y: rectHeight}];
  var ySpacingDimensionPositions = [{x: 0, y: rectHeight}, ...ySpacingInnerDimensionPositions.reverse(), {x: 0, y: 0}];

  return group({width: rectWidth, height: rectHeight, x: 0, y: 0, padding: 30}, [
    rect({width: rectWidth, height: rectHeight, strokeWidth: plateFinishStrokeWidth, stroke: plateFinishStrokeColor, fill: plateFinishColor, x: 0, y: 0}),
    ...(isBeveled ? [bevel] : []),
    ...(isScrewVisible ? [screws] : []),
    mechanismGroup,
    coverGroup,
    dimension({props: {positions: xSpacingDimensionPositions, extrudeDistance: 30}}),
    dimension({props: {positions: ySpacingDimensionPositions, extrudeDistance: 30}}),
    dimension({props: {positions: widthDimensionProps, extrudeDistance: 60}}),
    dimension({props: {positions: heightDimensionProps, extrudeDistance: 60}})
  ]);
}
