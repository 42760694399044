import { pluralize } from 'inflection';

async function uploadFile({files, setState, api, resource, resourceKey, trackResource, trackMedia, session, type}) {
  setState({uploading: true});

  var file = _.get(files, '0');
  var uploaderId = session.user.id;
  var {id: orgId} = session.activeOrg;

  if (resource && resourceKey) {
    var pluralResourceKey = pluralize(resourceKey);
    var {id, mediumId} = resource;
  }

  try {
    if (file.size < 5000000) {
      var fileName = _.first(_.split(file.name, '.'));
      var fileExtension = _.last(_.split(file.name, '.'));
      var actionKey = mediumId ? 'update' : 'create';

      var props = {
        fileName,
        fileExtension,
        uploaderId,
        orgId,
        type: type || 'thumbnail',
        resourceForMedium: resourceKey,
        hasFile: true,
        [`${resourceKey}Id`]: id
      };

      var dataToSend = {
        create: props,
        update: {
          where: {id: mediumId},
          props
        }
      };

      var medium = await api[actionKey]('medium', dataToSend[actionKey], {files: [file]});
    }
    else {
      alert('Please choose a file under 5mb');
    }

    setTimeout(async () => {
      setState({uploading: false, uploadingWasSuccessful: true});

      if (trackResource && resourceKey) {
        var resource = await api.get(resourceKey, {where: {id}});

        trackResource({[pluralResourceKey]: [resource]});
      }
      else {
        trackMedia({media: [medium]});
      }
    }, 4000);

  } catch (err) {
    console.error(err);
    setState({uploading: false, uploadingWasSuccessful: false, uploadingHasFailed: true});
  }
}

export default uploadFile;
