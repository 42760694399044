import React from 'react';

import K from '~/k';
import lib from '~/lib';
import CanvasLine from '~/components/canvas/line/canvas-line';
import CanvasText from '~/components/canvas/text/canvas-text';

import { toCanvas } from '~/helpers/canvas/canvas-helpers';
import _ from 'lodash';

export default class CanvasDimensionLineDistance extends React.PureComponent {
  render() {
    var {
      canvasPositionFor, distance, angle, labelY, canvasData, d,
      fontSize, prevDistance, defaultOpacity
    } = this.props;

    var offsetDistance = _.get(canvasData, 'dimensionOptions.offsetDistance', 5);
    var distanceOrigin = canvasPositionFor({distance: distance.total, perpendicularOffsetDistance: offsetDistance * canvasData.textDimsScale});

    var offset = offset => lib.object.sum(distanceOrigin, denormalize(offset));
    var denormalize = position => lib.trig.rotate({position, byDegrees: angle});

    var {pushOutside, useSquareTicks} = this.props.transformData;
    var shouldSlant = pushOutside || useSquareTicks;
    var trackingOffset = (shouldSlant ? 2 : 4) * canvasData.textDimsScale;

    if (d !== 0) {
      var mainLine = {
        from: toCanvas(canvasPositionFor({distance: distance.total, perpendicularOffsetDistance: offsetDistance * canvasData.textDimsScale}), canvasData),
        to: toCanvas(canvasPositionFor({distance: prevDistance.total, perpendicularOffsetDistance: offsetDistance * canvasData.textDimsScale}), canvasData)
      };
    }

    var trackingLine = {
      from: toCanvas(lib.trig.translate({point: distanceOrigin, by: -trackingOffset, alpha: lib.trig.degreesToRadians(angle) + (shouldSlant ? Math.PI / 2 : Math.PI / 4)}), canvasData),
      to: toCanvas(lib.trig.translate({point: distanceOrigin, by: trackingOffset, alpha: lib.trig.degreesToRadians(angle) + (shouldSlant ? Math.PI / 2 : Math.PI / 4)}), canvasData)
    };

    var labelOffsetAmount = -distance.delta / 2;

    var primaryLabelPosition = lib.object.sum(toCanvas(offset({x: labelOffsetAmount, y: _.get(canvasData, 'dimensionOptions.primaryLabel.offsetDistance', this.props.transformData.pushOutside ? -10 : 0) * canvasData.textDimsScale}), canvasData));
    var mainLineIsVisible = d !== 0;

    if (_.get(canvasData, 'dimensionOptions.secondaryLabel.enabled')) {
      var secondaryLabelPosition = lib.object.sum(toCanvas(offset({x: labelOffsetAmount, y: _.get(canvasData, 'dimensionOptions.secondaryLabel.offsetDistance', this.props.transformData.pushOutside ? 10 : 0) * canvasData.textDimsScale}), canvasData));
    }

    return (<>
      <CanvasLine
        {...trackingLine}
        stroke={'black'}
        strokeWidth={_.get(canvasData, 'dimensionOptions.trackingLine.strokeWidth', pushOutside ? 0.5 : 0.5)}
        opacity={_.get(canvasData, 'dimensionOptions.trackingLine.opacity', 1)}
      />
      {d !== 0 && (<>
        {mainLineIsVisible && (
          <CanvasLine
            {...mainLine}
            stroke='black'
            strokeWidth={_.get(canvasData, 'dimensionOptions.mainLine.strokeWidth', 0.5)}
            opacity={_.get(canvasData, 'dimensionOptions.mainLine.opacity', defaultOpacity)}
          />
        )}
        <CanvasText
          fill='black'
          align='center'
          alignVertical='center'
          text={canvasData.dimensionOptions?.primaryLabel?.getLabel ? canvasData.dimensionOptions?.primaryLabel?.getLabel(Math.abs(distance.delta)) : Math.abs(distance.delta) + '″'}
          fontSize={fontSize}
          onMouseDown={this.handleLabelMouseDown}
          {...primaryLabelPosition}
          rotation={_.get(canvasData, 'dimensionOptions.rotateText') ? angle : 0}
        />
        {_.get(canvasData, 'dimensionOptions.secondaryLabel.enabled') && (
          <CanvasText
            fill='black'
            align='center'
            alignVertical='center'
            text={canvasData.dimensionOptions?.secondaryLabel?.getLabel ? canvasData.dimensionOptions?.secondaryLabel?.getLabel(Math.abs(distance.delta)) : Math.abs(distance.delta) + '″'}
            fontSize={fontSize}
            onMouseDown={this.handleLabelMouseDown}
            {...secondaryLabelPosition}
            rotation={_.get(canvasData, 'dimensionOptions.rotateText') ? angle : 0}
          />
        )}
      </>)}
    </>);
  }
}
