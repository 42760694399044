import { useContext, useEffect } from 'react';
import { connect } from '@symbolic/redux';
import { resourceActions } from '~/redux';
import { toCanvas } from '~/helpers/canvas/canvas-helpers';
import { Circle } from 'react-konva';

import ArchDataContext from '~/contexts/arch-data-context';
import CanvasDataContext from '~/contexts/canvas-data-context';

import CanvasText from '~/components/canvas/text/canvas-text';
import RectTool from '~/components/canvas/tools/rect/rect-tool';
import PathTool from '~/components/canvas/tools/path/path-tool';

function CalicoWallLabel({calicoWallLabel, ...props}) {
  var archData = useContext(ArchDataContext);
  var canvasData = useContext(CanvasDataContext);

  useEffect(() => {
    var handleDestroyEntity = (event) => {
      if (event.code === 'Backspace' && isSelected) {
        props.destroyEntity({id: calicoWallLabel.id});

        archData.setSelectedEntity(undefined, undefined);
      }
    };

    document.addEventListener('keydown', handleDestroyEntity);

    return () => {
      document.removeEventListener('keydown', handleDestroyEntity);
    };
  });

  var {title, customViewPosition, customViewSize, labelPlanPosition, viewStartPlanPosition, indicateViewStartOnPlan} = calicoWallLabel.data;

  var isSelected = archData.selectedEntityId === calicoWallLabel.id && archData.selectedEntityResourceKey === 'calicoWallLabel';

  labelPlanPosition = {x: labelPlanPosition.x, y: labelPlanPosition.z};
  viewStartPlanPosition = {x: viewStartPlanPosition.x, y: viewStartPlanPosition.z};

  var labelOffset = toCanvas(customViewPosition, canvasData);
  var canvasLabelPlanPosition = toCanvas(labelPlanPosition, canvasData);
  var canvasViewStartPlanPosition = toCanvas(viewStartPlanPosition, canvasData);

  return (
    <>
      <CanvasText
        fill='black'
        align='left'
        alignVertical='bottom'
        fontWeight='bold'
        fontSize={12 * (isFinite(canvasData.scale) ? canvasData.scale : 1)}
        text={title}
        onMouseDown={() => archData.setSelectedEntity(calicoWallLabel.id, 'calicoWallLabel')}
        {...(archData.activeView.viewKey === 'top' ? canvasLabelPlanPosition : toCanvas({x: customViewPosition.x, y: customViewPosition.y - 5}, canvasData))}
      />
      {indicateViewStartOnPlan && (
        <Circle {...(archData.activeView.viewKey === 'top' ? canvasViewStartPlanPosition : labelOffset)} radius={4} fill={'red'}/>
      )}
      {isSelected && archData.activeView.viewKey === 'top' && (
        <PathTool
          isSelected={isSelected}
          strokeWidth={0}
          isInAddMode={false}
          onSelect={() => {}}
          onTransform={({points, transformEnd}) =>
            props.updateEntity({
              id: calicoWallLabel.id,
              props: {
                data: {
                  ...calicoWallLabel.data,
                  labelPlanPosition: {x: points[0].x, z: points[0].y},
                  ...(indicateViewStartOnPlan ? {viewStartPlanPosition: {x: points[1].x, z: points[1].y}} : {}),
                }},
              hitApi: !!transformEnd
            })}
          points={[labelPlanPosition, ...(indicateViewStartOnPlan ? [viewStartPlanPosition] : [])]}
          color='black'
        />
      )}
      {isSelected && archData.activeView.viewKey === 'custom' && (
        <RectTool
          {...{isSelected}}
          onSelect={() => archData.setSelectedEntity(calicoWallLabel.id, 'calicoWallLabel')}
          onTransform={({position: customViewPosition, size: customViewSize}) => {
            props.updateEntity({id: calicoWallLabel.id, props: {data: {...calicoWallLabel.data, customViewPosition, ...(customViewSize ? {customViewSize} : {})}}, hitApi: false});
          }}
          onTransformEnd={() => {
            props.updateEntity({id: calicoWallLabel.id, props: {data: calicoWallLabel.data}});
          }}
          position={toCanvas(customViewPosition, canvasData)}
          {...{size: customViewSize}}
        />
      )}
    </>
  );
}

export default connect({
  mapDispatch: {
    ..._.pick(resourceActions.entities, ['updateEntity', 'destroyEntity'])
  }
})(CalicoWallLabel);
