import { Component } from 'react';
import { Text, CopilotStepView } from '@symbolic/rn-lib';
import { View } from 'react-native';
import { connect } from '@symbolic/redux';
import { getNameFor } from '~/helpers/product-order-helper';
import { formatPrice } from '~/helpers/price-helper';
import { api } from '@symbolic/lib';

import K from '~/k';
import getPricingIsHidden from '~/helpers/get-pricing-is-hidden/get-pricing-is-hidden';
import { resourceActions } from '~/redux';

class ProductOrderPriceHudElement extends Component {
  componentDidMount = () => {
    this.props.updateProductOrder({id: this.props.productOrder.id, props: {costs: this.props.costs}, hitApi: false});
  };

  componentDidUpdate = (prevProps) => {
    if (prevProps.costs.orderTotal !== this.props.costs.orderTotal) {
      this.props.updateProductOrder({id: this.props.productOrder.id, props: {costs: this.props.costs}, hitApi: false});
    }
  };

  render() {
    var {productOrder, orderSubTotal} = this.props;
    var {id: orgId} = this.props.session.activeOrg;

    var pricingIsHidden = getPricingIsHidden(this.props);

    return !pricingIsHidden && (
      <CopilotStepView
        key={true}
        name='order price'
        order={5}
        text={getNameFor({productOrder, orgId, textToTransform: 'The total price for your order is shown here.'})}
        style={{...this.props.style}}
      >
        <View style={{flexDirection: 'row', alignItems: 'baseline'}}>
          <Text style={{fontSize: K.calcFont(18), marginRight: K.margin}}>${formatPrice(orderSubTotal, true)}</Text>
          <Text style={{...K.fonts.standard, opacity: 0.5}}>+ shipping & tax</Text>
        </View>
      </CopilotStepView>
    );
  }
}

export default connect({
  mapState: (state, ownProps) => {
    var productOrderId = ownProps.productOrder.id;
    var productOrder = _.get(state, `resources.productOrders.byId.${productOrderId}`);

    return {
      productOrder
    };
  },
  mapDispatch: {
    ..._.pick(resourceActions.productOrders, ['updateProductOrder'])
  }
})(ProductOrderPriceHudElement);
