import { View, Image } from 'react-native';
import { useRef, useState } from 'react';
import { getDimensionLines } from '~/helpers/canvas/canvas-helpers';
import { Text } from '@symbolic/rn-lib';

import K from '~/k';
import _ from 'lodash';
import lib from '~/lib';
import uuid from 'uuid';
import maderaLogo from '~/assets/madera/madera-logo-black.png';
import Page from '~/components/page';
import getSizeForProductInstance from '~/helpers/product/get-size-for-product-instance';
import CanvasView from '~/components/canvas/view/canvas-view.js';
import ArchDataContext from '~/contexts/arch-data-context';
import CanvasViewSvgRenderer from '~/components/canvas/canvas-view-svg-renderer/canvas-view-svg-renderer';
import ProductCanvasText from '~/components/canvas/product/product-canvas-text';
import ProductCanvasLine from '~/components/canvas/product/product-canvas-line';
import SpatialCanvasProductInstance from '~/components/canvas/spatial-canvas-product-instance';
import CanvasDimensionGroup from '~/components/canvas/dimensions/canvas-dimension-group';

export default function DrawingsSpatialCustomViewPage({page, pageNumber, pageSize, pageCount, isPrinting, canvasSize, productOrder, activeOrg}) {
  var {productInstances} = page;
  var layerRef = useRef();
  var [forceUpdateKey, setForceUpdateKey] = useState(null);

  var productInstancesInsideCanvasArea = _.filter(productInstances, productInstance => {
    var scaledPosition = {
      x: productInstance.properties.position.x * page.scale,
      y: productInstance.properties.position.y * page.scale
    };

    var canvasBorderPositions = {
      left: -canvasSize.width / 2,
      right: canvasSize.width / 2,
      top: canvasSize.height / 2,
      bottom: -canvasSize.height / 2
    };

    var isInsideCanvasArea = scaledPosition.x > canvasBorderPositions.left && scaledPosition.x < canvasBorderPositions.right && scaledPosition.y > canvasBorderPositions.bottom && scaledPosition.y < canvasBorderPositions.top;

    return isInsideCanvasArea;
  });

  var entities = _.map(productInstancesInsideCanvasArea, productInstance => ({data: {
    position: productInstance.properties.position,
    size: getSizeForProductInstance({productInstance})
  }}));

  var offset = {x: 0, y: 0};

  var footerContentTextStyle = {fontSize: 11, opacity: 0.8};

  return (
    <Page {...{isPrinting, pageSize}} orientation={'landscape'}>
      <View style={{borderWidth: 1, borderBottomWidth: 0, position: 'relative'}}>
        <CanvasViewSvgRenderer {...{layerRef, canvasSize}} forceCanvasUpdate={() => setForceUpdateKey(uuid())} key={`${canvasSize.width}-${canvasSize.height}`}>
          <CanvasView
            isStatic
            scale={page.scale}
            textDimsScale={15}
            dimensionOptions={{
              enableSecondaryLabel: true,
              primaryLabel: {offsetDistance: -6, getLabel: (mm) => `${lib.number.toFraction(lib.number.round(mm / 25.4, {toNearest: 0.5}), {normalscript: true, delimiter: '-'})}"`},
              secondaryLabel: {enabled: true, offsetDistance: 7, getLabel: (mm) => `[${mm}MM]`},
              rotateText: true,
              mainLine: {opacity: 1},
              offsetDistance: 30
            }}
            canvasSize={canvasSize}
            getOffset={() => offset} {...{canvasSize, layerRef, forceUpdateKey}}
          >
            <ArchDataContext.Provider value={{activeView: {viewKey: 'custom'}}}>
              {_.map(productInstancesInsideCanvasArea, productInstance => (
                <SpatialCanvasProductInstance {...{productInstance}} product={page.productsById[productInstance.productId]}/>
              ))}
              {_.map(page.productCanvasLines, productCanvasLine => (
                <ProductCanvasLine isStatic key={productCanvasLine.id} {...{productCanvasLine}}/>
              ))}
              {_.map(page.productCanvasTexts, productCanvasText => (
                <ProductCanvasText isStatic key={productCanvasText.id} {...{productCanvasText}}/>
              ))}
            </ArchDataContext.Provider>
            {_.map(entities, entity => (
              <CanvasDimensionGroup lines={getDimensionLines({entities: [entity], sides: ['left', 'top']})}/>
            ))}
          </CanvasView>
          <Text style={{textTransform: 'uppercase', marginLeft: 10, marginBottom: -4}}>{'Door - Elevation [Ground Floor]'}</Text>
          <View style={{position: 'absolute', bottom: 0, right: 10, width: 150}}>
            {_.map(['Initials', 'Date'], label => (
              <View key={label} style={{borderBottomWidth: 1, marginTop: 40}}>
                <Text style={{textTransform: 'uppercase'}}>{label}</Text>
              </View>
            ))}
          </View>
        </CanvasViewSvgRenderer>
        <View style={{paddingHorizontal: 10}}>
          <Text>{page.title}</Text>
        </View>
      </View>
      <View style={{height: 110, borderWidth: 1, marginTop: 10, flexDirection: 'row'}}>
        <View style={{flex: 1, padding: 10, paddingTop: 8}}>
          {/* HINT drawings footer left pane */}
          <Text style={{...footerContentTextStyle}}>{`Client: ${productOrder.contactName}`}</Text>
          <Text style={{...footerContentTextStyle}}>{`Project: ${productOrder.title}`}</Text>
          {activeOrg.id === 1053 && (
            <Text style={{...footerContentTextStyle}}>{`Estimate Number: ${productOrder.estimateNumber || ''}`}</Text>
          )}
          <Text style={{...footerContentTextStyle}}>{`Product Type: ${page.productOrderProductType}`}</Text>
          <Text style={{...footerContentTextStyle}}>{`Product Spec: ${page.footerData.productSpec}`}</Text>
        </View>
        <View style={{flex: 1, padding: 10, paddingTop: 8, borderLeftWidth: 1, borderRightWidth: 1, alignSelf: 'stretch'}}>
          {/* HINT drawings footer center pane */}
          {page.footerData.notes && (
            <Text style={{...footerContentTextStyle}}>{`Notes: ${page.footerData.notes}`}</Text>
          )}
        </View>
        <View style={{flex: 1, alignItems: 'center', justifyContent: 'center'}}>
          <Image source={maderaLogo} style={{width: 200, height: 80}} resizeMode='contain'/>
        </View>
      </View>
      <View style={{padding: 10, opacity: 0.5, flexDirection: 'row'}}>
        <Text>{`Page ${pageNumber + 1} of ${pageCount}`}</Text>
        <View style={{flex: 1}}></View>
        <Text>sales@maderasurfaces.com | 718 484 7260 | maderasurfaces.com</Text>
      </View>
      {/* <DrawingsArchFooter
        {...{page, pageNumber, pageCount, productOrder}}
        notesTitle={'Elevation Layout Notes'}
        archNotes={<DrawingsCalicoFooterNotes {...{page}}/>}
      /> */}
    </Page>
  );
}
