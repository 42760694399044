import { PureComponent } from 'react';
import { View, ScrollView } from 'react-native';
import { DocumentTitle } from '@symbolic/rn-lib';
import { api } from '@symbolic/lib';
import { connect } from '@symbolic/redux';
import { resourceActions } from '~/redux';

import AdminMenu from '~/components/admin-menu';
import MediumEditor from '~/components/medium-editor';
import FileInput from '~/components/file-input';
import uploadFile from '~/helpers/upload-file-helper';
import K from '~/k';
import SecondaryHeader from '~/components/secondary-header';
import _ from 'lodash';
import { TouchableWithoutFeedback } from 'react-native-web';

class AdminMediaIndexPage extends PureComponent {
  state = {
    filterType: 'image',
    filterOption: 'all',
    associationCandidates: [],
    uploading: false,
    uploadingWasSuccessful: false,
    uploadingHasFailed: false,
    activeMediumId: -1
  };

  handleFilterTypeChange = ({value}) => this.setState({filterType: value});

  updateAssociations = async ({id, resourceKey, action, mediumId}) => {
    var medium = await api.get('medium', {where: {id: mediumId}});
    var {associations} = medium;
    var resourceAssociations = _.get(associations, resourceKey, {});

    if (action === 'add') {
      resourceAssociations = _.set(resourceAssociations, id, {});
    }
    else if (action === 'remove') {
      delete resourceAssociations[id];
    }

    _.set(associations, resourceKey, resourceAssociations);

    this.props.updateMedium({id: mediumId, props: {associations}});
  };

  setActiveMediumId = ({id}) => {
    this.setState({activeMediumId: id});
  };

  render() {
    // var orgId = this.props.session.activeOrg.id;
    var media = _.filter(this.props.media, {'type': this.state.filterType});

    return (
      <DocumentTitle title={'Admin: Media'}>
        <View style={{flex: 1, flexDirection: 'row'}}>
          <AdminMenu activeOrg={this.props.session.activeOrg} />
          <View style={{flex: 1, resizeMode: 'contain'}}>
            <SecondaryHeader
              title="Media"
              // rightComponent={(
              //   <View style={{right: 20}}>
              //     <LabelledView label='Media Type' styles={{paddingTop: K.spacing}}>
              //       <PickerInput
              //         style={{height: K.inputHeight}}
              //         buttonStyle={{backgroundColor: K.colors.doubleGray}}
              //         showDownArrow={true}
              //         options={[
              //           {value: 'image', title: 'Image'},
              //           {value: 'video', title: 'Video'},
              //         ]}
              //         value={this.state.filterType}
              //         onChange={this.handleFilterTypeChange}
              //       />
              //     </LabelledView>
              //   </View>
              // )}
            />
            <FileInput
              style={{padding: K.spacing}}
              containerStyle ={{height: 64, padding: K.margin, margin: K.margin * 3}}
              uploading={this.state.uploading}
              uploadingWasSuccessful={this.state.uploadingWasSuccessful}
              uploadingHasFailed = {this.state.uploadingHasFailed}
              handleFilesPicked={(files) => {
                this.setState({medium: files});
                uploadFile({
                  files,
                  setState: (newState) => this.setState(newState),
                  api,
                  trackMedia: this.props.trackMedia,
                  session: this.props.session,
                  type: 'image'
                });
              }}
            >
            </FileInput>
            <ScrollView contentContainerStyle={{paddingBottom: 0}}>
              <View style={{flexWrap: 'wrap', flexDirection: 'row', alignSelf: 'center', paddingBottom: 20, margin: 0, width: '100%'}}>
                {_.map(_.reverse(media), medium => (
                  <MediumEditor
                    style={{flexGrow: 1}}
                    key={medium.id}
                    {...{media, medium}}
                    activeMediumId={this.state.activeMediumId}
                    setActiveMediumId={({id}) => this.setActiveMediumId({id})}
                  ></MediumEditor>
                ))}
              </View>
              {/* <View style={{position: 'relative', bottom: 0, width: '100vw', height: 60, backgroundColor: K.colors.gray}}>pagination footer</View>*/}
            </ScrollView>
          </View>
        </View>
      </DocumentTitle>
    );
  }
}

export default connect({
  mapState: (state) => {
    var productPropertiesById = state.resources.productProperties.byId;
    var productOptionsById = state.resources.productOptions.byId;
    var productsById = state.resources.products.byId;
    var media = state.resources.media.byId;

    return {
      productPropertiesById,
      productOptionsById,
      productsById,
      media,
    };
  },
  mapDispatch: {
    ..._.pick(resourceActions.media, ['trackMedia', 'destroyMedium', 'updateMedium'])
  },
})(AdminMediaIndexPage);
