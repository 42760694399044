import React from 'react';

import { useContext } from 'react';

import { Group, Image } from 'react-konva';
import { toCanvas } from '~/helpers/canvas/canvas-helpers';
import { getCalicoMuralTextureTransformProps } from '~/helpers/custom/calico/custom-calico-helpers';

import _ from 'lodash';
import lib from '~/lib';
import CanvasText from '~/components/canvas/text/canvas-text.js';
import CanvasMask from '~/components/canvas/mask/canvas-mask.js';
import ArchDataContext from '~/contexts/arch-data-context';
import CanvasDataContext from '~/contexts/canvas-data-context';
import RectTool from '~/components/canvas/tools/rect/rect-tool';

export default function CalicoCanvasPanelLayoutRenderer({calicoMuralPanelGroups, calicoMuralPanels, calicoMuralTextures, muralTextureImage, updateEntity}) {
  var canvasData = useContext(CanvasDataContext);
  var {scale, textDimsScale} = canvasData;
  var fontSize = 13 * scale;
  var {setSelectedEntity, selectedEntityId, selectedEntityResourceKey} = useContext(ArchDataContext);
  var fontScale = scale * textDimsScale;

  return (<>
    {_.map(calicoMuralPanelGroups, (calicoMuralPanelGroup) => {
      let {calicoMuralPanelsData} = calicoMuralPanelGroup;

      const topLeftPanelPosition = {x: _.min(_.map(calicoMuralPanelsData, 'position.x')), y: _.min(_.map(calicoMuralPanelsData, 'position.y')) - 22};

      return (<>
        {calicoMuralPanelGroup.calicoWallLabel && (
          <CanvasText {...{fontSize}} text={`${calicoMuralPanelGroup.calicoWallLabel.data.title}`} {...toCanvas(topLeftPanelPosition, canvasData)}/>
        )}
        {_.map(calicoMuralPanelsData, ({calicoMuralPanel}) => {
          var {data: {size: {width, height}}} = calicoMuralPanel; //TODO refactor to data.size
          var position = calicoMuralPanel.data.drawingsPanelLayoutPosition || calicoMuralPanel.data.position;
          var {x, y} = position;
          var textFill = calicoMuralPanel.data.isCriticalAlignmentPanel ? 'red' : 'black';
          var isSelected = selectedEntityId === calicoMuralPanel.id && selectedEntityResourceKey === 'calicoMuralPanel';
          var printNotes = _.get(calicoMuralPanel, 'data.panelPrintNotes', '');
          var printNotesHeight = printNotes ? (10 + printNotes.split(/\r\n|\r|\n/).length * 20) * textDimsScale : 0;

          return (
            <Group key={calicoMuralPanel.id} onMouseDown={() => setSelectedEntity(calicoMuralPanel.id, 'calicoMuralPanel')}>
              <CanvasMask maskingPolygons={[_.map([
                {x: x, y: y},
                {x: x + width, y: y},
                {x: x + width, y: y + height},
                {x: x, y: y + height},
                {x: x, y: y}
              ], position => toCanvas(position, canvasData))]}>
                {_.map(calicoMuralTextures, calicoMuralTexture => (
                  <Image
                    key={calicoMuralTexture.id}
                    image={muralTextureImage}
                    {...toCanvas(lib.object.sum(calicoMuralTexture.data.position, lib.object.difference(position, calicoMuralPanel.data.position)), canvasData)}
                    {...lib.object.multiply(calicoMuralTexture.data.size, scale)}
                    {...getCalicoMuralTextureTransformProps({calicoMuralTexture, scale})}
                  />
                ))}
              </CanvasMask>
              {isSelected && (
                <RectTool
                  {...{isSelected}}
                  {...{size: calicoMuralPanel.data.size}}
                  onSelect={() => setSelectedEntity(calicoMuralPanel.id, 'calicoMuralPanel')}
                  isScalable={false}
                  position={toCanvas(position, canvasData)}
                  onTransform={({position}) => {
                    updateEntity({id: calicoMuralPanel.id, props: {data: {..._.find(calicoMuralPanels, {id: calicoMuralPanel.id}).data, drawingsPanelLayoutPosition: position}}, hitApi: false});
                  }}
                  onTransformEnd={() => {
                    updateEntity({id: calicoMuralPanel.id, props: {data: _.find(calicoMuralPanels, {id: calicoMuralPanel.id}).data}});
                  }}
                />
              )}
              <Group {...toCanvas(lib.object.sum(position, {y: calicoMuralPanel.data.drawingsShowNotesAbovePanel ? (-165 * textDimsScale - printNotesHeight) : calicoMuralPanel.data.size.height}), canvasData)}>
                <CanvasText {...{fontSize, fill: textFill}} text={calicoMuralPanel.data.panelDescriptionOverride || `Panel ${calicoMuralPanel.data.panelNumber}`} y={10 * fontScale} fontStyle={'bold'}/>
                <CanvasText {...{fontSize, fill: textFill}} text={`height: ${calicoMuralPanel.data.originalSize.height}″`} y={35 * fontScale} fontStyle={'bold'}/>
                <CanvasText {...{fontSize, fill: textFill}} text={`${_.get(calicoMuralPanel.data, 'bleedTop', 1)}″ bleed top`} y={55 * fontScale} fontFamily={'HelveticaNeue-Light'}/>
                <CanvasText {...{fontSize, fill: textFill}} text={`${_.get(calicoMuralPanel.data, 'bleedBottom', 1)}″ bleed bottom`} y={75 * fontScale} fontFamily={'HelveticaNeue-Light'}/>
                <CanvasText {...{fontSize, fill: textFill}} text={`width: ${calicoMuralPanel.data.originalSize.width}″`} y={100 * fontScale} fontStyle={'bold'}/>
                <CanvasText {...{fontSize, fill: textFill}} text={`${_.get(calicoMuralPanel.data, 'bleedLeft', 1)}″ bleed left`} y={120 * fontScale} fontFamily={'HelveticaNeue-Light'}/>
                <CanvasText {...{fontSize, fill: textFill}} text={`${_.get(calicoMuralPanel.data, 'bleedRight', 1)}″ bleed right`} y={140 * fontScale} fontFamily={'HelveticaNeue-Light'}/>
                {!!printNotes && (
                  <CanvasText {...{fontSize, fill: textFill}} text={printNotes} y={165 * fontScale} fontFamily={'HelveticaNeue-Light'} fontStyle={'bold'} lineHeight={1.4}/>
                )}
              </Group>
            </Group>
          );
        })}
      </>);
    })}
  </>);
}
