import _ from 'lodash';
import getTextSize from '~/helpers/get-text-size';
import lib from '~/lib';

export default function getMeljacEngravingProductGraphicNodes({
  engravingsData, verticalOffset, horizontalOffset, text, xSpacing, ySpacing,
  dimension, image, hasMechanismBelow = 0, hasMechanismAbove = 0, plateFinish, backLightOpacity, rectHeight, totalRows, ledIndicatorIsEnabled, ledPosition
}) {
  const {engravingArrays, fontFamily, fontSize, index, positionId, fontColor, isMechanicalStyleEngraving} = engravingsData;
  var engravingsAreAbove = positionId === 623;
  var engravingsAreBelow = positionId === 624;
  const realFontSize = (1.4 * fontSize);
  const mechanismToFirstEngravingSpacing = 3;
  const engravingToEngravingSpacing = 2;
  const productGraphicNodes = [];
  const engravingHeight = (fontSize * 2) + engravingToEngravingSpacing;
  const textHeight = fontSize;
  const mechanismHeight = verticalOffset * 2;

  var engravings = _.get(engravingArrays, 'text', []);

  //available engraving height
  //TODO this should really be happening at a higher level, rather than the UI "constraining" whether a second engraving line is available or not
  //TODO create helper and use here as well as in getEngravingHeightLimit (which should really be called getEngravingSecondLineIsAvailable or something like that)
  var availableEngravingHeight;

  if (totalRows === 1) {
    availableEngravingHeight = 13;
  }
  else if (hasMechanismAbove === 0 && engravingsAreAbove || hasMechanismBelow === 0 && engravingsAreBelow) {
    availableEngravingHeight = (rectHeight / 2) - ((ySpacing * (totalRows - 1)) / 2) - (mechanismHeight / 2) - (mechanismToFirstEngravingSpacing * 2);
  }
  else {
    availableEngravingHeight = ySpacing - mechanismHeight - (mechanismToFirstEngravingSpacing * 2);
  }

  var textValues = _.filter(engravings[index], element => element && element !== '');

  //HINT reverse values if there are multiple lines of engravings above mech to match inputs in popup
  if (engravingsAreAbove && (availableEngravingHeight > engravingHeight)) _.reverse(textValues);

  if (ledIndicatorIsEnabled) {
    textValues = [textValues[0]];

    if (ledPosition === 'above') {
      engravingsAreAbove = false;
      engravingsAreBelow = true;
    }
    else {
      engravingsAreAbove = true;
      engravingsAreBelow = false;
    }
  }

  if (!xSpacing) xSpacing = 24; //HINT some bug with single mechanism in middle of panel causes NaN

  if (engravingHeight > availableEngravingHeight && textValues.length > 1) textValues = [textValues[0]];

  const textWidths = _.map(textValues, textValue => (
    getTextSize({text: textValue, fontSize: realFontSize, fontFamily: `${fontFamily}`, fontWeight: fontFamily === 'Helvetica' ? 'normal' : 'bold'}).width - 2
  ));

  const xOffset = horizontalOffset + -_.max(textWidths) / 2 - 2;

  var netYOffset = mechanismToFirstEngravingSpacing;

  _.forEach(textValues, textValue => {
    productGraphicNodes.push(text({
      fontSize, fontFamily,
      x: horizontalOffset,
      y: (engravingsAreAbove ? -1 : 1) * (netYOffset) + (engravingsAreAbove ? 0 : textHeight + mechanismHeight),
      fontWeight: fontFamily === 'Helvetica' ? 'normal' : 'bold',
      textAnchor: 'middle',
      fill: fontColor,
      opacity: isMechanicalStyleEngraving !== 0 ? 0.5 : 1
    }, textValue));

    netYOffset += engravingToEngravingSpacing + textHeight;
  });

  if (index === 0 && textValues[0]) {
    var dimensionYValues = [0, mechanismToFirstEngravingSpacing, textHeight, ...(textValues.length === 2 ? [engravingToEngravingSpacing, textHeight] : [])];

    _.forEach(dimensionYValues, (value, index) => {
      if (index > 0) {
        dimensionYValues[index] = dimensionYValues[index - 1] + value;
      }
    });

    if (engravingsAreAbove) dimensionYValues = dimensionYValues.reverse();

    dimensionYValues = dimensionYValues.reverse();

    // productGraphicNodes.push(dimension({props: {
    //   extrudeDistance: 8,
    //   lineColor: '#282828',
    //   textColor: '#282828',
    //   positions: _.map(dimensionYValues, dimensionYValue => (
    //     {x: xOffset, y: (engravingsAreAbove ? 0 : mechanismHeight) + (engravingsAreAbove ? -1 : 1) * (dimensionYValue)}
    //   ))
    // }}));
  }

  return productGraphicNodes;
}
