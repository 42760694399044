import { PureComponent } from 'react';
import { View, TouchableOpacity, ScrollView } from 'react-native';
import { Text, TextInput, Button, Popup, DocumentTitle, confirm, withKeyEvents } from '@symbolic/rn-lib';
import { connect } from '@symbolic/redux';
import { resourceActions } from '~/redux';
import { nodeTypes } from '~/helpers/product-graphic';
import { getProductInstanceWithData } from '~/helpers/product-order-helper';

import _ from '@symbolic/lodash';
import K from '~/k';
import uuid from 'uuid';
import settingsIcon from '~/assets/settings-icon.png';
import AdminMenu from '~/components/admin-menu';
import SecondaryHeader from '~/components/secondary-header';
import ProductGraphic from '~/components/product-graphic';

class TreeNode extends PureComponent {
  state = {
    isEditingTitle: false,
    isAddingChildNode: false
  };

  handleTitlePress = () => {
    if (this.isSelected) {
      this.setState({isEditingTitle: true});
    }
    else {
      this.props.onNodePress({node: this.props.node});
    }
  };

  get isSelected() {
    var {node, activeNode} = this.props;

    return node === activeNode || this.state.isAddingChildNode;
  }

  render() {
    var {node, depth} = this.props;
    var {isAddingChildNode, isEditingTitle} = this.state;
    var {isSelected} = this;

    return (
      <View>
        <TouchableOpacity onPress={this.handleTitlePress} style={{flexDirection: 'row', paddingVertical: K.margin, paddingLeft: K.spacing * (depth) * 1.5 + K.spacing * 1.5, paddingRight: K.spacing, backgroundColor: isSelected ? 'rgba(0, 0, 0, 0.2)' : ''}}>
          {isEditingTitle ? (
            <TextInput
              style={{}}
              value={node.title}
              onBlur={() => this.setState({isEditingTitle: false})}
              onChange={({value}) => this.props.handleNodeChange({node, title: value})}
            />
          ) : (
            <Text>{node.title}</Text>
          )}
          {node.type === 'group' && (
            <TouchableOpacity onPress={() => this.setState({isAddingChildNode: true})} style={{paddingHorizontal: K.spacing}}>
              <Text>+</Text>
            </TouchableOpacity>
          )}
          {isAddingChildNode && (
            <View style={{backgroundColor: 'white', position: 'absolute', left: '0', top: 0, marginLeft: 1, width: 100, zIndex: 1}}>
              {_.map(_.filter(nodeTypes, type => _.includes(type.graphicTypes, '3d')), type => (
                <TouchableOpacity
                  key={type.key}
                  style={{paddingVertical: K.margin, paddingHorizontal: K.spacing}}
                  onPress={() => {
                    this.props.addChildNode({parentNode: node, node: {id: uuid(), type: type.key, title: `Untitled ${type.title}`, props: {}, children: []}})
                    this.setState({isAddingChildNode: false});
                  }}
                >
                  <Text>{type.title}</Text>
                </TouchableOpacity>
              ))}
            </View>
          )}
        </TouchableOpacity>
        <View>
          {_.map(node.children, (node) => (
            <TreeNode key={node.id} depth={depth + 1} {...{node}} {..._.pick(this.props, ['onNodePress', 'activeNode', 'handleNodeChange', 'addChildNode', 'deleteNode'])}/>
          ))}
        </View>
      </View>
    );
  }
}

class AdminProductGraphicPage extends PureComponent {
  state = {
    canvasSize: {width: 0, height: 0},
  };

  componentDidMount() {
    if (K.isWeb) window.addEventListener('resize', this.handleWindowResize);

    if (K.isWeb) this.handleWindowResize();
  }

  componentWillUnmount() {
    if (K.isWeb) window.removeEventListener('resize', this.handleWindowResize);
  }

  handleWindowResize = () => {
    if (this.canvasRef) {
      var {top, left} = this.canvasRef.getBoundingClientRect();

      var canvasSize = {
        width: window.innerWidth - left,
        height: window.innerHeight - top
      };

      this.setState({canvasSize});
    }
  };

  handleTitleChange = ({value}) => this.props.updateProductGraphic({id: this.props.productGraphic.id, props: {title: value}});

  getCanvasRef = (ref) => this.canvasRef = ref;

  addChildNode = ({parentNode, node}) => {
    var {productGraphic} = this.props;

    parentNode.children.push(node);

    this.props.updateProductGraphic({id: productGraphic.id, props: {nodes: [...productGraphic.nodes]}});
  };

  deleteNode = async ({node}) => {
    if (await confirm('', 'Are you sure?')) {
      var {productGraphic} = this.props;

      var {nodes} = productGraphic;

      var deleteNode = ({nodes, node}) => {
        return _.map(_.reject(nodes, node2 => node === node2), node2 => ({...node2, children: deleteNode({nodes: node2.children, node})}));
      };

      nodes = deleteNode({nodes, node});

      this.props.updateProductGraphic({id: productGraphic.id, props: {nodes}});
    }
  };

  handleNodeChange = ({node, title}) => {
    var {productGraphic} = this.props;

    node.title = title; //TODO avoid mutation

    this.props.updateProductGraphic({id: productGraphic.id, props: {nodes: [...productGraphic.nodes]}});
  };

  handleKeyDown = (event) => {
    if (event.key === 'Backspace') {
      if (this.props.productGraphic.nodes[0] === this.state.activeNode) {
        alert('Oops! We require you to keep the top level group!');
      }
      else {
        this.deleteNode({node: this.state.activeNode});
      }
    }
  };

  render() {
    var {productGraphic} = this.props;
    var {canvasSize, activeNode} = this.state;

    if (activeNode) {
      var activeNodeType = _.find(nodeTypes, {key: activeNode.type});
    }

    var productInstance = getProductInstanceWithData({productInstance: {properties: {106: {value: 96}}, productId: 78}}, {
      ..._.pick(this.props, ['productOptionsById', 'productsById', 'productRulesById', 'productPropertiesById', 'productInstancesById'])
    });

    return (
      <DocumentTitle title={`Admin: ${productGraphic.title}`}>
        <View style={{flex: 1, flexDirection: 'row'}}>
          <AdminMenu activeOrg={this.props.session.activeOrg} />
          <View style={{flex: 1}}>
            <SecondaryHeader
              title={productGraphic.title}
              titleIsEditable
              onTitleChange={this.handleTitleChange}
              subTitle={`#${productGraphic.id}`}
              belowHeaderComponent={(
                <View style={{marginTop: K.spacing * 2}}>
                  <Button
                    icon={settingsIcon}
                    style={{...K.defaultIconSize, backgroundColor: 'transparent', opacity: 0.5}}
                    onPress={() => this.setState({settingsPopupIsVisible: true})}
                  />
                </View>
              )}
            ></SecondaryHeader>
            {this.state.settingsPopupIsVisible && (
              <Popup onClose={() => this.setState({settingsPopupIsVisible: false, uploading: false, uploadWasSuccessful: false, uploadHasFailed: false})}>
                <Button
                  light
                  style={{marginTop: K.spacing}}
                  onPress={this.archive}
                  label={productGraphic.archived === 0 ? 'ARCHIVE' : 'UNARCHIVE'}
                />
              </Popup>
            )}
            <View style={{flex: 1, position: 'relative', overflow: 'hidden'}} ref={this.getCanvasRef}>
              <ScrollView style={{position: 'absolute', top: 0, left: 0, maxHeight: '80%', backgroundColor: 'white', paddingVertical: K.spacing - K.margin, margin: K.spacing, zIndex: 1}}>
                {_.map(productGraphic.nodes, node => (
                  <TreeNode
                    {...{node, activeNode}}
                    {..._.pick(this, ['handleNodeChange', 'addChildNode', 'deleteNode'])}
                    key={node.id}
                    depth={0}
                    onNodePress={({node}) => this.setState({activeNode: node})}
                  />
                ))}
              </ScrollView>
              {activeNode && (
                <View style={{position: 'absolute', bottom: 0, right: 0, padding: K.spacing, margin: K.spacing, zIndex: 1, borderRadius: K.borderRadius, backgroundColor: 'rgba(255, 255, 255, 1)'}}>
                  {_.map(activeNodeType.propsData, propData => (
                    <View key={propData.key} style={{marginBottom: K.spacing}}>
                      <Text style={{marginLeft: K.spacing, marginBottom: K.margin}}>{propData.title}</Text>
                      <TextInput style={{width: 150}} value={activeNode.props[propData.key] || ''} onChange={({value}) => {
                        activeNode.props[propData.key] = value; //TODO avoid mutation

                        this.props.updateProductGraphic({id: productGraphic.id, props: {nodes: [...productGraphic.nodes]}});
                      }}/>
                    </View>
                  ))}
                </View>
              )}
              <ProductGraphic type='3d' nodes={productGraphic.nodes} scale={1} {...{canvasSize, productInstance}}/>
            </View>
          </View>
        </View>
      </DocumentTitle>
    );
  }
}

export default connect({
  mapState: (state, ownProps) => {
    var productGraphicId = parseInt(ownProps.match.params.productGraphicId);
    var productGraphic = _.get(state.resources.productGraphics.byId, productGraphicId);

    if (!productGraphic) {
      if (ownProps.history) ownProps.history.push('/404/product-graphic');
    }

    return {
      productGraphic,
      productOptionsById: state.resources.productOptions.byId,
      productInstancesById: state.resources.productInstances.byId,
      productRulesById: state.resources.productRules.byId,
      productPropertiesById: state.resources.productProperties.byId,
      productsById: state.resources.products.byId,
    };
  },
  mapDispatch: {
    ..._.pick(resourceActions.productGraphics, ['updateProductGraphic']),
  }
})(withKeyEvents(AdminProductGraphicPage));
