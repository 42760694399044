import _ from 'lodash';
import { lamellaData, grainDirectionSymbol, unitNumberSymbol } from './madera-script-helpers';
import getMaderaUnitNumberForProductInstance from '~/helpers/madera/get-madera-unit-number-for-product-instance';

export default function getCabinetFrontGraphicNodes({deps, group, circle, rect, path, text, dimension}) {
  var {productInstance, productOrderProductInstances} = deps;
  var {properties, propertiesDataById} = productInstance;

  var notes = _.get(properties, '192.value');

  var scale = 24.5;

  var unitNumber = getMaderaUnitNumberForProductInstance({productInstance, productOrderProductInstances});

  var frontWidth = _.get(properties, '151.value', 600);
  var frontHeight = _.get(properties, '152.value', 2000);

  var orientationOption = _.get(propertiesDataById, '148.selectedProductOption');
  var orientationVerticalOptionId = 1106;
  var orientationIsVertical = orientationOption.id === orientationVerticalOptionId;

  var determinedBy = _.get(propertiesDataById, '189.selectedProductOption.id') === 1296 ? 'width' : 'quantity';

  var numberOfLamellasOption = _.get(propertiesDataById, '149.selectedProductOption');
  var lamellaQuantity = _.has(numberOfLamellasOption, 'title') ? parseInt(numberOfLamellasOption.title) : null;

  var lamellaWidthValue = parseFloat(_.get(properties, '190.value', ''));
  var lamellaAlignment = 'center';

  if (determinedBy === 'width') {
    lamellaAlignment = _.lowerCase(_.get(propertiesDataById, '191.selectedProductOption.title'));
  }

  var {lamellaLinesGraphic, lamellaWidthInfoString} = lamellaData({boundingBox: {width: frontWidth, height: frontHeight}, determinedBy, path, lamellaQuantity, lamellaWidthValue, lamellaAlignment, productInstance});

  var dimensionWidth = {
    positions: [{x: frontWidth, y: frontHeight}, {x: 0, y: frontHeight}],
    extrudeDistance: 30
  };

  var dimensionHeight = {
    positions: [{x: frontWidth, y: 0}, {x: frontWidth, y: frontHeight}],
    extrudeDistance: 30
  };

  return group({width: frontWidth, height: frontHeight, padding: 30}, [
    rect({width: frontWidth, height: frontHeight, strokeWidth: 1, stroke: '#000', rx: 0, ry: 0, fill: '#FFF'}),
    ...lamellaLinesGraphic,
    // dimension({props: dimensionWidth}),
    // dimension({props: dimensionHeight}),
    // unitNumberSymbol({scale, group, circle, text, unitNumber}),
    group({y: frontHeight + 6 * scale}, [
      unitNumberSymbol({scale, group, circle, text, unitNumber}),
      text({fontSize: 7, x: 0 * scale, y: 0, width: (frontWidth + 30 > 50 * scale ? frontWidth + 30 : 50 * scale), fill: '#999'},
        `Lamella Width: ${lamellaWidthInfoString}
        ${notes ? `\nNotes: ${notes}` : ''}`
      ),
    ]),
    //TODO: add veneer and thickness to note - reference doors
    grainDirectionSymbol({grainDirectionIsVertical: orientationIsVertical, boundingBox: {width: frontWidth, height: frontHeight}}, {group, path}), //HINT should come last so it is drawn on top
  ]);
}
