import CanvasDataContext from '~/contexts/canvas-data-context';
import lib from '~/lib';
import K from '~/k';

import { useEffect, useState, useRef } from 'react';
import { ReactReduxContext, Provider } from 'react-redux';
import { Stage, Layer } from 'react-konva';

var spaceIsBeingPressed = false;

export default function CanvasView({
  scale: initialScale = 1, getOffset, cameraXZAngle, cameraYAngle, canvasSize, children,
  onCanvasClick, isStatic, cursor, updateOffsetId = 0, stageRef, layerRef, dimensionOptions,
  ...props
}) {
  var [isShifting, setIsShifting] = useState(false);
  var [offset, setOffset] = useState({x: 0, y: 0});
  var [scale, setScale] = useState(initialScale);
  var [isDragging, setIsDragging] = useState(false);
  var [lastMousePosition, setLastMousePosition] = useState(undefined);

  layerRef = layerRef || useRef();
  stageRef = stageRef || useRef();

  var offsetRef = useRef();

  var canvasData = {scale, cameraXZAngle, cameraYAngle, canvasSize, isShifting, dimensionOptions, precision: props.precision || K.minPrecision, layer: layerRef.current, textDimsScale: props.textDimsScale || 1};

  var updateOffset = () => setOffset(offsetRef.current = getOffset({canvasData: {...canvasData, offset: {x: 0, y: 0}}}));

  if (!offsetRef.current) updateOffset();

  useEffect(() => updateOffset(), [updateOffsetId]);

  canvasData.offset = offset || offsetRef.current;
  canvasData.getSnapData = props.getSnapData;

  //HINT offset is a positive value that is a pre-scaled/in-canvas value
  //HINT offset is a positive value that is subtracted
  //HINT both of these are holdovers from DEv1 that weren't worth refactoring a bunch of existing code to change to make more intuitive (ideally offset would be negative and not scaled)
  var handleWheel = ({evt: event}) => {
    event.preventDefault();

    // SCALE
    var deltaY = event.deltaY;
    var magnitude = Math.abs(deltaY) * 0.001;
    var scaleDelta = deltaY < 0 ? ((1 + magnitude)) : (1 / (1 + magnitude));

    var maxScale = 1 / 8 / 8 / 8;
    var minScale = 8 * 16;

    if (props.activeOrgId === 1053) {
      maxScale = 1 / 32;
      minScale = 4;
    }

    var newScale = Math.min(Math.max(scale * scaleDelta, maxScale), minScale);

    scaleDelta = newScale / scale; //correct scale delta for constrained scale

    //don't do anything if scale hasn't changed
    if (scaleDelta !== 1) {
      // OFFSET
      //basic offset correction for scaling regardless of mouse pos
      //disable mouse logic and set offset to something like x: 250 in state to test
      var basicOffsetDelta = lib.object.difference(lib.object.multiply(offset, scaleDelta), offset);

      var mousePosOffsetPercentages = {
        x: event.layerX / canvasSize.width - 0.5,
        y: event.layerY / canvasSize.height - 0.5
      }; //HINT -0.5 to 0.5

      //offset relative to mouse
      var mouseOffsetDelta = _.mapValues(mousePosOffsetPercentages, (percentage, axisKey) => {
        return percentage * canvasSize[axisKey === 'x' ? 'width' : 'height'] * (scaleDelta - 1);
      });

      //offset accounting for both
      var offsetDelta = lib.object.sum(basicOffsetDelta, mouseOffsetDelta);

      var newOffset = lib.object.sum(offset, offsetDelta);

      setOffset(newOffset);
      setScale(newScale);

      if (props.setPrecision && !props.precisionPickerIsLocked) {
        props.setPrecision({precision: 1 / lib.number.nearestFactors({of: 2, from: newScale}).lower});
      }
    }
  };

  function handleMouseDown(event) {
    if ((event.evt.button === 1 || spaceIsBeingPressed) && !isDragging) {
      const container = event.target.getStage().container();

      container.style.cursor = 'grabbing';

      setIsDragging(true);
      setLastMousePosition(event.target.getStage().getPointerPosition());
    }
    //HINT deselect when clicked on empty area
    else if (onCanvasClick && event.evt.button === 0 && event.target === event.target.getStage()/* && !this.state.inAddMode*/) {
      onCanvasClick();
    }
  }

  function handleMouseUp(event) {
    if (isDragging) {
      const container = event.target.getStage().container();

      container.style.cursor = 'default';

      setIsDragging(false);
      setLastMousePosition(undefined);
    }
  }

  function handleMouseMove(event) {
    if (isDragging) {
      const transform = lib.object.difference(event.target.getStage().getPointerPosition(), lastMousePosition);
      const newOffset = lib.object.difference(offset, transform);

      setOffset(newOffset);
      setLastMousePosition(event.target.getStage().getPointerPosition());
    }
  }

  useEffect(() => {
    if (stageRef.current) {
      stageRef.current.container().style.cursor = cursor;
    }
  }, [cursor]);

  useEffect(() => {
    var handleKeyDown = (event) => {
      if (event.shiftKey) setIsShifting(true);

      if (event.key === ' ') {
        spaceIsBeingPressed = true;
      }
    };

    var handleKeyUp = (event) => {
      if (!event.shiftKey && isShifting) setIsShifting(false);

      if (event.key === ' ') {
        spaceIsBeingPressed = false;
      }
    };

    document.addEventListener('keydown', handleKeyDown);
    document.addEventListener('keyup', handleKeyUp);

    return () => {
      document.removeEventListener('keydown', handleKeyDown);
      document.removeEventListener('keyup', handleKeyUp);
    };
  });

  return (
    // <Svg width={canvasSize.width} height={canvasSize.height} onClick={(event) => event.target.tagName === 'svg' && onCanvasClick()}>
    <ReactReduxContext.Consumer>
      {({store}) => (
        <Stage
          onWheel={!isStatic ? handleWheel : undefined}
          onMouseDown={!isStatic ? handleMouseDown : undefined}
          onMouseUp={!isStatic ? handleMouseUp : undefined}
          onMouseMove={!isStatic ? handleMouseMove : undefined}
          {...canvasSize}
          ref={stageRef}
        >
          <Provider store={store}>
            <Layer ref={layerRef}>
              <CanvasDataContext.Provider value={canvasData}>
                {children}
              </CanvasDataContext.Provider>
            </Layer>
          </Provider>
        </Stage>
      )}
    </ReactReduxContext.Consumer>
  );
}
