//move a point in a direction by a certain amount
function translatePoint(point, {by, alpha}) {
  return {
    x: point.x + Math.cos(alpha) * by,
    y: point.y + Math.sin(alpha) * by
  };
}

//grow a line by a certain size on both sides or only one side
function extendLine(line, {by = 1000000, rangeKey}) {
  var {from, to} = line;
  var alpha = Math.atan2(to.y - from.y, to.x - from.x);

  var fromBy = rangeKey !== 'to' ? by : 0;
  var toBy = rangeKey !== 'from' ? by : 0;

  return {
    from: {x: from.x - fromBy * Math.cos(alpha), y: from.y - fromBy * Math.sin(alpha)},
    to: {x: to.x + toBy * Math.cos(alpha), y: to.y + toBy * Math.sin(alpha)}
  };
}

//get the distance between two points
function getLineLength({from: p1, to: p2}) {
  return Math.sqrt(Math.pow(p1.x - p2.x, 2) + Math.pow(p1.y - p2.y, 2));
}

//get the angle of a line/two points
function getLineAlpha({from: p1, to: p2}, {perpendicular = 0} = {}) {
  var x1 = p1.x, y1 = p1.y, x2 = p2.x, y2 = p2.y;

  var delta = {x: x2 - x1, y: y2 - y1};
  var alpha = Math.atan2(delta.y, delta.x);

  if (perpendicular) alpha += ((Math.PI / 2) * perpendicular);

  return alpha;
}

//rotate a point around the origin (0, 0), or a custom origin
function rotatePoint({x, y}, {alpha, origin}) {
  var s = Math.sin(alpha);
  var c = Math.cos(alpha);

  // translate point back to origin:
  if (origin) {
    x -= origin.x;
    y -= origin.y;
  }

  // rotate point
  var xnew = x * c - y * s;
  var ynew = x * s + y * c;

  // translate point back:
  if (origin) {
    xnew = xnew + origin.x;
    xnew = ynew + origin.y;
  }

  return {x: xnew, y: ynew};
}

//get a point between two points
function getMidpoint({from: p1, to: p2}) {
  return {x: (p1.x + p2.x) / 2, y: (p1.y + p2.y) / 2};
}

//convert radians to degrees
function toDegrees(radians) {
  return (radians * 180) / Math.PI;
}

//convert radians to degrees
function toRadians(radians) {
  return (radians / 180) * Math.PI;
}

export { translatePoint, extendLine, getLineLength, getLineAlpha, rotatePoint, getMidpoint, toDegrees, toRadians };
