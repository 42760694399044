import { PureComponent } from 'react';
import { View, Image, TouchableOpacity } from 'react-native';
import { TextInput, Text, Tooltip } from '@symbolic/rn-lib';

import { connect } from '@symbolic/redux';
import { resourceActions } from '~/redux';

import closeIcon from '~/assets/x-icon.png';

import K from '~/k';
import _ from 'lodash';

class UserGroupView extends PureComponent {
  constructor(props) {
    super(props);

    this.handleSearchInputChange = _.debounce(this.handleSearchInputChange, 100);
  }

  state = {
    searchInput: '',
    isSearching: false,
    isAddingUserToUserGroup: false
  };

  componentDidMount() {
    document.addEventListener('mousedown', this.handleDocumentClick);
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleDocumentClick);
  }

  handleSearchInputChange = ({value}) => {
    this.setState({searchInput: value});
  };

  handleAddUserToUserGroup = async ({guest}) => {
    var {userGroup} = this.props;

    var userIds = _.uniq(_.concat(_.get(userGroup, 'userIds', []), guest.id));

    await this.props.updateUserGroup({id: userGroup.id, props: {userIds}});
  };

  handleRemoveUserFromGroup = ({guest}) => {
    var {userGroup} = this.props;

    var userIds = _.without(_.get(userGroup, 'userIds', []), guest.id);

    this.props.updateUserGroup({id: this.props.userGroup.id, props: {userIds}});
  };

  render () {
    var {searchInput, isAddingUserToUserGroup} = this.state;
    var {userGroup, guests} = this.props;

    return (
      <View>
        <View>
          <TextInput
            placeholder={'search by email'}
            value={searchInput}
            style={{marginTop: K.margin, ...(isAddingUserToUserGroup ? {borderBottomRightRadius: 0, borderBottomLeftRadius: 0} : {})}}
            onInput={({value}) => this.handleSearchInputChange({value})}
            onFocus={() => this.setState({isAddingUserToUserGroup: true})}
            onBlur={() => setTimeout(() => this.setState({isAddingUserToUserGroup: false, searchInput: ''}), 400)}
          />
          {isAddingUserToUserGroup && (
            <View
              ref={ref => this.addingAssociationsRef = ref}
              style={{flexDirection: 'column', width: '100%', maxHeight: 150, ...K.fonts.standard, flexShrink: 1, ...K.shadow, position: 'absolute', zIndex: 1, backgroundColor: 'white', top: '100%', borderBottomRightRadius: K.borderRadius, borderBottomLeftRadius: K, overflow: 'auto'}}
            >
              {_.map(guests, guest => {
                if (!_.includes(userGroup.userIds, guest.id) && _.includes(guest.email, this.state.searchInput)) {
                  return (
                    <TouchableOpacity
                      key={guest.id}
                      dataSet={{'hover-background-double-gray': 1}}
                      onPress={() => this.handleAddUserToUserGroup({guest})}
                      style={{paddingVertical: K.margin, paddingHorizontal: K.spacing}}
                    >
                      <Text>{guest.email}</Text>
                    </TouchableOpacity>
                  );
                }
              })}
            </View>
          )}
        </View>
        <View style={{marginTop: K.margin}}>
          {_.map(userGroup.userIds, (id) => {
            var guest = _.find(this.props.guests, {id});

            return (
              <View
                key={id}
                dataSet={{'hover-background-double-gray': 1}}
                style={{flexDirection: 'row', justifyContent: 'space-between', paddingHorizontal: K.spacing, paddingVertical: K.margin, marginBottom: K.margin, borderRadius: K.borderRadius, backgroundColor: K.colors.gray}}
              >
                <Text>{_.get(guest, 'email')}</Text>
                <Tooltip text='Remove user from group'>
                  <TouchableOpacity onPress={() => this.handleRemoveUserFromGroup({guest})}>
                    <Image source={closeIcon} style={{...K.defaultIconSize}}/>
                  </TouchableOpacity>
                </Tooltip>
              </View>);
          })}
        </View>
      </View>
    );
  }
}

export default connect({
  mapState: (state) => {
    var guests = _.get(state, 'resources.guests.byId');

    return {
      guests,
    };
  },
  mapDispatch: {
    ..._.pick(resourceActions.userGroups, ['updateUserGroup'])
  },
})(UserGroupView);
