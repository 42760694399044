import React, { useContext } from 'react';

import _ from 'lodash';
import lib from '~/lib';
import CanvasDimensionLine from './canvas-dimension-line';
import CanvasDataContext from '~/contexts/canvas-data-context';

class CanvasDimensionGroup extends React.PureComponent {
  constructor(props) {
    super(props);

    this.canvasObjects = [];
    this.oldCanvasObjectsData = {};

    this.shouldLayout = true;
  }

  //TODO replace canvasPosition/absolute

  get canvasObjectsData() {
    var objectsData = [];
    var {shouldGroup = true, shouldFilter = true, thresholdPadding = 0} = this.props;
    var lines = this.props.lines;

    if (lines.length > 0) {
      var bottomLeftmost = positions => ({x: _.min(_.map(positions, 'x')), y: _.max(_.map(positions, 'y'))});
      var bottomLeftmostPosition = bottomLeftmost(_.flatMap(lines, _.values));

      var linesData = _.map(lines, ({from, to, ...lineData}) => {
        var line = {from, to};
        var alpha = lib.trig.alpha({p1: line.from, p2: line.to});
        var distance = lib.trig.distance({fromPoint: line.from, toPoint: line.to});

        var bottomLeftDistance = lib.trig.distance({
          fromPoint: bottomLeftmostPosition,
          toPoint: bottomLeftmost([line.from, line.to])
        });

        //normalized line generally used for intersection/overlap detection
        var normalizedAlpha = lib.trig.normalize({radians: alpha});

        if (normalizedAlpha >= Math.PI) normalizedAlpha -= Math.PI;

        var normalizedLine = _.mapValues(line, point => lib.trig.rotate({point, byRadians: normalizedAlpha}));

        return {line, alpha, normalizedLine, normalizedAlpha, bottomLeftDistance, distance, ...lineData};
      });

      //Not inclusive for other lines, but inclusive for same line - generally what you care about
      var normalizedLinesOverlap = ({l1, l2}) => {
        var r1 = _.mapValues(l1, 'x'), r2 = _.mapValues(l2, 'x');

        //Consider each point in the context of each other range
        var subsetExists =_.some([[r1.from, r2], [r1.to, r2], [r2.from, r1], [r2.to, r1]], ([value, range]) => {
          var [from, to] = _.sortBy(range, v => v);

          return value > from && value < to;
        });

        //Both points are the same, regardless of order
        var sameLine = _.every(r1, p1 => _.includes(_.values(r2), p1));

        return subsetExists || sameLine;
      };

      linesData = lib.waterfall(linesData, [
        [_.map, lineData => {
          var l1 = lineData;
          var {alpha, line} = lineData;

          //intersections used for which side is "outside"
          var intersections = _.map([-1, 1], factor => {
            var candidates = _.filter(linesData, l2 => {
              var y1 = l1.normalizedLine.from.y, y2 = l2.normalizedLine.from.y;
              var parallel = l1.normalizedAlpha === l2.normalizedAlpha;
              var onRelevantSide = factor === -1 ? y2 < y1 : y1 < y2;

              return !l2.isStandalone && !l2.alwaysOutside && l1.groupId === l2.groupId && parallel && onRelevantSide;
            });

            var intersectionCount = _.filter(candidates, l2 => {
              return normalizedLinesOverlap({l1: l1.normalizedLine, l2: l2.normalizedLine});
            }).length;

            //WARNING alpha seems like it should be dependant on factor - unclear why it's working without that
            return {perpendicularAlpha: alpha - Math.PI / 2, intersectionCount, factor};
          });

          var {perpendicularAlpha, intersectionCount} = _.minBy(intersections, 'intersectionCount');

          var perpendicularEndpointLines = _.map(line, point => {
            return lib.trig.extendedLineFrom({point, alpha: alpha + Math.PI / 2});
          });

          return {
            perpendicularAlpha, intersectionCount, perpendicularEndpointLines, ...lineData
          };
        }],

        //sort by intersectionCount so outermost lines are kept first
        [_.sortBy, [
          'intersectionCount',
          lineData => (lineData.intersectionCount === 0 ? 1 : -1) * lineData.bottomLeftDistance
        ]],

        //filter out redundant lines (lines that measure the same positions along same axis)
        [_.filter, (l1, l, linesData) => {
          if (!shouldFilter || (l1.isStandalone && !l1.groupId) || l1.noFilter) return true;

          var consolidateStandalone = l1.isStandalone && l1.groupId;
          var candidates = _.filter(linesData, l2 => l1 !== l2 && l2.isOriginal && l1.normalizedAlpha === l2.normalizedAlpha);

          //WARNING need to mutate since we identify as we go
          l1.isOriginal = !_.some(candidates, l2 => {
            var {normalizedLine: nl1} = l1, {normalizedLine: nl2} = l2;
            var sameNormalizedRange = _.every(nl1, p1 => _.includes(_.map(nl2, 'x'), p1.x));

            //consider lines original within a forced group
            var sameGroup = l1.groupId === l2.groupId;

            return sameNormalizedRange && (!consolidateStandalone || sameGroup);
          });

          return l1.isOriginal;
        }]
      ]);

      //TODO filter out lines that have parallel vertices (perpendicularExtended alpha intersects with points)

      var lineGroups = [];

      _.forEach(linesData, l1 => {
        var _shouldGroup = shouldGroup && !_.get(l1, 'isStandalone', false);

        var lineGroup = _shouldGroup && _.find(lineGroups, ({linesData}) => {
          var sameKeys = ['perpendicularAlpha', 'intersectionCount', 'groupId'];
          var sameAxisAndSide = _.isEqual(_.pick(l1, sameKeys), _.pick(linesData[0], sameKeys));

          //for each line in group
          var noOverlappingLines = !_.some(linesData, l2 => {
            //for each comparison - WARNING necessary to check both if one is superset line
            return !l2.alwaysOutside && !l1.alwaysOutside && !l1.allowOverlapping && !l2.allowOverlapping && _.some([[l1, l2], [l2, l1]], ([l1, l2]) => {
              //see if perpendicular line at either end point intersects with other line
              return _.some(l1.perpendicularEndpointLines, perpendicularLine => {
                return lib.math.linesIntersect({l1: perpendicularLine, l2: l2.line});
              });
            });
          });

          var distance = lib.trig.distance({fromPoint: l1.line.from, toLine: lib.trig.extend({line: linesData[0].line})});
          var isNotTooFar = (distance < 80 || (l1.alwaysOutside && _.some(linesData, 'alwaysOutside'))); // && _.some()));

          var linesAreOutsideOrInline = true;

          if (linesData[0].intersectionCount > 0) {
            linesAreOutsideOrInline = l1.normalizedLine.from.y === linesData[0].normalizedLine.from.y;
          }

          var existingGroupMatches = !linesData[0].isStandalone && sameAxisAndSide && noOverlappingLines && linesAreOutsideOrInline && isNotTooFar;

          return existingGroupMatches;
        });

        if (!lineGroup) {
          lineGroup = {linesData: []};

          lineGroups.push(lineGroup);
        }

        lineGroup.linesData.push(l1);
      });

      _.forEach(lineGroups, ({linesData}) => {
        var {perpendicularAlpha, intersectionCount, isStandalone = false} = linesData[0];
        var positions = [];

        _.forEach(linesData, ({line, onChange}) => {
          positions.push(..._.map(line, pointOnLine => ({...lib.object.sum(pointOnLine, this.props.offset), onChange})));
        });

        var lineData = linesData[0];

        var props = {
          ..._.pick(lineData, ['tinyFont', 'hideSlashes', 'formatLabel']),
          positions,
          angle: lib.trig.radiansToDegrees(linesData[0].alpha),
          summarize: _.some(linesData, 'summarize'),
          isEnabled: true,
          ...this.props.dimensionProps
        };

        if (!isStandalone) {
          props.transformData = {
            perpendicularAlpha,
            pushOutside: intersectionCount === 0,
            fixedTransform: linesData[0].fixedTransform || (intersectionCount === 0 ? 20 + thresholdPadding : 5)
          };
        }
        else {
          props.transformData = {perpendicularAlpha, pushOutside: false, useSquareTicks: linesData[0].useSquareTicks, fixedTransform: 5};
        }

        objectsData.push({props});
      });
    }

    return objectsData;
  }

  render() {
    var {canvasObjectsData} = this;
    var {canvasData, elevation, room, parentOrigin} = this.props;

    return (
      _.map(canvasObjectsData, ({props}, d) => (
        <CanvasDimensionLine
          key={d}
          {...props}
          {...{canvasData, elevation, room, parentOrigin}}
        />
      ))
    );
  }
}

export default function CanvasDimensionGroupWithContext(props) {
  let canvasData = useContext(CanvasDataContext);

  return <CanvasDimensionGroup {...props} {...{canvasData}}/>
};


// if (!scrolling) {
//   var {oldCanvasObjectsData} = this;
//   var newCanvasObjectsData = this.canvasObjectsData;
//   var tempOldCanvasObjectsData = {...oldCanvasObjectsData};

//   //WARNING substantial optimization caching canvasobjects with same props - be careful to retain approach
//   _.forEach(newCanvasObjectsData, newData => {
//     var key = JSON.stringify(newData.diff);
//     var oldData = tempOldCanvasObjectsData[key];

//     if (!oldData) {
//       var object = new DimensionsCanvasObject2(newData.props);

//       object.addToCanvasView(this.canvasView);

//       this.canvasObjects.push(object);

//       oldCanvasObjectsData[key] = {...newData, object};
//     }
//     else {
//       delete tempOldCanvasObjectsData[key];
//     }
//   });

//   _.forEach(tempOldCanvasObjectsData, ({object}, key) => {
//     delete oldCanvasObjectsData[key];

//     object.removeFromCanvasView();

//     _.pull(this.canvasObjects, object);
//   });
// }

// _.invokeMap(this.canvasObjects, 'layout');
// }

// if (this.props.staticallyGetLines) this.shouldLayout = false;
