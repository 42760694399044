import { useContext, useEffect } from 'react';
import { extendLine, getLineAlpha, getLineLength, translatePoint } from '~/helpers/trig/trig-helpers';
import { toXYLine, ArchWall } from '~/helpers/arch/arch-helpers';
import { connect } from '@symbolic/redux';
import { resourceActions } from '~/redux';

import lib from '~/lib';

import CanvasCube from '~/components/canvas/cube/canvas-cube';
import PathTool from '~/components/canvas/tools/path/path-tool';
import CanvasDataContext from '~/contexts/canvas-data-context';
import ArchDataContext from '~/contexts/arch-data-context';

var selectedColor = '#ADC6EB';

function CanvasArchWall({archWall, archWalls, ...props}) {
  var canvasData = useContext(CanvasDataContext);
  var archData = useContext(ArchDataContext);

  var isSelected = archData.selectedEntityId === archWall.id && archData.selectedEntityResourceKey === 'archWall';

  var adjacentArchWalls = ArchWall.getAdjacentArchWalls({archWall, archWalls});

  var viewKey = canvasData.cameraYAngle === 0 ? 'top' : 'front';

  var {from, to} = archWall;

  function getThetaBetweenWalls(wall1, wall2) {
    var alpha1 = getLineAlpha(toXYLine(wall1));
    var alpha2 = getLineAlpha(toXYLine(wall2));

    return lib.trig.normalize({radians: alpha1 - alpha2});
  }

  if (from && to) {
    from = {x: from.x, y: from.z};
    to = {x: to.x, y: to.z};

    if (viewKey === 'top') {
      if (adjacentArchWalls.from && getThetaBetweenWalls(adjacentArchWalls.from, archWall) >= Math.PI) ({from} = extendLine({from, to}, {by: adjacentArchWalls.from.thickness, rangeKey: 'from'}));
      if (adjacentArchWalls.to && getThetaBetweenWalls(archWall, adjacentArchWalls.to) >= Math.PI) ({to} = extendLine({from, to}, {by: adjacentArchWalls.to.thickness, rangeKey: 'to'}));
    }

    var minX = from.x;
    var minZ = from.y;

    from = {x: from.x - minX, y: from.y - minZ};
    to = {x: to.x - minX, y: to.y - minZ};

    var alpha = getLineAlpha({from, to});

    var {x: offsetX, y: offsetZ} = translatePoint({x: 0, y: 0}, {by: 0, alpha: -alpha});
  }

  var points = [];

  if (archWall.from) points.push({x: archWall.from.x, y: archWall.from.z});
  if (archWall.to) points.push({x: archWall.to.x, y: archWall.to.z});

  useEffect(() => {
    var handleDestroyArchWall = (event) => {
      if (event.code === 'Backspace' && isSelected) {
        props.destroyArchWall({id: archWall.id});

        archData.setSelectedEntity(undefined, undefined);
      }
    };

    document.addEventListener('keydown', handleDestroyArchWall);

    return () => {
      document.removeEventListener('keydown', handleDestroyArchWall);
    };
  });

  return (<>
    {from && to && (
      <CanvasCube
        fill={isSelected ? selectedColor : (canvasData.cameraYAngle > 0 ? '#000' : '#000')}
        stroke={viewKey === 'front' ? 'black' : undefined}
        strokeWidth={viewKey === 'front' ? 1 : undefined}
        position={{x: minX + offsetX, y: 0, z: minZ + offsetZ}}
        dimensions={{width: getLineLength({from, to}), height: archWall.height, depth: -archWall.thickness}}
        rotation={{xz: alpha}}
        hitStrokeWidth={5}
        onMouseDown={() => archData.setSelectedEntity(archWall.id, 'archWall')} //TODO only plan
      />
    )}
    {(isSelected || (!archWall.from || !archWall.to)) && (
      <PathTool
        isInAddMode={!archWall.to}
        isSelected
        hideLine
        points={points}
        onAddModeMouseMove={({point}) => {
          if (archWall.from) {
            archData.updateTentativeArchWall({tentativeArchWall: {...archWall, addModePoint: {x: point.x, z: point.y}}});
          }
        }}
        onTransform={({points, transformEnd}) => {
          var from, to;

          if (points[0]) from = {x: points[0].x, z: points[0].y};
          if (points[1]) to = {x: points[1].x, z: points[1].y};

          if (!archWall.to) {
            archData.updateTentativeArchWall({tentativeArchWall: {...archWall, from, to}});
          }
          else {
            props.updateArchWall({id: archWall.id, props: {from, to}, hitApi: !!transformEnd});
          }
        }}
      />
    )}
  </>);
}

export default connect({
  mapDispatch: {
    ..._.pick(resourceActions.archWalls, ['updateArchWall', 'destroyArchWall'])
  }
})(CanvasArchWall);
