import { PureComponent } from 'react';
import { Image, View, TouchableOpacity, ScrollView } from 'react-native';
import { DocumentTitle, Text, TextInput, Button, LabelledView, CheckboxInput, Tooltip, Label } from '@symbolic/rn-lib';

import _ from '@symbolic/lodash';

import { connect } from '@symbolic/redux';
import { resourceActions } from '~/redux';
import { api } from '@symbolic/lib';

import K from '~/k';
import moment from 'moment';

import AdminMenu from '~/components/admin-menu';
import SecondaryHeader from '~/components/secondary-header';
import NumberInput from '~/components/number-input';
import { PickerInput } from '@symbolic/rn-lib';

import closeIcon from '~/assets/x-icon.png';

class GuestRow extends PureComponent {
  render() {
    var {guest, user, activeOrgId} = this.props;

    var salesTaxIsDisabled = _.get(guest, 'appData.designEngine.disableSalesTax', false);
    var pricingIsHidden = _.get(guest.orgAccessEdgeData, 'designEngine.pricingIsHidden');
    var dateAdded = moment(_.get(guest, 'created')) || moment();

    var guestCellStyle = {paddingHorizontal: K.spacing, overflowWrap: 'anywhere', cursor: 'default'};

    return (
      <View
        dataSet={{'hover-background-double-gray': 1}}
        style={{flexDirection: 'row', justifyContent: 'space-between', backgroundColor: K.colors.gray, marginBottom: K.margin, paddingVertical: K.spacing / 2, borderRadius: K.borderRadius}}
      >
        <Text style={{...guestCellStyle, flex: 2}}>{_.get(guest, 'email', '-')}</Text>
        <Text style={{...guestCellStyle, flex: 1}}>{_.get(guest, 'firstName', '')}</Text>
        <Text style={{...guestCellStyle, flex: 1}}>{_.get(guest, 'lastName', '')}</Text>
        {/* <Text style={{...guestCellStyle, flex: 1, opacity: 0.5}}>{this.getDiscountStringFor({guest})}</Text> */}
        <View style={{...guestCellStyle, flex: 1, opacity: 0.5}}>
          <NumberInput
            style={{backgroundColor: 'transparent', height: K.calcFont(16), paddingHorizontal: 0, borderRadius: 'none'}}
            onChange={({value}) => this.props.handleDataChangeForGuest({key: 'discount', value: {discountType: 'percent', discountValue: value}, guest})}
            value={_.get(guest, 'appData.designEngine.discount.value', 0)}
          />
        </View>
        <View style={{...guestCellStyle, flex: 1, opacity: 0.5}}>
          <PickerInput
            style={{padding: 0, borderRadius: 'none'}}
            textStyle={{cursor: 'pointer', paddingHorizontal: 0, minHeight: 0, height: K.calcFont(16), borderRadius: 'none', backgroundColor: 'transparent', ...(salesTaxIsDisabled === true && {color: 'red'})}}
            options={[
              {title: 'Enabled', value: false},
              {title: 'Disabled', value: true}
            ]}
            value={salesTaxIsDisabled}
            onChange={({value}) => this.props.handleDataChangeForGuest({key: 'disableSalesTax', value, guest})}
          />
        </View>
        <Text style={{...guestCellStyle, flex: 1, opacity: 0.5}}>{dateAdded.format('M/D/YYYY')}</Text>
        <View style={{flexDirection: 'row', justifyContent: 'flex-end', paddingHorizontal: K.spacing, flex: 1}}>
          <Tooltip text='Hide pricing'>
            <TouchableOpacity
              style={{marginHorizontal: K.spacing, opacity: pricingIsHidden === 1 ? 0.2 : 1}}
              onPress={() => this.props.hidePricingForGuest({pricingIsHidden, guest})}
            >
              <Text>$</Text>
            </TouchableOpacity>
          </Tooltip>
          <Tooltip text='Remove guest'>
            <TouchableOpacity
              onPress={() => this.props.disinviteGuest({userId: user.id, invitedUser: guest, orgId: activeOrgId})}
            >
              <Image source={closeIcon} style={{...K.defaultIconSize}}/>
            </TouchableOpacity>
          </Tooltip>
        </View>
      </View>
    );
  }
}

class AdminGuestsPage extends PureComponent {
  state = {
    isPickingActiveOrg: false,
    isManageGuestsPopupVisible: false,
    invitedGuestEmail: '',
  };

  handleGuestInputChange = ({value}) => this.setState({invitedGuestEmail: value});

  inviteGuest = async ({email, activeOrgId}) => {
    const {data: {guest}} = await api.request({uri: '/orgs/invite-guest', body: {email, activeOrgId, pricingIsHidden: this.state.pricingIsHiddenToInvitee}});

    this.props.trackGuests({guests: [guest, ..._.map(this.props.guests, item => item)], reset: true});
  };

  disinviteGuest = async ({userId, invitedUser, orgId}) => {
    var {userGroups, guests} = this.props;

    if (await confirm('Are you sure you want to disinvite this guest?', '')) {
      await api.request({uri: '/orgs/disinvite-guest', body: {userId, invitedUser, orgId}});

      await _.forEach(userGroups, (userGroup) => {
        var userIds = _.remove(userGroup.userIds, id => id !== userId);

        this.props.updateUserGroup({id: userGroup.id, props: {userIds}});
      });

      var guests = _.cloneDeep(guests);

      guests = _.filter(guests, (guest) => guest.id !== invitedUser.id);

      this.props.trackGuests({guests, reset: true});
    }
  };

  hidePricingForGuest = async ({pricingIsHidden, guest}) => {
    pricingIsHidden = pricingIsHidden ? 0 : 1;

    await api.request({uri: '/orgs/update-guest-data', body: {orgId: this.props.session.activeOrg.id, userId: guest.id, pricingIsHidden}});

    this.props.updateGuest({id: guest.id, props: {
      orgAccessEdgeData: {...guest.orgAccessEdgeData, designEngine: {..._.get(guest.orgAccessEdgeData, 'designEngine'), pricingIsHidden}}
    }, hitApi: false});
  };

  handleDataChangeForGuest = async ({key, value, guest}) => {
    var orgId = this.props.session.activeOrg.id;
    var appData = _.cloneDeep(guest.appData);

    if (key === 'discount') {
      var {discountType, discountValue} = value;

      _.set(appData, 'designEngine.discount', {type: discountType, value: discountValue});
    }
    else if (key === 'disableSalesTax') {
      _.set(appData, 'designEngine.disableSalesTax', value === 'true');
    }

    await api.request({uri: '/orgs/update-guest-data', body: {orgId, userId: guest.id, updateProps: {appData}}});

    var mondayItemIdForGuest = _.get(appData, 'designEngine.integrationsData.monday.itemId');

    if (mondayItemIdForGuest && orgId === 850) {
      await api.request({uri: '/configurator/push-user-update-to-monday', body: {
        userId: guest.id, orgId, mondayItemId: mondayItemIdForGuest,
      }});
    }

    this.props.updateGuest({id: guest.id, props: {appData}, hitApi: false});
  };

  render() {
    var {activeOrgId, guests, session: {user}} = this.props;

    return (
      <DocumentTitle title={'Admin: Guests'}>
        <View style={{flex: 1, flexDirection: 'row'}}>
          <AdminMenu activeOrg={this.props.session.activeOrg} />
          <View style={{flex: 1}}>
            <SecondaryHeader title='Guests' />
            <ScrollView style={{flex: 1}} contentContainerStyle={{padding: K.spacing, marginBottom: K.spacing * 8}}>
              <View style={{marginBottom: K.spacing, width: 500}}>
                <LabelledView gray label={'Guest Email'} styles={{outerView: {marginBottom: K.margin}}}>
                  <TextInput
                    blurOnSubmit
                    standardAutoheightStyles
                    autoFocus
                    value={this.state.invitedGuestEmail}
                    onChange={this.handleGuestInputChange}
                    labelledViewStyles={{outerView: {marginBottom: K.margin}}}
                  />
                </LabelledView>
                <CheckboxInput
                  label='Hide pricing'
                  value={this.state.pricingIsHiddenToInvitee}
                  onChange={({value}) => this.setState({pricingIsHiddenToInvitee: value})}
                />
                <Button
                  dark
                  label={'Invite Guest'}
                  style={{marginTop: K.margin}}
                  onPress={() => this.inviteGuest({email: this.state.invitedGuestEmail, activeOrgId})}
                />
              </View>
              <View style={{flexDirection: 'row'}}>
                {_.map([
                  {title: 'Email', style: {flex: 2}},
                  {title: 'First Name', style: {flex: 1}},
                  {title: 'Last Name', style: {flex: 1}},
                  {title: 'Discount (%)', style: {flex: 1}},
                  {title: 'Sales Tax', style: {flex: 1}},
                  {title: 'Date Added', style: {flex: 1}},
                  {title: '', style: {flex: 1}}
                ], ({title, style}, index) => (
                  <View key={index}
                    style={{...style, padding: K.spacing}}
                  >
                    <Label>{title}</Label>
                  </View>
                ))}
              </View>
              {_.map(_.orderBy(guests, 'id', 'desc'), (guest) =>
                <GuestRow
                  key={guest.id}
                  disinviteGuest={this.disinviteGuest}
                  hidePricingForGuest={this.hidePricingForGuest}
                  handleDataChangeForGuest={this.handleDataChangeForGuest}
                  {...{guest, user, activeOrgId}}
                />
              )}
            </ScrollView>
          </View>
        </View>
      </DocumentTitle>
    );
  }
}

export default connect({
  mapState: (state, ownProps) => {
    var activeOrgId = _.get(state, 'session.activeOrg.id');
    var guests = _.get(state, 'resources.guests.byId');

    return {
      activeOrgId,
      guests
    };
  },
  mapDispatch: {
    ..._.pick(resourceActions.guests, ['trackGuests', 'updateGuest'])
  }
})(AdminGuestsPage);
