import calicoLeftBleedIcon from '~/assets/calico/calico-left-bleed-icon.png';
import calicoRightBleedIcon from '~/assets/calico/calico-right-bleed-icon.png';
import calicoTopBleedIcon from '~/assets/calico/calico-top-bleed-icon.png';
import calicoBottomBleedIcon from '~/assets/calico/calico-bottom-bleed-icon.png';
import propertyTextIcon from '~/assets/property-text-icon.png';
import propertyAlignmentIcon from '~/assets/property-alignment-icon.png';
import propertySettingsIcon from '~/assets/property-settings-icon.png';
import propertySizeIcon from '~/assets/property-size-icon.png';
import propertyFlipXIcon from '~/assets/property-flip-x-icon.png';
import propertyFlipYIcon from '~/assets/property-flip-y-icon.png';
import propertySubstrateIcon from '~/assets/property-substrate-icon.png';
import propertySymbolIcon from '~/assets/property-symbol-icon.png';

export default function getSelectedEntityPropertiesData({selectedEntity, updateSelectedEntityData, selectedEntityResourceKey, productOptionsById, updateProductCanvasText, updateProductCanvasLine}) {
  var selectedEntityId = selectedEntity.id;
  var selectedEntityPropertiesData;

  if (selectedEntityResourceKey === 'calicoMuralArea') {
    selectedEntityPropertiesData = {
      propertyValues: _.defaults(selectedEntity.data, {isReturn: false}),
      propertyGroups: [
        {id: 1, title: 'Mural Area', properties: [
          {
            noOptionThumbnails: true,
            id: 'isReturn',
            title: 'Return',
            type: 'checkbox',
            onChange: ({value}) => updateSelectedEntityData('isReturn', value)
          }
        ]}
      ]
    };
  }
  if (selectedEntityResourceKey === 'calicoMuralSeam') {
    selectedEntityPropertiesData = {
      propertyValues: _.defaults(selectedEntity.data, {thickness: 1, alignmentMode: 'standard'}),
      propertyGroups: [
        {id: 1, title: 'Seam', properties: [
          {
            id: 'thickness',
            title: 'Thickness',
            type: 'number',
            icon: propertySizeIcon,
            onChange: ({value}) => updateSelectedEntityData('thickness', parseFloat(value))
          },
          {
            noOptionThumbnails: true,
            id: 'alignmentMode',
            title: 'Alignment Mode',
            type: 'singleSelect',
            icon: propertySettingsIcon,
            options: [
              {value: 'standard', title: 'Standard'},
              {value: 'custom', title: 'Custom'}
            ],
            onChange: ({value}) => updateSelectedEntityData('alignmentMode', value)
          },
          ...(selectedEntity.data.alignmentMode === 'custom' ? [
            {
              id: 'customAlignmentOffset',
              title: 'Custom Alignment Offset (in)',
              type: 'number',
              icon: propertyAlignmentIcon,
              onChange: ({value}) => updateSelectedEntityData('customAlignmentOffset', parseFloat(value))
            },
          ] : [
            {
              noOptionThumbnails: true,
              id: 'alignment',
              title: 'Alignment',
              type: 'singleSelect',
              icon: propertyAlignmentIcon,
              options: [
                {value: 'right', title: 'Right'},
                {value: 'left', title: 'Left'},
                {value: 'center', title: 'Center'}
              ],
              onChange: ({value}) => updateSelectedEntityData('alignment', value)
            },
          ])
        ]}
      ]
    };
  }
  else if (selectedEntityResourceKey === 'calicoWallLabel') {
    selectedEntityPropertiesData = {
      propertyValues: _.defaults(selectedEntity.data, {indicateViewStartOnPlan: false}),
      propertyGroups: [
        {id: 'wallLabel', title: 'Wall Label', properties: [
          {
            id: 'title',
            title: 'Title',
            type: 'text',
            icon: propertyTextIcon,
            onChange: ({value}) => updateSelectedEntityData('title', value)
          },
          {
            id: 'indicateViewStartOnPlan',
            title: 'Show Indicator Dot',
            type: 'checkbox',
            onChange: ({value}) => updateSelectedEntityData('indicateViewStartOnPlan', value)
          }
        ]}
      ]
    };
  }
  else if (selectedEntityResourceKey === 'calicoMuralPanel') {
    selectedEntityPropertiesData = {
      propertyValues: _.defaults(selectedEntity.data, {
        indicateViewStartOnPlan: false, isCriticalAlignmentPanel: false, bleedLeft: 1, bleedRight: 1, bleedTop: 1, bleedBottom: 1,
        panelDescriptionOverride: '',
        panelNumber: ''
      }),
      propertyGroups: [
        {id: 'positionSize', title: 'Position/Size', properties: _.map([
          {id: 'position.x', title: 'X'},
          {id: 'position.y', title: 'Y'},
          {id: 'size.width', title: 'Width'},
          {id: 'size.height', title: 'Height'}
        ], ({id, title}) => ({
          id,
          title,
          type: 'number',
          onChange: ({value}) => updateSelectedEntityData(id, parseFloat(value))
        }))},
        {id: 'muralPanelBleed', title: 'Bleed/Overlap', properties: _.map(['Left', 'Right', 'Top', 'Bottom'], sideKey => ({
          id: `bleed${sideKey}`,
          title: `${sideKey}`,
          type: 'number',
          icon: {
            Left: calicoLeftBleedIcon,
            Right: calicoRightBleedIcon,
            Top: calicoTopBleedIcon,
            Bottom: calicoBottomBleedIcon
          }[sideKey],
          onChange: ({value}) => updateSelectedEntityData(`bleed${sideKey}`, parseFloat(value))
        }))},
        {id: 'drawings', title: 'Drawings', properties: [
          {
            id: 'drawingsShowNotesAbovePanel',
            title: 'Show Notes Above Panel',
            type: 'checkbox',
            onChange: ({value}) => updateSelectedEntityData('drawingsShowNotesAbovePanel', value)
          },
          {
            id: 'panelNumber',
            title: 'Unique Panel Number',
            type: 'text',
            onChange: ({value}) => updateSelectedEntityData('panelNumber', value)
          },
          {
            id: 'panelDescriptionOverride',
            title: 'Panel Layout Description Override',
            type: 'text',
            onChange: ({value}) => updateSelectedEntityData('panelDescriptionOverride', value)
          },
          {
            id: 'panelPrintNotes',
            title: 'Print Notes',
            type: 'text',
            multiline: true,
            onChange: ({value}) => updateSelectedEntityData('panelPrintNotes', value)
          },
          {
            id: 'isDisabled',
            title: 'Hide Panel',
            type: 'checkbox',
            onChange: ({value}) => updateSelectedEntityData('isDisabled', value)
          }
        ]},
        // {id: 'muralPanelPrinting', title: 'Printing', properties: [
        //   {
        //     id: 'printQuantity',
        //     title: 'Print Quantity',
        //     type: 'text',
        //     onChange: ({value}) => updateSelectedEntityData('printQuantity', parseInt(value) || 0)
        //   }{
        //     id: 'printQuantity',
        //     title: 'Print Quantity',
        //     type: 'text',
        //     onChange: ({value}) => updateSelectedEntityData('printQuantity', parseInt(value) || 0)
        //   }
        // ]},
        {id: 'muralPanelOther', title: 'Other Options', properties: [
          {
            id: 'isCriticalAlignmentPanel',
            title: 'Critical Alignment',
            type: 'checkbox',
            onChange: ({value}) => updateSelectedEntityData('isCriticalAlignmentPanel', value)
          }
        ]},
      ]
    };
  }
  else if (selectedEntityResourceKey === 'calicoMuralTexture') {
    selectedEntityPropertiesData = {
      propertyValues: _.defaults(selectedEntity.data, {muralProductOptionId: 990, flipX: false, flipY: false}),
      propertyGroups: [
        {id: 'muralTexture', title: 'Mural', properties: [
          {
            id: 'muralProductOptionId',
            title: 'Pattern & Style',
            type: 'singleSelect',
            options: _.map(_.sortBy(_.filter(productOptionsById, {productPropertyId: 129, archived: 0}), 'rank'), ({id, data}) => ({id, title: `${data.pattern || 'Untitled'}: ${data.style || 'Untitled'}`})),
            onChange: ({value}) => updateSelectedEntityData('muralProductOptionId', value)
          },
          {
            id: 'substrate',
            title: 'Substrate',
            type: 'singleSelect',
            icon: propertySubstrateIcon,
            options: [
              {value: 'standardCommercial', title: 'Standard - Commercial'},
              {value: 'standardResidential', title: 'Standard - Residential'},
              {value: 'custom', title: 'CUSTOM - Refer to PO'}
            ],
            onChange: ({value}) => updateSelectedEntityData('substrate', value)
          },
          {
            id: 'flipX',
            title: 'Flip Horizontal',
            type: 'checkbox',
            icon: propertyFlipXIcon,
            onChange: ({value}) => updateSelectedEntityData('flipX', value)
          },
          {
            id: 'flipY',
            title: 'Flip Vertical',
            type: 'checkbox',
            icon: propertyFlipYIcon,
            onChange: ({value}) => updateSelectedEntityData('flipY', value)
          }
        ]}
      ]
    };
  }
  else if (selectedEntityResourceKey === 'productCanvasText') {
    selectedEntityPropertiesData = {
      propertyValues: _.defaults(selectedEntity, {text: ''}),
      propertyGroups: [
        {id: 'productCanvasTextProperties', title: 'Product canvas text properties', properties: [
          {
            id: 'text',
            title: 'Note text',
            type: 'text',
            icon: propertyTextIcon,
            onChange: ({value}) => updateProductCanvasText({id: selectedEntityId, props: {text: value}})
          }
        ]}
      ]
    };
  }
  else if (selectedEntityResourceKey === 'productCanvasLine') {
    selectedEntityPropertiesData = {
      propertyValues: _.defaults(selectedEntity, {fromSymbol: 'none', toSymbol: 'none'}),
      propertyGroups: [
        {id: 'productCanvasLineProperties', title: 'Product canvas line properties', properties: [
          {
            noOptionThumbnails: true,
            id: 'fromSymbol',
            title: 'Symbol A',
            type: 'singleSelect',
            icon: propertySymbolIcon,
            options: [
              {value: 'none', title: 'None'},
              {value: 'arrow', title: 'Arrow'}
            ],
            onChange: ({value}) => updateProductCanvasLine({id: selectedEntityId, props: {fromSymbol: value}})
          },
          {
            noOptionThumbnails: true,
            id: 'toSymbol',
            title: 'Symbol B',
            type: 'singleSelect',
            icon: propertySymbolIcon,
            options: [
              {value: 'none', title: 'None'},
              {value: 'arrow', title: 'Arrow'}
            ],
            onChange: ({value}) => updateProductCanvasLine({id: selectedEntityId, props: {toSymbol: value}})
          }
        ]}
      ]
    };
  }

  return selectedEntityPropertiesData;
}
