import { PureComponent } from 'react';
import { Text, Popup, Button, TextInput, Label, confirm, DocumentTitle, Link, withKeyEvents, CopilotStepView, ScrollView } from '@symbolic/rn-lib';
import { View, TouchableOpacity, Image } from 'react-native';
import { connect, setAppData } from '@symbolic/redux';
import { resourceActions, store } from '~/redux';
import { setActiveView } from '~/redux/active-view';
import { api, products } from '@symbolic/lib';
import { sortedProductPropertiesForProductInstance, productOptionsForProperty, getProductInstanceWithData, getNameFor, getProductInstanceIsInvalid, getIsSpatial } from '~/helpers/product-order-helper';
import { productGraphicScriptFor } from '~/product-graphic-scripts';
import { getUserEmployeeOrGuest } from '~/helpers/get-user-employee-or-guest';
import { getArchCanvasViews } from '~/helpers/canvas/views-helpers'; //TODO
import { formatPrice } from '~/helpers/price-helper';
import { pluralize } from 'inflection';
import { handleExportPanelsAsSvgs } from '../../helpers/calico/export-panels-as-svgs';
import getIsBacklitEngravingCompatible from '~/helpers/meljac/get-is-backlit-engraving-compatible';

import K from '~/k';
import moment from 'moment';
import dinero from 'dinero.js';

import HudElement from '~/components/hud-element';
import ProductGraphic from '~/components/product-graphic';
import SpatialCanvas from '~/components/canvas/spatial-canvas';
import Cart from '~/components/cart';
import MediaPopup from '~/components/popups/media-popup';
import ProductInstanceTitlePopup from '~/components/popups/product-instance-title-popup';
import Medium from '~/components/medium';
import ProductInstanceMedia from '../../components/product-instance-media';
import ProductOrderSettingsPopup from '~/components/popups/product-order-settings-popup';

import ProductOrderTitleHudElement from '../../components/hud-elements/pages/order-page/product-order-title-hud-element';
import ProductOrderPriceHudElement from '../../components/hud-elements/pages/order-page/product-order-price-hud-element';
import StatusDotsHudElement from '../../components/hud-elements/pages/order-page/status-dots-hud-element';
import AddButtonHudElement from '../../components/hud-elements/pages/order-page/add-button-hud-element';
import ProductInstanceControlsHudElement from '../../components/hud-elements/pages/order-page/product-instance-controls-hud-element';
import PropertiesHudElement from '../../components/hud-elements/pages/order-page/properties-hud-element';
import TreeHudElement from '../../components/hud-elements/pages/order-page/tree-hud-element';

import downloadIconBlack from '~/assets/download-icon-black.png';
import createIcon from '~/assets/create-icon.png';
import settingsIcon from '~/assets/settings-icon.png';
import treeIcon from '~/assets/tree-icon.png';
import mmIcon from '~/assets/mm-icon-black.png';
import inchesIcon from '~/assets/inches-icon-black.png';
import createBackBoxOrder from '~/helpers/meljac/create-back-box-order';
import getSelectedEntityPropertiesData from './selected-entity-data-helpers/calico-selected-entity-data-helpers';
import PaymentForm from '../../components/payment-form';
import _ from 'lodash';

var {
  getCostsForOrder,
  getActiveProductOrderStatus,
  getLastCompletedProductOrderStatus,
  getPaymentAmountForOrder,
  getFilteredProductOrderStatuses,
  getProductOrderStatusesFor,
  getDefaultDescriptionForProductOrderStatus
} = products;

class OrderPage extends PureComponent {
  state = {
    activeViewIndex: 0,
    treeIsVisible: true,
    canvasSize: {width: 0, height: 0},
    settingsPopupIsVisible: false,
    productInstanceNotesPopupIsVisible: false,
    productInstanceTitlePopupIsVisible: false,
    activePaymentMethod: this.preferredPaymentMethodKey,
    feedbackNotesPopupIsVisible: false,
    feedbackNotes: '',
    isEditingOrder: 0,
    tentativeArchElevation: undefined,
    tentativeArchWall: undefined,
    tentativeCalicoMuralSeam: undefined,
    tentativeCalicoMismatchLine: undefined,
    tentativeProductCanvasLine: undefined,
    selectedEntityId: undefined,
    selectedEntityResourceKey: undefined,
    payInFull: false,
    precision: 1,
    measurementSystem: 'metric'
  };

  // orderViewRef = createRef();
  views = [];

  constructor(props) {
    super(props);

    this.handleWindowResize = _.debounce(this.handleWindowResize, 100);
  }

  async componentDidMount() {
    var {productOrder} = this.props;

    if (K.isWeb) {
      document.addEventListener('keydown', this.handleKeyDown);
      document.addEventListener('keyup', this.handleKeyUp);
      window.addEventListener('resize', this.handleWindowResize);

      this.handleWindowResize();
    }

    // Animated
    if (!productOrder) return null;

    this.setState({feedbackNotes: this.props.productOrder.feedbackNotes});

    var productInstances = _.sortBy(await api.get('productInstances', {where: {productOrderId: this.props.productOrder.id}}), 'rank');

    this.props.trackProductInstances({productInstances});

    if (productOrder.type === 'arch') {
      var archWalls = await api.get('archWalls', {where: {productOrderId: productOrder.id}});

      this.props.trackArchWalls({archWalls});
    }

    var isSpatial = getIsSpatial({productOrder, session: this.props.session});

    if (isSpatial) {
      var archCustomViews = await api.get('archCustomViews', {where: {productOrderId: productOrder.id}});
      var entities = await api.get('entities', {where: {productOrderId: productOrder.id}});
      var productCanvasTexts = await api.get('productCanvasTexts', {where: {productOrderId: productOrder.id}});
      var productCanvasLines = await api.get('productCanvasLines', {where: {productOrderId: productOrder.id}});

      this.props.trackEntities({entities});
      this.props.trackArchCustomViews({archCustomViews});
      this.props.trackProductCanvasTexts({productCanvasTexts});
      this.props.trackProductCanvasLines({productCanvasLines});
    }

    if (!productInstances.length && !isSpatial) {
      this.setState({isCreating: true});
    }

    if (isSpatial && this.props.activeOrderViewData && this.props.activeOrderViewData.productOrderId === productOrder.id) {
      this.setState({activeViewIndex: this.props.activeOrderViewData.activeViewIndex});
    }

    if (!this.state.activeProductInstanceId && productInstances.length && !isSpatial) {
      this.setActiveProductInstanceId(productInstances[0].id);
    }
  }

  async componentDidUpdate(prevProps) {
    if (prevProps.productOrder.id !== this.props.productOrder.id) {
      var productInstances = await api.get('productInstances', {where: {productOrderId: this.props.productOrder.id}});

      this.props.trackProductInstances({productInstances});

      if (productInstances.length) this.setActiveProductInstanceId(productInstances[0].id);
    }

    var productOrderStatusHasBeenUpdated = prevProps.productOrder.statusData !== this.props.productOrder.statusData;

    if (productOrderStatusHasBeenUpdated) {
      this.handleWindowResize(); //HINT this only does something when there is a canvas
    }
  }

  componentWillUnmount() {
    if (K.isWeb) {
      window.removeEventListener('resize', this.handleWindowResize);
      document.addEventListener('keydown', this.handleKeyDown);
      document.addEventListener('keyup', this.handleKeyUp);
    }

    if (this.props.productOrder) this.considerDeletingOrder();
  }

  considerDeletingOrder = async () => {
    var {productOrder} = this.props;

    var productOrderTitleContainsUntitled = _.includes(_.lowerCase(productOrder.title), 'untitled');

    if (productOrder.promptedForProjectInfo === 0 && productOrderTitleContainsUntitled) {
      setTimeout(() => {
        this.props.destroyProductOrder({id: this.props.productOrder.id});
      });
    }
  };

  setActiveProductInstanceId = (id) => {
    this.setState({activeProductInstanceId: id, selectedEntityId: id, selectedEntityResourceKey: id ? 'productInstance' : undefined});
  };

  getCanvasRef = (ref) => this.canvasRef = ref;

  handleWindowResize = () => {
    if (this.canvasRef && this.canvasRef.getBoundingClientRect) {
      var {top, left} = this.canvasRef.getBoundingClientRect();

      var canvasSize = {
        width: window.innerWidth - left,
        height: window.innerHeight - top
      };

      if (canvasSize.width !== this.state.canvasSize?.width || canvasSize.height !== this.state.canvasSize?.height) {
        this.setState({canvasSize});
      }
    }
  };

  handleKeyDown = (event) => {
    if (event.key === 'Escape') {
      this.setState({tentativeArchWall: undefined, tentativeArchElevation: undefined});
    }
  };

  handleKeyUp = () => {

  };

  handleRequiresChangesPress = async ({productOrderId, feedbackNotes}) => {
    var {data: {feedbackNotes, statusData}} = await api.request({uri: '/configurator/product-order/action-required', body: {productOrderId, feedbackNotes}});

    this.props.updateProductOrder({id: productOrderId, props: {statusData, feedbackNotes}, hitApi: false});
  };

  handleProductInstanceUpdate = ({props}) => this.props.updateProductInstance({id: this.state.activeProductInstanceId, props});

  handleCustomPriceInput = ({value}) => this.props.updateProductInstance({id: this.state.activeProductInstanceId, props: {customPriceInCents: value}});

  handleCancelOrderPress = async ({productOrder}) => {
    if (await confirm('', 'Are you sure you want cancel your order?\n\nYour order status will be reset to the first step in the process.')) {
      var {data: statusData} = await api.request({uri: '/configurator/product-order/cancel', body: {productOrderId: productOrder.id}});

      this.props.updateProductOrder({id: productOrder.id, props: {statusData}, hitApi: false});
    }
  };

  handleViewDocumentsButtonPress = async ({productOrder, mode}) => {
    var path = `/orders/${productOrder.orgSpecificId}/documents`;

    if (mode === 'invoice') path += '?mode=invoice';

    this.props.history.push(path);
  };

  addCalicoMuralEntity = ({type, properties = null, data = null}, {productOrder, activeOrg}) => {
    var calicoMuralEntity = {
      type: type,
      parentId: productOrder.id,
      parentResourceKey: 'productOrder',
      orgId: activeOrg.id,
      properties,
      data
    };

    if (this.views[this.state.activeViewIndex]?.viewKey === 'custom') {
      calicoMuralEntity.data = {
        ...calicoMuralEntity.data,
        ...(_.includes(['calicoMuralSeam', 'calicoMuralPanel', 'calicoMuralTexture', 'calicoMuralArea'],) ? {position: {x: 0, y: 0}} : {}),
        archCustomViewId: this.views[this.state.activeViewIndex].archCustomView.id,
      };
    }

    this.props.createEntity({props: calicoMuralEntity});
  };

  addCalicoLineEntity = ({type, stateKey}) => {
    var {productOrder, session: {activeOrg}} = this.props;

    var tentativeCalicoLineEntity = {
      type,
      parentId: productOrder.id,
      parentResourceKey: 'productOrder',
      orgId: activeOrg.id,
      data: {
        shape: 'line',
        from: undefined,
        to: undefined,
        yRotation: 0,
        xzRotation: 0,
        size: 2,
        isKillPoint: false,
        alignment: 'center'
      },
      isTentative: true
    };

    if (this.views[this.state.activeViewIndex]?.viewKey === 'custom') {
      tentativeCalicoLineEntity.data = {
        ...tentativeCalicoLineEntity.data,
        archCustomViewId: this.views[this.state.activeViewIndex]?.archCustomView.id
      };
    }

    this.setState({[stateKey]: tentativeCalicoLineEntity});
  };

  addArchCustomView = async () => {
    var {productOrder, session: {activeOrg}} = this.props;
    var archCustomView = await api.create('archCustomView', {
      productOrderId: productOrder.id,
      orgId: activeOrg.id,
      title: 'Untitled Viewport',
      rank: _.max(_.map(this.props.archCustomViews, 'rank')) + 1
    });

    this.props.trackArchCustomViews({archCustomViews: [archCustomView]});
  };

  addProductCanvasText = async () => {
    var {session: {activeOrg}} = this.props;

    var productCanvasText = await api.create('productCanvasText', {
      position: {x: 0, y: 0},
      width: 100,
      text: 'Text',
      orgId: activeOrg.id,
      customViewId: this.views[this.state.activeViewIndex].archCustomView.id,
      productOrderId: this.props.productOrder.id
    });

    this.props.trackProductCanvasTexts({productCanvasTexts: [productCanvasText]});
  };

  addProductCanvasLine = async () => {
    var {session: {activeOrg}} = this.props;

    var productCanvasLine = await api.create('productCanvasLine', {
      from: {x: 0, y: 0},
      to: {x: 50, y: 50},
      orgId: activeOrg.id,
      customViewId: this.views[this.state.activeViewIndex].archCustomView.id,
      productOrderId: this.props.productOrder.id
    });

    this.props.trackProductCanvasLines({productCanvasLines: [productCanvasLine]});
  };

  createCustomProductInstance = async () => {
    var {rank, updates} = this.getRankDataForProductInstance();

    var productInstance = await api.create('productInstance', {
      productId: -1,
      orgId: this.props.session.activeOrg.id,
      productOrderId: this.props.productOrder.id,
      quantity: 1,
      rank
    });

    this.props.trackProductInstances({productInstances: [productInstance]});

    this.setState({isCreating: false});

    setTimeout(() => this.props.updateProductInstances({updates}));
    setTimeout(() => this.setActiveProductInstanceId(productInstance.id), 0);
  };

  createProductInstance = async ({productId, productInstanceTitle, properties}) => {
    var {productInstances, productOrder, session} = this.props;
    var {rank, updates} = this.getRankDataForProductInstance();

    var productInstanceProps = {
      productId, rank,
      productOrderId: productOrder.id,
      orgId: session.activeOrg.id,
      quantity: 1,
      properties: {},
      ...(this.views ? {archCustomViewId: this.views[this.state.activeViewIndex]?.archCustomView.id} : {})
    };

    if (productInstanceTitle) productInstanceProps.title = productInstanceTitle;

    var productInstanceWithData = getProductInstanceWithData({productInstance: _.find(productInstances, ({productId}) => productId !== -1) || productInstanceProps}, {..._.pick(this.props, ['productsById', 'productPropertiesById', 'productRulesById', 'productOptionsById', 'productInstancesById'])});

    productInstanceProps.properties = {...properties, ..._.mapValues(_.pickBy(_.get(productInstanceWithData, 'propertiesDataById'), 'productProperty.isOrderLevel'), (property) => ({optionId: _.get(property, 'selectedProductOption.id')}))};

    var productInstance = await api.create('productInstance', productInstanceProps);

    setTimeout(() => this.props.updateProductInstances({updates}));

    this.props.trackProductInstances({productInstances: [productInstance]});

    this.setState({isCreating: false});

    setTimeout(() => this.setActiveProductInstanceId(productInstance.id), 0);
  };

  copyProductInstance = async ({activeProductInstance}) => {
    var {rank, updates} = this.getRankDataForProductInstance();

    var productInstance = await api.create('productInstance', {
      productId: activeProductInstance.productId,
      productOrderId: this.props.productOrder.id,
      orgId: this.props.session.activeOrg.id,
      properties: _.cloneDeep(_.get(activeProductInstance, 'properties', {})),
      title: activeProductInstance.title ? `Copy of ${activeProductInstance.title}` : '',
      quantity: 1,
      rank
    });

    setTimeout(() => this.props.updateProductInstances({updates}));

    this.props.trackProductInstances({productInstances: [productInstance]});

    setTimeout(() => this.setActiveProductInstanceId(productInstance.id), 0);
  };

  getRankDataForProductInstance = () => {
    var {productInstances} = this.props;
    var {activeProductInstanceId} = this.state;

    var rank = 1;
    var updates = [];

    if (productInstances.length > 0) {
      var activeProductInstance = _.find(productInstances, {id: parseInt(activeProductInstanceId)});

      var activeProductInstanceRank = _.get(activeProductInstance, 'rank', 0);

      rank = activeProductInstanceRank + 1;

      //HINT update ranks for all productInstances after activeProductInstance
      var productInstancesToUpdate = _.filter(productInstances, (productInstance) => productInstance.rank > activeProductInstanceRank);

      _.forEach(productInstancesToUpdate, ({id, rank}) => updates.push({where: {id}, props: {rank: rank + 1}}));
    }

    return {rank, updates};
  };

  handleProductInstanceChange = ({value}) => {
    this.setActiveProductInstanceId(value);
  };

  handleSubmitButtonPress = async ({someProductInstanceIsInvalid, isDeliveryAddressIncomplete, productOrder, isGuestMode}) => {
    if (someProductInstanceIsInvalid) {
      alert('It looks like an item has an invalid configuration.\n\nPlease review your items and update any red selections.');
    }
    else if (isDeliveryAddressIncomplete) {
      alert('A complete delivery address is required to submit the order.');
      this.setState({settingsPopupIsVisible: true});
    }
    else if (!productOrder.firmName) {
      alert('Company is required to submit the order.');
      this.setState({settingsPopupIsVisible: true});
    }
    else {
      var {session: {activeOrg}} = this.props;

      if (productOrder.isStocked) {
        this.handleOrderSubmit({productOrderId: productOrder.id, isGuestMode});
      }
      else {
        if (await confirm('', `Are you sure you want to submit your order? It will be sent to ${activeOrg.title} for review.`)) {
          this.handleOrderSubmit({productOrderId: productOrder.id, isGuestMode});
        }
      }
    }
  };

  handleOrderSubmit = async ({productOrderId, isGuestMode}) => {
    var {data: statusData} = await api.request({uri: '/configurator/submit-order', body: {productOrderId, userId: this.props.session.user.id, isGuestMode}});

    await this.props.updateProductOrder({id: productOrderId, props: {statusData}, hitApi: false});
  };

  handleOrderApprove = async ({productOrderId}) => {
    var {data: statusData} = await api.request({uri: '/configurator/approve-order', body: {productOrderId, userId: this.props.session.user.id}});

    await this.props.updateProductOrder({id: productOrderId, props: {statusData}, hitApi: false});
  };

  handleConfirmedPayment = async ({productOrderId, paymentData, paymentMethod, isGuestMode, payInFull}) => {
    var {data: {statusData}} = await api.request({uri: '/configurator/confirm-order-payment', body: {
      productOrderId, paymentMethod, paymentData, isGuestMode, payInFull
    }});

    await this.props.updateProductOrder({id: productOrderId, props: {statusData}, hitApi: false});
  };

  handleRequestStatusUpdate = async ({productOrderId}) => {
    await api.request({uri: '/configurator/request-status-update', body: {productOrderId}});

    alert('Requested a status update successfully');
  };

  duplicateProductOrder = async () => {
    var {productOrder, productInstances, session} = this.props;

    var productOrderStatuses = _.filter(_.get(session, 'activeOrg.appData.designEngine.productOrderStatuses'), {isVisibleToGuest: 1});

    var newProductOrder = await api.create('productOrder', {
      title: `Copy of ${productOrder.title}`,
      statusData: {[ _.get(_.find(productOrderStatuses, {type: 'orderStarted'}), 'key')]: {date: moment.utc().format('YYYY-MM-DD HH:mm:ss')}},
      ..._.omit(productOrder, ['id', 'title', 'created', 'lastUpdated', 'orgSpecificId', 'involvedUsers', 'involvedUsersString', 'integrationData', 'statusData', 'estimatedShippingDate', 'trackingNumber'])
    });

    await this.props.createProductInstances({propsSets: _.map(productInstances, productInstance => {
      return {
        productOrderId: newProductOrder.id,
        ..._.omit(productInstance, ['id', 'productOrderId', 'created', 'lastUpdated'])
      };
    })});

    this.props.trackProductOrders({productOrders: [newProductOrder]});

    setTimeout(() => this.props.history.push('/orders'), 0);
  };

  deleteProductOrder = async () => {
    if (await confirm('', 'Are you sure?')) {
      this.props.history.push('/orders');

      setTimeout(() => {
        this.props.destroyProductOrder({id: this.props.productOrder.id});
      });
    }
  };

  deleteProductInstance = async () => {
    var {activeProductInstanceId} = this.state;
    var {productInstances, productInstancesById} = this.props;

    var activeProductInstance = _.get(productInstancesById, activeProductInstanceId);

    if (await confirm('', 'Are you sure?')) {
      var indexOfActiveProductInstance = _.indexOf(productInstances, activeProductInstance);

      if (this.props.productInstances.length == 1) {
        this.setState({isCreating: true});
      }
      else {
        if (indexOfActiveProductInstance === 0) {
          setTimeout(() => this.setActiveProductInstanceId(productInstances[1].id));
        }
        else {
          setTimeout(() => this.setActiveProductInstanceId(productInstances[indexOfActiveProductInstance - 1].id));
        }
      }

      this.setState({productInstanceNotesPopupIsVisible: false});

      setTimeout(() => this.props.destroyProductInstance({id: activeProductInstanceId}));
    }
  };

  handleApplyAll = async ({productProperty, productOption, activeProductInstanceWithData}) => {
    var {productOrder, productRulesById, productOptionsById, productsById} = this.props;
    var {id: orgId} = this.props.session.activeOrg;
    var hasCustomProductInstance = false;

    var filteredProductInstances = _.filter(this.props.productInstances, productInstance => {
      if (productInstance.productId === -1) {
        hasCustomProductInstance = true;
      }
      else {
        var availablePropertyOptions = productOptionsForProperty({productProperty, productInstance}, {productRulesById, productOptionsById, productsById});

        if (productProperty.id === 26) { //HINT special rules for MNA backlit engraving type
          var isBacklitEngravingCompatible = getIsBacklitEngravingCompatible({activeProductInstanceWithData});

          if (!isBacklitEngravingCompatible) {
            availablePropertyOptions = _.reject(availablePropertyOptions, {id: 139});
          }
        }

        return availablePropertyOptions;
      }
    });

    filteredProductInstances.length === this.props.productInstances.length && hasCustomProductInstance === false
      ? await confirm('', getNameFor({productOrder, orgId, textToTransform: 'Are you sure? This option will be applied to all products in your order'}))
      : await confirm('', getNameFor({productOrder, orgId, textToTransform: 'Are you sure? This option will be applied to all products in your order that support it'}));

    var updates = _.map(filteredProductInstances, productInstance => {
      var updatedProperties = _.set(_.cloneDeep(productInstance.properties), `${productProperty.id}.optionId`, productOption.id);

      return {
        where: {id: productInstance.id},
        props: {properties: updatedProperties}
      };
    });

    this.props.updateProductInstances({updates});
  };

  toggleCartVisibility = () => {
    this.props.setAppData({key: 'cartIsVisible', value: !this.props.cartIsVisible, appKey: 'designEngine'});
  };

  toggleDimensionsVisibility = () => {
    this.props.setAppData({key: 'dimensionsAreVisible', value: !this.props.dimensionsAreVisible, appKey: 'designEngine'});
  };

  get archWallsAreLoaded() {
    if (this.props.archWallsAreLoaded) this._archWallsAreLoaded = true;

    return this._archWallsAreLoaded;
  }

  get preferredPaymentMethodKey() {
    var {session} = this.props;

    var preferredPaymentMethodKey = null;

    var paymentMethods = _.get(session, 'activeOrg.appData.designEngine.paymentMethods');

    var filteredSortedPaymentMethods = _.sortBy(_.filter(paymentMethods, (paymentMethod) => {
      return paymentMethod.disabled !== 1 && paymentMethod.isHidden !== 1;
    }), 'rank');

    if (filteredSortedPaymentMethods.length > 0) {
      var preferredPaymentMethod = _.first(filteredSortedPaymentMethods);

      if (_.some(filteredSortedPaymentMethods, {isPreferred: 1})) {
        preferredPaymentMethod = _.first(_.find(filteredSortedPaymentMethods, {isPreferred: 1}));
      }

      preferredPaymentMethodKey = _.get(preferredPaymentMethod, 'key');
    }

    return preferredPaymentMethodKey;
  }

  setPrecision = ({multiplyBy, precision}) => {
    var {id: orgId} = this.props.session.activeOrg;

    if (multiplyBy) precision = this.state.precision * multiplyBy;

    if (orgId === 1053) {
      if (precision < 0.5) precision = 0.5;
      else if (precision > 16) precision = 16;
    }

    this.setState({precision});
  };

  setActiveViewIndex = (activeViewIndex) => {
    this.setState({activeViewIndex});

    this.props.setActiveView({data: {activeOrderViewData: {activeViewIndex, productOrderId: this.props.productOrder.id}}});
  };

  toggleMeasurmentSystem = () => {
    this.setState({measurementSystem: this.state.measurementSystem === 'metric' ? 'imperial' : 'metric'});
  }

  render() {
    var {
      productOrder,
      productGraphic,
      productsById,
      productInstances,
      productPropertiesById,
      productPricingRulesById,
      productOptionsById,
      productOptionClassifiersById,
      productRulesById,
      productInstancesById,
      session,
      archElevations,
      archWalls,
      archCustomViews,
      cartIsVisible,
      dimensionsAreVisible
    } = this.props;

    var {activeOrg} = session;
    var {role} = activeOrg;
    var {activeProductInstanceId, canvasSize, activePaymentMethod, stripeActivePaymentMethod, payInFull, measurementSystem} = this.state;
    var {id: orgId} = this.props.session.activeOrg;
    var productOrderStatuses = _.filter(_.get(session, 'activeOrg.appData.designEngine.productOrderStatuses'), {isVisibleToGuest: 1});

    if (!productOrder) {
      return (
        <View style={{flex: 1, justifyContent: 'center', alignItems: 'center'}}>
          <Text>{getNameFor({orgId, textToTransform: 'You might need to ask for an invite, or the order you\'re looking for might have been deleted.'})}</Text>
          <Link to='/orders' style={{...K.button, backgroundColor: K.colors.gray, padding: K.spacing, margin: K.spacing, width: 'auto'}}><Label>Go to orders</Label></Link>
        </View>
      );
    }

    var isSpatial = getIsSpatial({session, productOrder});

    var {statusData = {}, promptedForProjectInfo} = productOrder;

    var productOrderProductIds = _.uniq(_.map(productInstances, ({productId}) => productId));

    var products = _.filter(productsById, (product) => {
      if (!product.productCategoryId && !this.props.productOrder.productCategoryId) return false;
      else if (product.productCategoryId !== this.props.productOrder.productCategoryId) return false;
      else if (product.archived && !_.includes(productOrderProductIds, product.id)) return false;
      else if (!_.includes(['owner', 'member'], this.props.session.activeOrg.role) && (product.publishLevel === 'admin' || product.publishLevel === 'internal')) return false;
      else if (this.props.session.activeOrg.role === 'member' && product.publishLevel === 'admin') return false;
      else if (product.productCategoryId === productOrder.productCategoryId) return true;
    });

    var activeProductInstance = _.find(productInstances, {id: parseInt(activeProductInstanceId)});
    var isCustomProduct = false;

    if (productInstances.length && activeProductInstance) {
      if (activeProductInstance.productId !== -1) {
        var product = _.find(products, {id: activeProductInstance.productId});
        var productScript = productGraphicScriptFor({productId: product.id});
        var productHasScript = !!productScript;
        var productHasNoScript = !productScript;

        var sortedProductProperties = sortedProductPropertiesForProductInstance({productInstance: activeProductInstance}, {productsById, productPropertiesById, productRulesById, productOptionsById, productInstancesById});

        var orderLevelProperties = _.filter(sortedProductProperties, {isOrderLevel: 1});

        var productMedia = _.filter(this.props.media, medium => medium.type === 'image' && _.has(medium.associations.products, `${activeProductInstance.productId}`));
        var mediumSize = (K.standardColumnWidth - K.spacing * 2) / 2 - K.margin;

        var activeProductInstanceWithData = getProductInstanceWithData({productInstance: {...activeProductInstance}}, {
          productOptionsById, productsById, productRulesById, productPropertiesById, productInstancesById,
        });

        var someProductInstanceIsInvalid = _.some(productInstances, productInstance => getProductInstanceIsInvalid({productInstance}, this.props));
        var activeProductInstanceIsInvalid = getProductInstanceIsInvalid({productInstance: activeProductInstance}, this.props);
      }
      else {
        isCustomProduct = true;
      }
    }

    var unfilteredProductOrderStatuses = _.get(session, 'activeOrg.appData.designEngine.productOrderStatuses');
    var productOrderStatuses = getProductOrderStatusesFor({productOrder, productOrderStatuses: getFilteredProductOrderStatuses({productOrderStatuses: unfilteredProductOrderStatuses, userRole: role, productOrder})});
    var activeProductOrderStatus = getActiveProductOrderStatus({productOrderStatuses, statusData});
    var lastCompletedProductOrderStatus = getLastCompletedProductOrderStatus({productOrderStatuses, statusData});
    var activeStatusType = this.state.isEditingOrder === 1 ? 'submittedForApproval' : _.get(activeProductOrderStatus, 'type');
    var lastCompletedStatusType = _.get(lastCompletedProductOrderStatus, 'type');

    //HINT hide cart when initial project info form is visible
    if (lastCompletedProductOrderStatus.key !== 'orderStarted' || this.props.productOrder.promptedForProjectInfo === 0) cartIsVisible = false;

    var orderCosts = getCostsForOrder({productOrder, productInstances}, {productOptionsById, productPricingRulesById, productOptionClassifiersById, productsById, productPropertiesById, productRulesById, productInstancesById, activeOrg});
    var {orderTotal: totalPrice, orderSubTotal} = orderCosts;

    var isCardPayment = activePaymentMethod === 'stripe' && stripeActivePaymentMethod === 'card';

    var isDeliveryAddressIncomplete = !_.every(_.map(['city', 'state', 'streetAddress', 'zip'], (field) => !!productOrder.deliveryAddress[field]));

    var isGuestMode = !!(activeOrg.prevRole && activeOrg.role);

    var {isEmployee, isGuest, isGuestMode} = getUserEmployeeOrGuest({activeOrg});

    var selectedEntityPropertiesData;
    var {selectedEntityId, selectedEntityResourceKey} = this.state;

    var lastCompletedProductOrderStatusDescription = lastCompletedProductOrderStatus.description || getDefaultDescriptionForProductOrderStatus({productOrder, productOrderStatus: lastCompletedProductOrderStatus, org: activeOrg});

    if (productOrder.isStocked && lastCompletedProductOrderStatus.stockedOrderDescription) lastCompletedProductOrderStatusDescription = lastCompletedProductOrderStatus.stockedOrderDescription;

    if (selectedEntityId && selectedEntityResourceKey && _.includes([1053, 1798], session.activeOrg.id)) {
      var reduxState = store.getState();
      var selectedEntity = (reduxState.resources[pluralize(selectedEntityResourceKey)] || reduxState.resources.entities).byId[selectedEntityId];

      var updateSelectedEntityData = async (path, value) => {
        var reduxState = store.getState();
        var selectedEntity = (reduxState.resources[selectedEntityResourceKey] || reduxState.resources.entities).byId[selectedEntityId];
        var data = _.cloneDeep(selectedEntity.data);

        _.set(data, path, value);

        this.props.updateEntity({id: selectedEntityId, props: {data}});
      };

      selectedEntityPropertiesData = getSelectedEntityPropertiesData({selectedEntity, updateSelectedEntityData, selectedEntityResourceKey, productOptionsById, ..._.pick(this.props, ['updateProductCanvasText', 'updateProductCanvasLine'])});
    }

    var shippedWithoutFinalPayment = session?.activeOrg.id === 850 ? (lastCompletedProductOrderStatus.key === 'shipped' && productOrder.amountPaidInCents < orderCosts.orderTotal) : false;

    var {paymentAmount, shouldApplyUpcharge, upchargePercentage, upchargeAmount} = getPaymentAmountForOrder({productOrder, orderCosts, isCardPayment, payInFull, shippedWithoutFinalPayment}, {productOrderStatuses: unfilteredProductOrderStatuses, activeProductOrderStatus, activeOrg});

    var accentColor = _.get(this.props.session, 'activeOrg.appData.designEngine.accentColor', 'black');

    var hudButtonStyle = {
      ...K.fonts.standard,
      borderRadius: K.borderRadius * 3,
      height: 24,
      alignItems: 'center',
      justifyContent: 'center',
      paddingHorizontal: K.spacing * 2,
      marginBottom: K.margin,
      position: 'relative'
    };

    var minorHudButtonStyle = {
      ...hudButtonStyle,
      alignSelf: 'flex-end',
      backgroundColor: 'white',
      borderColor: K.colors.doubleGray,
      borderWidth: 1
    };

    if (isSpatial) {
      this.views = getArchCanvasViews({archCustomViews, archWalls, archElevations, session: this.props.session});
    }

    activeStatusType = activeStatusType || lastCompletedStatusType;

    var isConfiguring = lastCompletedStatusType === 'orderStarted';

    return (
      <DocumentTitle title={productOrder.title}>
        <View style={{flexDirection: 'row', flex: 1}}>
          <View style={{flex: 1, position: 'relative', minWidth: '70%'}} ref={this.orderViewRef}>

            {/* STATUS DOTS - always visible for orders that can be submitted & follow a process */}
            {!isSpatial && (
              <StatusDotsHudElement {...{productOrder}}/>
            )}

            <HudElement x='left' y='top' style={{marginTop: K.spacing, paddingLeft: K.spacing}}>
              {/* TITLE - always visible */}
              <ProductOrderTitleHudElement
                {...{productOrder, lastCompletedProductOrderStatus}}
              />

              {isSpatial && (
                <View style={{flexDirection: 'row', alignItems: 'center', marginTop: K.margin}}>
                  <TouchableOpacity
                    style={{marginRight: 12}}
                    onPress={() => this.setState({settingsPopupIsVisible: true})}
                  >
                    <Image source={settingsIcon} {...K.defaultIconStyle} />
                  </TouchableOpacity>
                  <Button icon={treeIcon} style={{backgroundColor: 'transparent'}} onPress={() => this.setState({treeIsVisible: !this.state.treeIsVisible})}/>
                  {session.activeOrg.id === 1798 && (
                    <Button
                      icon={downloadIconBlack}
                      style={{backgroundColor: 'transparent'}}
                      onPress={() => {
                        if (this.views[this.state.activeViewIndex].archCustomView) {
                          handleExportPanelsAsSvgs({calicoMuralPanels: _.filter(this.props.entities, {type: 'calicoMuralPanel'}), filename: `order-${productOrder.id}`, activeCustomViewId: this.views[this.state.activeViewIndex].archCustomView.id});
                        }
                        else {
                          alert('Please go to a viewport to export it to SVG');
                        }
                      }}
                    />
                  )}
                </View>
              )}
              <View>
                {/* PRICE top left (below title) */}
                {lastCompletedStatusType === 'orderStarted' && !isSpatial && activeProductInstance && isConfiguring && (
                  <ProductOrderPriceHudElement
                    style={{marginTop: K.margin}}
                    {...{productOrder, activeProductInstance, products, orderSubTotal, costs: orderCosts}}
                  />
                )}
                {/* Org Specific ID - always visible */}
                <View style={{marginTop: K.margin + 2}}>
                  <Text style={{...K.fonts.standard, opacity: 0.5}}>#{productOrder.orgSpecificId}</Text>
                </View>
                {lastCompletedStatusType === 'orderStarted' && !isSpatial && activeProductInstance && isConfiguring && (
                  <TouchableOpacity
                    style={{...hudButtonStyle, backgroundColor: someProductInstanceIsInvalid ? 'red' : accentColor, alignSelf: 'flex-start', marginTop: K.margin * 3, marginBottom: 0}}
                    dataSet={{fadeOnHover: 1}}
                    onPress={() => this.handleSubmitButtonPress({someProductInstanceIsInvalid, isDeliveryAddressIncomplete, productOrder, isGuestMode})}
                  >
                    <Text style={{color: '#ffffff'}}>{productOrder.isStocked ? 'Continue to Payment' : 'Submit'}</Text>
                  </TouchableOpacity>
                )}
              </View>
            </HudElement>

            {/* ORDER CONTROLS - top right */}
            {!isSpatial && activeProductInstance && (
              <HudElement x='right' y='top' style={{marginTop: K.spacing, paddingRight: K.spacing}}>
                <CopilotStepView
                  key={'order controls tip'}
                  name='order controls'
                  order={1}
                  text={getNameFor({productOrder, orgId, textToTransform: 'Order controls can be found here.'})}
                  style={{flex: 1}}
                >
                  {isConfiguring && (
                    <CopilotStepView
                      key={'cart button tip'}
                      name='cart button'
                      order={2}
                      text={getNameFor({productOrder, orgId, textToTransform: 'Click this button to hide/show the cart.\n\nThe cart is a helpful tool for managing all the items in your order.'})}
                      style={{flex: 1}}
                    >
                      <TouchableOpacity
                        dataSet={{fadeOnHover: 1}}
                        style={minorHudButtonStyle}
                        onPress={this.toggleCartVisibility}
                      >
                        <Text style={{opacity: 0.5}}>{cartIsVisible ? 'Hide Cart' : 'View Cart'}</Text>
                      </TouchableOpacity>
                    </CopilotStepView>
                  )}
                  {(isConfiguring || isEmployee) && (
                    <CopilotStepView
                      key={'edit quote button tip'}
                      name='edit quote button'
                      order={3}
                      text={getNameFor({productOrder, orgId, textToTransform: 'Click this button to manage the details and information associated with your order.'})}
                      style={{flex: 1}}
                    >
                      <TouchableOpacity
                        dataSet={{fadeOnHover: 1}}
                        style={minorHudButtonStyle}
                        onPress={() => this.setState({settingsPopupIsVisible: true})}
                      >
                        <Text style={{opacity: 0.5}}>Edit Quote Details</Text>
                      </TouchableOpacity>
                    </CopilotStepView>
                  )}
                  {(activeOrg.id === 850) && (
                    <TouchableOpacity
                      style={minorHudButtonStyle}
                      dataSet={{fadeOnHover: 1}}
                      onPress={() => this.handleViewDocumentsButtonPress({productOrder, mode: 'quote'})}
                    >
                      <Text style={{opacity: 0.5}}>View Quote</Text>
                    </TouchableOpacity>
                  )}
                  {isConfiguring && productHasScript && (
                    <TouchableOpacity
                      dataSet={{fadeOnHover: 1}}
                      style={minorHudButtonStyle}
                      onPress={this.toggleDimensionsVisibility}
                    >
                      <Text style={{opacity: 0.5}}>{dimensionsAreVisible ? 'Hide Dimensions' : 'Show Dimensions'}</Text>
                    </TouchableOpacity>
                  )}
                  {(activeOrg.id === 850 && dimensionsAreVisible && isConfiguring) && (
                    <TouchableOpacity
                      onPress={this.toggleMeasurmentSystem}
                      style={{flexDirection: 'row', ...minorHudButtonStyle, justifyContent: 'flex-end', flexShrink: 1}}
                    >
                      <Text
                        style={{
                          opacity: measurementSystem === 'imperial' ? 1 : 0.5,
                          paddingRight: 12,
                        }}
                      >in</Text>
                      <View style={{width: 1, borderColor: K.doubleGray, borderRight: `1px solid ${K.doubleGray}`}} />
                      <Text
                        style={{
                          opacity: measurementSystem === 'metric' ? 1 : 0.5,
                          paddingLeft: 8,
                        }}
                      >mm</Text>
                    </TouchableOpacity>
                  )}
                  {isConfiguring && (
                    <TouchableOpacity
                      dataSet={{fadeOnHover: 1}}
                      style={minorHudButtonStyle}
                      onPress={() => {
                        global.isShowingHelpCopilot = true;

                        this.props.startCopilot({startArgs: [false, global.processInstanceScrollViewRef], key: 'processInstanceShowView'});
                      }}
                    >
                      <Text style={{opacity: 0.5}}>Guided Tour</Text>
                    </TouchableOpacity>
                  )}
                  {(!isConfiguring && activeOrg.id === 850) && (
                    <TouchableOpacity
                      style={minorHudButtonStyle}
                      dataSet={{fadeOnHover: 1}}
                      onPress={() => this.handleViewDocumentsButtonPress({productOrder, mode: 'invoice'})}
                    >
                      <Text style={{opacity: 0.5}}>View Invoice</Text>
                    </TouchableOpacity>
                  )}
                  {/* CANCEL ORDER button - not available after an amount has been paid */}
                  {(productOrder.amountPaidInCents === 0 && lastCompletedProductOrderStatus.key !== 'orderStarted') && (
                    <TouchableOpacity
                      style={minorHudButtonStyle}
                      dataSet={{fadeOnHover: 1}}
                      onPress={() => this.handleCancelOrderPress({productOrder})}
                    >
                      <Text style={{opacity: 0.5}}>Cancel Order</Text>
                    </TouchableOpacity>
                  )}
                  {isConfiguring && activeOrg.id === 850 && (isEmployee || isGuest || isGuestMode)
                    && _.includes([1, 2, 5, 10, 11, 6, 7, 8, 15, 9, 87, 72, 89], productOrder.productId)
                    && !_.some([[55, 330], [58, 414], [66, 467]], ([propertyId, optionId]) => _.get(productInstances, `0.properties.${propertyId}.optionId`) === optionId) //make sure
                  && (
                    <TouchableOpacity
                      dataSet={{fadeOnHover: 1}}
                      style={minorHudButtonStyle}
                      onPress={async () => {
                        if (await confirm('', 'Would you like to create a new quote for the required Meljac back boxes?')) {
                          var {backBoxProductOrder} = await createBackBoxOrder({
                            productInstances, session, productOrder,
                            productsById, productRulesById, productPropertiesById, productOptionsById,
                            ..._.pick(this.props, ['trackProductOrders', 'createProductInstances'])
                          });

                          //HINT need the setTimeouts after trackProductOrders
                          setTimeout(() => {
                            this.props.history.push(`/orders/${backBoxProductOrder.orgSpecificId}`);
                          });
                        }
                      }}
                    >
                      <Text style={{opacity: 0.5}}>Generate Back Box Quote</Text>
                    </TouchableOpacity>
                  )}
                </CopilotStepView>
              </HudElement>
            )}

            {/* MAIN CONFIGURATION UI ELEMENTS - visible when order hasn't yet been submitted - includes price/submit button (see above) */}
            {lastCompletedStatusType === 'orderStarted' && (<>

              {/* PROPERTIES - bottom right */}
              {((activeProductInstance && promptedForProjectInfo) || selectedEntityPropertiesData) && (<>
                {(activeProductInstanceWithData || selectedEntityPropertiesData) && (
                  <PropertiesHudElement
                    {...{selectedEntityPropertiesData, activeProductInstanceWithData}}
                    showMediaPopup={({resourceKey, resourceId, relevantMedia}) => {
                      this.setState({mediaPopupIsVisible: true, mediaPopupResourceKey: resourceKey, mediaPopupResourceId: resourceId, mediaPopupRelevantMedia: relevantMedia});
                    }}
                    productInstance={activeProductInstance}
                    productOrderId={productOrder.id}
                    handleApplyAll={({productProperty, productOption, activeProductInstanceWithData}) => this.handleApplyAll({productProperty, productOption, activeProductInstanceWithData})}
                  />
                )}
              </>)}

              {/* ACTIVE VIEW / ENTITY / PRODUCT CONTROLS - bottom center */}
              {activeProductInstance && !!promptedForProjectInfo && !isSpatial && (
                <ProductInstanceControlsHudElement
                  setActiveProductInstanceId={({activeProductInstanceId}) => this.setState({activeProductInstanceId})}
                  showProductInstanceNotesPopup={() => this.setState({productInstanceNotesPopupIsVisible: true})}
                  handleCustomPriceInput={({value}) => this.handleCustomPriceInput({value})}
                  {...{activeProductInstance, productOrder}}
                />
              )}

              {/* ADD BUTTON - bottom left and project info from at start */}
              <AddButtonHudElement
                activeView={this.views[this.state.activeViewIndex]}
                addArchWall={() => this.setState({tentativeArchWall: {from: undefined, to: undefined, height: 96, thickness: 4, isTentative: true}})}
                addProductCanvasText={this.addProductCanvasText}
                addProductCanvasLine={() => this.setState({tentativeProductCanvasLine: {from: undefined, to: undefined, strokeWidth: 1, fromSymbol: 'none', toSymbol: 'none', customViewId: this.views[this.state.activeViewIndex]?.archCustomView.id, isTentative: true}})}
                addArchElevation={() => this.setState({tentativeArchElevation: {from: undefined, to: undefined, viewDepth: 50, isTentative: true}})}
                addCalicoLineEntity={this.addCalicoLineEntity}
                addCalicoMuralEntity={({type, properties, data}) => this.addCalicoMuralEntity({type, properties, data}, {productOrder, activeOrg})}
                addArchCustomView={this.addArchCustomView}
                isCreating={this.state.isCreating}
                showCreateInstancePopup={() => this.setState({createInstancePopupIsVisible: true})}
                showProductInstanceTitlePopup={() => this.setState({productInstanceTitlePopupIsVisible: true})}
                createProductInstance={(params) => {
                  if (params) {
                    this.createProductInstance(params);
                  }
                  else if (productOrder.productId) {
                    this.createProductInstance({productId: productOrder.productId, orderLevelProperties});
                  }
                  else {
                    this.setState({createInstancePopupIsVisible: true});
                  }
                }}
                copyProductInstance={() => {
                  var activeProductInstance = _.find(this.props.productInstances, {id: parseInt(this.state.activeProductInstanceId)});

                  this.copyProductInstance({activeProductInstance});
                }}
                createCustomProductInstance={() => this.createCustomProductInstance()}
                {...{promptedForProjectInfo, productOrder, totalPrice}}
              />

              {/* CANVAS CONTROLS */}
              {isSpatial && (
                <View style={{position: 'absolute', zIndex: 2, top: 30, left: '50%', transform: [{translateX: '-50%'}], flexDirection: 'row'}}>
                  <View style={{alignItems: 'center'}}>
                    <Label>Dim precision</Label>
                    <View style={{flexDirection: 'row', marginTop: 10}}>
                      <TouchableOpacity onPress={() => this.setPrecision({multiplyBy: 0.5})}><Text>-</Text></TouchableOpacity>
                      <TouchableOpacity style={{marginHorizontal: 15}}><Text>{this.state.precision + (this.props.session.activeOrg.id === 1053 ? 'mm' : '"')}</Text></TouchableOpacity>
                      <TouchableOpacity onPress={() => this.setPrecision({multiplyBy: 2})}><Text>+</Text></TouchableOpacity>
                    </View>
                  </View>
                </View>
              )}

              {isSpatial && this.state.treeIsVisible && (
                <TreeHudElement
                  {...{productOrder}}
                  {..._.pick(this, ['setActiveViewIndex'])}
                  {..._.pick(this.state, ['activeViewIndex'])}
                />
              )}

              {/* SPATIAL CANVAS */}
              <View style={{flex: 1, alignItems: 'center', justifyContent: 'center'}} ref={this.getCanvasRef}>
                {isSpatial && this.views[this.state.activeViewIndex] ? (this.archWallsAreLoaded && (
                  <SpatialCanvas
                    {...{canvasSize, selectedEntityId, selectedEntityResourceKey, productOptionsById}}
                    {..._.pick(this, ['setPrecision', 'views', 'setActiveViewIndex'])}
                    {..._.pick(this.props, ['session', 'updateEntities', 'productCanvasTexts', 'productsById', 'productInstances', 'trackEntities', 'trackProductInstances', 'productPropertiesById', 'productRulesById', 'updateProductInstance'])}
                    {..._.pick(this.state, ['precision', 'tentativeArchWall', 'tentativeArchElevation', 'tentativeCalicoMuralSeam', 'tentativeCalicoMismatchLine', 'activeViewIndex'])}
                    activeView={this.views[this.state.activeViewIndex]}
                    productCanvasLines={[
                      ...(this.props.productCanvasLines ? _.values(this.props.productCanvasLines) : []),
                      ...(this.state.tentativeProductCanvasLine ? [this.state.tentativeProductCanvasLine] : [])
                    ]}
                    setArchCustomViewDrawingNotesData={({archCustomViewId, drawingNotesData}) => this.props.updateArchCustomView({id: archCustomViewId, props: {drawingNotesData}})}
                    archWalls={[
                      ...(this.props.archWalls ? _.values(this.props.archWalls) : []),
                      ...(this.state.tentativeArchWall ? [this.state.tentativeArchWall] : [])
                    ]}
                    archElevations={[
                      ...(this.props.archElevations ? _.values(this.props.archElevations) : []),
                      ...(this.state.tentativeArchElevation ? [this.state.tentativeArchElevation] : [])
                    ]}
                    archCustomViews={this.props.archCustomViews}
                    calicoMuralAreas={_.filter(this.props.entities, {type: 'calicoMuralArea'})}
                    calicoMuralTextures={_.filter(this.props.entities, {type: 'calicoMuralTexture'})}
                    calicoMuralPanels={_.filter(this.props.entities, {type: 'calicoMuralPanel'})}
                    calicoMuralSeams={[
                      ...(_.filter(this.props.entities, {type: 'calicoMuralSeam'})),
                      ...(this.state.tentativeCalicoMuralSeam ? [this.state.tentativeCalicoMuralSeam] : []),
                    ]}
                    calicoMismatchLines = {[
                      ...(_.filter(this.props.entities, {type: 'calicoMismatchLine'})),
                      ...(this.state.tentativeCalicoMismatchLine ? [this.state.tentativeCalicoMismatchLine] : []),
                    ]}
                    calicoWallLabels={_.filter(this.props.entities, {type: 'calicoWallLabel'})}
                    entities={this.props.entities}
                    setActiveProductInstanceId={this.setActiveProductInstanceId}
                    updateTentativeArchWall={async ({tentativeArchWall}) => {
                      if (tentativeArchWall.to && !this.state.tentativeArchWall.to) {
                        var archWall = await api.create('archWall', {
                          to: tentativeArchWall.to,
                          from: tentativeArchWall.from,
                          height: tentativeArchWall.height,
                          thickness: tentativeArchWall.thickness,
                          productOrderId: productOrder.id,
                          orgId: this.props.session.activeOrg.id
                        });

                        this.props.trackArchWalls({archWalls: [archWall]});

                        this.setState({tentativeArchWall: {from: archWall.to, to: undefined, height: 96, thickness: 4, isTentative: true}});
                      }
                      else {
                        this.setState({tentativeArchWall});
                      }
                    }}
                    updateTentativeArchElevation={async ({tentativeArchElevation}) => {
                      if (tentativeArchElevation.to && !this.state.tentativeArchElevation.to) {
                        var archElevation = await api.create('archElevation', {
                          to: tentativeArchElevation.to,
                          from: tentativeArchElevation.from,
                          height: tentativeArchElevation.height,
                          thickness: tentativeArchElevation.thickness,
                          productOrderId: productOrder.id,
                          orgId: this.props.session.activeOrg.id
                        });

                        this.props.trackArchElevations({archElevations: [archElevation]});
                        this.setState({tentativeArchElevation: undefined});
                      }
                      else {
                        this.setState({tentativeArchElevation});
                      }
                    }}
                    updateTentativeCalicoMuralSeam={async ({tentativeCalicoMuralSeam}) => {
                      if (tentativeCalicoMuralSeam.data.to && !this.state.tentativeCalicoMuralSeam.data.to) {
                        var tentativeCalicoMuralSeam = await api.create('entity', {
                          type: 'calicoMuralSeam',
                          parentId: productOrder.id,
                          parentResourceKey: 'productOrder',
                          orgId: activeOrg.id,
                          data: {
                            ...tentativeCalicoMuralSeam.data,
                            shape: 'line',
                          },
                          isTentative: true
                        });

                        this.props.trackEntities({entities: [tentativeCalicoMuralSeam]});
                        this.setState({tentativeCalicoMuralSeam: undefined});
                      }
                      else {
                        this.setState({tentativeCalicoMuralSeam});
                      }
                    }}
                    updateTentativeCalicoMismatchLine={async ({tentativeCalicoMismatchLine}) => {
                      if (tentativeCalicoMismatchLine.data.to && !this.state.tentativeCalicoMismatchLine.data.to) {
                        var tentativeCalicoMismatchLine = await api.create('entity', {
                          type: 'calicoMismatchLine',
                          parentId: productOrder.id,
                          parentResourceKey: 'productOrder',
                          orgId: activeOrg.id,
                          data: {
                            ...tentativeCalicoMismatchLine.data,
                            shape: 'line',
                          },
                          isTentative: true
                        });

                        this.props.trackEntities({entities: [tentativeCalicoMismatchLine]});
                        this.setState({tentativeCalicoMismatchLine: undefined});
                      }
                      else {
                        this.setState({tentativeCalicoMismatchLine});
                      }
                    }}
                    updateTentativeProductCanvasLine={async ({tentativeProductCanvasLine}) => {
                      if (tentativeProductCanvasLine.to && !this.state.tentativeProductCanvasLine.to) {
                        var tentativeProductCanvasLine = await api.create('productCanvasLine', {
                          orgId: activeOrg.id,
                          from: tentativeProductCanvasLine.from,
                          to: tentativeProductCanvasLine.to,
                          fromSymbol: tentativeProductCanvasLine.fromSymbol,
                          toSymbol: tentativeProductCanvasLine.toSymbol,
                          customViewId: tentativeProductCanvasLine.customViewId,
                          isTentative: true
                        });

                        this.props.trackProductCanvasLines({productCanvasLines: [tentativeProductCanvasLine]});
                        this.setState({tentativeProductCanvasLine: undefined});
                      }
                      else {
                        this.setState({tentativeProductCanvasLine});
                      }
                    }}
                    isShifting={this.state.isShifting}
                    showControls
                    scale={this.props.session.activeOrg.id === 1053 ? 0.08 : _.get(this.props.session.activeOrg, 'appData.designEngine.defaultScale', 2)}
                    setSelectedEntity={(selectedEntityId, selectedEntityResourceKey) => {
                      this.setState({selectedEntityId, selectedEntityResourceKey});
                    }}
                  />
                )) : (!!promptedForProjectInfo && (
                  activeProductInstanceIsInvalid ? (
                    <Text style={{opacity: 0.6, maxWidth: 400, textAlign: 'center'}}>{'This item has an invalid configuration.\n\nPlease update it to have no red options on the right.'}</Text>
                  ) : (<>
                    {productHasScript && (
                      <ProductGraphic
                        type='2d'
                        scale={_.get(this.props.session.activeOrg, 'appData.designEngine.defaultScale', 3)}
                        canvasPadding={{width: 100, height: 300}}
                        {...{product, canvasSize, activeProductInstanceId, productInstance: activeProductInstanceWithData, dimensionsAreVisible, measurementSystem}}
                      />
                    )}
                    {productGraphic && activeProductInstanceWithData && (
                      <ProductGraphic
                        type='3d'
                        nodes={productGraphic.nodes}
                        // scale={_.get(this.props.session.activeOrg, 'appData.designEngine.defaultScale', 3)}
                        {...{product, canvasSize, activeProductInstanceId, productInstance: activeProductInstanceWithData, dimensionsAreVisible, measurementSystem}}
                      />
                    )}
                    {!productGraphic && productHasNoScript && (
                      <View style={{width: '400px', height: '400px', position: 'relative', opacity: 1}}>
                        <Image source={product.thumbnailUrl} style={{width: '100%', height: '100%', resizeMode: 'contain'}} />
                      </View>
                    )}
                    {isCustomProduct && (
                      <ProductInstanceMedia
                        {...{activeProductInstance}}
                      />
                    )}
                  </>)
                ))}
              </View>

            </>)}

            {/* ------ POST-SUBMISSION ELEMENTS ------ */}
            {!isConfiguring && (
              <ScrollView style={{flex: 1, marginTop: K.spacing * 8, paddingVertical: K.spacing * 2}}>
                {/* SUBMITTED FOR APPROVAL */}
                {lastCompletedStatusType === 'submittedForApproval' && (<>
                  {/* APPROVAL FORM - for employees */}
                  {_.includes(['member', 'owner'], role) && (
                    <View style={{alignItems: 'center', justifyContent: 'center', width: '100%', height: '100%'}}>
                      <View style={{width: 500}}>
                        <TextInput
                          autoFocus
                          multiline
                          disableAutoHeight
                          style={{alignSelf: 'stretch', height: 150, paddingVertical: K.margin}}
                          labelledViewStyles={{outerView: {alignSelf: 'stretch'}}}
                          ref={this.getCanvasRef}
                          value={this.state.feedbackNotes}
                          onChange = {({value}) => this.setState({feedbackNotes: value})}
                          label={'Feedback for Customer'}
                        />
                        <View style={{flexDirection: 'row', marginTop: K.spacing}}>
                          <Button label='Requires Changes' onPress={() => this.handleRequiresChangesPress({productOrderId: productOrder.id, feedbackNotes: this.state.feedbackNotes})} style={{backgroundColor: K.colors.deleteRed, flex: 1, marginRight: K.margin}} />
                          <Button dark label='Approve' onPress={() => this.handleOrderApprove({productOrderId: productOrder.id})} style={{flex: 1}}/>
                        </View>
                      </View>
                    </View>
                  )}

                  {/* PENDING REVIEW INDICATOR - for clients */}
                  {!_.includes(['member', 'owner'], role) && (
                    <View style={{flex: 1, alignItems: 'center', justifyContent: 'center'}}>
                      <View style={{backgroundColor: K.colors.gray, padding: K.spacing * 2, width: 500}}>
                        <Text style={{...K.fonts.pageHeader, marginBottom: K.spacing * 2.5}}>Order Submitted</Text>
                        <Text style={{...K.fonts.standard, color: K.colors.overlay, marginBottom: K.spacing * 2}}>Your order has been submitted and is pending review by {activeOrg.nickname}.</Text>
                        {!_.includes(['owner', 'member'], role) && (
                          <TouchableOpacity
                            onPress={() => this.handleRequestStatusUpdate({productOrderId: productOrder.id})}
                            style={{backgroundColor: '#000000', paddingVertical: K.margin * 2, paddingHorizontal: K.margin * 2.5, width: '45%', marginTop: K.spacing * 10}}>
                            <Text style={{...K.fonts.standard, color: '#ffffff'}}>Request a status update</Text>
                          </TouchableOpacity>
                        )}
                      </View>
                    </View>
                  )}
                </>)}

                {/* STATIC STATUS VIEW */}
                {(_.includes(['static', 'inProduction', 'shipped', 'delivered', 'afterSaleService'], activeStatusType) && lastCompletedProductOrderStatus.key !== 'orderStarted') && (
                  <View style={{flex: 1, alignItems: 'center', justifyContent: 'center'}}>
                    <View style={{backgroundColor: K.colors.gray, padding: K.spacing * 2, margin: 'auto', maxWidth: 500, ...K.shadow}}>
                      <Text style={{...K.fonts.pageHeader, marginBottom: K.spacing * 2}}>
                        {productOrder.isStocked && _.get(lastCompletedProductOrderStatus, 'stockedOrderTitle') ? _.get(lastCompletedProductOrderStatus, 'stockedOrderTitle') : _.get(lastCompletedProductOrderStatus, 'title')}
                      </Text>
                      <Text style={{...K.fonts.standard, color: K.colors.overlay}}>
                        {lastCompletedProductOrderStatusDescription}
                      </Text>
                      <View style={{marginTop: K.spacing * 2}}>
                        <Text style={{...K.fonts.standard, color: K.colors.overlay, marginBottom: K.spacing}}>
                          {`Amount Paid: $${formatPrice(productOrder.amountPaidInCents)}`}
                        </Text>
                        <Text style={{...K.fonts.standard, color: K.colors.overlay, marginBottom: K.spacing}}>
                          {`Total Order Amount: $${formatPrice(orderCosts.orderTotal)}`}
                        </Text>
                        {productOrder.amountPaidInCents !== orderCosts.total && (
                          <Text style={{...K.fonts.standard, color: K.colors.overlay, marginBottom: K.spacing}}>
                            {`Balance Due${!shippedWithoutFinalPayment ? ' Prior to Shipment' : ''}: $${formatPrice(dinero({amount: orderCosts.orderTotal}).subtract(dinero({amount: productOrder.amountPaidInCents})).getAmount())}`}
                          </Text>
                        )}
                      </View>
                      {productOrder.orgId === 850 && (
                        <View>
                          {(_.has(statusData, 'shipped') && productOrder.trackingNumber) && (
                            <Text style={{...K.fonts.standard, color: K.colors.overlay}}>
                              {`Tracking number: ${_.get(productOrder, 'trackingNumber')}`}
                            </Text>
                          )}
                          {(!_.has(statusData, 'shipped') && productOrder.estimatedShippingDate) && (
                            <Text style={{...K.fonts.standard, color: K.colors.overlay}}>
                              {`Estimated shipping date: ${moment(productOrder.estimatedShippingDate).format('M/D/YYYY')}`}
                            </Text>
                          )}
                          <View style={{opacity: 0.5, marginTop: K.spacing * 2}}> {/* HINT order status fine print */}
                            {!_.has(statusData, 'shipped') && (
                              <Text style={{marginTop: K.spacing, fontSize: K.calc(12)}}>
                                {!productOrder.estimatedShippingDate ? 'You will receive an email with the estimated shipping date as soon as production is scheduled.' : 'Due to the handcrafted nature of Meljac products, an expected ship date is provided as an estimate and not a guarantee.\n\nYou will receive a notification when your order has shipped from our production facility in France. After your order has been processed by our team in the United States you will receive an additional notification with your tracking number included.'}
                              </Text>
                            )}
                            {lastCompletedProductOrderStatus.key === 'inProduction' && (
                              <Text style={{marginTop: K.spacing, fontSize: K.calc(12)}}>
                                When your order has shipped from France to our team in California you will receive an email notification, including the payment request for any outstanding balance on your order.  Shipping time from France to California is generally 3 days.
                              </Text>
                            )}
                            <Text style={{marginTop: K.spacing, fontSize: K.calc(12)}}>
                              Should you have any questions or concerns, please contact orders@meljac-na.com or call 323-421-7426.
                            </Text>
                            <Text style={{marginTop: K.spacing, fontSize: K.calc(12)}}>
                              We appreciate your business and being a part of your project!
                            </Text>
                          </View>
                        </View>
                      )}
                      {(!_.includes(['owner', 'member'], activeOrg.role) && lastCompletedProductOrderStatus.type !== 'payment') && (
                        <TouchableOpacity
                          onPress={() => this.handleRequestStatusUpdate({productOrderId: productOrder.id})}
                          style={{...K.fonts.standard, paddingHorizontal: K.spacing, paddingVertical: K.spacing * 1, borderRadius: K.borderRadius * 3, backgroundColor: K.colors.doubleGray, marginTop: K.spacing * 2, justifyContent: 'center', alignItems: 'center'}}
                        >
                          <Text style={{...K.fonts.standard}}>Request a status update</Text>
                        </TouchableOpacity>
                      )}
                    </View>
                  </View>
                )}

                {/* PAYMENT FORM */}
                {(activeStatusType === 'payment' || shippedWithoutFinalPayment) && (
                  <View style={{...(shippedWithoutFinalPayment && {marginTop: K.spacing * 2})}}>
                    <PaymentForm
                      paymentMethods={_.get(session, 'activeOrg.appData.designEngine.paymentMethods')}
                      activePaymentMethod={this.state.activePaymentMethod}
                      onPaymentMethodChange={(key) => this.setState({activePaymentMethod: key})}
                      onPayInFullChange={({value}) => this.setState({payInFull: value})}
                      totalPrice={paymentAmount}
                      onConfirmPayment={({paymentData}) => {
                        this.handleConfirmedPayment({productOrderId: productOrder.id, paymentData, paymentMethod: this.state.activePaymentMethod, isGuestMode, payInFull});
                      }}
                      handleStripePaymentMethodChange={({type}) => {
                        if (this.state.stripeActivePaymentMethod !== type) {
                          this.setState({stripeActivePaymentMethod: type});
                        }
                      }}
                      {...{orderCosts, productOrder, productInstances, lastCompletedProductOrderStatus, session, isCardPayment, stripeActivePaymentMethod, isGuestMode, isEmployee, payInFull, paymentAmount, shouldApplyUpcharge, upchargePercentage, upchargeAmount, shippedWithoutFinalPayment, activeProductOrderStatus}}
                    />
                  </View>
                )}
              </ScrollView>
            )}

            {/* POPUPS & CONDITIONAL VIEWS - don't need to worry about current status because the buttons that
              would show these aren't shown in the first place in cases where these popups would be disabled */}
            {this.state.feedbackNotesPopupIsVisible && (
              <Popup onClose={() => this.setState({feedbackNotesPopupIsVisible: false})}>
                <Text style={{...K.fonts.pageHeader, marginBottom: K.spacing}}>Action Required</Text>
                <Text>{productOrder.feedbackNotes}</Text>
              </Popup>
            )}
            {this.state.productInstanceNotesPopupIsVisible && (
              <Popup onClose={() => this.setState({productInstanceNotesPopupIsVisible: false})}>
                <TextInput
                  grayLabelledView
                  multiline
                  standardAutoheightStyles
                  label={'Notes'}
                  labelledViewStyles={{outerView: {marginBottom: K.margin}}}
                  placeholder={'Enter notes for this specific product...'}
                  value={this.props.productInstancesById[this.state.activeProductInstanceId].notes}
                  onChange={({value}) => this.handleProductInstanceUpdate({props: {notes: value}})}
                />
                <Button
                  nativeID={'OrderPageProductInstanceNotesDeleteButton'}
                  label='Delete'
                  onPress={this.deleteProductInstance}
                  style={{backgroundColor: K.colors.deleteRed, marginTop: K.spacing}}
                />
                {product && !!product.links && (
                  <View style={{paddingVertical: K.spacing * 1.5}}>
                    {_.map(_.split(product.links, '\n'), (linkData) => {
                      var [link, text] = _.split(linkData, ',');

                      return (
                        <a href={link} target={'_blank'} style={{
                          display: 'flex',
                          marginBottom: K.spacing / 2,
                          textDecoration: 'none',
                          backgroundColor: K.colors.gray,
                          minHeight: K.inputHeight,
                          padding: K.margin,
                          borderRadius: K.borderRadius,
                          alignItems: 'center',
                          justifyContent: 'center'
                        }}
                        rel="noreferrer"
                        >
                          <Text>{text}</Text>
                        </a>
                      );
                    })}
                  </View>
                )}
                <View style={{paddingTop: K.spacing, flexWrap: 'wrap', flexDirection: 'row', width: '100%', justifyContent: 'space-between'}}>
                  {_.map(productMedia, (medium) => <Medium key={medium.id} {...{medium}} style={{width: mediumSize, height: mediumSize, marginBottom: K.margin}}/>)}
                </View>
              </Popup>
            )}
            {this.state.createInstancePopupIsVisible && (
              <Popup onClose={() => this.setState({createInstancePopupIsVisible: false})}>
                {_.size(products) === 0 ? (
                  <View style={{alignItems: 'center'}}>
                    <Text>No Products Available to select.</Text>
                  </View>
                ) : _.map(_.sortBy(products, ['rank', 'id']), (product) => (
                  <View
                    key={product.id}
                    style={{flexDirection: 'row', marginBottom: K.spacing}}
                    dataSet={{conditionalOpacityParent: 1}}
                  >
                    <TouchableOpacity
                      style={{justifyContent: 'center', alignItems: 'center', marginRight: K.spacing, borderRadius: 50, borderWidth: 1, borderColor: 'rgba(0, 0, 0, 0.2)', width: 100, height: 100}}
                      onPress={() => {
                        this.setState({createInstancePopupIsVisible: false});

                        if (product.instanceTitleIsRequired) {
                          this.setState({tentativeProductToCreate: product});
                          this.setState({productInstanceTitlePopupIsVisible: true});
                        }
                        else {
                          this.createProductInstance({productId: product.id, orderLevelProperties});
                        }
                      }}
                    >
                      {product.thumbnailUrl && (
                        <Image source={product.thumbnailUrl ? product.thumbnailUrl : null} style={{width: '100%', height: '100%', borderRadius: 50, position: 'relative', opacity: 1}} resizeMode='cover'/>
                      )}
                      <View dataSet={{conditionalOpacity: 1}} style={{position: 'absolute', width: '100%', height: '100%', justifyContent: 'center', alignItems: 'center', backgroundColor: 'rgba(255, 255, 255, 0.9)', borderRadius: 100}}>
                        <Image source={createIcon} style={{width: K.calc(20), height: K.calc(20) }} />
                      </View>
                    </TouchableOpacity>
                    <View style={{paddingVertical: K.spacing, flex: 1, alignSelf: 'stretch', justifyContent: 'center'}}>
                      <Text style={{fontWeight: 'bold'}}>{product.title}</Text>
                      {product.description && (
                        <Text>{product.description}</Text>
                      )}
                    </View>
                  </View>
                ))}
              </Popup>
            )}
            {!!this.state.settingsPopupIsVisible && (
              <ProductOrderSettingsPopup
                onClose={() => this.setState({settingsPopupIsVisible: false})}
                duplicateProductOrder={this.duplicateProductOrder}
                {...{productOrder, totalPrice, activeOrg, orgId}}
              />
            )}
            {!!this.state.productInstanceTitlePopupIsVisible && (
              <ProductInstanceTitlePopup
                hideProductInstanceTitlePopup={() => this.setState({productInstanceTitlePopupIsVisible: false})}
                createProductInstance={({product, title}) => this.createProductInstance({productId: product.id, orderLevelProperties, productInstanceTitle: title})}
                product={productOrder.productId ? productsById[productOrder.productId] : this.state.tentativeProductToCreate}
              />
            )}
          </View>
          {!!this.state.mediaPopupIsVisible && (
            <MediaPopup
              resourceKey={this.state.mediaPopupResourceKey}
              resourceId={this.state.mediaPopupResourceId}
              relevantMedia={this.state.mediaPopupRelevantMedia}
              onClose={() => this.setState({mediaPopupIsVisible: false})}
            />
          )}
          {(!!cartIsVisible && session.activeOrg.id !== 1798 && session.activeOrg.id !== 1053) && (
            <Cart
              {...{product, productOrder, activeProductInstanceId}}
              productOrder={productOrder}
              setActiveInstanceId={({id}) => this.setActiveProductInstanceId(id)}
            />
          )}
        </View>
      </DocumentTitle>
    );
  }
}

export default connect({
  mapState: (state, ownProps) => {
    var productOrderOrgSpecificId = parseInt(ownProps.match.params.productOrderOrgSpecificId);
    var productOrder = _.values(state.resources.productOrders.byFieldKeyIndex.orgSpecificId[productOrderOrgSpecificId])[0];

    if (productOrder) {
      var productOrderId = productOrder.id;
      var productsById = state.resources.products.byId;
      var productPropertiesById = state.resources.productProperties.byId;
      var productInstances = _.sortBy(_.filter(state.resources.productInstances.byId, {productOrderId}), 'rank');
      var productInstancesById = _.get(state.resources.productInstances, `byFieldKeyIndex.productOrderId.${productOrderId}`);
      var productPricingRulesById = state.resources.productPricingRules.byId;
      var productOptionsById = state.resources.productOptions.byId;
      var productOptionClassifiersById = state.resources.productOptionClassifiers.byId;
      var productRulesById = state.resources.productRules.byId;
      var productPropertyGroups = state.resources.productPropertyGroups.byId;
      var media = state.resources.media.byId;
      var usersById = state.resources.users.byId;
      var productGraphic;
    }

    //TODO not sure why these are empty to start
    if (productOrder && state.resources.archWalls.byFieldKeyIndex) {
      var archWalls = state.resources.archWalls.byFieldKeyIndex.productOrderId[productOrder.id];
    }

    if (productOrder && state.resources.entities.byFieldKeyIndex) {
      var entities = state.resources.entities.byFieldKeyIndex.parentId[productOrder.id];
    }

    if (state.session.activeOrg.id === 1450) {
      productGraphic = state.resources.productGraphics.byId[1];
    }

    if (productOrder && state.resources.archCustomViews.byFieldKeyIndex) {
      var archCustomViews = state.resources.archCustomViews.byFieldKeyIndex.productOrderId[productOrderId];
    }

    var cartIsVisible = _.get(state, 'session.user.appData.designEngine.cartIsVisible', true);
    var dimensionsAreVisible = _.get(state, 'session.user.appData.designEngine.dimensionsAreVisible', true);

    return {
      archCustomViews,
      archWalls,
      archWallsAreLoaded: state.session.activeOrg.id === 1798 ? state.resources.archWalls.isLoading === false : true, //WARNING specific conditional check to avoid rendering arch canvas until arch walls are loaded for offset calculation
      productCanvasTexts: state.resources.productCanvasTexts.byId,
      productCanvasLines: state.resources.productCanvasLines.byId,
      entities,
      productOrder,
      productGraphic,
      productsById,
      productInstances,
      productInstancesById,
      productPropertiesById,
      productPricingRulesById,
      productOptionsById,
      productOptionClassifiersById,
      productRulesById,
      productPropertyGroups,
      usersById,
      media,
      cartIsVisible,
      dimensionsAreVisible,
      activeMediumId: state.activeView.data.activeMediumId,
      activeOrderViewData: state.activeView.data.activeOrderViewData
    };
  },
  mapDispatch: {
    ..._.pick(resourceActions.productInstances, ['trackProductInstances', 'updateProductInstances', 'updateProductInstance', 'destroyProductInstance', 'createProductInstances']),
    ..._.pick(resourceActions.productOrders, ['updateProductOrder', 'destroyProductOrder', 'trackProductOrders']),
    ..._.pick(resourceActions.archElevations, ['trackArchElevations', 'updateArchElevations']),
    ..._.pick(resourceActions.archWalls, ['trackArchWalls', 'updateArchWalls']),
    ..._.pick(resourceActions.archCustomViews, ['trackArchCustomViews', 'updateArchCustomView']),
    ..._.pick(resourceActions.entities, ['trackEntities', 'updateEntity', 'createEntity', 'updateEntities']),
    ..._.pick(resourceActions.productCanvasTexts, ['trackProductCanvasTexts', 'updateProductCanvasText']),
    ..._.pick(resourceActions.productCanvasLines, ['trackProductCanvasLines', 'updateProductCanvasLine']),
    setActiveView, setAppData
  }
})(withKeyEvents(OrderPage));
