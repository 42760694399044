import { Component } from 'react';
import { TextInput, LabelledView, PickerInput } from '@symbolic/rn-lib';
import { View } from 'react-native';
import { keyedStates, stateOptions } from '~/helpers/address-input-keyed-states.js';
import { keyedCountries, countryOptions } from '~/helpers/address-input-keyed-countries';

class AddressInput extends Component {
  constructor(props) {
    super(props);

    this.handleChange = this.handleChange.bind(this);
  }

  handleChange({key, value}) {
    var deliveryAddress = _.cloneDeep(this.props.value);

    deliveryAddress = _.set(deliveryAddress, key, value);

    this.props.onChange({value: deliveryAddress});
  }

  render() {
    return (
      <LabelledView gray label={_.startCase(this.props.type)} styles={this.props.labelledViewStyles}>
        <View styles={{outerView: {marginBottom: 0}}}>
          <TextInput
            textContentType='streetAddressLine1'
            key={'streetAddress'}
            placeholder={'STREET ADDRESS'}
            value={_.get(this.props.value, 'streetAddress', '')}
            onChange={({value}) => this.handleChange({key: 'streetAddress', value})}
          />
        </View>
        <View style={{flexDirection: 'row'}}>
          {_.map(['streetAddress2'], key => (
            <TextInput
              textContentType='streetAddressLine2'
              key={key}
              placeholder={_.upperCase(_.join(_.words(key), ' '))}
              value={_.get(this.props.value, key, '')}
              onChange={({value}) => this.handleChange({key: key, value})}
              style={{flex: 1, width: '100%'}}
            />
          ))}
          <PickerInput
            key={'country'}
            autocomplete='country'
            // placeholderValue=''
            options= {countryOptions()}
            value={_.get(this.props.value, 'country', 'US')}
            onChange={({value}) => this.handleChange({key: 'country', value})}
            style={{flex: 1, width: '100%'}}
            buttonStyle={{backgroundColor: 'transparent'}}
          />
        </View>
        <View style={{flexDirection: 'row'}}>
          <TextInput
            key={'city'}
            placeholder={'CITY'}
            textContentType='addressCity'
            value={_.get(this.props.value, 'city', '')}
            onChange={({value}) => this.handleChange({key: 'city', value})}
            style={{flex: 1, width: '100%'}}
          />
          {_.get(this.props.value, 'country', '') === 'United States' || _.get(this.props.value, 'country', '') === '' ? (
            <PickerInput
              key={'state'}
              autocomplete='address-level1'
              options= {stateOptions()}
              value={_.get(this.props.value, 'state', '')}
              onChange={({value}) => this.handleChange({key: 'state', value})}
              placeholderValue={''}
              style={{flex: 1, width: '100%'}}
              buttonStyle={{backgroundColor: 'transparent'}}
            />
          ) : (
            <TextInput
              key={'state'}
              placeholder={'STATE'}
              textContentType='addressState'
              value={_.get(this.props.value, 'state', '')}
              onChange={({value}) => this.handleChange({key: 'state', value})}
              style={{flex: 1, width: '100%'}}
            />
          )}
          <TextInput
            key={'zip'}
            placeholder={'ZIP'}
            textContentType='postalCode'
            value={_.get(this.props.value, 'zip', '')}
            onChange={({value}) => this.handleChange({key: 'zip', value})}
            style={{flex: 1, width: '100%'}}
          />
        </View>
      </LabelledView>
    );
  }
}

export default AddressInput;
