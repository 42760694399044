import { PureComponent } from 'react';
import { View, Text, TouchableOpacity } from 'react-native';
import { PickerInput, TextInput } from '@symbolic/rn-lib';
import K from '~/k';
import _ from 'lodash';
import EngravingSymbolsPicker from './engraving-symbols-picker';

export default class CustomEngravingsInput extends PureComponent {
  state = {
    activeSymbolFileName: _.get(this.props.productInstance, `properties[27].engravings.${this.props.mechanismIndex}.${this.props.engravingLocationKey}[${this.props.inputIndex}].symbol`, '')
  };

  handleUpdateEngravings = ({mechanismIndex, engravingLocationKey, inputIndex, type, value}) => {
    var updatedEngravingObject = {[type]: value};
    var updatedEngravings = _.cloneDeep(this.props.engravings);
    var updatedMechanismEngravingData = _.get(updatedEngravings, `${mechanismIndex}`, {[engravingLocationKey]: []});

    //HINT set inputIndex 0 to default value if null
    if (inputIndex === 1 && !_.get(updatedMechanismEngravingData, `${engravingLocationKey}[0]`)) {
      _.set(updatedMechanismEngravingData, `${engravingLocationKey}[0]`, {text: ''});
    }

    _.set(updatedMechanismEngravingData, `${engravingLocationKey}[${inputIndex}]`, updatedEngravingObject);

    _.set(updatedEngravings, `${mechanismIndex}`, updatedMechanismEngravingData);

    this.props.updateEngravings({engravings: updatedEngravings});
  };

  render () {
    var engravingTypeOptions = [
      {value: 'text', title: 'TEXT'},
      {value: 'symbol', title: 'SYMBOLS'}
    ];

    var {engravingLocationKey, inputIndex, type, mechanismIndex, engravings, productInstance, productProperty} = this.props;

    var styles = {
      inputLabel: {
        ...K.fonts.label, fontSize: 11, letterSpacing: '.04rem'
      }
    };

    return (
      <View>
        <View style={{display: 'flex', flex: 1, flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between'}}>
          <View style={{display: 'flex', flexDirection: 'row', gap: K.spacing, marginLeft: K.spacing}}>
            <Text style={{...styles.inputLabel}}>{`Mechanism ${mechanismIndex + 1} ${engravingLocationKey}`}</Text>
            {(type === 'symbol' && this.state.activeSymbolFileName !== '') && (
              <TouchableOpacity
                onPress={() => {
                  this.setState({activeSymbolFileName: ''});
                  this.handleUpdateEngravings({mechanismIndex, engravingLocationKey, inputIndex, type: 'symbol', value: ''});
                }}
              >
                <Text style={{...styles.inputLabel, opacity: 0.4, textTransform: 'uppercase'}}>clear</Text>
              </TouchableOpacity>
            )}
          </View>
          <PickerInput
            options= {engravingTypeOptions}
            value={type}
            onChange={({value}) => this.handleUpdateEngravings({mechanismIndex, engravingLocationKey, inputIndex, type: value, value: ''})}
            style={{color: K.colors.gray, opacity: 0.9, height: K.inputHeight}}
            buttonStyle={{backgroundColor: 'transparent', height: K.inputHeight, ...K.fonts.label, textAlign: 'right', cursor: 'pointer'}}
          />
        </View>
        {type === 'text' && (
          <TextInput
            style={{ backgroundColor: K.colors.gray, marginBottom: K.spacing / 2}}
            placeholder={`${inputIndex === 0 ? 'LINE 1' : 'LINE 2'}`}
            value={_.get(engravings, `${mechanismIndex}.${engravingLocationKey}.${inputIndex}.${type}`, '')}
            onBlur={({value}) => this.handleUpdateEngravings({mechanismIndex, engravingLocationKey, inputIndex, type: 'text', value})}
            onInput={({value}) => _.debounce(() => this.handleUpdateEngravings({mechanismIndex, engravingLocationKey, inputIndex, type: 'text', value}), 400)}
          />
        )}
        {type === 'symbol' && (
          <View style={{marginBottom: K.spacing / 2}}>
            <EngravingSymbolsPicker
              style={{ backgroundColor: K.colors.gray, marginBottom: K.spacing}}
              {...{productInstance, productProperty, inputIndex, engravingLocationKey, mechanismIndex}}
              handleUpdateEngravings={this.handleUpdateEngravings}
              activeSymbolFileName={this.state.activeSymbolFileName}
              handleUpdateActiveSymbolFileName={({value}) => this.setState({activeSymbolFileName: value})}
            />
          </View>
        )}
      </View>
    );
  }
}
