import { useContext, useEffect } from 'react';
import { toCanvas } from '~/helpers/canvas/canvas-helpers';
import { connect } from '@symbolic/redux';
import { resourceActions } from '~/redux';
import { Rect } from 'react-konva';

import ArchDataContext from '~/contexts/arch-data-context';
import CanvasDataContext from '~/contexts/canvas-data-context';
import RectTool from '~/components/canvas/tools/rect/rect-tool';
import ProductGraphic from '~/components/product-graphic';
import getSizeForProductInstance from '~/helpers/product/get-size-for-product-instance';

function SpatialCanvasProductInstance({productInstance, product, ...props}) {
  var archData = useContext(ArchDataContext);
  var canvasData = useContext(CanvasDataContext);

  var {position} = productInstance.properties;
  var size = getSizeForProductInstance({productInstance});

  var isSelected = archData.selectedEntityId === productInstance.id;

  useEffect(() => {
    var handleDestroyEntity = (event) => {
      if (event.code === 'Backspace' && isSelected) {
        props.destroyProductInstance({id: productInstance.id});

        archData.setSelectedEntity(undefined, undefined);
      }
    };

    document.addEventListener('keydown', handleDestroyEntity);

    return () => {
      document.removeEventListener('keydown', handleDestroyEntity);
    };
  });

  return (
    <>
      <Rect
        {...toCanvas(position, canvasData)}
        {..._.mapValues(size, (value) => value * canvasData.scale)}
        stroke='gray'
        strokeWidth={0.5}
      />
      <ProductGraphic
        type='2d'
        isCanvas
        scale={canvasData.scale}
        {...{product, position, canvasSize: {width: 1000, height: 1000}, activeProductInstanceId: archData.selectedEntityId, productInstance, dimensionsAreVisible: true}}
      />
      <RectTool
        {...{isSelected, size}}
        position={toCanvas(position, canvasData)}
        isScalable={false}
        onSelect={() => {
          archData.setSelectedEntity(productInstance.id, 'productInstance');
          archData.setActiveProductInstanceId(productInstance.id);
        }}
        onTransform={({position}) => {
          props.updateProductInstance({id: productInstance.id, props: {properties: {...productInstance.properties, position}}, hitApi: false});
        }}
        onTransformEnd={() => {
          props.updateProductInstance({id: productInstance.id, props: {properties: productInstance.properties}});
        }}
      />
    </>
  );
}

export default connect({
  mapDispatch: {
    ..._.pick(resourceActions.productInstances, ['updateProductInstance', 'destroyProductInstance'])
  }
})(SpatialCanvasProductInstance);
