import _ from 'lodash';
import { rotatePoint, getLineLength } from '~/helpers/trig/trig-helpers';

function threeDToTwoD({x, y, z}, canvasData) {
  var {x: x2d} = rotatePoint({x, y: z}, {alpha: canvasData.cameraXZAngle || 0});
  var {x: y2d} = rotatePoint({x: z, y: y}, {alpha: canvasData.cameraYAngle || 0});

  return {x: x2d, y: y2d};
}

function twoDToThreeD({x, y}, canvasData) {
  var {x: x2d, y: z2d} = rotatePoint({x, y: 0}, {alpha: -canvasData.cameraXZAngle || 0});

  return {x: x2d, y, z: z2d};
}

function getPlanesForCube({position, dimensions, rotation}) {
  var {x, y, z} = position;
  var {width, height, depth} = dimensions;

  return _.map([
    [{x: 0, y: 0, z: 0}, {x: width, y: 0, z: 0}, {x: width, y: 0, z: depth}, {x: 0, y: 0, z: depth}],
    [{x: 0, y: 0, z: 0}, {x: width, y: 0, z: 0}, {x: width, y: height, z: 0}, {x: 0, y: height, z: 0}],
    [{x: 0, y: 0, z: 0}, {x: 0, y: height, z: 0}, {x: 0, y: height, z: depth}, {x: 0, y: 0, z: depth}],
    [{x: 0, y: height, z: 0}, {x: width, y: height, z: 0}, {x: width, y: height, z: depth}, {x: 0, y: height, z: depth}],
    [{x: 0, y: 0, z: depth}, {x: width, y: 0, z: depth}, {x: width, y: height, z: depth}, {x: 0, y: height, z: depth}],
    [{x: width, y: 0, z: 0}, {x: width, y: height, z: 0}, {x: width, y: height, z: depth}, {x: width, y: 0, z: depth}],
  ], points => {
    if (rotation.xz) {
      points = _.map(points, ({x, y, z}) => {
        var {x: rotatedX, y: rotatedZ} = rotatePoint({x, y: z}, {alpha: rotation.xz || 0});

        return ({x: rotatedX, y, z: rotatedZ});
      });
    }

    return _.map(points, ({x: px, y: py, z: pz}) => ({x: px + x, y: py + y, z: pz + z}));
  });
}

function getPlaneForRect({position, size, rotation}) {
  var {x, y, z} = position;
  var {width, height} = size;

  return _.map([
    {x: 0, y: -height, z: 0},
    {x: 0, y: 0, z: 0},
    {x: width, y: 0, z: 0},
    {x: width, y: -height, z: 0}
  ], (point) => {
    //HINT: y rotation only happens on a rectangle that is “upright” or not on the xz plane
    if (!!rotation && rotation.y) {
      var {x: rotatedX, y: rotatedY} = rotatePoint({x: point.x, y: point.y}, {alpha: rotation.y || 0});

      point = {x: rotatedX, y: rotatedY, z: point.z};
    }

    if (!!rotation && rotation.xz) {
      var {x: rotatedX, y: rotatedZ} = rotatePoint({x: point.x, y: point.z}, {alpha: rotation.xz || 0});

      point = {x: rotatedX, y: point.y, z: rotatedZ};
    }

    return {x: point.x + x, y: point.y + y, z: point.z + z};
  });
}

function toReal({x, y}, canvasData) {
  var transform = {x: canvasData.canvasSize.width / 2, y: canvasData.canvasSize.height / 2};

  return {
    x: (x - transform.x + canvasData.offset.x) / canvasData.scale,
    y: (y - transform.y + canvasData.offset.y) / canvasData.scale
  };
}

function toCanvas({x, y}, canvasData) {
  var transform = {x: canvasData.canvasSize.width / 2, y: canvasData.canvasSize.height / 2};

  return {
    x: (x) * canvasData.scale - canvasData.offset.x + transform.x,
    y: (y) * canvasData.scale - canvasData.offset.y + transform.y
  };
}

function pathDataFrom({points, closed, scale = 1}) {
  var pathCommands = [];

  points.forEach((point, p) => {
    var command = [];

    if (p > 0) {
      var lastPoint = points[p - 1];

      if (point.arc) {
        // var delta = {x: point.x - lastPoint.x, y: point.y - lastPoint.y};
        // var semicircleRadius = (Math.abs(delta.x) + Math.abs(delta.y)) / 2;
        // var magnitude = scale / (point.magnitude || 1);
        // var radii = {x: semicircleRadius * magnitude, y: semicircleRadius * magnitude};

        // if (point.quarterCircle) {
        //   ['x', 'y'].forEach(axis => {
        //     radii[axis] = Math.abs(delta[axis]) * magnitude;
        //   });
        // }

        // var angle = lib.math.trig.alpha({p1: lastPoint, p2: point, perpendicular: 1});
        // var isFlipped = point.flipped ? 1 : 0;

        // command.push('A', radii.x, radii.y, angle, 0, isFlipped);
      }
      else {
        command.push('L');
      }
    }
    else {
      command.push('M');
    }

    command.push(point.x * scale, point.y * scale);
    pathCommands.push(command.join(' '));
  });

  if (closed) {
    pathCommands.push(['Z']);
  }

  return pathCommands.join(' ');
}

function snap(position, candidateSnapPositions, canvasData, {mode = 'real'} = {}) {
  var {scale} = canvasData;
  var threshold = 5;

  if (mode === 'canvas') position = toReal(position, canvasData);

  var snapData = _.mapValues({x: null, y: null}, (_value, axisKey) => {
    var candidatesData = _.chain(candidateSnapPositions)
      .map(snapPosition => ({snapPosition}))
      .map(data => ({...data, thresholdDistance: Math.abs(data.snapPosition[axisKey] - position[axisKey])}))
      .filter(({thresholdDistance}) => thresholdDistance <= threshold / scale)
      .map(data => ({...data, distance: getLineLength({from: position, to: data.snapPosition})}))
      .sortBy('distance')
      .value();

    var candidateData = candidatesData[0];
    var isSnapped = candidateData && candidateData.snapPosition[axisKey] !== position[axisKey];

    if (!isSnapped) candidateData = undefined;

    return {candidatesData, isSnapped, candidateData, positionValue: _.get(candidateData, `snapPosition.${axisKey}`)};
  });

  position = _.mapValues(snapData, ({positionValue}, axisKey) => positionValue === undefined ? position[axisKey] : positionValue);

  if (mode === 'canvas') position = toCanvas(position, canvasData);

  return {position, wasSnapped: _.mapValues(_.pickBy(_.mapValues(snapData, 'positionValue'), s => s !== undefined), () => true), snapData};
}

var getCanvasContentSize = ({entities, positions, scale = 1}) => {
  if (entities) {
    positions = _.flatMap(entities, ({data: {position: {x, y}, size: {width, height}}}) => [
      {x: x, y: y},
      {x: x + width, y: y + height}
    ]);
  }

  var xs = _.map(positions, 'x'), ys = _.map(positions, 'y');
  var minX = _.min(xs), maxX = _.max(xs);
  var minY = _.min(ys), maxY = _.max(ys);
  var width = maxX - minX, height = maxY - minY;

  return {width: width * scale, height: height * scale};
};

var getCenteredCanvasContentOffset = ({entities, positions, invert = false, scale = 1}) => {
  if (entities) {
    positions = _.flatMap(entities, ({data: {position: {x, y}, size: {width, height}}}) => [
      {x: x, y: y},
      {x: x + width, y: y + height}
    ]);
  }

  var xs = _.map(positions, 'x'), ys = _.map(positions, 'y');
  var minX = _.min(xs), maxX = _.max(xs);
  var minY = _.min(ys), maxY = _.max(ys);
  var width = maxX - minX, height = maxY - minY;

  return {
    x: (invert ? -1 : 1) * (-minX - width / 2) * scale,
    y: (invert ? -1 : 1) * (-minY - height / 2) * scale
  };
};

var getDimensionLines = ({entities, sides = ['top', 'left', 'right', 'bottom']}) => {
  return _.flatMap(entities, ({data: {position: {x, y}, size: {width, height}}}) => [
    ...(_.includes(sides, 'top') ? [{from: {x, y: y}, to: {x: x + width, y: y}}] : []),
    ...(_.includes(sides, 'bottom') ? [{from: {x: x + width, y: height + y}, to: {x: x, y: height + y}}] : []),
    ...(_.includes(sides, 'right') ? [{from: {x: x + width, y: y}, to: {x: x + width, y: height + y}}] : []),
    ...(_.includes(sides, 'left') ? [{from: {x: x, y: height + y}, to: {x: x, y: y}}] : [])
  ]);
};

export {
  threeDToTwoD, twoDToThreeD, getPlanesForCube, getPlaneForRect,
  toReal, toCanvas, pathDataFrom, snap,
  getCenteredCanvasContentOffset, getCanvasContentSize, getDimensionLines
};
