import { api } from '@symbolic/lib';
import { getProductInstancesWithData } from '~/helpers/product-order-helper';

import getDuplicateProductOrderProps from '~/helpers/get-duplicate-product-order-props';

export default async function createBackBoxOrder({
  productInstances, session, productOrder,
  productsById, productRulesById, productPropertiesById, productOptionsById,
  trackProductOrders, createProductInstances
}) {
  var productOrderProps = getDuplicateProductOrderProps(productOrder, {
    orgId: 850,
    title: 'Untitled Order',
    productCategoryId: 5,
    isStocked: true,
    userId: session.user.id,
    type: 'standalone'
  });

  var {data: backBoxProductOrder} = await api.request({uri: '/configurator/create-product-order', body: {productOrderProps}});

  await trackProductOrders({productOrders: [backBoxProductOrder]});

  var productQuantitiesById = {};
  var productInstancesWithData = getProductInstancesWithData({productInstances: productInstances}, {productsById, productRulesById, productPropertiesById, productOptionsById});

  _.forEach(productInstancesWithData, productInstance => {
    var backBoxProductOptionId = _.find(_.map([92, 29, 59, 54], productPropertyId => _.get(productInstance, `propertiesDataById.${productPropertyId}.selectedProductOption.id`)), value => value);

    if (backBoxProductOptionId) {
      var backBoxProductOption = productOptionsById[backBoxProductOptionId];

      if (backBoxProductOption) {
        var backBoxProductId = _.get(backBoxProductOption, 'data.backBoxProductId');

        if (backBoxProductId) {
          productQuantitiesById[backBoxProductId] = (productQuantitiesById[backBoxProductId] || 0) + productInstance.quantity;
        }
      }
    }
  });

  var productInstancePropsSets = _.map(productQuantitiesById, (quantity, productId) => {
    return {
      quantity,
      productId: parseInt(productId),
      productOrderId: backBoxProductOrder.id,
      orgId: 850
    };
  });

  await createProductInstances({propsSets: productInstancePropsSets});

  return {backBoxProductOrder};
}
