import { PureComponent } from 'react';
import { Link, Text } from '@symbolic/rn-lib';

import K from '~/k';

class AdminDataRow extends PureComponent {
  render() {
    var {data, linkTo} = this.props;

    return (
      <Link
        to={linkTo}
        style={{flexDirection: 'row', alignItems: 'center', height: 30, borderRadius: K.borderRadius, backgroundColor: K.colors.gray, marginBottom: K.margin, marginLeft: K.spacing, marginRight: K.spacing}}
      >
        {/* → */}
        <Text style={{width: 50, opacity: 0.5, paddingHorizontal: K.spacing, textAlign: 'center'}}>{data.id}</Text>
        <Text style={{flex: 1, paddingHorizontal: K.spacing, marginLeft: K.spacing}}>{data.title}</Text>
      </Link>
    );
  }
}

export default AdminDataRow;
