import React, { useContext } from 'react';

import { Path } from 'react-konva';
import { pathDataFrom } from '~/helpers/canvas/canvas-helpers';

export default function CanvasPath({...props}) {
  const {points, closed} = props;

  if (!props.data && points?.length > 0) props.data = pathDataFrom({points, closed});

  return <Path shadowForStrokeEnabled={false} perfectDrawingEnabled={false} {...props}/>;
}
