import React from 'react';
import { View } from 'react-native';
import { Text as SFText } from '@symbolic/rn-lib';
import { getProductInstanceWithData, filterInstancePropertiesFor } from '~/helpers/product-order-helper';

import { productGraphicScriptFor } from '~/product-graphic-scripts';
import { getShouldRenderElementForOrg } from '~/helpers/get-should-render-helpers';

import K from '~/k';
import _ from 'lodash';
import Page from '~/components/page';
import PageHeader from '~/components/page-header';
import ProductGraphic from '~/components/product-graphic';
import ProductInstanceMedia from '~/components/product-instance-media';

var Text = ({...props}) => {
  return (
    <SFText {...props} style={{...props.style, letterSpacing: 0}}/>
  );
};

export default function DrawingsStandalonePage({
  page, pageNumber, pageSize, pageCount,
  isPrinting, isPdfing, activeOrg, productOrder,
  productPropertiesById, productOptionsById, productsById, productRulesById, productInstancesById,
  inFactoryMode, inClientMode, drawingSetMode, orderLevelProperties, dimensionsAreVisible, measurementSystem
}) {
  return (
    <Page
      {...{isPrinting}}
      key={pageNumber}
      orientation={inFactoryMode ? 'landscape' : 'portrait'}
      {...pageSize}
      style={{alignItems: 'center', justifyContent: 'center', overflow: 'hidden'}}
    >
      <Text style={{...K.fonts.pageHeader, top: K.spacing * 3, left: K.spacing * 3, position: 'absolute'}}>{productOrder.title}</Text>
      {getShouldRenderElementForOrg({activeOrgId: activeOrg.id, visibleOrgIds: [850]}) && (
        <View style={{position: 'absolute', right: K.spacing * 3, top: K.spacing * 3}}>
          {orderLevelProperties && _.size(orderLevelProperties) > 0 && (<>
            <Text style={{...K.fonts.pageHeader, marginBottom: K.margin}}>ORDER DETAILS</Text>
            {productOrder.productId && (
              <Text style={{marginBottom: K.margin - 3}}>
                <Text style={{fontWeight: 'bold'}}>Collection: </Text>
                {_.get(_.get(productsById, _.get(_.values(productInstancesById), '0.productId')), 'title')}
              </Text>
            )}
            {_.map(orderLevelProperties, (propertyInfo, index) => <Text key={index} style={{marginBottom: K.margin - 3}}><Text style={{fontWeight: 'bold'}}>{_.get(propertyInfo, 'productProperty.title')}: </Text> {_.get(propertyInfo, 'selectedProductOption.title')}</Text>)}
          </>)}
        </View>
      )}
      <View style={{...(inFactoryMode ? {flexDirection: 'row', justifyContent: 'center'} : null)}}>
        {_.map(page.productInstancesData, ({productInstance, indexInProductOrder}, indexInPage) => {
          productInstance = getProductInstanceWithData({productInstance}, {productPropertiesById, productOptionsById, productsById, productRulesById, productInstancesById});

          var {product} = productInstance;
          var productHasScript = !!productGraphicScriptFor({productId: product.id});
          var isCustomProduct = product.id === -1;
          var scale = inClientMode ? 3.7795275591 : 2.5;
          var nonOrderLevelProperties = filterInstancePropertiesFor({productInstance}, {isOrderLevel: 0, type: 'singleSelect', drawingVisibility: 'visible'});

          var productTitle = '';

          if (page.productInstancesData.length > 1) productTitle = `${indexInProductOrder + 1}. `;
          if (productOrder.productId) productTitle += productInstance.title || product.title;
          if (!productOrder.productId) productTitle += (productInstance.title ? ' - ' : '') + product.title;

          return (
            <View style={{justifyContent: 'center', marginLeft: indexInPage === 0 ? 0 : K.spacing * 2, minWidth: 300}} key={indexInProductOrder}>
              {productHasScript && (
                <ProductGraphic type='2d' {...{product, scale, activeProductInstanceId: productInstance.id, productInstance, dimensionsAreVisible: dimensionsAreVisible && inFactoryMode, measurementSystem}}/>
              )}
              {isCustomProduct && (
                <ProductInstanceMedia
                  allowFileUpload={false}
                  {...{activeProductInstance: productInstance}}
                />
              )}
              <View style={{width: '100%', marginTop: K.spacing * 2, alignItems: 'center', maxWidth: 360}}>
                <View>
                  <Text style={{fontWeight: 'bold', marginVertical: 2}}>{productTitle}</Text>
                  <Text style={{marginBottom: K.margin - 3}}>Qty: {productInstance.quantity}</Text>
                  {!isCustomProduct ? (
                    <View>
                      {_.map(nonOrderLevelProperties, (propertyInfo, index) => (
                        <Text key={index} style={{marginBottom: K.margin - 3}}>
                          <Text>{_.get(propertyInfo, 'productProperty.title')}: </Text>
                          {_.get(propertyInfo, 'selectedProductOption.title')}
                        </Text>
                      ))}
                    </View>
                  ) : (
                    <View>
                      <Text style={{marginBottom: K.margin - 3}}>Type: Custom Product</Text>
                      {productInstance.notes && (
                        <Text>Notes: {productInstance.notes}</Text>
                      )}
                    </View>
                  )}
                </View>
              </View>
            </View>
          );
        })}
      </View>
      <PageHeader {...{pageNumber, pageCount, productOrder, activeOrgId: activeOrg.id, drawingSetMode, isPdfing}} renderForDrawings/>
    </Page>
  );
}
