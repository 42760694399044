import { Component } from 'react';
import { View, Image, ScrollView } from 'react-native';
import { Button, Link, Popup, DocumentTitle, Text } from '@symbolic/rn-lib';
import { connect } from '@symbolic/redux';
import { resourceActions } from '~/redux';
import { api } from '@symbolic/lib';

import AdminMenu from '~/components/admin-menu';
import AdminSection from '~/components/admin-section';
import SecondaryHeader from '~/components/secondary-header';
import FileInput from '~/components/file-input';

import K from '~/k';
import handleFileUpload from '~/helpers/upload-file-helper';

import settingsIcon from '~/assets/settings-icon.png';
import thumbnailPlaceholder from '~/assets/thumbnail-placeholder.png';
import _ from 'lodash';

class AdminProductCategoryPage extends Component {
  state = {isProductCategorySettingsPopupVisible: false, image: null, uploading: false, uploadWasSuccessful: false, uploadHasFailed: false};

  toggleProductCategorySettingsPopup = () => this.setState({isProductCategorySettingsPopupVisible: !this.state.isProductCategorySettingsPopupVisible});

  archive = async () => {
    this.props.updateProductCategory({id: this.props.productCategory.id, props: {archived: this.props.productCategory.archived ? 0 : 1}});
  };

  render() {
    const {productCategory, productsById} = this.props;

    var sortedProductsForProductCategory = _.sortBy(_.filter(productsById, {productCategoryId: productCategory.id, archived: 0}), ['rank', 'id']);

    return (
      <DocumentTitle title={`Admin: ${productCategory.title} - Product Category`}>
        <View style={{flex: 1, flexDirection: 'row'}}>
          <AdminMenu activeOrg={this.props.session.activeOrg} />
          <View style={{flex: 1}}>
            <SecondaryHeader
              title={productCategory.title}
              titleIsEditable
              subTitle={`ID: ${productCategory.id}`}
              onTitleChange={({value: title}) => this.props.updateProductCategory({id: productCategory.id, props: {title}})}
              belowHeaderComponent={
                <View style={{marginTop: K.spacing * 2}}>
                  <Button
                    onPress={this.toggleProductCategorySettingsPopup}
                    icon={settingsIcon}
                    style={{backgroundColor: 'transparent', ...K.defaultIconSize}}
                  />
                </View>
              }
            />
            <ScrollView style={{flex: 1}} contentContainerStyle={{padding: K.spacing, flex: 1}}>
              <AdminSection title={'Products'} contentStyle={{marginLeft: K.spacing}}>
                {_.map(sortedProductsForProductCategory, ({rank, title, id}) => (
                  <Text
                    key={id}
                    style={{marginBottom: K.calc(5)}}
                  >
                    {`${rank}. ${title} (ID: ${id})`}
                  </Text>
                ))}
              </AdminSection>
            </ScrollView>
            {this.state.isProductCategorySettingsPopupVisible && (
              <Popup onClose={this.toggleProductCategorySettingsPopup}>
                <View style={{flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between', marginBottom: K.spacing}}>
                  <FileInput
                    containerStyle ={{height: 64}}
                    uploading={this.state.uploading}
                    uploadWasSuccessful={this.state.uploadWasSuccessful}
                    uploadHasFailed = {this.state.uploadHasFailed}
                    handleFilesPicked={(files) => handleFileUpload({files,
                      setState: (newState) => this.setState(newState),
                      api,
                      resource: this.props.productCategory,
                      session: this.props.session,
                      resourceKey: 'productCategory',
                      trackResource: ({productCategories}) => this.props.trackProductCategories({productCategories})
                    })}
                  >
                    <Image source={this.props.productCategory.thumbnailUrl ? {uri: this.props.productCategory.thumbnailUrl} : thumbnailPlaceholder} style={{height: 40, width: 40, border: '1px solid black', borderRadius: 25}}/>
                  </FileInput>
                </View>
                <Button
                  light
                  onPress={this.archive}
                  label={this.props.productCategory.archived === 0 ? 'ARCHIVE' : 'UNARCHIVE'}
                />
              </Popup>
            )}
          </View>
        </View>
      </DocumentTitle>
    );
  }
}

export default connect({
  mapState: (state, ownProps) => ({
    productCategory: _.get(state, `resources.productCategories.byId[${ownProps.match.params.productCategoryId}]`),
    productsById: state.resources.products.byId
  }),
  mapDispatch: {
    ..._.pick(resourceActions.productCategories, ['updateProductCategory', 'destroyProductCategory', 'trackProductCategories'])
  }
})(AdminProductCategoryPage);
