import { partial_ratio } from 'fuzzball';

export function isOrderSearchMatch(searchTerm, productOrder) {
  const isNumberSearch = !isNaN(searchTerm);
  const isNumberSearchMatch = isNumberSearch && productOrder.orgSpecificId === parseInt(searchTerm);
  const isStringSearchMatch = !isNumberSearch && partial_ratio(searchTerm, productOrder.title) > 80 || partial_ratio(searchTerm, productOrder.projectName) > 80 || partial_ratio(searchTerm, (productOrder.ownerName)) > 80 ;

  return isNumberSearchMatch || isStringSearchMatch;
}

export function isOptionSearchMatch(searchTerm, medium) {
  const isStringSearchMatch = !isNumberSearch && partial_ratio(searchTerm, medium.title) > 80;

  return isStringSearchMatch;
}