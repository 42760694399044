export default function getBevel({group, path, rectWidth, rectHeight}) {
  var bevel = group({x: 0, y: 0, width: rectWidth, height: rectHeight}, [
    path({d: [
      {command: 'M', x: 0, y: 0},
      {command: 'H', x: rectWidth},
      {command: 'L', x: rectWidth - 2, y: 2},
      {command: 'H', x: 2},
      {command: 'Z'}
    ], opacity: 0.2}),
    path({d: [
      {command: 'M', x: 0, y: 0},
      {command: 'V', y: rectHeight},
      {command: 'L', x: 2, y: rectHeight - 2},
      {command: 'V', y: 2},
      {command: 'Z'}
    ], opacity: 0.2}),
    path({d: [
      {command: 'M', x: 0, y: rectHeight},
      {command: 'H', x: rectWidth},
      {command: 'L', x: rectWidth - 2, y: rectHeight - 2},
      {command: 'H', x: 2},
      {command: 'Z'}
    ], opacity: 0.5}),
    path({d: [
      {command: 'M', x: rectWidth, y: 0},
      {command: 'V', y: rectHeight},
      {command: 'L', x: rectWidth - 2, y: rectHeight - 2},
      {command: 'V', y: 2},
      {command: 'Z'}
    ], opacity: 0.5})
  ]);

  return bevel;
}