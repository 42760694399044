import { PureComponent } from 'react';
import { View, TouchableOpacity } from 'react-native';
import { ScrollView, Text, TextInput, prepareToAnimate, withKeyEvents } from '@symbolic/rn-lib';
import { productOptionsForProperty, getArrayQuantityForArrayProductProperty, getProductPropertyIsInvalid } from '~/helpers/product-order-helper';
import { connect } from '@symbolic/redux';
import { resourceActions } from '~/redux';
import { getAdminTitleFor } from '~/helpers/product-order-helper';

import getIsBacklitEngravingCompatible from '~/helpers/meljac/get-is-backlit-engraving-compatible';

import _ from 'lodash';
import K from '~/k';

import ProductOption from '~/components/product-option';

class ProductProperty extends PureComponent {
  state = {
    selectedOptionId: null,
    searchTerm: '',
  };

  setActiveProductOption = ({id}) => {
    var {productInstance, productProperty, productsById, productRulesById, productOptionsById, productPropertiesById, session, activeProductInstanceWithData} = this.props;

    var oldProperties = _.cloneDeep(_.get(productInstance, 'properties', {}));
    var newProperties = _.set(_.cloneDeep(oldProperties), productProperty.id, {optionId: id});
    var clonedProductInstance = _.set(_.cloneDeep(productInstance), 'properties', newProperties);
    var emptyProductProperty;

    var allPropertiesWillStillHaveOptionsAvailable = _.every(_.map(newProperties, (_value, productPropertyId) => {
      var productProperty = productPropertiesById[productPropertyId];

      if (productProperty) {
        var availablePropertyOptions = productOptionsForProperty({productProperty, productInstance: clonedProductInstance}, {productRulesById, productOptionsById, productsById});

        if (productProperty.id === 26) { //HINT special rules for MNA backlit engraving type
          var isBacklitEngravingCompatible = getIsBacklitEngravingCompatible({activeProductInstanceWithData});

          if (!isBacklitEngravingCompatible) {
            availablePropertyOptions = _.reject(availablePropertyOptions, {id: 139});
          }
        }

        if (_.isEmpty(availablePropertyOptions)) {
          emptyProductProperty = productProperty;

          return false;
        }
        else {
          return true;
        }
      }
      else {
        return true; //TODO fix some edge case where productProperty is undefined on a new order
      }
    }));

    if (allPropertiesWillStillHaveOptionsAvailable) {
      this.props.updateProductInstance({id: productInstance.id, props: {properties: newProperties}});

      this.setState({selectedOptionId: null, searchTerm: ''});

      if (session.activeOrg.id === 850 && productProperty.id === 7) {
        //HINT display leadtime alert if mechanism finish other than brass or chrome are selected
        if (!_.includes([22, 23], id)) {
          alert('Note: selecting a mechanism finish other than brass or chrome will require additional lead-time.\n\nPlease contact orders@meljac-na.com for specific details.');
        }
      }
    }
    else {
      alert(`Oops selecting this option means  ${_.get(emptyProductProperty, 'title')}  will not have any options. Please select another option or update your other options.`);
    }

    setTimeout(() => this.props.updateDependentProductProperties({optionId: id}));
  };

  get filteredProductOptions() {
    var {activeOrg} = this.props.session;
    var {productProperty, activeProductInstanceWithData} = this.props;

    if (productProperty.options) {
      return productProperty.options;
    }

    var filteredProductOptions = productProperty.type === 'singleSelect' && productOptionsForProperty({..._.pick(this.props, ['productProperty', 'productInstance'])}, {..._.pick(this.props, ['productRulesById', 'productOptionsById', 'productsById'])});

    if (productProperty.id === 26) { //HINT special rules for MNA backlit engraving type
      var isBacklitEngravingCompatible = getIsBacklitEngravingCompatible({activeProductInstanceWithData});

      if (!isBacklitEngravingCompatible) {
        filteredProductOptions = _.reject(filteredProductOptions, {id: 139});
      }
    }
    else if (productProperty.id === 4) { //HINT special rules for MNA classique mechanism layouts
      var selectedBackboxType = _.get(activeProductInstanceWithData, 'propertiesDataById.29.selectedProductOption.data.type');
      var selectedPlateHeight = _.get(activeProductInstanceWithData, 'propertiesDataById.6.selectedProductOption.data.height');

      if (selectedBackboxType === 'MUD RING' && selectedPlateHeight === 82) {
        //HINT reject layouts with toggles on the second row
        filteredProductOptions = _.reject(filteredProductOptions, ({data}) => {
          var {layoutString} = data;
          var layoutStringRows = layoutString.split('-');

          return layoutStringRows[1] ? layoutStringRows[1].includes('T') : false;
        });
      }
    }

    if (activeOrg.role === 'owner') {
      return filteredProductOptions;
    }
    else if (activeOrg.role === 'member') {
      var memberProductOptions = _.filter(filteredProductOptions, {'publishLevel': 'internal'});

      memberProductOptions.push(_.filter(filteredProductOptions, {'publishLevel': 'all'}));

      return _.flatten(memberProductOptions); //incase member products with 'all' is empty
    }
    else {
      return _.filter(filteredProductOptions, {'publishLevel': 'all'});
    }
  }

  suggestedOptionsFor({searchTerm}) {
    var {filteredProductOptions} = this;
    var lowerSearchTerm = _.toLower(searchTerm);

    var suggestedOptions = _.filter(filteredProductOptions, productOption => {
      return _.includes(_.toLower(productOption.title), lowerSearchTerm);
    });

    return suggestedOptions;
  }

  handleSearch = (searchTerm, activeOption) => {
    var suggestedOptions = this.suggestedOptionsFor({searchTerm});

    if (suggestedOptions.length) {
      this.setState({
        selectedOptionId: _.includes(suggestedOptions, activeOption) ? activeOption.id : suggestedOptions[0].id,
        showingSearchErrorMessage: false});
    }
    else {
      this.setState({showingSearchErrorMessage: true});
    }
  };

  handleKeyDown = event => {
    if (this.props.mode === 'order') {
      var {expandedPropertyId, productProperty, chunkSize} = this.props;
      var {suggestedOptions, selectedOptionId, searchTerm} = this.state;

      var suggestedOptions = this.suggestedOptionsFor({searchTerm});

      if ((expandedPropertyId === productProperty.id)) {
        var selectedOptionIndex = _.findIndex(suggestedOptions, {id: selectedOptionId});

        var leftOption = suggestedOptions[selectedOptionIndex - 1];
        var rightOption = suggestedOptions[selectedOptionIndex + 1];
        var upOption = (selectedOptionIndex - chunkSize) >= 0 ? suggestedOptions[selectedOptionIndex - chunkSize] : suggestedOptions[0];
        var downOption = (selectedOptionIndex + chunkSize) <= suggestedOptions.length - 1 ? suggestedOptions[selectedOptionIndex + chunkSize] : suggestedOptions[suggestedOptions.length - 1];

        if (event.keyCode === 37 && leftOption) {
          this.setState({selectedOptionId: leftOption.id});
        }
        else if (event.keyCode === 39 && rightOption) {
          this.setState({selectedOptionId: rightOption.id});
        }
        else if (event.keyCode === 13) {
          this.setActiveProductOption({id: this.state.selectedOptionId});
          this.props.setExpandedPropertyId(null);
        }
        else if (event.keyCode === 40) { //down
          this.setState({selectedOptionId: downOption.id});
        }
        else if (event.keyCode === 38) { //up
          this.setState({selectedOptionId: upOption.id});
        }
        // else if (event.keyCode === 27) { //escape
        //   var previousOptionId = _.get(this.props.productInstance, `properties.${productProperty.id}.optionId`, filteredProductOptions[0]); // this should be changed to choose placeholder option when implemented.
        //   this.setActiveProductOption({id: previousOptionId});
        //   this.props.setExpandedPropertyId(null);
        // }
      }
    }
  };

  render() {
    var {productProperty, productOptions, mode, expandedPropertyId, expandedPropertyId, productRulesById, productsById, productInstance, productOptionsById, productPropertiesById, disabledProductOptionIds, activeProductInstanceWithData, selectedEntityPropertiesData} = this.props;

    if (mode === 'order') {
      var {filteredProductOptions} = this;

      if (selectedEntityPropertiesData) {
        var activeOptionId = _.get(selectedEntityPropertiesData, `propertyValues.${productProperty.id}`);
      }
      else {
        var activeOptionId = _.get(this.props.productInstance, `properties.${productProperty.id}.optionId`, this.state.selectedOptionId);

        if (_.includes(['text', 'select', 'dimensions'], productProperty.type)) {
          var inputQuantity = getArrayQuantityForArrayProductProperty({productInstance, productProperty}, {productOptionsById, productRulesById, productsById, productPropertiesById});
          var {arrayInputProps} = productProperty;
        }
      }
    }

    if (mode === 'admin') {
      var title = getAdminTitleFor({productProperty});
      var filteredProductOptions = _.chain(productOptions)
        .reject('archived')
        .sortBy('rank')
        .value();

      return (
        <View style={{padding: K.spacing, marginRight: K.spacing * 4}}>
          <View>
            <View>
              <Text style={{fontWeight: 'bold', opacity: 1, marginBottom: K.spacing / 2, paddingRight: K.spacing}}>
                {title}
              </Text>
            </View>
            {productProperty.type === 'singleSelect' ? (
              <ScrollView style={{maxHeight: 300}}>
                {_.map(filteredProductOptions, (productOption) => (
                  <ProductOption
                    key={productOption.id}
                    toggleProductOptionDisabled={this.props.toggleProductOptionDisabled}
                    disabled={_.includes(disabledProductOptionIds, productOption.id)}
                    {...{productOption, productProperty, productInstance, mode, activeProductInstanceWithData}}
                  />
                ))}
              </ScrollView>
            ) : (
              <Text>Type: {productProperty.type}</Text>
            )}
          </View>
        </View>
      );
    }
    else if (mode === 'order') {
      var activeOption = _.find(productProperty.options || productOptions, ({id, value}) => _.includes([id, value], this.state.selectedOptionId || activeOptionId)) || filteredProductOptions[0];
      var activeOptionIdIsInvalid = productInstance && getProductPropertyIsInvalid({productInstance, productProperty}, {productsById, productPropertiesById, productRulesById, productOptionsById});
      var selectedValue = activeOption && _.get(activeOption, 'value', activeOption.id);

      return (
        <View style={{marginBottom: K.margin, marginRight: K.spacing, flexDirection: 'row-reverse', alignItems: 'flex-end'}}>
          {_.isEmpty(filteredProductOptions) && productProperty.type === 'singleSelect' ? (
            <Text style={{padding: K.spacing}}>{productProperty.title}: No options available</Text>
          ) : (
            <ProductOption
              {...{
                selectedEntityPropertiesData, productProperty, mode, expandedPropertyId, productInstance,
                inputQuantity, arrayInputProps, activeProductInstanceWithData
              }}
              isActive
              key={activeOptionId}
              setActiveProductOption={this.setActiveProductOption}
              productOption={activeOption}
              isSubdued={!!expandedPropertyId && expandedPropertyId !== productProperty.id}
              setExpandedPropertyId={(value) => this.props.setExpandedPropertyId(value)}
              isInvalid={activeOptionIdIsInvalid}
              handleApplyAll={this.props.handleApplyAll}
            />
          )}
          {expandedPropertyId === productProperty.id && (
            <View style={{position: 'absolute', zIndex: 1, backgroundColor: 'white', flexDirection: 'column', bottom: '120%', borderRadius: K.borderRadius, ...K.shadow}}>
              <ScrollView style={{maxHeight: 300}}
                contentContainerStyle={{paddingTop: K.spacing * 2, paddingBottom: K.spacing, paddingHorizontal: K.margin, width: 275, ...(productProperty.noOptionThumbnails === 0 ? {flexDirection: 'column'} : {flexDirection: 'column', alignItems: 'end'}), justifyContent: 'flex-end', flexWrap: 'wrap', minHeight: 62, maxWidth: 320}}
              >
                {this.state.showingSearchErrorMessage ? (
                  <Text style={{paddingHorizontal: K.spacing, paddingTop: K.spacing / 2}}>No relevant results</Text>
                ) : (
                  _.map(_.filter(filteredProductOptions, productOption => {
                    return _.includes(_.toLower(productOption.title), _.toLower(this.state.searchTerm));
                  }), (productOption) => {
                    var value = _.get(productOption, 'value', productOption.id);

                    return (
                      <TouchableOpacity nativeID={`OrderPageProductPropertyOption-${_.kebabCase(productOption.title)}`}>
                        <ProductOption
                          key={value}
                          setActiveProductOption={this.setActiveProductOption}
                          isActive={selectedValue === value || this.state.selectedOptionId == value}
                          propertyIsExpanded={expandedPropertyId === productProperty.id}
                          setExpandedPropertyId={(value) => this.props.setExpandedPropertyId(value)}
                          handleOrderLevelPropertyChange={(propertyId, optionId) => this.props.handleOrderLevelPropertyChange(propertyId, optionId)}
                          showMediaPopup={this.props.showMediaPopup}
                          {...{productOption, productProperty, productInstance, mode, expandedPropertyId, activeProductInstanceWithData}}
                        />
                      </TouchableOpacity>
                    );
                  })
                )}
              </ScrollView>
              {filteredProductOptions.length > 10 && (
                <TextInput
                  nativeID={'OrderPageProductPropertySearchInput'}
                  style={{paddingHorizontal: K.spacing, backgroundColor: K.colors.gray, width: '100%', alignSelf: 'flex-end', borderTopLeftRadius: 0, borderTopRightRadius: 0}}
                  onChangeText={(value) => {
                    this.setState({searchTerm: value});
                    this.handleSearch(value, activeOption);
                  }}
                  placeholder='SEARCH...'
                  returnKeyType={'done'}
                  value={this.state.searchTerm}
                  autoFocus={K.isWeb}
                  onKeyPress={this.handleKeyDown}
                  onSubmitEditing={() => {
                    prepareToAnimate();
                    //this.setState({searchTerm: ''});

                  }}
                  onFocus={() => {
                    prepareToAnimate();
                  }}
                  onBlur={() => {
                    prepareToAnimate();
                  }}
                />
              )}
              {productProperty.isOrderLevel === 1 && (<View style={{paddingHorizontal: K.spacing, marginBottom: K.spacing, opacity: 0.5, textAlign: 'right'}}>
                <Text>applies to all items in order</Text>
              </View>)}
            </View>
          )}
        </View>
      );
    }
  }
}

export default connect({
  mapState: (state, ownProps) => {
    var productPropertyId = ownProps.productProperty.id;
    var productOptions = _.filter(state.resources.productOptions.byId, {productPropertyId});
    var productOptionsById = state.resources.productOptions.byId;
    var productOptionClassifiersById = state.resources.productOptionClassifiers.byId;
    var productRulesById = state.resources.productRules.byId;
    var productsById = state.resources.products.byId;
    var productPropertiesById = _.get(state.resources, 'productProperties.byId');
    var productInstancesById = _.get(state.resources, 'productInstances.byId');

    return {
      productOptions,
      productOptionsById,
      productOptionClassifiersById,
      productRulesById,
      productsById,
      productPropertiesById,
      productInstancesById
    };
  },
  mapDispatch: {
    ..._.pick(resourceActions.productInstances, ['updateProductInstance'])
  }
})(withKeyEvents(ProductProperty));
