import React from 'react';
import K from '~/k';

import { View, TouchableOpacity, Image } from 'react-native';
import { Link, Text, Label } from '@symbolic/rn-lib';

export default class GetDisclaimer extends React.PureComponent {
  render() {
    var {orgId, disclaimerType} = this.props;
    var disclaimers;

    if (orgId === 850) {
      disclaimers = {
        graphicDisclaimer: 'Plate renderings are provided for coordination purposes and do not represent an exact or binding match of physical products.  We encourage you to view product images for the most accurate graphic examples.',
        generalProductDisclaimer: 'Configurations and details offered in the configurator represent the most frequently used, though nonexhaustive, options.  For any elements that you wish to achieve but do not see, please contact Meljac North America.',
        backBoxDisclaimer: 'NOTE: Meljac back boxes are not included on this quote. Please generate a separate quote from the back box section of stocked products. Back boxes are quoted separately in order to allow for purchasing/rough-in prior to order confirmation.',
        engravingDisclaimer: 'Renderings are provided for coordination purposes and do not represent an exact or binding match of physical products. Please view product images for the most accurate graphic examples.',
        termsAndConditionsDisclaimer: (<>
          <View>
            <Label style={{marginBottom: K.margin * 2}}>Payment Terms</Label>
            <Text>
              For stocked items (back boxes and samples), payment in full is required prior to shipping. For all made-to-order products, a minimum 50% deposit is required once the order is confirmed.  The remaining balance is due once the order is complete.  Final payment is required for the order to ship.
              {'\n\n'}ACH payments, checks, wire transfers, and credit card payments under $3,000.00 can be made with no additional fee. Credit card payments over $3,000 incur a 2.9% processing fee.
              {'\n\n'}Sales tax will be applied on orders where MNA is required to collect sales tax.
            </Text>
            <Text style={{fontWeight: 'bold'}}>
              {'\n'}Checks are payable to Meljac North America and can be mailed to:
            </Text>
            <Text>
              Meljac North America
              {'\n'}2566 Lake View Ave
              {'\n'}Los Angeles, CA 90039
            </Text>
            <Text style={{fontWeight: 'bold'}}>
              {'\n'}The project number and quote name must be clearly noted when mailing a check.
            </Text>
            <Label style={{marginTop: K.spacing * 2.5, marginBottom: K.margin * 2}}>Order Conditions</Label>
            <Text>
            Quotes are valid for 60 days.
              {'\n\n'}All sales orders are final due to the bespoke nature of the product. No cancellations, refunds or changes can be made to an order once it has been submitted by a customer and a deposit has been made.
            </Text>
            <Label style={{marginTop: K.spacing * 2.5, marginBottom: K.margin * 2}}>Lead Times</Label>
            <Text>
            Please allow 16 weeks for standard products (keypads, outlets, reading lamps) with additional time for custom products or finishes. Production times begin upon receipt of deposit. Lead-times may vary and cannot be guaranteed. Project update requests should be made through the MNA client portal.
              {'\n\n'}Shipping is FOB Los Angeles 90039.
              {'\n\n'}Storage, due to insurance and space constraints, cannot be provided longer than 2 weeks from order completion and notification. Storage of store purchased goods beyond 2 weeks will be billed at a weekly rate of $250.
            </Text>
          </View>
        </>)
      };
    }

    return (
      <Text style={{...K.fonts.standard, paddingTop: K.margin, paddingBottom: K.margin, opacity: 0.7}}>{disclaimers[disclaimerType]}</Text>
    );
  }
}
