
import { View } from 'react-native';
import { useRef, useState } from 'react';
import { getDimensionLines, getCenteredCanvasContentOffset } from '~/helpers/canvas/canvas-helpers';
import { Text } from '@symbolic/rn-lib';

import _ from 'lodash';
import uuid from 'uuid';
import Page from '~/components/page';
import CanvasView from '~/components/canvas/view/canvas-view.js';
import ArchDataContext from '~/contexts/arch-data-context';
import DrawingsArchFooter from '~/pages/drawings/page-types/arch/footer/drawings-arch-footer.js';
import DrawingsPageHeader from '~/pages/drawings/page-header/drawings-page-header';
import CalicoCanvasMuralArea from '~/components/custom/calico/calico-canvas-mural-area';
import CanvasDimensionGroup from '~/components/canvas/dimensions/canvas-dimension-group';
import CanvasViewSvgRenderer from '~/components/canvas/canvas-view-svg-renderer/canvas-view-svg-renderer';
import DrawingsCalicoFooterNotes from '~/pages/drawings/page-types/custom/calico/drawings-calico-footer-notes';
import CalicoCanvasMismatchLine from '~/components/custom/calico/calico-canvas-mismatch-line';
import CalicoWallLabel from '~/components/custom/calico/calico-wall-label';
import ProductCanvasText from '~/components/canvas/product/product-canvas-text';
import ProductCanvasLine from '~/components/canvas/product/product-canvas-line';

export default function DrawingsCustomCalicoMuralAreaPage({page, pageNumber, pageSize, pageCount, isPrinting, canvasSize, productOrder}) {
  var {calicoMuralAreas} = page;
  var layerRef = useRef();
  var [forceUpdateKey, setForceUpdateKey] = useState(null);

  var dimensionLines = getDimensionLines({entities: [...calicoMuralAreas]});
  var offset = getCenteredCanvasContentOffset({entities: [...calicoMuralAreas], invert: true, scale: page.scale});

  return (
    <Page {...{isPrinting, pageSize}} orientation={'landscape'}>
      <DrawingsPageHeader title='Elevation Layout' subtitle={<View style={{flexDirection: 'row', alignItems: 'center'}}><Text style={{fontSize: 13}}>The areas highlighted in blue </Text><View style={{width: 20, marginHorizontal: 3, height: 20, borderWidth: 1, backgroundColor: '#A3B3D6'}}></View><Text style={{fontSize: 13}}> will receive wallpaper.</Text></View>}/>
      <View style={{marginTop: 66, flex: 1}}>
        <CanvasViewSvgRenderer {...{layerRef, canvasSize}} forceCanvasUpdate={() => setForceUpdateKey(uuid())}>
          <CanvasView textDimsScale={0.75} isStatic scale={page.scale} getOffset={() => offset} {...{canvasSize, layerRef, forceUpdateKey}}>
            <ArchDataContext.Provider value={{activeView: {viewKey: 'custom'}}}>
              {_.map(calicoMuralAreas, calicoMuralArea => (
                <CalicoCanvasMuralArea isStatic key={calicoMuralArea.id} {...{calicoMuralArea}}/>
              ))}
              {_.map(page.calicoWallLabels, calicoWallLabel => (
                <CalicoWallLabel isStatic key={calicoWallLabel.id} {...{calicoWallLabel}}/>
              ))}
              {_.map(page.calicoMismatchLines, calicoMismatchLine => (
                <CalicoCanvasMismatchLine isStatic key={calicoMismatchLine.id} {...{calicoMismatchLine}}/>
              ))}
              {_.map(page.productCanvasLines, productCanvasLine => (
                <ProductCanvasLine isStatic key={productCanvasLine.id} {...{productCanvasLine}}/>
              ))}
              {_.map(page.productCanvasTexts, productCanvasText => (
                <ProductCanvasText isStatic key={productCanvasText.id} {...{productCanvasText}}/>
              ))}
            </ArchDataContext.Provider>
            <CanvasDimensionGroup lines={dimensionLines}/>
          </CanvasView>
        </CanvasViewSvgRenderer>
      </View>
      <DrawingsArchFooter
        {...{page, pageNumber, pageCount, productOrder}}
        notesTitle={'Elevation Layout Notes'}
        archNotes={<DrawingsCalicoFooterNotes {...{page}}/>}
      />
    </Page>
  );
}
