import { PureComponent } from 'react';
import { Text, CopilotStepView } from '@symbolic/rn-lib';
import { View, TouchableOpacity } from 'react-native';
import { connect } from '@symbolic/redux';
import { resourceActions } from '~/redux';
import { getNameFor } from '~/helpers/product-order-helper';
import { getUserEmployeeOrGuest } from '../../../../helpers/get-user-employee-or-guest';

import HudElement from '~/components/hud-element';
import K from '~/k';
import { api, products } from '@symbolic/lib';

var { getFilteredProductOrderStatuses, getProductOrderStatusesFor } = products;

class StatusDots extends PureComponent {
  render() {
    var {productOrder, session} = this.props;
    var {activeOrg} = session;
    var {id: orgId, role} = activeOrg;
    var {user} = session;
    var productOrderStatuses = getProductOrderStatusesFor({productOrder, productOrderStatuses: getFilteredProductOrderStatuses({productOrderStatuses: _.get(session, 'activeOrg.appData.designEngine.productOrderStatuses'), userRole: role})});
    var {statusData} = productOrder;

    var isStockedOrder = productOrder.isStocked === 1;

    return (
      <HudElement x='center' y='top' style={{marginTop: K.spacing}}>
        <CopilotStepView
          order={4}
          key={true}
          name='status dots'
          text={getNameFor({productOrder, orgId, textToTransform: 'These dots represent the status of your order'})}
          style={{height: 20}}
        />
        <View dataSet={{conditionalOpacityParent: 1}} style={{flexDirection: 'row', position: 'relative', top: -11}}>
          {_.map(productOrderStatuses, (status) => {
            var {isMilestone} = status;
            var isComplete = statusData[`${status.type}`] || statusData[`${status.key}`];
            var diameter = isMilestone && !isStockedOrder ? 14 : 7;

            return (
              <View key={`product-order-status-${status.key}`} style={{width: 30, transform: [{rotate: '-45deg'}]}}>
                <Text
                  dataSet={{conditionalOpacity: 1}}
                  style={{
                    position: 'absolute',
                    width: 300,
                    right: 30,
                    top: -5,
                    textAlign: 'right',
                    color: isComplete ? '#000' : '#999',
                    fontWeight: isMilestone && !isStockedOrder ? 'bold' : 'regular',
                    textShadow: `1px 1px 3px ${isComplete ? 'rgba(0, 0, 0, 0.2)' : 'rgba(50, 50, 50, 0.2)'}`
                  }}
                >
                  {productOrder.isStocked && status.stockedOrderTitle ? status.stockedOrderTitle : status.title}
                </Text>
                <TouchableOpacity
                  onPress={async () => {
                    var skipNotifyingGuest = null;
                    // var isEmployee = !!(!activeOrg.prevRole && _.includes(activeOrg.role, ['member', 'owner']));
                    var {isEmployee} = getUserEmployeeOrGuest({activeOrg});

                    if (isEmployee) {
                      var {data: statusData} = await api.request({uri: '/configurator/update-order-status', body: {productOrderId: this.props.productOrder.id, orgId, userId: user.id, statusKey: status.key, skipNotifyingGuest}});

                      this.props.updateProductOrder({id: productOrder.id, props: {statusData}, hitApi: false});
                    }
                  }}
                  style={{
                    width: diameter,
                    height: diameter,
                    transform: [{translateX: K.spacing + (isMilestone && !isStockedOrder ? -3.5 : 0)}, {translateY: (isMilestone && !isStockedOrder ? -3.5 : 0)}],
                    borderRadius: diameter / 2,
                    backgroundColor: isComplete ? 'rgb(119, 115, 108)' : 'rgb(228, 227, 226)'
                  }}
                >
                </TouchableOpacity>
              </View>
            );
          })}
        </View>
      </HudElement>
    );
  }
}

export default connect({
  mapDispatch: {
    ..._.pick(resourceActions.productOrders, ['updateProductOrder']),
  }
})(StatusDots);
