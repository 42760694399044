import { PureComponent } from 'react';
import { View, ScrollView } from 'react-native';
import { DocumentTitle, Text, Button } from '@symbolic/rn-lib';
import _ from '@symbolic/lodash';

import { connect } from '@symbolic/redux';
import K from '~/k';

import { api } from '@symbolic/lib';
import { paragon } from '@symbolic/paragon-connect';

import AdminMenu from '~/components/admin-menu';
import SecondaryHeader from '~/components/secondary-header';

class AdminIntegrationsPage extends PureComponent {
  handlIntegrationButtonPress = async ({integrationKey}) => {
    var {data: {paragonJwt}} = await api.request({uri: '/integrations/get-paragon-jwt', body: {orgId: this.props.session.activeOrg.id}});

    //TODO organize by orgId
    var paragonProjectId = { //HINT mna only
      main: '00feb4d8-b72e-400a-9175-b8922ca30a46',
      staging: '20bb9488-92f1-4e97-8131-e1d23b5cb9c6',
      production: 'f0f98028-67fd-4576-9ddf-9323507888cb'
    }['main']; //TODO use process.env.PARAGON_ENVIRONMENT?

    await paragon.authenticate(paragonProjectId, paragonJwt);
    paragon.connect(integrationKey);
  };

  render() {
    var {session: {activeOrg}} = this.props;

    var enabledIntegrations = _.get(activeOrg, 'appData.designEngine.enabledIntegrations', []);

    return (
      <DocumentTitle title={'Admin: Integrations'}>
        <View style={{flex: 1, flexDirection: 'row'}}>
          <AdminMenu activeOrg={this.props.session.activeOrg} />
          <View style={{flex: 1}}>
            <SecondaryHeader title='Integrations' />
            <ScrollView style={{flex: 1}} contentContainerStyle={{padding: K.spacing, marginBottom: K.spacing * 8}}>
              {_.map(enabledIntegrations, (integration, index) => {
                return (
                  <View key={index} style={{marginBottom: K.spacing * 2}}>
                    <View style={{marginBottom: K.margin, marginLeft: K.spacing}}>
                      <Text style={{...K.fonts.label, color: 'black'}}>
                        {integration.title}
                      </Text>
                    </View>
                    <View style={{flexDirection: 'row', alignItems: 'center'}}>
                      <Button
                        label='Manage'
                        onPress={() => this.handlIntegrationButtonPress({integrationKey: integration.key})}
                        style={{marginRight: K.spacing}}
                      />
                    </View>
                  </View>
                );
              })}
            </ScrollView>
          </View>
        </View>
      </DocumentTitle>
    );
  }
}

export default connect({
  mapState: (state, ownProps) => {

  },
  mapDispatch: {

  }
})(AdminIntegrationsPage);
