import { PureComponent } from 'react';
import { View, Image } from 'react-native';
import { Text, Popup, Button, TextInput, LabelledView, PickerInput } from '@symbolic/rn-lib';
import { connect } from '@symbolic/redux';
import { resourceActions } from '~/redux';
import { api, products } from '@symbolic/lib';
var {getCleanedEngravings} = products.meljac;

import _ from 'lodash';
import AceEditor from 'react-ace';

import getEngravingWidthLimitData from '~/helpers/meljac/get-engraving-width-limit';
var {getEngravingHeightLimit, getCleanedEngravings} = products.meljac;
import CustomEngravingsInput from '~/helpers/meljac/custom-engravings-input';

import GetDisclaimer from '~/helpers/get-disclaimer';

import K from '~/k';
import ProductRule from '~/components/product-rule';
import FileInput from '~/components/file-input';
import handleFileUpload from '~/helpers/upload-file-helper';
import thumbnailPlaceholder from '~/assets/thumbnail-placeholder.png';
import { publishLevelOptions } from '../../helpers/publish-level-options';

import 'ace-builds/src-noconflict/mode-json';
import 'ace-builds/src-noconflict/theme-github';
import 'ace-builds/src-noconflict/ext-language_tools';

class ProductOptionPopup extends PureComponent {
  state = {
    script: this.props.productOption && (typeof(this.props.productOption.data) === 'object' ? !_.keys(this.props.productOption.data).length ? '' : JSON.stringify(this.props.productOption.data) : this.props.productOption.data),
    image: null,
    uploading: false,
    uploadWasSuccessful: false,
    uploadHasFailed: false,
  };

  constructor(props) {
    super(props);

    this.handleInputChangeDebounced = _.debounce(this.handleInputChange, 150);
  }

  close = () => {
    this.props.onClose();
    this.setState({uploading: false, uploadWasSuccessful: false, uploadHasFailed: false});
  };

  handleTitleChange = ({value}) => this.props.updateProductOption({id: this.props.productOption.id, props: {title: value}});

  handleProductOptionPropsChange = ({propKey, value}) => this.props.updateProductOption({id: this.props.productOption.id, props: {[propKey]: value}});

  archive = async () => {
    this.props.updateProductOption({id: this.props.productOption.id, props: {archived: (this.props.productOption.archived === 1 ? 0 : 1)}});
  };

  handleInputChange = ({value, index, inputIndex, hitApi = true}) => {
    var {productInstance, productProperty} = this.props;

    var properties = _.get(productInstance, 'properties', {});
    var propertyData = _.get(properties, `${productProperty.id}`, {});
    var path = (inputIndex || inputIndex === 0) ? `[${index}[${inputIndex}]` : `[${index}]`;

    propertyData = _.set(propertyData, `${productProperty.type}.${path}`, value);
    properties = _.set(properties, productProperty.id, propertyData);

    this.props.updateProductInstance({id: productInstance.id, props: {properties}, hitApi});
  };

  createProductRule = async () => {
    var productRule = await api.create('productRule', {
      orgId: this.props.session.activeOrg.id,
      fromResourceKey: 'productOption',
      toResourceKey: 'productProperty',
      fromIds: [this.props.productOption.id],
      type: 'incompatible'
    });

    this.props.trackProductRules({productRules: [productRule]});
  };

  handleReferenceCodeChange = ({value, key}) => {
    var referenceCodes = _.cloneDeep(_.get(this.props.productOption, 'referenceCodes', {}));

    referenceCodes = _.set(referenceCodes, key, value);

    this.props.updateProductOption({id: this.props.productOption.id, props: {referenceCodes}});
  };

  handleDataChange = ({value}) => {
    this.setState({script: value});
  };

  updateData({value}) {
    try {
      value = JSON.parse(value);
    } catch (err) {
      console.error('value is not json.');
    }

    this.props.updateProductOption({id: this.props.productOption.id, props: {data: value}});
  }

  updateEngravings = ({engravings}) => {
    var updatedProperties = _.cloneDeep(_.get(this.props.activeProductInstanceWithData, 'properties', {}));
    var updatedEngravingsPropertyData = _.get(updatedProperties, '27', {});

    _.set(updatedEngravingsPropertyData, 'engravings', engravings);

    _.set(updatedProperties, '27', updatedEngravingsPropertyData);

    this.props.updateProductInstance({id: this.props.productInstance.id, props: {properties: updatedProperties}});
  };

  handleToggleCustomEngravings = () => {
    var engravings = getCleanedEngravings({properties: _.get(this.props.activeProductInstanceWithData, 'properties'), propertiesDataById: this.props.activeProductInstanceWithData.propertiesDataById});

    this.updateEngravings({engravings});
  };

  render() {
    var {productOption, mode, productProperty, inputQuantity, productInstance, productRules, arrayInputProps, activeProductInstanceWithData} = this.props;

    var engravingsData = _.get(activeProductInstanceWithData, 'properties.27');
    var hasCustomEngravings = _.has(engravingsData, 'engravings');

    var referenceCodeTypes = [
      {title: 'Catalog', key: 'catalog'},
      {title: 'Factory', key: 'factory'},
      {title: 'Machining', key: 'machining'}
    ];

    return (
      <Popup onClose={this.close}>
        {mode === 'admin' && (
          <View>
            <TextInput
              style={{...K.fonts.pageHeader, paddingHorizontal: K.spacing, paddingTop: 0, backgroundColor: 'white', marginTop: K.spacing, marginBottom: K.spacing}}
              value={productOption.title}
              onChange={({value}) => this.handleTitleChange({value})}
              blurOnEnter
              blurOnSubmit
              multiline
              standardAutoheightStyles
              returnKeyType='done'
            />
            <View style={{marginBottom: K.spacing, flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center'}}>
              <FileInput
                containerStyle ={{height: 64}}
                uploading={this.state.uploading}
                uploadWasSuccessful={this.state.uploadWasSuccessful}
                uploadHasFailed = {this.state.uploadHasFailed}
                handleFilesPicked={(files) => handleFileUpload({files,
                  setState: (newState) => this.setState(newState),
                  api,
                  resource: this.props.productOption,
                  session: this.props.session,
                  resourceKey: 'productOption',
                  trackResource: ({productOptions}) => this.props.trackProductOptions({productOptions})
                })}
              >
                <Image source={this.props.productOption.thumbnailUrl ? {uri: this.props.productOption.thumbnailUrl} : thumbnailPlaceholder} style={{height: 40, width: 40, border: '1px solid black', borderRadius: 25}}/>
              </FileInput>
            </View>
            <View style={{marginBottom: K.spacing}}>
              {_.map(referenceCodeTypes, ({title, key}) => (
                <TextInput
                  key={key}
                  grayLabelledView
                  label={title + ' Reference Code'}
                  labelledViewStyles={{outerView: {marginBottom: K.margin, marginRight: K.margin}}}
                  onChange={({value}) => this.handleReferenceCodeChange({value, key})}
                  value={_.get(productOption, `referenceCodes[${key}]`, '')}
                />
              ))}
            </View>
            {this.props.session.activeOrg.role === 'owner' &&
              <LabelledView label={'Product Option Visibility'} styles={{outerView: {marginBottom: K.spacing * 2}}}>
                <PickerInput
                  inline
                  basic
                  style={{height: K.inputHeight, width: 200}}
                  buttonStyle={{backgroundColor: K.colors.gray}}
                  options={publishLevelOptions}
                  value={productOption.publishLevel}
                  onChange={({value}) => this.handleProductOptionPropsChange({propKey: 'publishLevel', value})}
                />
              </LabelledView>
            }
            {!!productRules.length && (
              <LabelledView label={'Product Option Rules'} styles={{outerView: {marginBottom: K.spacing}}}>
                {_.map(productRules, (productRule, index) => <ProductRule key={index} {...{productRule}} />)}
              </LabelledView>
            )}
            <LabelledView label={'Product Option Data'} styles={{innerView: {marginBottom: K.margin}, outerView: {marginBottom: K.spacing}}}>
              <AceEditor
                mode="json"
                theme="github"
                value={this.state.script}
                onChange={(value) => this.handleDataChange({value})}
                onBlur={() => this.updateData({value: this.state.script})}
                height={'100px'}
                setOptions={{
                  useWorker: false,
                  enableBasicAutocompletion: true,
                  enableLiveAutocompletion: true,
                  enableSnippets: true,
                  showLineNumbers: true,
                  tabSize: 2
                }}
              />
            </LabelledView>
            <Button
              label={'Add rule'}
              dark
              style={{marginTop: K.margin}}
              onPress={this.createProductRule}
            />
            <Button
              light
              style={{marginTop: K.spacing}}
              onPress={this.archive}
              label={this.props.productOption.archived === 0 ? 'ARCHIVE' : 'UNARCHIVE'}
            />
          </View>
        )}
        {mode === 'order' && (<>
          <View>
            <Text style={{marginLeft: K.spacing, marginTop: K.spacing, opacity: 0.5}}>Engraving text is case-sensitive.</Text>
            {this.props.session.activeOrg.id === 850 && productProperty.type === 'text' && (<View style={{marginLeft: K.spacing, marginBottom: K.spacing * 2, opacity: 0.65}}><GetDisclaimer orgId={this.props.session.activeOrg.id} disclaimerType={'engravingDisclaimer'} /></View>)}

            <View style={{marginBottom: K.spacing}}>
              {(this.props.session.activeOrg.id === 850 && this.props.session.activeOrg.role === 'owner' && !hasCustomEngravings) && (
                <Button
                  label={'Toggle Custom Engravings'}
                  onPress={this.handleToggleCustomEngravings}
                  style={{marginBottom: K.spacing * 2}}
                />
              )}
              {hasCustomEngravings && (
                <View style={{marginBottom: K.spacing}}>
                  {_.times(inputQuantity, mechanismIndex => (
                    <View
                      key={mechanismIndex}
                      style={{display: 'flex', flexDirection: 'column', marginBottom: K.spacing * 1.5}}
                    >
                      {_.times(2, positionIndex => {
                        var engravingLocationKey = positionIndex === 0 ? 'above' : 'below';

                        return (
                          <View key={positionIndex}>
                            {_.times(2, inputIndex => {
                              var type = 'text';
                              var engravingObject = _.get(engravingsData.engravings, `${mechanismIndex}.${engravingLocationKey}[${inputIndex}]`);

                              if (engravingObject) {
                                type = Object.keys(_.get(engravingsData.engravings, `${mechanismIndex}.${engravingLocationKey}[${inputIndex}]`))[0];
                              }

                              return (
                                <CustomEngravingsInput
                                  key={inputIndex}
                                  updateEngravings={this.updateEngravings}
                                  {...{engravingLocationKey, inputIndex, type, activeProductInstanceWithData, engravings: engravingsData.engravings, mechanismIndex, productInstance, productProperty}}
                                />
                              );
                            })}
                          </View>
                        );
                      })}
                    </View>
                  ))}
                </View>
              )}
              {!hasCustomEngravings && (
                <View style={{marginBottom: K.spacing}}>
                  {_.times(inputQuantity, index => {
                    var maxNumberOfInputs = 1000000;
                    var ledIndicatorEnabled;

                    if (productInstance.productId === 1) {
                      var ledIndicatorEnabled = _.get(productInstance, 'properties.85.optionId') === 519;

                      if (ledIndicatorEnabled) maxNumberOfInputs = 1;
                    }

                    if (this.props.session.activeOrg.id === 850) {
                      var engravingHeightIsOverLimit = getEngravingHeightLimit({activeProductInstanceWithData, productInstance, productProperty, inputArrayIndex: index});

                      if (engravingHeightIsOverLimit === true) maxNumberOfInputs = 1;
                      if (ledIndicatorEnabled) maxNumberOfInputs = 1;
                    }

                    if (productProperty.type === 'text') {
                      if (arrayInputProps && !_.isEmpty(arrayInputProps)) {
                        return (
                          <View
                            style={{ marginBottom: K.margin * 3}}
                            key={index}
                          >
                            {_.map(_.take(arrayInputProps.inputs, maxNumberOfInputs), (props, inputIndex) => {
                              var backgroundColor = K.colors.gray;

                              if (this.props.session.activeOrg.id === 850) {
                                var {engravingWidthIsOverLimit, engravingWidthIsOverSoftLimit} = getEngravingWidthLimitData({activeProductInstanceWithData, productInstance, productProperty, inputArrayIndex: index, inputIndex});

                                if (engravingWidthIsOverSoftLimit) backgroundColor = '#F5E1BA';
                                if (engravingWidthIsOverLimit) backgroundColor = '#F5BBBA';
                              }

                              return (
                                <TextInput
                                  nativeID={`OrderPageEngravingsPopupInput-${inputIndex}`}
                                  label={inputIndex === 0 ? `${arrayInputProps.label} ${index + 1}${engravingWidthIsOverSoftLimit && !engravingWidthIsOverLimit ? ' (over recommended limit)' : ''}${engravingWidthIsOverLimit ? ' (over limit)' : ''}` : ''}
                                  style={{ backgroundColor, marginBottom: K.spacing}}
                                  key={inputIndex}
                                  placeholder={_.get(props, 'placeholder', '')}
                                  value={_.defaultTo(_.get(productInstance.properties, `${productProperty.id}.text[${index}][${inputIndex}]`, ''), '')}
                                  onBlur={({value}) => this.handleInputChange({value, index, inputIndex, hitApi: true})}
                                  onInput={({value}) => this.handleInputChangeDebounced({value, index, inputIndex, hitApi: false})}
                                />
                              );
                            })}
                          </View>
                        );
                      }
                    }
                    else {
                      return (
                        <TextInput
                          label={`${index + 1}`}
                          style={{marginBottom: K.spacing}}
                          key={index}
                          value={_.get(productInstance.properties, `${productProperty.id}.text[${index}]`, '')}
                          onChange={({value}) => this.handleInputChange({value, index})}
                        />
                      );
                    }
                  })}
                </View>
              )}
            </View>
          </View>
        </>)}
      </Popup>
    );
  }
}

export default connect({
  mapState: (state, ownProps) => {
    var productRules = ownProps.productOption && _.filter(state.resources.productRules.byId, productRule => {
      return productRule.fromResourceKey === 'productOption' && _.includes(productRule.fromIds, ownProps.productOption.id);
    });

    return {
      productRules,
    };
  },
  mapDispatch: {
    ..._.pick(resourceActions.productOptions, ['updateProductOption', 'trackProductOptions', 'destroyProductOption']),
    ..._.pick(resourceActions.productInstances, ['updateProductInstance']),
    ..._.pick(resourceActions.productRules, ['trackProductRules'])
  }
})(ProductOptionPopup);
