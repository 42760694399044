import _ from 'lodash';
import K from '~/k';
import lib from '~/lib';
import React from 'react';
import { Circle } from 'react-konva';
import { roundPoint } from '~/helpers/arch/arch-helpers';
import { snap, toReal, toCanvas } from '~/helpers/canvas/canvas-helpers';

export default class Grip extends React.PureComponent {
  componentDidMount() {
    document.addEventListener('mousemove', this.handleMouseMove);
    document.addEventListener('mouseup', this.handleMouseUp);
  }

  componentWillUnmount() {
    document.removeEventListener('mousemove', this.handleMouseMove);
    document.removeEventListener('mouseup', this.handleMouseUp);
  }

  handleMouseMove = (event) => {
    if (this.isMoving) {
      var snappedPosition = snap(toReal({x: event.layerX, y: event.layerY}, this.props.canvasData), this.props.candidateSnapPositions, this.props.canvasData).position;

      var delta = roundPoint({x: snappedPosition.x - this.props.position.x, y: snappedPosition.y - this.props.position.y}, this.props.canvasData.precision);

      var position = roundPoint(lib.object.sum(this.props.position, delta), K.minPrecision);
      console.log(snappedPosition, this.props.position);
      this.props.onMove({
        gripIndex: this.props.gripIndex,
        position
      });
    }
  };

  handleMouseUp = () => {
    this.isMoving = false;

    this.props.onMoveEnd({gripIndex: this.props.gripIndex});
  };

  render() {
    var {position} = this.props;
    var canvasPosition = toCanvas(position, this.props.canvasData);

    return (
      <Circle
        x={canvasPosition.x}
        y={canvasPosition.y}
        ref={(ref) => {
          if (ref) ref.moveToTop();
        }}
        radius={4}
        stroke='black'
        strokeWidth={1}
        fill='white'
        onMouseDown={() => {
          this.isMoving = true;

          this.props.onMoveStart({gripIndex: this.props.gripIndex});
        }}
      />
    );
  }
}
