import React from 'react';
import K from '~/k';

import { View } from 'react-native';
import { Text, TextInput } from '@symbolic/rn-lib';

export default class SecondaryHeader extends React.PureComponent {
  render() {
    return (
      <View style={{padding: K.spacing * 2, backgroundColor: K.colors.gray, flexDirection: 'row'}}>
        <View style={{flex: 1}}>
          {this.props.titleIsEditable ? (
            <TextInput
              multiline
              blurOnSubmit
              returnKeyType='done'
              style={{...K.fonts.pageHeader, borderRadius: 0, paddingHorizontal: 0, height: 'auto', top: K.isWeb  ? 0 : -3}}
              value={this.props.title}
              onChange={this.props.onTitleChange}
              selectTextOnFocus={this.props.autoFocusTitleInput}
            />
          ) : (
            <Text style={{...K.fonts.pageHeader}}>{this.props.title}</Text>
          )}
          {!!this.props.subTitle && (
            <Text style={{opacity: 0.5, display: 'block', marginTop: K.margin}}>{this.props.subTitle}</Text>
          )}
          {this.props.belowHeaderComponent}
        </View>
        {this.props.rightComponent && (
          <View style={{}}>
            {this.props.rightComponent}
          </View>
        )}
      </View>
    );
  }
}
