import { useContext, useEffect } from 'react';
import { Image, Group, Circle } from 'react-konva';
import { connect } from '@symbolic/redux';
import { resourceActions } from '~/redux';
import { toCanvas } from '~/helpers/canvas/canvas-helpers';
import { getCalicoMuralTextureImageUrl, getCalicoMuralTextureTransformProps, getCalicoMuralTextureOption } from '~/helpers/custom/calico/custom-calico-helpers';

import useImage from 'use-image';

import lib from '~/lib';
import CanvasDataContext from '~/contexts/canvas-data-context';
import ArchDataContext from '~/contexts/arch-data-context';
import RectTool from '~/components/canvas/tools/rect/rect-tool';
import CanvasMask from '~/components/canvas/mask/canvas-mask';

function CalicoCanvasMuralTexture({calicoMuralTexture, productOptionsById, ...props}) {
  var [image] = useImage(getCalicoMuralTextureImageUrl({calicoMuralTexture, productOptionsById}));
  var archData = useContext(ArchDataContext);
  var canvasData = useContext(CanvasDataContext);

  var {position, size, yRotation} = calicoMuralTexture.data;
  var canvasSize = _.mapValues(size, (measure) => measure * canvasData.scale);
  var muralTextureOption = getCalicoMuralTextureOption({calicoMuralTexture, productOptionsById});

  position = {x: position.x, y: position.y};

  var canvasPosition = toCanvas(position, canvasData);
  var isSelected = calicoMuralTexture.id === archData.selectedEntityId && archData.selectedEntityResourceKey === 'calicoMuralTexture';

  useEffect(() => {
    var handleDestroyEntity = (event) => {
      if (event.code === 'Backspace' && isSelected) {
        props.destroyEntity({id: calicoMuralTexture.id});

        archData.setSelectedEntity(undefined, undefined);
      }
    };

    document.addEventListener('keydown', handleDestroyEntity);

    return () => {
      document.removeEventListener('keydown', handleDestroyEntity);
    };
  });

  var {data: {position: {x, y}, size: {width, height}}} = calicoMuralTexture;

  var p1 = _.map([
    {x: x, y: y},
    {x: x + width, y: y},
    {x: x + width, y: y + height},
    {x: x, y: y + height},
    {x: x, y: y}
  ], point => {
    return yRotation ? lib.trig.rotate({point, byDegrees: yRotation, aroundOrigin: lib.object.sum({x, y}/*, lib.trig.rotate({point: {x: width / 2, y: height / 2}, byDegrees: yRotation})*/)}) : point;
  });

  var shouldClip = props.muralMaskingPolygons.length > 0 && _.some(props.muralMaskingPolygons, p2 => lib.polygon.polygonsOverlap(p1, p2, {inclusive: false}));

  var transformProps = getCalicoMuralTextureTransformProps({calicoMuralTexture, scale: canvasData.scale});

  var imageComponent = (
    <Image
      {...{image}} {...transformProps} {...canvasSize} {...canvasPosition}
      onMouseDown={() => archData.setSelectedEntity(calicoMuralTexture.id, 'calicoMuralTexture')}
    />
  );

  return (
    <>
      {shouldClip ? _.map(props.muralMaskingPolygons, maskingPolygon => {
        return (
          <CanvasMask
            maskingPolygons={[_.map(maskingPolygon, point => toCanvas(point, canvasData))]}
          >
            {imageComponent}
          </CanvasMask>
        );
      }) : imageComponent}
      {isSelected && (
        <Image {...{image, ...transformProps, ...canvasSize, ...canvasPosition}}
          opacity={0.2}
        />
      )}
      <RectTool
        {...{isSelected}}
        {..._.pick(muralTextureOption.data, ['canScaleX', 'canScaleY'])}
        disabledAnchors={[
          ...(!muralTextureOption.data.canScaleX ? ['middle-left', 'middle-right'] : []),
          ...(!muralTextureOption.data.canScaleY ? ['top-center', 'bottom-center'] : [])
        ]}
        isRotatable
        rotation={yRotation}
        doubleClickToSelect
        onSelect={() => archData.setSelectedEntity(calicoMuralTexture.id, 'calicoMuralTexture')}
        onTransform={({position, rotation = 0, size}) => {
          var newPosition = position;

          props.updateEntity({id: calicoMuralTexture.id, props: {data: {...calicoMuralTexture.data, position: newPosition, ...(size ? {size} : {}), yRotation: rotation}}, hitApi: false});
        }}
        onTransformEnd={() => {
          props.updateEntity({id: calicoMuralTexture.id, props: {data: calicoMuralTexture.data}});
        }}
        position={canvasPosition}
        {...{size}}
      />
    </>
  );
}

export default connect({
  mapState: (state) => {
    return {productOptionsById: state.resources.productOptions.byId};
  },
  mapDispatch: {
    ..._.pick(resourceActions.entities, ['updateEntity', 'destroyEntity'])
  }
})(CalicoCanvasMuralTexture);
