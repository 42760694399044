import { PureComponent, useRef } from 'react';
import { G } from 'react-native-svg';
import { connect } from '@symbolic/redux';
import { productGraphicScriptFor } from '~/product-graphic-scripts';
import { getArcPathArrayWithCenterFromAngles } from '~/helpers/svg-arc-helper';
import { Canvas, extend, useFrame, useThree } from '@react-three/fiber';
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls';
import { getProductInstanceWithData } from '~/helpers/product-order-helper';
import { Group as CanvasGroup } from 'react-konva';
import { toCanvas } from '~/helpers/canvas/canvas-helpers';

import Svg from 'react-native-svg';
import getTextHeight from '~/helpers/get-text-height';
import ProductGraphicNode from './product-graphic-node';
import CanvasDataContext from '~/contexts/canvas-data-context';
import _ from 'lodash';

extend({OrbitControls});

const CameraControls = () => {
  var {camera, gl: { domElement }} = useThree();
  var controls = useRef();

  useFrame(() => controls.current.update());

  return <orbitControls ref={controls} args={[camera, domElement]} minPolarAngle={Math.PI * 0.4} maxPolarAngle={Math.PI * 0.6} maxDistance={120} minDistance={40}/>;
};

const SetCamera = () => {
  useThree(({camera}) => {
    camera.fov *= 1;
    camera.far = 2000;
    camera.position.set(0, 0, -100);
    // camera.lookAt([0, 0, 0]);
    camera.updateProjectionMatrix();
  });

  return null;
};

class ProductGraphic extends PureComponent {
  static evalScript({product, productInstance, productOrderProductInstances, scale}) {
    var script = productGraphicScriptFor({productId: product.id});

    if (!script) return null;

    var dimensions = {width: 5, height: 7};

    var rect = (props) => ({type: 'rect', props});
    var circle = (props) => ({type: 'circle', props});
    var ellipse = (props) => ({type: 'ellipse', props});
    var image = (props) => ({type: 'image', props});
    var mask = (props, children) => ({type: 'mask', props, children});
    var text = (props, children) => ({type: 'text', props, children});
    var group = (props, children) => ({type: 'group', props, children});
    var path = (props) => ({type: 'path', props});
    var polyline = (props) => ({type: 'polyline', props});
    var linearGradient = (props, children) => ({type: 'linearGradient', props, children});
    var radialGradient = (props, children) => ({type: 'radialGradient', props, children});
    var dropShadow = (props) => ({type: 'dropShadow', props});
    var dimension = (props) => ({type: 'dimension', props});
    var shortDimension = (props) => ({type: 'shortDimension', props});

    var deps = {
      scale,
      productInstance,
      productOrderProductInstances,
      getTextHeight,
      getArcPathArrayWithCenterFromAngles
    };

    var mappedValues = _.mapValues(deps.productInstance.properties, 'optionId');

    deps.productOptionsByIdData = _.mapValues(deps.productOptionsById, 'data');
    deps.productInstance.mappedProperties = mappedValues;

    var rootObjectData = typeof(script) === 'string' ? eval(script) : script({
      deps, dimensions, rect, circle, ellipse, image, mask, text, group, path, polyline, linearGradient, radialGradient, dropShadow, dimension, shortDimension
    });

    //TODO try catch
    return rootObjectData;
  }

  render() {
    var {canvasSize = {width: 0, height: 0}, scale, nodes, canvasPadding, productInstance, dimensionsAreVisible, measurementSystem, productsById, productPropertiesById, productRulesById, productOptionsById, productOrderProductInstances} = this.props;

    if (!nodes) {
      var rootObjectData = ProductGraphic.evalScript({...this.props, productInstance: productInstance.propertiesDataById ? productInstance : getProductInstanceWithData({productInstance}, {productsById, productPropertiesById, productRulesById, productOptionsById, productOrderProductInstances})});

      nodes = [rootObjectData];
    }
    else {
      rootObjectData = nodes[0];
    }

    if (nodes.length === 0) return null;

    if (this.props.type === '3d') {
      var width = parseFloat(rootObjectData.props.width);

      if (typeof(rootObjectData.props.width) === 'object') {
        width = _.get(productInstance, `properties.${rootObjectData.props.width.productPropertyId}.value`, 0);
      }

      var rootObjectSize = {width, height: parseFloat(rootObjectData.props.height), depth: parseFloat(rootObjectData.props.depth)};
    }
    else {
      var rootObjectSize = {width: rootObjectData.props.width * scale, height: rootObjectData.props.height * scale};
    }

    var padding = {
      width: (dimensionsAreVisible ? (rootObjectData.props.padding || 0) * 2 * scale : 20),
      height: (dimensionsAreVisible ? (rootObjectData.props.padding || 0) * 2 * scale : 20)
    };

    if (_.isEqual(canvasSize, {width: 0, height: 0})) {
      canvasSize = {
        width: rootObjectSize.width + padding.width,
        height: rootObjectSize.height + padding.width
      };
    }

    if (this.props.canvasPadding) {
      var maxScale = _.min(_.map(['width', 'height'], (sizeKey) => {
        return (canvasSize[sizeKey] - canvasPadding[sizeKey]) / rootObjectData.props[sizeKey];
      }));

      if (maxScale < scale) {
        rootObjectSize = _.mapValues(rootObjectSize, size => size / (scale / maxScale));

        scale = Math.min(scale, maxScale);
      }
    }

    var x = canvasSize.width / 2 - rootObjectSize.width / 2;
    var y = canvasSize.height / 2 - rootObjectSize.height / 2;

    if (this.props.type === '3d') {
      nodes = [
        {type: 'group', props: {x: -rootObjectSize.width / 2, y: 0, z: -rootObjectSize.depth / 2}, children: nodes},
        {type: 'group', props: {width: 0, height: 0, depth: 0, x: 0, y: 0, z: 0}, children: [
          // {type: 'cube', props: {origin: 'center', width: 1, height: 1, depth: 1, x: 0, y: 50, z: 0, fill: 'red'}, castShadow: false}, //HINT debugging centerpoint
          {type: 'cube', props: {origin: 'center', width: 500, height: 1, depth: 500, x: 0, y: -0.5, z: 0, fill: 'gray'}, castShadow: false},
          {type: 'cube', props: {origin: 'center', width: 500, height: 1, depth: 500, x: 0, y: 100.5, z: 0, fill: 'gray'}, castShadow: false},
          ..._.flatten(_.times(3, x => _.times(3, z => ({type: 'cube', props: {origin: 'center', x: (x - 1) * 120, y: 98, z: (z - 1) * 120, width: 5, height: 1, depth: 5}, castShadow: false}))))
        ]}
      ];
    }

    if (this.props.isCanvas) {
      return (
        <CanvasDataContext.Consumer>
          {canvasData => (
            <CanvasGroup {...toCanvas(this.props.position, canvasData)}>
              {_.map(nodes, node => (
                <ProductGraphicNode key={node.id} {...{mode: this.props.type, node, scale, size: rootObjectSize, substrate: 'canvas', canvasData, productInstance, dimensionsAreVisible, measurementSystem}}/>
              ))}
            </CanvasGroup>
          )}
        </CanvasDataContext.Consumer>
      );
    }

    var nodes = _.map(nodes, node => (
      <ProductGraphicNode key={node.id} {...{mode: this.props.type, node, scale, size: rootObjectSize, productInstance, dimensionsAreVisible, measurementSystem}}/>
    ));

    return this.props.type === '3d' ? (
      <Canvas dpr={Math.max(window.devicePixelRatio, 2)} shadows>
        <CameraControls />
        <SetCamera />
        <ambientLight intensity={0.3}/>
        <group position={[0, -40, 0]}> {/* HINT: -50 is to adjust camera position to be 50" off ground */}
          {_.times(3, x => _.times(3, z => (<>
            <pointLight position={[(x - 1) * 120, 90, (z - 1) * 120]} intensity={0.1} color={'#fff3d1'} castShadow shadow-mapSize-height={1024} shadow-mapSize-width={1024}/>
          </>)))}
          {nodes}
        </group>
      </Canvas>
    ) : (
      <Svg {...canvasSize}>
        <G {...{x, y}}>
          {nodes}
        </G>
      </Svg>
    );
  }
}

export default connect({
  mapState: (state, ownProps) => {
    var productInstance = ownProps.productInstance;
    var productOrderProductInstances = _.filter(state.resources.productInstances.byId, {productOrderId: productInstance.productOrderId});

    return {
      productOptionsById: state.resources.productOptions.byId,
      productRulesById: state.resources.productRules.byId,
      productPropertiesById: state.resources.productProperties.byId,
      productsById: state.resources.products.byId,
      productOrderProductInstances
    };
  }
})(ProductGraphic);
