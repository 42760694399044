import { useRef, useState } from 'react';
import { View } from 'react-native';
import { getCenteredCanvasContentOffset } from '~/helpers/canvas/canvas-helpers';
import { getMuralMaskingPolygons } from '~/helpers/custom/calico/custom-calico-helpers';

import _ from 'lodash';
import uuid from 'uuid';
import Page from '~/components/page';
import CanvasView from '~/components/canvas/view/canvas-view';
import ArchDataContext from '~/contexts/arch-data-context';
import DrawingsArchFooter from '~/pages/drawings/page-types/arch/footer/drawings-arch-footer';
import DrawingsPageHeader from '~/pages/drawings/page-header/drawings-page-header';
import CalicoCanvasMuralTexture from '~/components/custom/calico/calico-canvas-mural-texture';
import CalicoCanvasMismatchLine from '~/components/custom/calico/calico-canvas-mismatch-line';
import CalicoWallLabel from '~/components/custom/calico/calico-wall-label';
import CanvasViewSvgRenderer from '~/components/canvas/canvas-view-svg-renderer/canvas-view-svg-renderer';
import DrawingsCalicoFooterNotes from '~/pages/drawings/page-types/custom/calico/drawings-calico-footer-notes';

export default function DrawingsCustomCalicoMuralTexturePage({page, pageNumber, pageSize, pageCount, isPrinting, canvasSize, productOrder}) {
  var {calicoMuralTextures, calicoMuralPanels} = page;
  var [forceUpdateKey, setForceUpdateKey] = useState(null);
  var layerRef = useRef();

  var muralMaskingPolygons = getMuralMaskingPolygons({calicoMuralPanels});
  var offset = getCenteredCanvasContentOffset({positions: _.flatten(muralMaskingPolygons), invert: true, scale: page.scale});

  return (
    <Page {...{isPrinting, pageSize}} orientation={'landscape'}>
      <DrawingsPageHeader title='Mural Rendering' subtitle={'This is the selected mural crop on the provided elevation.'}/>
      <View style={{marginTop: 66, flex: 1}}>
        <CanvasViewSvgRenderer {...{layerRef, canvasSize}} forceCanvasUpdate={() => setForceUpdateKey(uuid())}>
          <CanvasView textDimsScale={0.75} isStatic scale={page.scale} getOffset={() => offset} {...{canvasSize, layerRef, forceUpdateKey}}>
            <ArchDataContext.Provider value={{activeView: {viewKey: 'custom'}}}>
              {_.map(calicoMuralTextures, calicoMuralTexture => (
                <CalicoCanvasMuralTexture key={calicoMuralTexture.id} {...{calicoMuralTexture, muralMaskingPolygons}}/>
              ))}
              {_.map(page.calicoWallLabels, calicoWallLabel => (
                <CalicoWallLabel isStatic key={calicoWallLabel.id} {...{calicoWallLabel}}/>
              ))}
              {_.map(page.calicoMismatchLines, calicoMismatchLine => (
                <CalicoCanvasMismatchLine isStatic key={calicoMismatchLine.id} {...{calicoMismatchLine}}/>
              ))}
            </ArchDataContext.Provider>
          </CanvasView>
        </CanvasViewSvgRenderer>
      </View>
      <DrawingsArchFooter
        {...{page, pageNumber, pageCount, productOrder}}
        notesTitle={'Mural Rendering Notes'}
        archNotes={<DrawingsCalicoFooterNotes {...{page}}/>}
      />
    </Page>
  );
}
