import { useContext, useEffect } from 'react';
import { toCanvas } from '~/helpers/canvas/canvas-helpers';
import { connect } from '@symbolic/redux';
import { resourceActions } from '~/redux';

import CanvasDataContext from '~/contexts/canvas-data-context';
import ArchDataContext from '~/contexts/arch-data-context';

import CanvasText from '~/components/canvas/text/canvas-text';
import RectTool from '~/components/canvas/tools/rect/rect-tool';

function ProductCanvasText({productCanvasText, ...props}) {
  const canvasData = useContext(CanvasDataContext);
  const archData = useContext(ArchDataContext);
  const isSelected = archData.selectedEntityId === productCanvasText.id && archData.selectedEntityResourceKey === 'productCanvasText';

  useEffect(() => {
    var handleDestroyProductCanvasText = (event) => {
      if (event.code === 'Backspace' && isSelected) {
        props.destroyProductCanvasText({id: productCanvasText.id});

        archData.setSelectedEntity(undefined, undefined);
      }
    };

    document.addEventListener('keydown', handleDestroyProductCanvasText);

    return () => {
      document.removeEventListener('keydown', handleDestroyProductCanvasText);
    };
  });

  let {text, position, width} = productCanvasText;

  position = toCanvas(position, canvasData);

  return (
    <>
      <CanvasText
        text={text}
        x={position.x}
        y={position.y}
        fontSize={11 * canvasData.scale * canvasData.textDimsScale}
        width={width * canvasData.scale}
        onClick={() => archData.setSelectedEntity(productCanvasText.id, 'productCanvasText')}
      />
      {!props.isStatic && (
        <RectTool
          {...{isSelected}}
          rotation={0}
          onSelect={() => archData.setSelectedEntity(productCanvasText.id, 'productCanvasText')}
          onTransform={({position, size}) => props.updateProductCanvasText({id: productCanvasText.id, props: {...productCanvasText, position, width: size.width}, hitApi: false})}
          onTransformEnd={() => {
            props.updateProductCanvasText({id: productCanvasText.id, props: {position: productCanvasText.position, width: productCanvasText.width}});
          }}
          position={position}
          size={{width, height: 100}}
        />
      )}
    </>
  );
}

export default connect({
  mapDispatch: {
    ..._.pick(resourceActions.productCanvasTexts, ['updateProductCanvasText', 'destroyProductCanvasText'])
  }
})(ProductCanvasText);
