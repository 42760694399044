import React from 'react';
import _ from 'lodash';
import K from '~/k';

import trashIcon from '~/assets/trash-icon.png';

import { View, Image, TouchableOpacity } from 'react-native';
import { Label, Text, PickerInput, LabelledView, CheckboxInput } from '@symbolic/rn-lib';
import { connect } from '@symbolic/redux';
import { resourceActions } from '~/redux';
import { getAdminTitleFor } from '~/helpers/product-order-helper';
import thumbnailPlaceholder from '~/assets/thumbnail-placeholder.png';

class ProductRule extends React.PureComponent {
  handleTitleChange = ({value}) => this.props.updateProductRule({id: this.props.productRule.id, props: {title: value}});

  deleteProductRule = async () => {
    if (await confirm('Are you sure?', '')) {
      setTimeout(() => {
        this.props.destroyProductRule({id: this.props.productRule.id});
      });
    }
  };

  handleToResourceKeyInputChange = ({value}) => {
    this.props.updateProductRule({id: this.props.productRule.id, props: {toResourceKey: value}});
  };

  handleToProductPropertyInputChange = ({value}) => {
    if (this.props.productRule.toResourceKey === 'productProperty') {
      this.props.updateProductRule({id: this.props.productRule.id, props: {toProductPropertyId: value, toIds: [parseInt(value)]}});
    }
    else {
      this.props.updateProductRule({id: this.props.productRule.id, props: {toProductPropertyId: value, toIds: []}});
    }
  };

  handleProductOptionClick = ({productOptionId}) => {
    var toIds = _.get(this.props.productRule, 'toIds', []);

    if (!_.includes(toIds, productOptionId)) {
      toIds.push(productOptionId);
    }
    else {
      toIds = _.pull(toIds, productOptionId);
    }

    this.props.updateProductRule({id: this.props.productRule.id, props: {toIds}});
  };

  render() {
    var {productRule, productPropertiesById, productOptionsById} = this.props;

    var productProperties = _.chain(productPropertiesById)
      .reject('archived')
      .sortBy('rank')
      .value();

    var productOptions = _.chain(productOptionsById)
      .reject('archived')
      .filter({productPropertyId: parseInt(productRule.toProductPropertyId)})
      .sortBy('rank')
      .value();

    return (
      <View style={{marginBottom: K.margin, padding: K.margin, borderColor: 'rgba(0, 0, 0, 0.2)', borderWidth: 1, borderRadius: K.borderRadius, borderStyle: 'solid'}}>
        <PickerInput
          showDownArrow
          style={{height: K.inputHeight, marginBottom: K.margin}}
          buttonStyle={{backgroundColor: K.colors.gray}}
          options={[
            {value: 'incompatible', title: 'Incompatible with...'},
            {value: 'compatible', title: 'Compatible with...'}
          ]}
          value={productRule.type}
          onChange={({value}) => this.props.updateProductRule({id: this.props.productRule.id, props: {type: value}})}
        />
        <PickerInput
          showDownArrow
          style={{height: K.inputHeight, marginBottom: K.margin}}
          buttonStyle={{backgroundColor: K.colors.gray}}
          options={[
            {value: 'productOption', title: 'Product Options'},
            {value: 'productProperty', title: 'Product Property'}
          ]}
          value={productRule.toResourceKey}
          onChange={({value}) => this.handleToResourceKeyInputChange({value})}
        />
        <LabelledView
          label={productRule.toResourceKey === 'productOption' ? 'Filter by product property' : 'Select product property'}
          styles={{outerView: {marginTop: K.margin}}}
        >
          <PickerInput
            showDownArrow
            style={{height: K.inputHeight, marginBottom: K.margin}}
            buttonStyle={{backgroundColor: K.colors.gray}}
            options={[
              {value: -1, title: ''},
              ..._.map(productProperties, (productProperty) => ({value: productProperty.id, title: getAdminTitleFor({productProperty})}))
            ]}
            value={productRule.toProductPropertyId}
            onChange={({value}) => this.handleToProductPropertyInputChange({value})}
          />
          {productRule.toResourceKey === 'productOption' && (
            <LabelledView label={'Select product options that apply'} styles={{outerView: {marginTop: K.margin}}}>
              {_.map(productOptions, ({title, id, thumbnailUrl}, index) => (
                <View style={{flexDirection: 'row', height: 30, marginBottom: K.margin}}>
                  <View style={{width: 30, marginRight: K.margin}}>
                    <Image source={thumbnailUrl ? (typeof(thumbnailUrl) === 'string' ? {uri: thumbnailUrl} : thumbnailUrl) : thumbnailPlaceholder} style={{width: '100%', height: '100%', borderRadius: 20}}/>
                    <View style={{borderColor: 'rgba(0, 0, 0, 0.2)', borderWidth: 1, width: '100%', height: '100%', borderRadius: '50%', position: 'absolute', top: 0, left: 0}}/>
                  </View>
                  <CheckboxInput
                    key={`option-${id}`}
                    label={`${index + 1}. ${title}`}
                    value={_.includes(productRule.toIds, id)}
                    onChange={() => this.handleProductOptionClick({productOptionId: id})}
                    style={{flex: 1}}
                  />
                </View>
              ))}
            </LabelledView>
          )}
        </LabelledView>
        <TouchableOpacity style={{alignSelf: 'flex-end'}} onPress={this.deleteProductRule}>
          <Image source={trashIcon} style={{...K.defaultIconSize, opacity: 0.5}} />
        </TouchableOpacity>
      </View>
    );
  }
}

export default connect({
  mapState: (state, ownProps) => {
    var productPropertiesById = state.resources.productProperties.byId;
    var productOptionsById = state.resources.productOptions.byId;

    return {
      productPropertiesById,
      productOptionsById
    };
  },
  mapDispatch: {
    ..._.pick(resourceActions.productRules, ['updateProductRule', 'destroyProductRule'])
  }
})(ProductRule);
