import _ from 'lodash';

if (!window.lib) require('henrybuilt-js-library');

var lib = window.lib;

lib.trig.extendedLineFrom = ({point, alpha}) => ({
  from: lib.object.sum(point, lib.trig.rotate({
    point: {x: -100000, y: 0}, byRadians: alpha
  })),
  to: lib.object.sum(point, lib.trig.rotate({
    point: {x: 100000, y: 0}, byRadians: alpha
  }))
});

lib.number.rangesOverlap = ({r1, r2, inclusive=true} = {}) => {
  var x1 = r1.from, x2 = r1.to, y1 = r2.from, y2 = r2.to;

  var outerDistance = Math.max(x2, y2) - Math.min(x1, y1);
  var netWidth = (x2 - x1) + (y2 - y1);

  return inclusive ? outerDistance <= netWidth : outerDistance < netWidth;
};

lib.trig.anglesAreParallel = ({a1, a2, mode='radians'}) => {
  a1 = lib.trig.normalize({[mode]: a1});
  a2 = lib.trig.normalize({[mode]: a2});

  if (a1 >= Math.PI) a1 -= Math.PI;
  if (a2 >= Math.PI) a2 -= Math.PI;

  return a1 === a2;
};

lib.trig.anglesAreEqual = ({a1, a2, mode='radians'}) => {
  a1 = lib.trig.normalize({[mode]: a1});
  a2 = lib.trig.normalize({[mode]: a2});

  return Math.abs(a1 - a2) <= Number.EPSILON;
};

lib.number.evenlyDistributedDistancesFor = ({distance, maxDistance, precision}) => {
  var count = _.ceil(distance / maxDistance);
  var subDistance = distance / count;

  if (precision) subDistance = lib.number.round(subDistance, {toNearest: precision});

  //WARNING total needs to add up to distance, despite rounding, so last width is relative
  var distances = _.times(count, index => {
    var isMiddle = index === Math.floor(count / 2);

    return isMiddle ? (distance - subDistance * (count - 1)) : subDistance;
  });

  return distances;
};

lib.filterEvery = (collection, predicates) => {
  return _.filter(collection, (...args) => {
    return _.every(predicates, predicate => predicate(...args));
  });
};

lib.DimensionConstrainer = window.DimensionConstrainer;

lib.trig.pointsAreEqual = ({p1, p2}) => {
  return p1 && p2 && p1.x === p2.x && p1.y === p2.y;
};

lib.trig.linesAreEqual = ({l1, l2}) => {
  return (lib.trig.pointsAreEqual({p1: l1.from, p2: l2.from}) && lib.trig.pointsAreEqual({p1: l1.to, p2: l2.to}))
      || (lib.trig.pointsAreEqual({p1: l1.from, p2: l2.to}) && lib.trig.pointsAreEqual({p1: l1.to, p2: l2.from}));
};

lib.math.pointIsOnLine = ({line, point}) => {
  var lpa = line.from, lpb = line.to, p = point;

  var d = (a, b) => Math.sqrt((a.x - b.x) ** 2 + (a.y - b.y) ** 2);

  return Math.abs(d(lpa, lpb) - d(lpa, p) - d(lpb, p)) < Number.EPSILON;
};

lib.event = {};

lib.event.keyPressed = (event, key) => {
  var keyCode = event.keyCode;
  var pressed = false;

  if (key === 'left') pressed = keyCode === 37;
  if (key === 'up') pressed = keyCode === 38;
  if (key === 'right') pressed = keyCode === 39;
  if (key === 'down') pressed = keyCode === 40;

  if (key === 's') pressed = keyCode === 83;
  if (key === 'y') pressed = keyCode === 89;
  if (key === 'z') pressed = keyCode === 90;
  if (key === '+') pressed = keyCode === 187;
  if (key === '-') pressed = keyCode === 189;
  if (key === 'd') pressed = keyCode === 68;
  if (key === 't') pressed = keyCode === 84;
  if (key === 'v') pressed = keyCode === 86;
  if (key === 'm') pressed = keyCode === 77;
  if (key === 'e') pressed = keyCode === 69;
  if (key === 'c') pressed = keyCode === 67;
  if (key === 'x') pressed = keyCode === 88;
  if (key === 'a') pressed = keyCode === 65;

  if (key === 'enter') pressed = keyCode === 13;
  if (key === 'ctrlcmd') pressed = (event.ctrlKey || event.metaKey || event.which === 22 || event.which === 224);
  if (key === 'alt') pressed = (event.altKey);
  if (key === 'esc') pressed = keyCode === 27;
  if (key === 'tab') pressed = keyCode === 9;
  if (key === 'shift') pressed = event.shiftKey;
  if (key === 'backspace') pressed = keyCode === 8;
  if (key === 'space') pressed = keyCode === 32;
  if (key === 'delete') pressed = keyCode === 8 || keyCode === 46;

  return pressed;
};

lib.event.numberKeyPressed = (event) => {
  var numberKeyCodes = [49, 50, 51, 52, 53, 54, 55, 56, 57, 48];
  var keyCode = event.keyCode;

  return _.includes(numberKeyCodes, keyCode);
};

lib.math.linesIntersectInclusive = ({l1, l2, inclusive = true}) => {
  var linesIntersect = false;
  var {from: {x: a, y: b}, to: {x: c, y: d}} = l1;
  var {from: {x: p, y: q}, to: {x: r, y: s}} = l2;

  var det, gamma, lambda;
  det = (c - a) * (s - q) - (r - p) * (d - b);

  if (det !== 0) {
    lambda = ((s - q) * (r - a) + (p - r) * (s - b)) / det;
    gamma = ((b - d) * (r - a) + (c - a) * (s - b)) / det;

    linesIntersect = (0 < lambda && lambda < 1) && (0 < gamma && gamma < 1);
  }

  if (inclusive && det !== 0 && !linesIntersect) {
    linesIntersect = _.some(l1, point => lib.math.pointIsOnLine({point, line: l2})) || _.some(l2, point => lib.math.pointIsOnLine({point, line: l1}));
  }

  return linesIntersect;
};

lib.polygon.makeClockwise = ({points}) => {
  const isClockwise = lib.math.polygon.clockwise({points});

  if (!isClockwise) {
    // Get the center (mean value) using reduce
    const center = lib.polygon.center({points});

    // Add an angle property to each point using tan(angle) = y/x
    const angles = points.map(({ x, y }, index) => {
      const next = lib.array.next(points, index);
      return { x, y, angle: lib.trig.alpha({p1: {x, y}, p2: next})};
    });

    // Sort your points by angle
    points = angles.sort((a, b) => a.angle - b.angle);
  }

  return points;
};

//WARNING do not move to henrybuilt-js-library for now - not a client library yet

lib.cookie = {};

lib.cookie.set = ({scope, key, value, days, domain}) => {
  App.setCookie(scope, key, JSON.stringify(value), days, domain);
};

lib.cookie.get = ({scope, key, defaultValue, parse=true}) => {
  var value = App.getCookie(scope, key);

  if (value === null) {
    value = undefined;
  }
  else {
    if (parse) value = JSON.parse(value);
  }

  if (defaultValue !== undefined && value === undefined) {
    value = defaultValue;
  }

  return value;
};


var App = {};

/**
 * Creates a new cookie
 * @param  {string} scope pt, hb, cs, oc...
 * @param  {string} name key
 * @param  {string} value value
 * @param  {integer} days expiration length
 * @param  {string} domain url
 */
 App.setCookie = function(scope, name, value, days, domain) {
  if (days) {
    var date = new Date();
    date.setTime(date.getTime()+(days*24*60*60*1000));
    var expires = "; expires="+date.toGMTString();
  }
  else {
    var expires = "";
  }

  if (domain) {
    var domain = domain.replace(/(^\w+:|^)\/\//, '');

    if (domain.includes("localhost")) domain="localhost";

    domain = "; domain="+domain;
  }
  else {
    var domain = "";
  }

  document.cookie = scope+'_'+name+"="+value.replace(/"/g, '%22').replace(/,/g, '%2C')+expires+domain+"; path=/";
};

/**
 * Read a key given a scope
 * @param  {string} scope pt, hb, cs, oc...
 * @param  {string} name key
 * @param  {string} domain url
 */
App.getCookie = function(scope, name) {
  if (scope) name = `${scope}_${name}`;

  var nameEQ = name + "=";
  var ca = document.cookie.split(';');
  for(var i=0;i < ca.length;i++) {
    var c = ca[i];
    while (c.charAt(0)==' ') c = c.substring(1,c.length);
    if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length,c.length).replace(/%22/g, '"').replace(/%2C/g, ',');
  }
  return null;
};


export default lib;
