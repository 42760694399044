import { PureComponent } from 'react';
import { Text } from '@symbolic/rn-lib';
import { View, Image } from 'react-native';
import { connect } from '@symbolic/redux';
import { resourceActions } from '~/redux';
import { api } from '@symbolic/lib';

import FileInput from '~/components/file-input';
import handleFileUpload from '~/helpers/upload-file-helper';

import thumbnailPlaceholder from '~/assets/thumbnail-placeholder.png';

import K from '~/k';
import _ from 'lodash';

class ProductInstanceMedia extends PureComponent {
  state = {
    image: null,
    uploading: false,
    uploadWasSuccessful: false,
    uploadHasFailed: false
  };

  render() {
    var {activeProductInstance, allowFileUpload = true} = this.props;

    return (
      <View>
        <View style={{flexDirection: 'column', alignItems: 'center', justifyContent: 'center'}}>
          <View style={{marginBottom: K.spacing, height: 400, width: 400}}>
            <Image source={activeProductInstance.thumbnailUrl ? {uri: activeProductInstance.thumbnailUrl} : thumbnailPlaceholder} style={{resizeMode: 'contain', alignSelf: 'center', overflow: 'hidden', width: '100%', height: '100%'}}/>
          </View>
          {allowFileUpload && (
            <FileInput
              containerStyle ={{justifyContent: 'center'}}
              uploading={this.state.uploading}
              uploadWasSuccessful={this.state.uploadWasSuccessful}
              uploadHasFailed = {this.state.uploadHasFailed}
              handleFilesPicked={(files) => handleFileUpload({files,
                setState: (newState) => this.setState(newState),
                api,
                type: 'image',
                resource: activeProductInstance,
                session: this.props.session,
                resourceKey: 'productInstance',
                trackResource: ({productInstances}) => this.props.trackProductInstances({productInstances})
              })}
            />
          )}
        </View>
      </View>
    );
  }
}

export default connect({
  mapState: (state, ownProps) => {

  },
  mapDispatch: {
    ..._.pick(resourceActions.productInstances, ['trackProductInstances']),
  }
})(ProductInstanceMedia);
