import React from 'react';
import K from '~/k';

import { View } from 'react-native';
import { Text, Popup, TextInput, Button } from '@symbolic/rn-lib';

class ProductInstanceTitlePopup extends React.PureComponent {
  state = {};

  handleAddProductButtonPress = () => {
    if (this.state.tentativeProductInstanceTitle) {
      this.props.createProductInstance({product: this.props.product, title: this.state.tentativeProductInstanceTitle});

      this.props.hideProductInstanceTitlePopup();
    }
    else {
      alert('This item can not be added without an identifier/description.');
    }
  };

  render() {
    return (
      <Popup
        displayMode='fitted'
        onClose={() => this.props.hideProductInstanceTitlePopup()}
      >
        <View>
          <View style={{marginLeft: K.spacing, marginBottom: K.spacing * 2}}>
            <Text style={{...K.fonts.standard, fontWeight: 'bold', marginBottom: K.spacing}}>
              {this.props.product.title}
            </Text>
            <Text>
              Enter a short identifier or description to help with project coordination. Example: <span style={{fontStyle: 'italic'}}>Entry Foyer</span>
            </Text>
          </View>
          <TextInput
            nativeID={'OrderPageProductInstanceTitlePopupInput'}
            placeholder={'Identifier/Description'}
            style={{marginBottom: K.spacing}}
            value={''}
            maxLength={30}
            onChange={({value}) => this.setState({tentativeProductInstanceTitle: value})}
            autoFocus
            onKeyDown={(event) => event.keyCode === 13 && this.handleAddProductButtonPress()}
          />
        </View>
        <View style={{flex: 1, justifyContent: 'flex-end'}}>
          <Button
            nativeID={'OrderPageProductInstanceTitleAddButton'}
            dark
            label='Add item'
            onPress={() => this.handleAddProductButtonPress()}
            style={{alignSelf: 'flex-end'}}
          />
        </View>
      </Popup>
    );
  }
}

export default ProductInstanceTitlePopup;
