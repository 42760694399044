import { getOffsetForPoints } from '~/helpers/arch/arch-helpers';

function getArchCanvasViews({archCustomViews, session, archWalls}) {
  var views = [];

  var planOffset = getOffsetForPoints(_.flatMap(archWalls, ({from, to}) => [from, to]));

  if (session.activeOrg.id === 1798) views.push({title: 'Plan', cameraYAngle: 0, cameraXZAngle: 0, getOffset: () => planOffset, viewKey: 'top'});

  if (archCustomViews) {
    _.forEach(_.sortBy(archCustomViews, 'rank'), (archCustomView) => {
      views.push({title: archCustomView.title || 'Untitled Viewport', cameraYAngle: Math.PI / 2, cameraXZAngle: 0, getOffset: () => ({x: 0, y: 0}), archCustomView, viewKey: 'custom'});

      if (session.activeOrg.id === 1798) { //TODO if calico
        views.push({title: `${archCustomView.title || 'Untitled Viewport'}: Panel Layout`, cameraYAngle: Math.PI / 2, cameraXZAngle: 0, getOffset: () => ({x: 0, y: 0}), archCustomView, viewKey: 'calicoPanelLayout'});
      }
    });
  }

  return views;
}

export { getArchCanvasViews };
