export default function getTextSize({
  text,
  fontSize = 12.6,
  fontFamily = 'Arial',
  fontWeight = 'normal'
}) {
  var newEl = document.createElement('div');

  newEl.style.position = 'fixed';
  newEl.innerHTML = text;
  newEl.style['white-space'] = 'pre-line';
  newEl.style['font-size'] = fontSize + 'px';
  newEl.style['font-family'] = fontFamily;
  newEl.style['font-weight'] = fontWeight;

  document.body.appendChild(newEl);

  var width = newEl.clientWidth + 2;
  var height = newEl.clientHeight + 2;

  newEl.remove();

  return {width, height};
}