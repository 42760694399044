var commonProps = [
  {key: 'width', title: 'Width', type: 'size'},
  {key: 'height', title: 'Height', type: 'size'},
  {key: 'depth', title: 'Depth', type: 'size'},
  {key: 'x', title: 'X', type: 'size'},
  {key: 'y', title: 'Y', type: 'size'},
  {key: 'z', title: 'Z', type: 'size'},
  {key: 'fill', title: 'Fill', type: 'color'}
];

var getCommonPropsDataFor = (keys) => {
  return _.filter(commonProps, commonProp => _.includes(keys, commonProp.key));
};

var nodeTypes = [
  {key: 'group', graphicTypes: ['2d', '3d'], title: 'Group', propsData: [...getCommonPropsDataFor(['x', 'y', 'z', 'width', 'height', 'depth'])]},
  {key: 'rect', graphicTypes: ['2d'], title: 'Rectangle', propsData: [...getCommonPropsDataFor(['x', 'y', 'width', 'height', 'fill'])]},
  {key: 'cube', graphicTypes: ['3d'], title: 'Cube', propsData: [...getCommonPropsDataFor(['x', 'y', 'z', 'width', 'height', 'depth', 'fill'])]}
];

export { nodeTypes };
