import randomWithSeed from 'random-seed';

import lib from '~/lib';

var getRandomValues = ({count, total, target, tolerance, seed}) => {
  var array = [];
  var length = count;
  var number;
  var totalProcessed;
  var i;
  var rand = randomWithSeed.create(seed);

  for (i = 0; i < length; i++) {
    // FIRST ONE
    if (i == 0) {
      number = rand.intBetween(target - tolerance, target + tolerance);
      totalProcessed = number;
      array.push(number);
    }

    //  MIDDLE SECTION
    if (i > 0 && i < length - 1) {
      if (length > 2) {
        number = rand.intBetween(target - tolerance, target + tolerance);
        totalProcessed = totalProcessed + number;

        array.push(number);
      }
    }

    // LAST ONE
    if (i == length - 1) {
      var lastOne = total - totalProcessed;
      array.push(lastOne);
    }
  }

  return array;
};

var lamellaLines = ({lamellaQuantity, lamellaWidths, lamellaAlignment, orientationIsVertical = true, boundingBox}, {path}) => {
  var lineStyle = {strokeWidth: 0.5, stroke: '#000', fill: 'transparent'};
  var remainder = boundingBox.width - _.sum(lamellaWidths);
  var offset = {random: 0, center: remainder / 2, left: 0, right: remainder}[lamellaAlignment];

  if (orientationIsVertical) {
    return _.map(_.dropRight(lamellaWidths), lamellaWidth => {
      var x = lamellaWidth + offset;

      offset += lamellaWidth;

      return path({d: [
        {command: 'M', x, y: 0},
        {command: 'L', x, y: boundingBox.height},
      ], ...lineStyle});
    });
  }
  else {
    return _.map(_.dropRight(lamellaWidths), lamellaWidth => {
      var y = lamellaWidth + offset;

      offset += lamellaWidth;

      return path({d: [
        {command: 'M', x: 0, y},
        {command: 'L', x: boundingBox.width, y},
      ], ...lineStyle});
    });
  }
};

var lamellaData = ({boundingBox, determinedBy, path, lamellaQuantity, lamellaWidthValue, lamellaAlignment, productInstance}) => {
  var lamellaLinesGraphic = [];
  var lamellaWidth = boundingBox.width;

  var getLamellaWidthInfoString = ({lamellaAlignment, lamellaWidth}) => {
    return `${lamellaAlignment === 'random' ? 'RANDOM' : lib.number.toFraction(lib.number.round(lamellaWidth / 25.4, {toNearest: 1 / 32}), {normalscript: true, delimiter: '-'})}${lamellaAlignment === 'random' ? '' : `” [${lamellaWidth}MM]`}`;
  };

  if (determinedBy === 'quantity') {
    lamellaWidth = Math.round(boundingBox.width / lamellaQuantity * 10) / 10;

    var lamellaWidths = _.times(lamellaQuantity, () => lamellaWidth);

    lamellaLinesGraphic = lamellaLines({lamellaQuantity, lamellaWidths, lamellaAlignment, boundingBox: {width: boundingBox.width, height: boundingBox.height}}, {path});
  }
  else if (determinedBy === 'width') {
    var lamellaQuantity = Math.ceil(boundingBox.width / lamellaWidthValue);
    var lamellaWidths = [];

    if (lamellaAlignment === 'random') {
      lamellaWidths = getRandomValues({
        count: lamellaQuantity,
        total: boundingBox.width,
        target: lamellaWidth,
        tolerance: 50,
        seed: productInstance.id
      });
    }
    else if (lamellaWidthValue > 0) {
      lamellaWidth = lamellaWidthValue;

      lamellaWidths = _.times(lamellaQuantity, () => lamellaWidthValue);
    }
    else {
      lamellaWidths = _.times(lamellaQuantity, () => lamellaWidth);
    }

    lamellaLinesGraphic = lamellaLines({
      lamellaQuantity,
      lamellaWidths,
      lamellaAlignment,
      boundingBox: {width: boundingBox.width, height: boundingBox.height}
    }, {path});
  }

  return {
    lamellaLinesGraphic,
    lamellaWidthInfoString: getLamellaWidthInfoString({lamellaAlignment, lamellaWidth})
  };
};

var grainDirectionSymbol = ({grainDirectionIsVertical, boundingBox}, {group, path}) => {
  var symbolWidth = grainDirectionIsVertical ? 50 : 200;
  var symbolHeight = grainDirectionIsVertical ? 200 : 50;

  return group({
    width: symbolWidth,
    height: symbolHeight,
    x: (boundingBox.width / 2) - (symbolWidth / 2),
    y: (boundingBox.height / 2) - (symbolHeight / 2)
  }, [
    path({d:
      grainDirectionIsVertical ? ([ //HINT vertical grain
        {command: 'M', x: 0, y: symbolHeight / 4},
        {command: 'L', x: symbolWidth / 2, y: 0},
        {command: 'L', x: symbolWidth / 2, y: symbolHeight},
        {command: 'L', x: symbolWidth, y: symbolHeight - (symbolHeight / 4)}
      ]) : ([ //HINT horizontal grain
        {command: 'M', x: symbolWidth / 4, y: symbolHeight},
        {command: 'L', x: 0, y: symbolHeight / 2},
        {command: 'L', x: symbolWidth, y: symbolHeight / 2},
        {command: 'L', x: symbolWidth - (symbolWidth / 4), y: 0}
      ])
    , strokeWidth: 0.5, stroke: 'black', fill: 'transparent'})
  ]);
};

var unitNumberSymbol = ({scale, group, circle, text, unitNumber}) => {
  return group({y: 6 * scale, x: -320}, [
    circle({radius: 6 * scale, fill: '#43807A'}),
    text({fontSize: 7, x: 0, y: 0, radius: 10 * scale, fill: 'white', align: 'center', alignVertical: 'center'}, unitNumber)
  ]);
};

export { lamellaData, grainDirectionSymbol, unitNumberSymbol };
