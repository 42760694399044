import React from 'react';
import { View } from 'react-native';
import { Text } from '@symbolic/rn-lib';
import { getCalicoMuralSeamColor } from '~/helpers/custom/calico/custom-calico-helpers';

export default class CalicoMuralSeamNotes extends React.PureComponent {
  render() {
    var fontSize = 11;
    var fontFamily = 'HelveticaNeue-Light';
    var {page} = this.props;
    var {calicoMuralSeams, allCalicoMuralSeamsInOrder} = page;
    var thicknesses = _.sortBy(_.uniq(_.map(calicoMuralSeams, ({data}) => data.thickness || 1)), v => v);

    return (<>
      <View>
        <View style={{flexDirection: 'row', alignItems: 'center'}}>
          <View style={{border: '1px dashed #000', width: 20, marginRight: 10}}></View>
          <Text style={{fontFamily, fontSize}}>double cut seam</Text>
        </View>
        {_.map(thicknesses, thickness => {
          var color = getCalicoMuralSeamColor({thickness, allCalicoMuralSeamsInOrder});

          return (
            <View key={thickness} style={{flexDirection: 'row', alignItems: 'center', marginTop: 4}}>
              <View style={{width: 20, height: 10, marginRight: 10, backgroundColor: color}}/>
              <Text style={{fontFamily, fontSize}}>{`indicates ${thickness}" overlap`}</Text>
            </View>
          );
        })}
      </View>
    </>);
  }
}
