import React from 'react';
import _ from 'lodash';
import K from '~/k';

import { View, TouchableOpacity } from 'react-native';
import { Text, Popup, LabelledView, ScrollView } from '@symbolic/rn-lib';
import { connect } from '@symbolic/redux';
import { resourceActions } from '~/redux';
import { getAdminTitleFor } from '~/helpers/product-order-helper';

class ProductPropertiesPopup extends React.PureComponent {
  close = () => {
    this.props.onClose();
  };

  updateProductProperties = ({id, action}) => {
    var {isGrouping, productPropertyGroup, productPropertyGroupsById} = this.props;

    if (isGrouping) {
      var propertyGroupProperties = _.get(productPropertyGroup, 'properties') || {};

      if (action === 'add') {
        propertyGroupProperties = _.set(propertyGroupProperties, id, {});

        this.props.updateProductProperty({id: id, props: {productPropertyGroupId: productPropertyGroup.id}});

        //HINT remove product property from other property groups
        var otherProductPropertyGroupsWithProductPropertyId = _.chain(productPropertyGroupsById)
          .map(propertyGroup => propertyGroup)
          .filter(propertyGroup => {
            var includesProductPropertyId = _.includes(_.keys(propertyGroup.properties), `${id}`);
            var isNotActiveProductPropertyGroup = propertyGroup.id !== productPropertyGroup.id;

            return includesProductPropertyId && isNotActiveProductPropertyGroup;
          })
          .value();

        _.forEach(otherProductPropertyGroupsWithProductPropertyId, propertyGroup => {
          var propertyGroupProperties = _.get(propertyGroup, 'properties', {});

          delete propertyGroupProperties[id];

          this.props.updateProductPropertyGroup({id: propertyGroup.id, props: {properties: propertyGroupProperties}});
        });
      }
      else if (action === 'remove') {
        delete propertyGroupProperties[id];

        this.props.updateProductProperty({id: id, props: {productPropertyGroupId: 0}});
      }

      this.props.updateProductPropertyGroup({id: productPropertyGroup.id, props: {properties: propertyGroupProperties}});
    }
    else {
      var productProperties = _.get(this.props.product, 'associations.productProperties', {});

      if (action === 'add') {
        productProperties = _.set(productProperties, id, {});
      }
      else if (action === 'remove') {
        delete productProperties[id];
      }

      this.props.updateProduct({id: this.props.product.id, props: {associations: {productProperties}}});
    }
  };

  render() {
    var {product, productPropertiesById, productPropertyGroup} = this.props;
    if (product) {
      var productPropertyIds = _.map(_.get(product, 'associations.productProperties', {}), (value, key) => parseInt(key));
    }
    else {
      var productPropertyIds = _.map(_.get(productPropertyGroup, 'properties', {}), (value, key) => parseInt(key));
    }

    var sortedAndFilteredProductProperties = _.chain(productPropertiesById)
      .filter(productProperty => productProperty.archived !== 1)
      .sortBy('rank')
      .value();

    return (
      <Popup onClose={this.close}>
        <LabelledView label='Applied properties' styles={{outerView: {marginBottom: K.spacing}}}>
          <ScrollView style={{marginBottom: K.spacing}}>
            {!_.isEmpty(productPropertyIds) ? (
              <View>
                {_.map(productPropertyIds, propertyId => {
                  var productProperty = _.find(productPropertiesById, {id: propertyId});

                  if (productProperty) {
                    var {id} = productProperty;
                    var title = getAdminTitleFor({productProperty});

                    return (
                      <TouchableOpacity key={id} style={{marginBottom: K.spacing / 2, flexDirection: 'row', minHeight: K.button.height}} onPress={() => this.updateProductProperties({id, action: 'remove'})}>
                        <View style={{paddingVertical: K.spacing / 2, paddingHorizontal: K.spacing, flex: 1, backgroundColor: K.colors.gray, justifyContent: 'center', borderTopLeftRadius: 5, borderBottomLeftRadius: 5}}>
                          <Text>{title}</Text>
                        </View>
                        <View style={{padding: K.spacing / 2, width: K.button.width, backgroundColor: K.colors.doubleGray, borderTopRightRadius: 5, borderBottomRightRadius: 5, justifyContent: 'center', alignItems: 'center'}}>
                          <Text style={{textAlign: 'center'}}>-</Text>
                        </View>
                      </TouchableOpacity>
                    );
                  }
                })}
              </View>
            ) : (
              <View style={{paddingHorizontal: K.spacing, marginTop: K.spacing}}>
                <Text style={{opacity: 0.5}}>Select properties in the list below to add</Text>
              </View>
            )}
          </ScrollView>
        </LabelledView>
        <LabelledView label='All Properties' styles={{outerView: {marginBottom: K.spacing}}}>
          <ScrollView>
            {_.map(sortedAndFilteredProductProperties, (productProperty) => {
              var {id} = productProperty;
              var title = getAdminTitleFor({productProperty});

              if (!_.includes(productPropertyIds, id)) {
                return (
                  <TouchableOpacity key={id} style={{marginBottom: K.spacing / 2, flexDirection: 'row', minHeight: K.button.height}} onPress={() => this.updateProductProperties({id, action: 'add'})}>
                    <View style={{paddingVertical: K.spacing / 2, paddingHorizontal: K.spacing, flex: 1, backgroundColor: K.colors.gray, justifyContent: 'center', borderTopLeftRadius: 5, borderBottomLeftRadius: 5}}>
                      <Text>{title}</Text>
                    </View>
                    <View style={{padding: K.spacing / 2, width: K.button.width, backgroundColor: '#000', borderTopRightRadius: 5, borderBottomRightRadius: 5, justifyContent: 'center', alignItems: 'center'}}>
                      <Text style={{textAlign: 'center', color: '#FFF'}}>+</Text>
                    </View>
                  </TouchableOpacity>
                );
              }
            })}
          </ScrollView>
        </LabelledView>
      </Popup>
    );
  }
}

export default connect({
  mapState: (state, ownProps) => {
    var productPropertiesById = state.resources.productProperties.byId;
    var productPropertyGroupsById = state.resources.productPropertyGroups.byId;

    return {
      productPropertiesById,
      productPropertyGroupsById
    };
  },
  mapDispatch: {
    ..._.pick(resourceActions.products, ['updateProduct']),
    ..._.pick(resourceActions.productPropertyGroups, ['updateProductPropertyGroup']),
    ..._.pick(resourceActions.productProperties, ['updateProductProperty'])
  }
})(ProductPropertiesPopup);
