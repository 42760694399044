function polarToCartesian(centerX, centerY, radius, angleInDegrees) {
  var angleInRadians = (angleInDegrees - 90) * Math.PI / 180.0;

  return {
    x: centerX + (radius * Math.cos(angleInRadians)),
    y: centerY + (radius * Math.sin(angleInRadians))
  };
}

function getArcPathArrayWithCenterFromAngles({cx, cy, radius, startAngle, endAngle}) {
  var start = polarToCartesian(cx, cy, radius, endAngle);
  var end = polarToCartesian(cx, cy, radius, startAngle);

  var largeArcFlag = endAngle - startAngle <= 180 ? '0' : '1';

  var d = [
    {command: 'M', x: start.x, y: start.y},
    {command: 'A', rx: radius, ry: radius, xAxisRotation: 0, largeArcFlag, sweepFlag: 0, x: end.x, y: end.y}
  ];

  return d;
}

export { getArcPathArrayWithCenterFromAngles };
