import { connect } from '@symbolic/redux';
import { View, Image } from 'react-native';
import { useRef, useState } from 'react';
import { Text } from '@symbolic/rn-lib';
import { getSizeForPoints, getOffsetForPoints } from '~/helpers/arch/arch-helpers';
import { getCalicoMuralTextureOption } from '~/helpers/custom/calico/custom-calico-helpers';

import K from '~/k';
import lib from '~/lib';
import uuid from 'uuid';
import SpatialCanvas from '~/components/canvas/spatial-canvas';
import calicoWallpaperLogo from './calico-wallpaper-logo.png';
import CalicoMuralSeamNotes from '~/pages/drawings/page-types/arch/footer/helpers/calico/calico-mural-seam-notes';
import CanvasViewSvgRenderer from '~/components/canvas/canvas-view-svg-renderer/canvas-view-svg-renderer';

export var FooterText = ({style, ...props}) => <Text {...props} style={{fontSize: 11, fontFamily: 'HelveticaNeue-Light', letterSpacing: 0, lineHeight: 'auto', ...style}}/>;

function DrawingsArchFooter({page, pageNumber, productOrder, offset, notesTitle, archNotes, ...props}) {
  const [forceUpdateKey, setForceUpdateKey] = useState(null);

  var canvasSize = 100;
  var wallPoints = _.map(_.flatMap(page.archWalls, ({from, to}) => [from, to]), ({x, z}) => ({x, y: z}));
  var planFootprintSize = getSizeForPoints(wallPoints);
  var planScale = Math.min(canvasSize / (planFootprintSize.width * 1.5), canvasSize / (planFootprintSize.height * 1.5));
  var layerRef = useRef();
  var calicoMuralTextureOption = getCalicoMuralTextureOption({calicoMuralTexture: page.calicoMuralTexture, productOptionsById: props.productOptionsById});

  if (!isFinite(planScale)) {
    planScale = 1;
  }

  return (
    <>
      <View style={{borderWidth: 1, flexDirection: 'row'}}>
        <View style={{borderRightWidth: 1, paddingTop: K.spacing / 2}}>
          {_.map([
            {label: 'Client', value: productOrder.contactName || ''},
            {label: 'Project', value: productOrder.title},
            {label: 'Invoice', value: `#${productOrder.purchaseOrderNumber || ''}`},
            {label: 'Pattern', value: calicoMuralTextureOption.data.pattern},
            {label: 'Style', value: calicoMuralTextureOption.data.style},
            {label: 'Substrate', value: _.startCase(page.calicoMuralTexture.data.substrate || 'standardCommercial')}
          ], ({label, value}) => (
            <View key={label} style={{flexDirection: 'row', marginTop: 1}}>
              {typeof label === 'string' ? <FooterText style={{width: 65, textAlign: 'right', fontWeight: 'bold'}}>{label}</FooterText> : label}
              {typeof value === 'string' ? <FooterText style={{width: 150, paddingHorizontal: 8, textTransform: 'capitalize'}}>{value}</FooterText> : value}
            </View>
          ))}
        </View>
        <View style={{borderRightWidth: 1, paddingVertical: 8, paddingHorizontal: K.spacing, flex: 1, flexDirection: 'row'}}>
          <View style={{flex: 1}}>
            <FooterText style={{fontWeight: 'bold', marginBottom: 5}}>{notesTitle ?? 'Notes'}</FooterText>
            {archNotes}
          </View>
          {page.type === 'calicoMuralSeam' && (
            <CalicoMuralSeamNotes {...{page, offset}} />
          )}
        </View>
        <View style={{borderRightWidth: 1, position: 'relative'}}>
          <FooterText style={{marginLeft: K.spacing, marginTop: K.spacing / 2, position: 'absolute', fontWeight: 'bold'}}>Floorplan</FooterText>
          <View style={{justifyContent: 'center', alignItems: 'center'}}>
            <View style={{width: canvasSize, height: canvasSize - 20, marginTop: 20}}>
              <CanvasViewSvgRenderer {...{layerRef, canvasSize: {width: canvasSize, height: canvasSize - 20}}} forceCanvasUpdate={() => setForceUpdateKey(uuid())}>
                <SpatialCanvas
                  {...{layerRef, forceUpdateKey}}
                  isInContextMode
                  canvasSize={{width: canvasSize, height: canvasSize - 20}}
                  archWalls={page.archWalls}
                  calicoWallLabels={page.allCalicoWallLabels}
                  activeView={{viewKey: 'top', cameraYAngle: 0, getOffset: () => lib.object.multiply(getOffsetForPoints(wallPoints), planScale)}}
                  scale={planScale}
                />
              </CanvasViewSvgRenderer>
            </View>
          </View>
        </View>
        <View>
          <Image source={calicoMuralTextureOption.thumbnailUrl} style={{height: '100%', width: canvasSize}}/>
        </View>
      </View>
      <View style={{borderBottomWidth: 1, borderLeftWidth: 1, borderRightWidth: 1, alignItems: 'center', flexDirection: 'row'}}>
        <View style={{flex: 1, alignItems: 'center', height: 20, justifyContent: 'center'}}>
          <Image source={calicoWallpaperLogo} style={{width: 300, height: 11, resizeMode: 'contain', imageRendering: 'crisp-edges'}}/>
        </View>
        <FooterText style={{position: 'absolute', right: K.spacing}}>{`Page ${pageNumber + 1}`}</FooterText>
      </View>
    </>
  );
}

export default connect({
  mapState: (state) => {
    return {productOptionsById: state.resources.productOptions.byId};
  }
})(DrawingsArchFooter);
