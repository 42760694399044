import { useState, useEffect, useRef } from 'react';

import C2S from 'canvas2svg';

global.svgClipPathMap = {};
global.isRenderingSvg = false;

export default function CanvasViewSvgRenderer({canvasSize, layerRef, children, forceCanvasUpdate}) {
  var [svgString, setSvgString] = useState(undefined);
  var svgDivRef = useRef();

  useEffect(() => {
    if (layerRef.current) {
      var oldContext = layerRef.current.canvas.context._context;

      var c2s = layerRef.current.canvas.context._context = C2S({...canvasSize, ctx: oldContext});

      forceCanvasUpdate();

      //wait for canvas to re-render
      setTimeout(() => {
        setSvgString(c2s.getSerializedSvg());

        //wait for svg to render
        setTimeout(() => {
          if (svgDivRef.current) {
            //remove white rectangles that must be meant as background rectangles
            svgDivRef.current.querySelectorAll('rect').forEach(rect => {
              if (rect.getAttribute('fill') === '#FFFFFF' && rect.getAttribute('x') === '0') {
                rect.remove();
              }
            });

            //add additional styles like stroke-dasharray to paths - konva must not be adding these in a standard way
            svgDivRef.current.querySelectorAll('path').forEach(path => {
              var stroke = path.getAttribute('stroke');

              if (stroke[0] === '{') {
                _.forEach(JSON.parse(stroke), (value, key) => {
                  path.setAttribute(key, value);
                });
              }
            });

            //set path d attribute on all clipPaths (for masking)
            svgDivRef.current.querySelectorAll('svg defs clipPath').forEach(clipPath => {
              var clipPathId = clipPath.getAttribute('id');
              var correspondingGroup = svgDivRef.current.querySelector(`svg g[clip-path="url(#${clipPathId})"]`);
              var svgClipPathMapKey = correspondingGroup.querySelector('path').getAttribute('fill');
              var clipPathPolygon = global.svgClipPathMap[svgClipPathMapKey];

              var d = _.join(_.map(clipPathPolygon, (point, index) => {
                return `${index === 0 ? 'M' : 'L'} ${point.x} ${point.y}`;
              }), ' ');

              clipPath.querySelector('path').setAttribute('d', d);
            });
          }
        }, 1000);
      }, 1000); //TODO would be better to poll for complete/useffect for rerender complete somehow - same on inner svg timeout^
    }
  }, [layerRef.current]);

  if (svgString) {
    return <div ref={svgDivRef} dangerouslySetInnerHTML={{__html: svgString}}/>;
  }
  else {
    return children;
  }
}
