import { FooterText } from '~/pages/drawings/page-types/arch/footer/drawings-arch-footer.js';
import { View } from 'react-native';

import K from '~/k';

export default function DrawingsCalicoFooterNotes({page}) {
  var {drawingNotesData: notes} = page.archCustomView;
  var shouldIndicateViewStartOnPlan = !!_.find(page.calicoWallLabels, ['data.indicateViewStartOnPlan', true]);
  var pageSpecificNotes = notes[page.type];

  return (<>
    {pageSpecificNotes && (<FooterText style={{marginBottom: 2}}>{pageSpecificNotes}</FooterText>)}
    {notes.general && (<FooterText style={{marginBottom: 2}}>{notes.general}</FooterText>)}
    {notes.criticalPanelAlignment && (
      <FooterText style={{marginLeft: K.spacing, marginTop: K.spacing / 2, color: 'red'}}>{notes.criticalPanelAlignment}</FooterText>
    )}
    {page.type !== 'calicoMuralPanel' && shouldIndicateViewStartOnPlan && (
      <View style={{flexDirection: 'row', marginLeft: 0, marginTop: K.spacing / 2}}>
        <View style={{width: 8, height: 8, borderRadius: 4, backgroundColor: 'red', marginTop: K.spacing / 3}}></View>
        <FooterText style={{marginLeft: K.spacing}}>Panels begin at the red dot and continue clockwise around the space.</FooterText>
      </View>
    )}
    {notes.mismatch && (
      <View style={{flexDirection: 'row', marginLeft: 0, marginTop: K.spacing / 2, alignItems: 'center'}}>
        <View style={{width: 30, height: 1, backgroundColor: 'red'}}></View>
        <FooterText style={{marginLeft: K.spacing, color: 'red'}}>{notes.mismatch}</FooterText>
      </View>
    )}
  </>);
}
