import { Text, Label, Tag } from 'react-konva';

import React, { useContext } from 'react';
import _ from 'lodash';
import lib from '~/lib';
import K from '~/k';
import CanvasDataContext from '~/contexts/canvas-data-context';

const getTextSize = ({
  text,
  fontSize = 12.6,
  fontFamily = K.fonts.standard.fontFamily,
  fontWeight = 'normal',
  canvasData
}) => {
  if (!canvasData.layer) return {width: 0, height: 0};

  var context = canvasData.layer.canvas.context._context;

  context.font = `${fontWeight} ${Math.round(fontSize * 100) / 100}px ${fontFamily} `;
  context.letterSpacing = 0;

  const measurement = context.measureText(text);

  return {width: measurement.width, height: (measurement.actualBoundingBoxAscent + measurement.actualBoundingBoxDescent) * 1.2};
};

function CanvasText({...props}) {
  var {x = 0, y = 0, width, height, align, alignVertical, } = props;
  var fontData = {fontSize: (props.fontSize || global.fontSize || 12.6) * props.canvasData.textDimsScale, fontFamily: props.fontFamily || K.fonts.standard.fontFamily};

  if (width) {
    var textSize = {width, height};
  }
  if (!textSize && (align || alignVertical || props.origin || props.backgroundColor)) {
    textSize = getTextSize({...props, ...fontData, fontWeight: props.fontStyle || 'normal'});
  }

  if (props.origin && !align) align = props.origin.x;
  if (props.origin && !alignVertical) alignVertical = props.origin.y;

  var alignmentOffset = {x: 0, y: 0};

  if (align) {
    alignmentOffset.x = -1 * {
      left: 0,
      right: textSize.width,
      center: textSize.width / 2
    }[align || 'left'];
  }

  var { alignVertical } = props;

  if (alignVertical) {
    if (alignVertical === 'center') alignVertical = 'middle';

    alignmentOffset.y = -1 * {
      top: 0,
      bottom: textSize.height,
      middle: textSize.height / 2 //WARNING Konva might use middle, not center, should test more
    }[alignVertical || 'top'];
  }

  if (props.rotation) {
    alignmentOffset = lib.trig.rotate({point: alignmentOffset, byDegrees: props.rotation});
  }

  x += alignmentOffset.x;
  y += alignmentOffset.y;

  if (props.backgroundColor) {
    return (
      <Label {...{x, y}}>
        <Tag fill={props.backgroundColor}/>
        <Text {..._.omit(props, ['backgroundColor', 'x', 'y'])} {...fontData}/>
      </Label>
    );
  }
  else {
    return (
      //WARNING for some reason this letter spacing prop helps - some default value scales in a way we don't like
      <Text {...props} {...{x, y}} {...fontData}/>
    );
  }
}

export default function CanvasTextWithContext(props) {
  let canvasData = useContext(CanvasDataContext);

  return <CanvasText {...props} {...{canvasData}}/>;
}
