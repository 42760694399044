import _ from 'lodash';
import { products } from '@symbolic/lib';
import { sortedProductPropertiesForProductInstance } from '~/helpers/product-order-helper';

var { defaultProductOptionFor } = products;

const referenceCodeFor = ({productInstance, type, activeOrgId}, dependencies) => {
  var {product, productsById, productPropertiesById, productRulesById, productOptionsById, productInstancesById} = dependencies;

  var referenceCode = '';

  var productHasReferenceCodeDataForType = product.referenceCodes[type];

  if (productHasReferenceCodeDataForType) {
    if (type === 'factory' && activeOrgId === 850) referenceCode = 'F-';

    referenceCode = `${referenceCode}${product.referenceCodes[type]}`;

    var productHasReferenceCodeFormulaForType = product.referenceCodeFormulas[type];

    if (productHasReferenceCodeFormulaForType) {
      if (_.get(product, 'associations.productProperties')) {
        var {productPropertySequence, separator} = product.referenceCodeFormulas[type];

        var productPropertiesForProductInstance = sortedProductPropertiesForProductInstance({productInstance}, {productsById, productPropertiesById, productRulesById, productOptionsById, productInstancesById});

        _.forEach(productPropertySequence, productPropertyId => {
          //HINT check if productProperty is valid for productInstance based on rules
          var productPropertyIsValid = _.filter(productPropertiesForProductInstance, {id: productPropertyId}).length > 0;

          if (productPropertyIsValid) {
            var defaultOption = defaultProductOptionFor({productPropertyId}, {productOptionsById});
            var selectedOptionId = _.get(productInstance, `propertiesDataById.${productPropertyId}.selectedProductOption.id`, defaultOption.id);

            var productOption = productOptionsById[selectedOptionId];

            //MNA specific
            //HINT special combined condition for screw type/edge type
            if (type === 'factory' && productPropertyId === 3 && activeOrgId === 850) {
              var slug;

              var selectedEdgeType = () => {
                var defaultOption = defaultProductOptionFor({productPropertyId: 2}, {productOptionsById});
                var selectedOptionId = _.get(productInstance, `properties.${2}.optionId`, defaultOption.id);

                return selectedOptionId;
              };

              if (_.includes([5, 6], selectedOptionId)) { //screw type visible
                if (selectedEdgeType() === 3) slug = 'A'; //edge type beveled
                else if (selectedEdgeType() === 4) slug = 'B'; //edge type straight
              }
              else if (selectedOptionId === 103) { //screw type hidden
                if (selectedEdgeType() === 3) slug = 'C'; //edge type beveled
                else if (selectedEdgeType() === 4) slug = 'D'; //edge type straight
              }

              referenceCode = `${referenceCode}${separator}${slug}`;
            }
            else {
              referenceCode = `${referenceCode}${separator}${_.get(productOption, `referenceCodes[${type}]`, '*')}`;
            }
          }
        });
      }
    }
  }

  return referenceCode;
};

export { referenceCodeFor };
