import {PureComponent} from 'react';
import { View, TouchableOpacity, Image } from 'react-native';
import { withRouter } from 'react-router-native';
import { connect } from '@symbolic/redux';
import { resourceActions, setActiveView} from '~/redux';
import { Button, Popup, LabelledView, AutocompleteInput, Tooltip, Text, styleSpread, TextInput } from '@symbolic/rn-lib';
import { getUserEmployeeOrGuest } from '~/helpers/get-user-employee-or-guest';

import lib from '@symbolic/lib';
import K from '~/k';
import styles from './order-page-share-popup.styles';

import deleteIcon from '~/assets/x-icon.png';
import ownerIcon from '~/assets/owner-icon.png';
import _ from 'lodash';

var s = styleSpread(styles);

function getInvolvedUserIdsFor({productOrder}) {
  return productOrder && _.map(_.keys(productOrder.involvedUsers), idString => parseInt(idString));
}

async function getInvolvedUsersFor({productOrder}) {
  var {data: {involvedUsersById}} = await lib.api.request({uri: '/configurator/product-order/get-involved-users', body: {productOrderOrgSpecificId: productOrder.orgSpecificId, orgId: productOrder.orgId}});

  return _.values(involvedUsersById);
}

function getUserSuggestionsFor({searchTerm, usersById, session, productOrder, filterUserIds, orgUsersOnly = false}) {
  var lowerSearchTerm = _.toLower(searchTerm);
  var involvedUserIds = getInvolvedUserIdsFor({productOrder});
  var orgIds = [productOrder.orgId];

  if (productOrder.orgId === session.user.personalOrgId) {
    orgIds = _.map(session.orgs, 'id');
  }

  var userSuggestions = _.filter(usersById, user => {
    if (searchTerm && (!_.includes(_.toLower(user.name), lowerSearchTerm) && !_.includes(_.toLower(user.email), lowerSearchTerm))) return false; //HINT no search match
    if (!(_.some(orgIds, orgId => (_.get(user, `rolesByOrgId.${orgId}`) && !_.includes(['left', 'disabled'], user.rolesByOrgId[orgId]))) || (!orgUsersOnly && _.includes(involvedUserIds, user.id)))) return false; //HINT not in workspace together or specifically shared
    if (filterUserIds && _.includes(filterUserIds, user.id)) return false; //HINT specifically filtered out

    return true;
  });

  userSuggestions = _.sortBy(userSuggestions, [
    user => user.id === session.user.id ? 0 : 1,
    user => _.includes(involvedUserIds, user.id) ? 0 : 1,
    user => _.get(user, `rolesByOrgId.${productOrder.orgId}`) ? 0 : 1,
    'name'
  ]);

  if (searchTerm && (!userSuggestions.length || lib.validation.emailIsValid(searchTerm))) {
    userSuggestions.unshift({
      id: -1,
      firstName: lib.validation.emailIsValid(searchTerm) ? `"${searchTerm}" (valid email)` : `"${searchTerm}"`,
      lastName: '',
      invalidMessage: lib.validation.emailIsValid(searchTerm) ? '' : 'Please enter a valid collaborator or email'
    });
  }

  return userSuggestions;
}

class ProductOrderSharePopup extends PureComponent {
  state = {
    searchTerm: '',
    hideAutocomplete: true,
    pendingSharees: [],
    involvedUsers: null,
    justNotified: false,
  };

  async componentDidMount() {
    var involvedUsers = await getInvolvedUsersFor({productOrder: this.props.productOrder});

    this.setState({involvedUsers});
  }

  handleGenerateShareableLinkClick = async () => {
    var {data: {shareableLink}} = await lib.api.request({uri: '/configurator/product-order/generate-shareable-link', body: {productOrderId: this.props.productOrder.id}});

    this.props.updateProductOrder({id: this.props.productOrder.id, props: {shareableLink}, hitApi: false});
  };

  handleShare = async () => {
    var {productOrder} = this.props;
    var {pendingSharees, shareNote} = this.state;

    var {data: {involvedUsersById}} = await lib.api.request({uri: '/configurator/product-order/share', body: {sharees: pendingSharees, productOrderOrgSpecificId: productOrder.orgSpecificId, shareNote, orgId: productOrder.orgId}});

    this.setState({justNotified: true});

    setTimeout(() => {
      this.setState({justNotified: false});
    }, 1000);

    var involvedUsers = _.map(involvedUsersById, (user) => user); //TODO has no effect

    this.setState({pendingSharees: []});
    this.setState({involvedUsers}); //TODO remove from state - just get off of productOrder

    await this.props.updateProductOrder({id: productOrder.id, props: {involvedUsers}, hitApi: false});
  };

  handleAddSharee = async ({sharee}) => {
    await lib.api.request({uri: '/configurator/product-order/add-sharee', body: {
      productOrderId: this.props.productOrder.id,
      shareeEmail: sharee.email,
    }});

    this.setState({pendingSharees: [sharee, ...this.state.pendingSharees]});

    this.setState({hideAutocomplete: true, searchTerm: '', pressingAutofill: false});
  };

  handleAssignEmail = async () => {
    var {data: {sharee}} = await lib.api.request({uri: '/configurator/product-order/get-or-create-sharee', body: {productOrderId: this.props.productOrder.id, shareeEmail: this.state.searchTerm}});

    this.props.trackUsers({users: [sharee]});

    await this.handleAddSharee({sharee});
  };

  removeSharee = async ({sharee}) => {
    var {productOrder} = this.props;
    var productOrderOrgSpecificId = this.props.productOrder.orgSpecificId;

    var {data: {productOrder}} = await lib.api.request({uri: '/configurator/product-order/remove-sharee', body: {
      productOrderOrgSpecificId,
      shareeEmail: sharee.email,
      orgId: productOrder.orgId
    }});

    this.props.updateProductOrder({id: productOrder.id, props: productOrder, hitApi: false});

    this.setState({
      involvedUsers: _.filter(this.state.involvedUsers, (user) => user.id !== sharee.id),
      pendingSharees: _.filter(this.state.pendingSharees, (user) => user.id !== sharee.id)
    });
  };

  get autocompleteOptions() {
    return getUserSuggestionsFor({
      ..._.pick(this.props, ['session', 'usersById', 'productOrder']),
      searchTerm: this.state.searchTerm,
      filterUserIds: [
        ..._.map(this.state.pendingSharees, 'id'),
        ..._.map(this.props.involvedUsers, 'id'),
        this.props.productOrder.userId,
        this.props.session.user.id
      ],
      orgUsersOnly: true
    });
  }

  render() {
    var {hideAutocomplete, searchTerm, pendingSharees, involvedUsers} = this.state;
    var {productOrder, onClose, session} = this.props;
    var {activeOrg} = session;
    var {autocompleteOptions} = this;

    var {isEmployee, isGuest, isGuestMode} = getUserEmployeeOrGuest({activeOrg});
    var pricingIsHiddenForGuest = isGuest && _.get(session.user, 'orgAccessEdgeData.designEngine.pricingIsHidden');

    return (
      <Popup
        scrollEnabled
        onClose={onClose}
      >
        <Button
          label={ _.size(pendingSharees) ? `Share with (${_.size(pendingSharees)})` : 'Share'}
          mode={'dark'}
          onPress={this.handleShare}
          style={{height: 40}}
        />
        <View>
          <LabelledView gray label='Invite' styles={{outerView: {zIndex: 1, marginTop: K.spacing * 2}}}>
            <AutocompleteInput
              data={autocompleteOptions}
              placeholder={'NAME OR EMAIL (NO SIGN UP NECESSARY)'}
              inputValue={searchTerm}
              setInputValue={(value) => this.setState({searchTerm: value})}
              autocompleteIsVisible={!hideAutocomplete}
              setAutocompleteIsVisible={(value) => this.setState({hideAutocomplete: !value})}
              onSelect={sharee => sharee.id === -1 ? this.handleAssignEmail() : this.handleAddSharee({sharee})}
            />
          </LabelledView>
          <View style={{zIndex: 0}}>
            {!!pendingSharees.length && (
              <LabelledView label='Pending Collaborators' styles={{outerView: {marginTop: K.spacing}}}>
                {_.map(pendingSharees, (sharee, index) => (
                  <View key={`${sharee.id}-${index}`} style={{
                    ...styles.shareRow,
                    borderRadius: 0,
                    marginBottom: index === pendingSharees.length - 1 ? 0 : 1,
                    backgroundColor: K.colors.gray,
                    ...(index === pendingSharees.length - 1 ? {borderBottomRightRadius: K.borderRadius, borderBottomLeftRadius: K.borderRadius} : {marginBottom: 1}),
                    ...(index === 0 ? {borderTopRightRadius: K.borderRadius, borderTopLeftRadius: K.borderRadius} : {})
                  }}>
                    <Text {...s.shareLeftText}>{sharee.email}</Text>
                    {(isEmployee || isGuest || isGuestMode) && (
                      <TouchableOpacity onPress={() => this.removeSharee({sharee})} style={{position: 'absolute', right: 15}}>
                        <Image source={deleteIcon} {...s.deleteImage}/>
                      </TouchableOpacity>
                    )}
                  </View>
                ))}
              </LabelledView>
            )}
            {!!involvedUsers && !!involvedUsers.length > 0 && (
              <LabelledView label={'Collaborators'} styles={{outerView: {marginTop: K.spacing}}}>
                {_.map(involvedUsers, (sharee, index) => (
                  <View key={`${sharee.id}-${index}`} style={{
                    ...styles.shareRow,
                    ...((index === 0) ? {borderTopRightRadius: K.borderRadius, borderTopLeftRadius: K.borderRadius} : {}),
                    ...(index === involvedUsers.length - 1 ? {borderBottomRightRadius: K.borderRadius, borderBottomLeftRadius: K.borderRadius} : {marginBottom: 1}),
                    borderRadius: 0,
                    backgroundColor: K.colors.gray
                  }}>
                    <Text {...s.shareLeftText}>{sharee.email}</Text>
                    {sharee.data.role === 'owner' && (
                      <View style={{position: 'absolute', right: 15}}>
                        <Tooltip text={'Owner'}>
                          <Image source={ownerIcon} {...s.ownerImage}/>
                        </Tooltip>
                      </View>
                    )}
                    {(isEmployee || productOrder.userId === session.user.id) && (<>
                      {!pricingIsHiddenForGuest && (
                        <TouchableOpacity style={{marginHorizontal: K.spacing, opacity: sharee.data.pricingIsHidden === 1 ? 0.2 : 1, right: 20}} onPress={async () => {
                          var pricingIsHidden = sharee.data.pricingIsHidden ? 0 : 1;

                          var {data: {involvedUsers}} = await lib.api.request({uri: '/configurator/product-order/update-involved-users-data', body: {orgId: this.props.session.activeOrg.id, shareeId: sharee.id, productOrderOrgSpecificId: productOrder.orgSpecificId, pricingIsHidden}});

                          this.props.updateProductOrder({id: productOrder.id, props: {involvedUsers}, hitApi: false});

                          _.map(this.state.involvedUsers, (user) => {
                            user.data = involvedUsers[user.id];

                            return user;
                          });
                        }}>
                          <Text>$</Text>
                        </TouchableOpacity>
                      )}
                      {(isEmployee || isGuest || isGuestMode) && sharee.data.role !== 'owner' && (
                        <TouchableOpacity onPress={() => this.removeSharee({sharee})} style={{position: 'absolute', right: 15}}>
                          <Image source={deleteIcon} {...s.deleteImage}/>
                        </TouchableOpacity>
                      )}
                    </>)}
                  </View>
                ))}
              </LabelledView>
            )}
          </View>
        </View>
        <LabelledView gray label='Shareable Link' styles={{outerView: {marginTop: K.spacing * 2}, innerView: {padding: K.spacing}}}>
          {!productOrder.shareableLink ? (
            <Button
              label={'Generate Shareable Link'}
              mode={'dark'}
              onPress={this.handleGenerateShareableLinkClick}
              style={{marginTop: K.spacing}}
            />
          ) : (
            <><Text style={{marginTop: K.spacing / 2}}>{productOrder.shareableLink}</Text></>
          )}
        </LabelledView>
      </Popup>
    );
  }
}

export default withRouter(connect({
  mapState: (state) => ({
    ..._.pick(state, ['session', 'activeView']),
    usersById: state.resources.users.byId,
  }),
  mapDispatch: {
    setActiveView,
    ..._.pick(resourceActions.productOrders, ['trackProductOrders', 'updateProductOrder', 'destroyProcessOrder']),
    ..._.pick(resourceActions.users, ['trackUsers'])
  }
})(ProductOrderSharePopup));
