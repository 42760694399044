import _ from 'lodash';
import lib, { memo } from '@symbolic/lib';
import dinero from 'dinero.js';
import getEngravingWidthLimitData from '~/helpers/meljac/get-engraving-width-limit';
import getIsBacklitEngravingCompatible from '~/helpers/meljac/get-is-backlit-engraving-compatible';

var { productOptionsForProperty, productOptionForInstanceProperty, sortedProductPropertiesForProductInstance, getProductInstancesWithData, getProductInstanceWithData, getArrayQuantityForArrayProductProperty } = lib.products;

function filterInstancePropertiesFor({productInstance}, filter) {
  return _.filter(_.get(productInstance, 'sortedPropertiesData'), (productPropertyData) => {
    var isValidProperty;
    var filterConditions = [];

    _.forEach(filter, (filterItemValue, key) => {
      filterConditions.push(filterItemValue === _.get(productPropertyData, `productProperty.${key}`));
    });

    isValidProperty = _.every(filterConditions);

    return isValidProperty;
  });
}

var formatAmount = (amount) => `${dinero({amount}).toFormat('$0,0.00')}`;

const productOrderStatuses = [
  {value: 'new', title: 'New', rank: 0},
  {value: 'inProgress', title: 'In Progress', rank: 1},
  {value: 'submittedForApproval', title: 'Submitted', rank: 2},
  {value: 'readyToPay', title: 'Ready To Pay', rank: 3},
  {value: 'inProduction', title: 'In Production', rank: 4},
  {value: 'shipped', title: 'Shipped', rank: 5},
  {value: 'delivered', title: 'Delivered', rank: 6},
  {value: 'received', title: 'Received', rank: 7}
];

function getNameFor({productOrder, orgId, textToTransform: text, pluralize = false}) {
  var name = 'Order';

  text = text || 'Order';

  if (orgId === 850) {
    name = 'Quote';
    //if (_.get(productOrder, 'status') !== productOrderStatuses[4].value) {
  }
  if (orgId === 1798) {
    name = 'Project';
  }

  if (name !== 'Order') {
    text = _.replace(text, new RegExp('order', 'g'), name.toLowerCase());
    text = _.replace(text, new RegExp('Order', 'g'), name);
  }

  return text;
}

function getAdminTitleFor({productProperty}) {
  return productProperty.internalTitle || productProperty.title;
}

var getProductInstanceIsInvalid = memo(({productInstance, productProperties}, {productsById, productPropertiesById, productRulesById, productOptionsById}) => {
  var productProperties = productProperties || sortedProductPropertiesForProductInstance({productInstance}, {productsById, productPropertiesById, productRulesById, productOptionsById});

  return _.some(productProperties, productProperty => getProductPropertyIsInvalid({productInstance, productProperty}, {productsById, productPropertiesById, productRulesById, productOptionsById}));
}, {});

var getProductPropertyIsInvalid = memo(({productInstance, productProperty}, {productsById, productPropertiesById, productRulesById, productOptionsById}) => {
  productInstance = getProductInstanceWithData({productInstance}, {productsById, productPropertiesById, productRulesById, productOptionsById});

  if (productProperty.type === 'singleSelect') {
    var compatibleProductOptions = productOptionsForProperty({productProperty, productInstance}, {productOptionsById, productRulesById, productsById});

    if (productProperty.id === 26) { //HINT special rules for MNA backlit engraving type
      var isBacklitEngravingCompatible = getIsBacklitEngravingCompatible({activeProductInstanceWithData: productInstance});

      if (!isBacklitEngravingCompatible) {
        compatibleProductOptions = _.reject(compatibleProductOptions, {id: 139});
      }
    }

    //HINT if no option was selected by the user, use *default option
    // *default option is the first in compatibleProductOptions, which we're using selectedProductOption to get because it returns the first of the compatible options if the selected option is invalid. But it would be better retrieved by getting compatible product options and picking the first one directly.
    var selectedOptionId = _.get(productInstance, `properties.${productProperty.id}.optionId`) || _.get(productInstance, `propertiesDataById.${productProperty.id}.selectedProductOption.id`);

    return !_.includes(_.map(compatibleProductOptions, 'id'), selectedOptionId);
  }
  else if (productProperty.isArray) {
    if (productInstance.orgId === 850 && _.includes([27, 112], productProperty.id)) { //meljac engraving properties
      var arrayCount = getArrayQuantityForArrayProductProperty({productInstance, productProperty}, {productOptionsById, productRulesById, productsById, productPropertiesById});
      var inputCount = _.get(productProperty.arrayInputProps, 'inputs.length');

      if (productInstance.productId === 1) {
        var ledIndicatorEnabled = _.get(productInstance, 'propertiesDataById.85.selectedProductOption.id') === 519;

        if (ledIndicatorEnabled) {
          inputCount = 1;
        }
      }

      var flatInputsData = _.flatMap(_.times(arrayCount, index => _.times(inputCount, inputIndex => ({index, inputIndex}))));

      return _.some(flatInputsData, ({index, inputIndex}) => {
        var {engravingWidthIsOverLimit} = getEngravingWidthLimitData({
          activeProductInstanceWithData: productInstance, productInstance, productProperty, inputArrayIndex: index, inputIndex
        });

        return engravingWidthIsOverLimit;
      });
    }
  }

  return false;
});

const getIsSpatial = ({productOrder, session, activeProductId}) => {
  return _.includes([1798], session.activeOrg.id) || _.includes([20, 21], productOrder.productCategoryId);
};

export {
  getProductInstanceIsInvalid,
  getProductPropertyIsInvalid,
  productOptionsForProperty,
  productOptionForInstanceProperty,
  sortedProductPropertiesForProductInstance,
  getProductInstancesWithData,
  getProductInstanceWithData,
  getArrayQuantityForArrayProductProperty,
  filterInstancePropertiesFor,
  formatAmount,
  productOrderStatuses,
  getNameFor,
  getAdminTitleFor,
  getIsSpatial
};
