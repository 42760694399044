import { PureComponent } from 'react';
import { View, ScrollView, Image, TouchableOpacity, TouchableWithoutFeedback} from 'react-native';
import { TextInput, Text } from '@symbolic/rn-lib';
import { api } from '@symbolic/lib';
import { connect } from '@symbolic/redux';
import { resourceActions } from '~/redux';

import Medium from '~/components/medium';

import trashIcon from '~/assets/trash-icon.png';
import xIcon from '~/assets/x-icon.png';

import K from '~/k';
import _ from 'lodash';
import { getAdminTitleFor } from '~/helpers/product-order-helper';

class MediumEditor extends PureComponent {
  constructor(props) {
    super(props);

    this.handleSearchInputChange = _.debounce(this.handleSearchInputChange, 100);
    this.handleTitleChange = _.debounce(this.handleTitleChange, 400);
  }

  state = {
    titleInput: '',
    searchInput: '',
    isSearching: false,
  };

  componentDidMount() {
    document.addEventListener('mousedown', this.handleDocumentClick);
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleDocumentClick);
  }

  handleDocumentClick = (event) => {
    if (this.addingAssociationsRef && !this.addingAssociationsRef.contains(event.target)) {
      this.setState({isAddingAssociations: false});
    }
  };

  handleTitleChange = ({props}) => {
    this.props.updateMedium({id: this.props.medium.id, props});
  };

  handleSearchInputChange = ({value}) => {
    this.setState({searchInput: value});
  };

  handleDeletePress = async (id) => {
    if (await confirm('Are you sure?', 'Are you sure you want to delete this product instance?')) {
      this.props.destroyMedium({id: id});
    }
  };

  updateAssociations = async ({id, resourceKey, action, mediumId}) => {
    var medium = await api.get('medium', {where: {id: mediumId}});
    var {associations} = medium;
    var resourceAssociations = _.get(associations, resourceKey, {});

    if (action === 'add') {
      resourceAssociations = _.set(resourceAssociations, id, {});
    }
    else if (action === 'remove') {
      delete resourceAssociations[id];
    }

    associations = _.set(associations, resourceKey, resourceAssociations);
    this.props.updateMedium({id: mediumId, props: {associations}});
  };

  render () {
    var {medium} = this.props;
    var {searchInput} = this.state;

    var associationCandidateGroups = [
      {
        title: 'Products',
        resourceKey: 'products',
        options: _.map(this.props.productsById, product => {
          return {
            resourceKey: 'products',
            id: product.id,
            title: product.title
          };
        })
      },
      {
        title: 'Product Properties',
        resourceKey: 'productProperties',
        options: _.map(this.props.productPropertiesById, productProperty => {
          return {
            resourceKey: 'productProperties',
            id: productProperty.id,
            title: getAdminTitleFor({productProperty}),
          };
        })
      },
      {
        title: 'Product Options',
        resourceKey: 'productOptions',
        options: _.map(this.props.productOptionsById, productOption => {
          return {
            resourceKey: 'productOptions',
            id: productOption.id,
            title: productOption.title
          };
        })
      }
    ];

    if (this.state.searchInput !== '') {
      associationCandidateGroups = _.map(associationCandidateGroups, associationCandidateGroup => {
        return {
          title: associationCandidateGroup.title,
          resourceKey: associationCandidateGroup.resourceKey,
          options: _.filter(associationCandidateGroup.options, option => option.title.toLowerCase().includes(searchInput.toLowerCase()))
        };
      });
    }

    return (
      <View
        key={medium.id}
        dataSet={{'conditional-opacity-parent': 1}}
        style={{margin: K.spacing, width: 250, height: 500, position: 'relative'}}
      >
        <TouchableOpacity
          dataSet={{'conditional-opacity': 1}}
          style={{zIndex: 1, width: 20, height: 20, alignItems: 'center', justifyContent: 'center', position: 'absolute', top: K.spacing, right: K.spacing, backgroundColor: 'white', borderRadius: 100, ...K.shadow}}
          onPress={() => this.handleDeletePress(medium.id)}
        >
          <Image source={trashIcon} style={{...K.defaultIconSize, opacity: 0.7}}/>
        </TouchableOpacity>
        <Medium {...{medium}} style={{width: 250, height: 250, marginBottom: K.spacing}}/>
        <TextInput
          style={{width: '100%'}}
          placeholder={'ENTER CAPTION'}
          onInput={({value}) => this.handleTitleChange({props: {title: value}})}
          value={this.props.medium.title || ''}
        />
        <View style={{position: 'relative'}}>
          <TextInput
            value={this.state.searchInput}
            placeholder={'ADD ASSOCIATIONS'}
            style={{marginTop: K.margin, ...(this.state.isAddingAssociations ? {borderBottomRightRadius: 0, borderBottomLeftRadius: 0} : {})}}
            onInput={({value}) => this.handleSearchInputChange({value})}
            onFocus={() => setTimeout(() => this.setState({isAddingAssociations: true}))}
          />
          {this.state.isAddingAssociations && (
            <ScrollView ref={ref => this.addingAssociationsRef = ref} style={{flexDirection: 'column', width: '100%', maxHeight: 150, ...K.fonts.standard, flexShrink: 1, ...K.shadow, position: 'absolute', zIndex: 1, backgroundColor: 'white', top: '100%', borderBottomRightRadius: K.borderRadius, borderBottomLeftRadius: K}}>
              {_.map(associationCandidateGroups, (associationCandidateGroup, index) => {
                var selectedOptions = medium.associations[`${associationCandidateGroup.resourceKey}`] || {};

                return (
                  <View key={index}>
                    {associationCandidateGroup.options.length > 0 && (
                      <Text style={{ ...K.fonts.standard, fontWeight: 'bold', flexShrink: 1, paddingVertical: K.margin, paddingHorizontal: K.spacing}}>{associationCandidateGroup.title}</Text>
                    )}
                    <View className={associationCandidateGroup.resourceKey}>
                      {_.map(associationCandidateGroup.options, option => {
                        var optionTitle = '';

                        if (option.resourceKey === 'productProperties') {
                          var productProperty = this.props[`${option.resourceKey}ById`][option.id];

                          optionTitle = getAdminTitleFor({productProperty});
                        }
                        else if (option.resourceKey === 'productOptions') {
                          var productOption = this.props[`${option.resourceKey}ById`][option.id];
                          var productProperty = this.props.productPropertiesById[productOption.productPropertyId];

                          optionTitle = `${option.title}${productProperty ? ' + ' + getAdminTitleFor({productProperty}) : ''}`;
                        }
                        else {
                          optionTitle = option.title;
                        }

                        return (
                          <View
                            key={option.id}
                            style={{flexDirection: 'row', paddingVertical: K.margin, paddingHorizontal: K.spacing, ...(selectedOptions[option.id] ? {backgroundColor: K.colors.gray} : {})}}
                          >
                            <TouchableOpacity
                              style={{flex: 1}}
                              onPress={() => this.updateAssociations({id: option.id, resourceKey: option.resourceKey, action: 'add', mediumId: medium.id})}
                            >
                              <Text style={{...K.fonts.standard, opacity: .7, flexShrink: 1}}>{optionTitle}</Text>
                            </TouchableOpacity>
                            {selectedOptions[option.id] && (
                              <TouchableOpacity onPress={() => this.updateAssociations({id: option.id, resourceKey: option.resourceKey, action: 'remove', mediumId: medium.id})}>
                                <Image source={xIcon} style={{width: K.margin * 3, height: K.margin * 3}} />
                              </TouchableOpacity>
                            )}
                          </View>
                        );
                      })}
                    </View>
                  </View>
                );
              })}
            </ScrollView>
          )}
        </View>
        <ScrollView style={{maxHeight: 150, marginTop: K.margin, ...K.fonts.standard}}>
          {_.map(medium.associations, (resourceAssociations, resourceKey) => {
            return (
              <View
                key={resourceKey}
                style={{marginBottom: K.spacing}}
              >
                {_.map(resourceAssociations, (_associationData, associationId) => {
                  var resourceOption = this.props[`${resourceKey}ById`][associationId];

                  return resourceOption && (
                    <View
                      key={associationId}
                      style={{flexDirection: 'row', justifyContent: 'space-between', paddingHorizontal: K.spacing, paddingVertical: K.margin, backgroundColor: K.colors.gray, borderRadius: K.margin, marginBottom: K.margin, alignItems: 'center', minHeight: K.inputHeight}}
                      dataSet={{'conditional-opacity-parent': 1}}
                    >
                      <Text style={{flexShrink: 1}}>{resourceOption.title}</Text>
                      <TouchableOpacity onPress={() => this.updateAssociations({id: associationId, resourceKey: resourceKey, action: 'remove', mediumId: medium.id})}>
                        <Image source={xIcon} style={{...K.defaultIconSize}} dataSet={{'conditional-opacity': 1}} />
                      </TouchableOpacity>
                    </View>
                  );
                })}
              </View>
            );
          })}
        </ScrollView>
      </View>
    );
  }
}

export default connect({
  mapState: (state) => {
    var productPropertiesById = state.resources.productProperties.byId;
    var productOptionsById = state.resources.productOptions.byId;
    var productsById = state.resources.products.byId;

    return {
      productPropertiesById,
      productOptionsById,
      productsById,
    };
  },
  mapDispatch: {
    ..._.pick(resourceActions.media, ['destroyMedium', 'updateMedium'])
  },
})(MediumEditor);
