import React, { useRef, useState } from 'react';

import { View } from 'react-native';
import { connect } from '@symbolic/redux';
import { Group, Image } from 'react-konva';
import { getCenteredCanvasContentOffset } from '~/helpers/canvas/canvas-helpers';
import { getCalicoMuralTextureImageUrl } from '~/helpers/custom/calico/custom-calico-helpers';
import CalicoCanvasPanelLayoutRenderer from '~/components/custom/calico/calico-canvas-panel-layout-renderer';

import _ from 'lodash';
import K from '~/k';
import uuid from 'uuid';
import lib from '~/lib';
import Page from '~/components/page';
import CanvasView from '~/components/canvas/view/canvas-view.js';
import DrawingsArchFooter from '~/pages/drawings/page-types/arch/footer/drawings-arch-footer.js';
import DrawingsPageHeader from '~/pages/drawings/page-header/drawings-page-header';
import DrawingsCalicoFooterNotes from '~/pages/drawings/page-types/custom/calico/drawings-calico-footer-notes';
import CanvasViewSvgRenderer from '~/components/canvas/canvas-view-svg-renderer/canvas-view-svg-renderer';
import CanvasText from '~/components/canvas/text/canvas-text.js';
import CanvasMask from '~/components/canvas/mask/canvas-mask.js';

import useImage from 'use-image';

function DrawingsCalicoMuralPanelPage({
  page, pageNumber, pageSize, pageCount,
  isPrinting, canvasSize, productOrder, ...props
}) {
  const [muralTextureImage] = useImage(getCalicoMuralTextureImageUrl({calicoMuralTexture: page.calicoMuralTexture, productOptionsById: props.productOptionsById}));
  const [forceUpdateKey, setForceUpdateKey] = useState(null);
  const layerRef = useRef();
  const positions = [];

  var {calicoMuralPanelGroups, calicoMuralTextures} = page;

  _.forEach(calicoMuralPanelGroups, calicoMuralPanelGroup => {
    var {calicoMuralPanelsData} = calicoMuralPanelGroup;

    positions.push(..._.flatMap(calicoMuralPanelsData, ({calicoMuralPanel}) => {
      var {x, y} = calicoMuralPanel.data.drawingsPanelLayoutPosition || {x: 0, y: 0}, {width, height} = calicoMuralPanel.data.size;

      return [{x, y: y - (_.some(calicoMuralPanelsData, 'flip') ? 155 : 0)}, {x: x + Math.max(width, 90), y: y + height + 155}];
    }));
  });

  var offset = getCenteredCanvasContentOffset({positions, invert: true, scale: 1});

  return (
    <Page {...{isPrinting, pageSize}} orientation={'landscape'}>
      <DrawingsPageHeader title='Print Panel Layout' subtitle={'The measurements below include bleed; for net measurements, subtract the bleed.'}/>
      <View style={{flex: 1, marginTop: 66}}>
        <CanvasViewSvgRenderer {...{layerRef, canvasSize}} forceCanvasUpdate={() => setForceUpdateKey(uuid())}>
          <CanvasView textDimsScale={0.75} isStatic scale={1} getOffset={() => offset} {...{canvasSize, layerRef, forceUpdateKey}}>
            {/* <Group {...lib.object.sum(offset, {x: canvasSize.width / 2, y: canvasSize.height / 2})}> */}
            <CalicoCanvasPanelLayoutRenderer
              {...{calicoMuralPanelGroups, calicoMuralTextures, muralTextureImage}}
            />
            {/* </Group> */}
          </CanvasView>
        </CanvasViewSvgRenderer>
      </View>
      <DrawingsArchFooter
        {...{page, pageNumber, pageCount, productOrder}}
        notesTitle={'Print Panel Layout Notes'}
        archNotes={<DrawingsCalicoFooterNotes {...{page}}/>}
      />
    </Page>
  );
}

export default connect({
  mapState: (state) => {
    return {productOptionsById: state.resources.productOptions.byId};
  }
})(DrawingsCalicoMuralPanelPage);
