function getShouldRenderHudElement({activeProductOrderStatusType, visibleForProductOrderStatusesTypes, visibleForStockedProductOrderStatusTypes, activeUserRole, visibleForUserRoles, productOrderIsStocked = false}) {
  var isVisibleForActiveProductOrderStatusType = _.includes(visibleForProductOrderStatusesTypes, activeProductOrderStatusType);

  if (productOrderIsStocked) {
    isVisibleForActiveProductOrderStatusType = _.includes(visibleForStockedProductOrderStatusTypes, activeProductOrderStatusType);
  }

  if (isVisibleForActiveProductOrderStatusType) {
    if (_.size(visibleForUserRoles) && activeUserRole) {
      if (_.includes(visibleForUserRoles, activeUserRole)) {
        return true;
      }
      else {
        return false;
      }
    }
    else {
      return true;
    }
  }
  else {
    return false;
  }
}

var getShouldRenderElementForOrg = ({activeOrgId, visibleOrgIds}) => _.includes(visibleOrgIds, activeOrgId);

export {getShouldRenderHudElement, getShouldRenderElementForOrg};
