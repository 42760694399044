import { useContext, useEffect } from 'react';
import { getPlaneForRect, twoDToThreeD, threeDToTwoD, toCanvas } from '~/helpers/canvas/canvas-helpers';
import { toRadians } from '~/helpers/trig/trig-helpers';
import { connect } from '@symbolic/redux';
import { resourceActions } from '~/redux';
import { Rect } from 'react-konva';

import RectTool from '~/components/canvas/tools/rect/rect-tool';
import CanvasDataContext from '~/contexts/canvas-data-context';
import ArchDataContext from '~/contexts/arch-data-context';

function CalicoCanvasMuralPanel({calicoMuralPanel, ...props}) {
  var archData = useContext(ArchDataContext);
  var canvasData = useContext(CanvasDataContext);

  var {position, size, yRotation} = calicoMuralPanel.data;
  var isTwoDPosition = !position.z;

  //HINT: calicoMuralPanel for archCustomView with {x, y} only. so applying z: 0
  if (isTwoDPosition) position = {...position, z: 0};

  var isSelected = archData.selectedEntityId === calicoMuralPanel.id && archData.selectedEntityResourceKey === 'calicoMuralPanel';

  useEffect(() => {
    var handleDestroyEntity = (event) => {
      if (event.code === 'Backspace' && isSelected) {
        props.destroyEntity({id: calicoMuralPanel.id});

        archData.setSelectedEntity(undefined, undefined);
      }
    };

    document.addEventListener('keydown', handleDestroyEntity);

    return () => {
      document.removeEventListener('keydown', handleDestroyEntity);
    };
  });

  var displayPosition = {...position};
  var displaySize = {...size};

  if (props.showBleed) {
    displayPosition.x -= calicoMuralPanel.data.bleedLeft || 1;
    displayPosition.y -= calicoMuralPanel.data.bleedTop || 1;

    displaySize.width += (calicoMuralPanel.data.bleedLeft || 1) + (calicoMuralPanel.data.bleedRight || 1);
    displaySize.height += (calicoMuralPanel.data.bleedTop || 1) + (calicoMuralPanel.data.bleedBottom || 1);
  }

  return (
    <>
      <Rect
        {...toCanvas(displayPosition, canvasData)}
        {..._.mapValues(displaySize, (value) => value * canvasData.scale)}
        fill={'transparent'}
        strokeWidth={1}
        stroke={'#ffb74a'}
        rotation={yRotation}
        onMouseDown={() => archData.setSelectedEntity(calicoMuralPanel.id, 'calicoMuralPanel')}
      />
      <RectTool
        {...{isSelected}}
        rotation={yRotation}
        onSelect={() => archData.setSelectedEntity(calicoMuralPanel.id, 'calicoMuralPanel')}
        onTransform={({position, rotation = 0, size}) => {
          var newPosition;

          newPosition = position;

          props.updateEntity({id: calicoMuralPanel.id, props: {data: {...calicoMuralPanel.data, position: newPosition, ...(size ? {size} : {}), yRotation: rotation}}, hitApi: false});
        }}
        onTransformEnd={() => {
          props.updateEntity({id: calicoMuralPanel.id, props: {data: calicoMuralPanel.data}});
        }}
        position={isTwoDPosition ? toCanvas(position, canvasData) : threeDToTwoD(position, canvasData)}
        {...{size}}
      />
    </>
  );
}

export default connect({
  mapDispatch: {
    ..._.pick(resourceActions.entities, ['updateEntity', 'destroyEntity'])
  }
})(CalicoCanvasMuralPanel);
