import { products } from '@symbolic/lib';
import getTextSize from '~/helpers/get-text-size';

var {getKeypadData, getMechanismLayoutData, getMechanismSpacings} = products.meljac;

export default function getEngravingWidthLimitData({activeProductInstanceWithData: productInstanceWithData, productInstance, productProperty, inputArrayIndex, inputIndex}) {
  var engravingWidthIsOverLimit = false;
  var engravingWidthIsOverSoftLimit = false;
  var productId = productInstanceWithData.productId;

  var {plateSizePropertyId, backBoxType, backBoxId, mechanismLayoutPropertyId} = getKeypadData({productId, productInstanceWithData});
  var {layoutStringArray, totalRows, totalColumns} = getMechanismLayoutData({mechanismLayoutPropertyId, productInstanceWithData});

  var plateSizeData = (_.get(productInstanceWithData, `propertiesDataById.${plateSizePropertyId}` + '.selectedProductOption.data'));
  var screwData = _.get(productInstanceWithData.propertiesDataById, '3');
  var screwIsHidden = _.get(screwData, 'selectedProductOption.id') === 103 ? true : false;

  var {xSpacing} = getMechanismSpacings({width: plateSizeData.width, height: plateSizeData.height, backBoxId, backBoxType, totalRows, totalColumns, productId, mechanismTypeData: _.get(productInstanceWithData, 'propertiesDataById.23.selectedProductOption')});

  if (totalColumns === 1) {
    if (plateSizeData.width >= plateSizeData.height && screwIsHidden === false) xSpacing = 28;
    if (plateSizeData.width >= plateSizeData.Height && screwIsHidden === true) xSpacing = plateSizeData.width - 4;
  }

  var columnIndex = 0;
  var rowIndex = 0;
  var flatIndex = 0;
  var found = false;

  while (!found) {
    if (flatIndex === inputArrayIndex || flatIndex > totalColumns * totalRows) {
      found = true;
    }
    else {
      if (!layoutStringArray[rowIndex][columnIndex + 1]) {
        rowIndex++;
        columnIndex = 0;
      }
      else {
        columnIndex++;
      }

      flatIndex++;
    }
  }

  var engravingTextWidths = {};

  _.forEach([-1, 0, 1], columnIndexOffset => {
    var relativeInputArrayIndex = inputArrayIndex + columnIndexOffset;
    var text = _.get(productInstance.properties, `${productProperty.id}.text[${relativeInputArrayIndex}][${inputIndex}]`, '');

    //TODO [27, 112] in keypad script
    //TODO put script-specific helpers in meljac/product-grahic-scripts/helpers

    if ((columnIndexOffset === 0 || (layoutStringArray[rowIndex] && layoutStringArray[rowIndex][columnIndex + columnIndexOffset])) && text) {
      var engravingFontFamily = _.get(productInstanceWithData, 'propertiesDataById.25.selectedProductOption.data.fontFamily', 'Arial');
      var engravingFontSize = _.get(productInstanceWithData, 'propertiesDataById.38.selectedProductOption.data.fontSize') * 1.4;
      var engravingTextWidth = getTextSize({text, fontSize: engravingFontSize, fontFamily: engravingFontFamily, fontWeight: 'bold'}).width - 2;

      engravingTextWidths[columnIndexOffset] = engravingTextWidth;
    }
  });

  var maxEngravingWidth = _.min([xSpacing * 2, ..._.map(_.reject(engravingTextWidths, (_engraving, columnIndexOffset) => columnIndexOffset === '0'), width => xSpacing - width / 2)]);

  if (engravingTextWidths[0] / 2 > maxEngravingWidth) engravingWidthIsOverLimit = true;
  if (engravingTextWidths[0] / 2 > maxEngravingWidth - 6) engravingWidthIsOverSoftLimit = true;

  return {engravingWidthIsOverLimit, engravingWidthIsOverSoftLimit};
}
