import React from 'react';
import K from '~/k';

import { View } from 'react-native';

class HudElement extends React.PureComponent {
  render() {
    var style = {position: 'absolute', zIndex: 1, ...this.props.style};
    var {x, y} = this.props;

    if (x === 'left') style = {...style, left: 0, marginLeft: K.spacing};
    if (x === 'right') style = {...style, right: 0, marginRight: K.spacing};
    if (x === 'center') style = {...style, left: '50%', transform: 'translate(-50%, 0%)'};

    if (y === 'top') style = {...style, top: 0, paddingTop: K.spacing};
    if (y === 'bottom') style = {...style, bottom: 0, paddingBottom: K.spacing};
    if (y === 'center') style = {...style, top: '50%', transform: 'translate(0, -50%)'};

    return (
      <View {...{style}} >{this.props.children}</View>
    );
  }
}

export default HudElement;
