import { useContext, useEffect } from 'react';
import { toCanvas } from '~/helpers/canvas/canvas-helpers';
import { Line, Rect } from 'react-konva';
import { connect } from '@symbolic/redux';
import { resourceActions } from '~/redux';
import { getCalicoMuralSeamColor } from '~/helpers/custom/calico/custom-calico-helpers';

import lib from '~/lib';
import CanvasDataContext from '~/contexts/canvas-data-context';
import ArchDataContext from '~/contexts/arch-data-context';
import PathTool from '~/components/canvas/tools/path/path-tool';
import _ from 'lodash';

function CalicoCanvasMuralSeam({calicoMuralSeam, ...props}) {
  var canvasData = useContext(CanvasDataContext);
  var archData = useContext(ArchDataContext);

  var alignment = _.get(calicoMuralSeam, 'data.alignment', 'center');
  var thickness = _.get(calicoMuralSeam, 'data.thickness', 1);

  var {from, to, size} = calicoMuralSeam.data;

  var isSelected = archData.selectedEntityId === calicoMuralSeam.id && archData.selectedEntityResourceKey === 'calicoMuralSeam';

  var points = [];

  if (_.size(from)) {
    from = {x: from.x, y: from.y};

    points.push(from);
  }
  if (_.size(to)) {
    to = {x: to.x, y: to.y};

    points.push(to);
  }

  var canvasPoints, rectCanvasPosition;

  if (_.size(from) && _.size(to)) {
    var length = lib.trig.distance({fromPoint: from, toPoint: to});
    var alpha = lib.trig.alpha({p1: from, p2: to});
    var offsetAmount = 0;

    if (calicoMuralSeam.data.alignmentMode === 'custom') {
      offsetAmount = -1 * (calicoMuralSeam.data.customAlignmentOffset || 0);
    }
    else {
      if (alignment === 'right') offsetAmount = thickness / 2;
      else if (alignment === 'left') offsetAmount = thickness / -2;

      offsetAmount -= thickness / 2;
    }

    var alignmentOffset = lib.trig.translate({point: {x: 0, y: 0}, by: offsetAmount, alpha: alpha - Math.PI / 2});

    rectCanvasPosition = toCanvas(lib.object.sum(alignmentOffset, from), canvasData);

    canvasPoints = _.map(points, (point) => toCanvas(point, canvasData));
    canvasPoints = _.flatMap(_.mapValues(canvasPoints, (canvasPoint) => _.values(canvasPoint)));
  }

  var onSelect = () => archData.setSelectedEntity(calicoMuralSeam.id, 'calicoMuralSeam');

  useEffect(() => {
    var handleDestroyEntity = (event) => {
      if (event.code === 'Backspace' && isSelected) {
        props.destroyEntity({id: calicoMuralSeam.id});

        archData.setSelectedEntity(undefined, undefined);
      }
    };

    document.addEventListener('keydown', handleDestroyEntity);

    return () => {
      document.removeEventListener('keydown', handleDestroyEntity);
    };
  });

  return (
    <>
      {rectCanvasPosition && (
        <Rect
          fill={props.color}
          width={thickness * canvasData.scale}
          height={length * canvasData.scale}
          x={rectCanvasPosition.x}
          y={rectCanvasPosition.y}
          rotation={lib.trig.radiansToDegrees(alpha - Math.PI / 2)}
          onMouseDown={onSelect}
        />
      )}
      <Line
        points={canvasPoints}
        stroke={global.isRenderingSvg ? '{"stroke": "black", "stroke-dasharray": "5 3"}' : 'black'}
        strokeWidth={1}
        hitStrokeWidth={2.5}
        dash={[5, 3]}
        onMouseDown={onSelect}
      />
      {(!to || isSelected) && (
        <PathTool
          isSelected={isSelected}
          strokeWidth={size}
          color={'transparent'}
          isInAddMode={!to}
          onSelect={onSelect}
          points={points}
          onTransform={({points, transformEnd}) => {
            var fromDelta, toDelta;

            if (points[0]) {
              fromDelta = lib.object.difference(points[0], from);

              from = lib.object.sum(fromDelta, calicoMuralSeam.data.from);
              from = {x: from.x, y: from.y, z: 0};
            }

            if (points[1]) {
              toDelta = lib.object.difference(points[1], to);

              to = lib.object.sum(toDelta, calicoMuralSeam.data.to);
              to = {x: to.x, y: to.y, z: 0};
            }

            if (!calicoMuralSeam.data.to) {
              archData.updateTentativeCalicoMuralSeam({tentativeCalicoMuralSeam: {...calicoMuralSeam, data: {...calicoMuralSeam.data, from, to}}});
            }
            else {
              props.updateEntity({id: calicoMuralSeam.id, props: {data: {...calicoMuralSeam.data, from, to}}, hitApi: !!transformEnd});
            }
          }}
        />
      )}
    </>
  );
}

export default connect({
  mapState: (state, ownProps) => {
    var {calicoMuralSeam} = ownProps;
    var allCalicoMuralSeamsInOrder = _.filter(state.resources.entities.byFieldKeyIndex.parentId[calicoMuralSeam.parentId], {type: 'calicoMuralSeam'});
    var color = getCalicoMuralSeamColor({thickness: calicoMuralSeam.data.thickness || 1, allCalicoMuralSeamsInOrder});

    return {
      color
    };
  },
  mapDispatch: {
    ..._.pick(resourceActions.entities, ['updateEntity', 'destroyEntity'])
  }
})(CalicoCanvasMuralSeam);
