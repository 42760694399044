import _ from 'lodash';
import CanvasRenderer from '~/components/canvas/renderer/canvas-renderer';

import { getPlanesForCube } from '~/helpers/canvas/canvas-helpers';

export default function CanvasCube({position, dimensions, rotation = {}, ...props}) {
  var planes = getPlanesForCube({position, dimensions, rotation});

  var nodes = _.map(planes, points => ({type: 'plane', points, ...props}));

  return (
    <CanvasRenderer {...{nodes}}/>
  );
}
