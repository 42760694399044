import { PureComponent } from 'react';
import { View } from 'react-native';
import { Popup, LabelledView, DateInput, CurrencyInput, Button, TextInput, Text, PickerInput } from '@symbolic/rn-lib';
import { connect } from '@symbolic/redux';
import { resourceActions } from '~/redux';

import K from '~/k';
import moment from 'moment';

import ProjectInfoForm from '~/components/project-info-form';
import NumberInput from '~/components/number-input';
import { getUserEmployeeOrGuest } from '~/helpers/get-user-employee-or-guest';
import { downloadCsv } from '~/helpers/csv-helper';
import { getProductInstancesWithData } from '~/helpers/product-order-helper';

import getMaderaUnitNumberForProductInstance from '~/helpers/madera/get-madera-unit-number-for-product-instance';

import _, { property } from 'lodash';

class ProductOrderSettingsPopup extends PureComponent {
  state = {};

  handleInputChange = ({propKey, value}) => this.props.updateProductOrder({id: this.props.productOrder.id, props: {[propKey]: value}});

  handleOwnerInputChange = ({value}) => {
    var {productOrder} = this.props;

    var involvedUsers = _.cloneDeep(_.get(productOrder, 'involvedUsers', {}));
    var formerOwnerUserId = _.findKey(involvedUsers, {role: 'owner'});

    involvedUsers = _.set(involvedUsers, formerOwnerUserId, {role: 'sharee', pricingIsHidden: false});
    involvedUsers = _.set(involvedUsers, value, {role: 'owner', pricingIsHidden: false});

    this.props.updateProductOrder({id: productOrder.id, props: {userId: value, involvedUsers}});
  };

  handleDiscountChange = ({value, type}) => {
    var {productOrder} = this.props;

    if (type === 'percentage') {
      if (productOrder.orgId === 850 && productOrder.productCategoryId === 5) {
        value = Math.min(value, 20); //HINT Meljac cap backbox discount at 20%
      }

      this.props.updateProductOrder({id: productOrder.id, props: {discountPercentage: value}});
    }
    else if (type === 'flatAmount') {
      this.props.updateProductOrder({id: productOrder.id, props: {discountFlatAmount: value}});
    }
  };

  handleAmountPaidInCentsChange = ({productOrderId, value}) => {
    this.props.updateProductOrder({id: productOrderId, props: {amountPaidInCents: value}});
  };

  handleCommandDataChange = ({propKey, value}) => {
    var {productOrder} = this.props;

    var commandData = _.get(productOrder, 'commandData', {});

    commandData = _.set(commandData, propKey, value);

    this.props.updateProductOrder({id: productOrder.id, props: {commandData}});
  };

  handleExportCSVButtonPress = () => {
    var {productOrder, orgId, productInstancesById, productPropertiesById, productOptionsById, productsById, productRulesById, archCustomViews} = this.props;

    var productInstancesWithData = getProductInstancesWithData({productInstances: productInstancesById}, {
      productPropertiesById, productOptionsById, productsById, productRulesById, productInstancesById
    });

    productInstancesWithData = _.map(productInstancesWithData, productInstance => {
      var unitNumber = getMaderaUnitNumberForProductInstance({productInstance, productOrderProductInstances: productInstancesWithData});

      return {...productInstance, unitNumber};
    });

    var sortedProductInstancesWithData = _.sortBy(productInstancesWithData, ['archCustomViewId', 'unitNumber']);

    var csv = [];

    //HINT start create headers
    var headers = [];
    var firstProductInstanceSortedPropertiesData = sortedProductInstancesWithData[0].sortedPropertiesData;

    if (orgId === 1053 && productOrder.productId === 104) { //HINT Madera Door Slabs
      headers.push('Viewport Title', 'Unit Number');

      firstProductInstanceSortedPropertiesData = _.filter(firstProductInstanceSortedPropertiesData, propertyData => {
        return !_.includes([161], propertyData.productProperty.id); //HINT remove door # override
      });
    }

    headers.push(..._.flatMap(firstProductInstanceSortedPropertiesData, propertyData => {
      return _.get(propertyData, 'productProperty.title');
    }));

    csv.push(headers);
    //HINT end create headers

    //HINT start create rows
    _.forEach(sortedProductInstancesWithData, productInstance => {
      var dataRow = [];
      var sortedPropertiesData = productInstance.sortedPropertiesData;

      if (orgId === 1053 && productOrder.productId === 104) { //HINT Madera Door Slabs
        dataRow.push(
          _.find(archCustomViews, {id: productInstance.archCustomViewId}).title,
          productInstance.unitNumber
        );

        sortedPropertiesData = _.filter(sortedPropertiesData, propertyData => {
          return !_.includes([161], propertyData.productProperty.id); //HINT remove door # override
        });
      }

      dataRow.push(..._.map(sortedPropertiesData, propertyData => {
        if (_.includes(['text', 'dimensions'], propertyData.productProperty.type)) {
          var productPropertyId = propertyData.productProperty.id;

          if (propertyData.productProperty.type === 'dimensions') {
            var defaultValue = _.get(propertyData, 'productProperty.data.minDimension') || 0;
            var value = _.get(productInstance, `properties.${productPropertyId}.value`, defaultValue);

            if (orgId === 1053) value += 'MM';

            return value;
          }
          else {
            return _.get(productInstance, `properties.${productPropertyId}.value`, '');
          }
        }
        else {
          return _.get(propertyData, 'selectedProductOption.title');
        }
      }));

      csv.push(dataRow);
    });
    //HINT end create rows

    var filename = `${_.kebabCase(productOrder.title)}-export-${moment().format('YYYY-MM-DD')}`;

    downloadCsv({csv, filename});
  };

  formatDate = (value) => {
    if (value) value = moment.utc(value);

    return value ? value.format('YYYY-MM-DD HH:mm:ss') : value;
  };

  toggleArchive = async () => {
    this.props.updateProductOrder({id: this.props.productOrder.id, props: {archived: (this.props.productOrder.archived === 1 ? 0 : 1)}});
  };

  toggleDisableSalesTax = async () => {
    this.props.updateProductOrder({id: this.props.productOrder.id, props: {disableSalesTax: (this.props.productOrder.disableSalesTax === 1 ? 0 : 1)}});
  };

  render() {
    var {productOrder, totalPrice, activeOrg, shippingMode, discountMode, orgId, usersById, guestsById} = this.props;
    var discountMode = productOrder.discountMode;
    var shippingMode = productOrder.shippingMode;
    var {isEmployee, isGuest, isGuestMode} = getUserEmployeeOrGuest({activeOrg});
    var productOrderOwner = _.get(usersById, productOrder.userId) || _.get(guestsById, productOrder.userId);
    var salesTaxIsDisabled = _.get(productOrder, 'disableSalesTax') === 1;

    var userOptions = _.chain({...usersById, ...guestsById})
      .map(({id, firstName, lastName, email}) => {
        return {value: id, title: `${_.startCase(firstName)}${lastName && ` ${_.startCase(lastName)}`} (${email})`};
      })
      .sortBy('title')
      .value();

    return (
      <Popup onClose={() => this.props.onClose()}>
        {productOrderOwner && (
          <LabelledView gray label={'Owner'} styles={{outerView: {marginBottom: K.margin}}}>
            {isEmployee ? (
              <PickerInput
                options= {userOptions}
                value={productOrderOwner.id}
                onChange={({value}) => this.handleOwnerInputChange({value})}
                style={{flex: 1, width: '100%'}}
                buttonStyle={{backgroundColor: 'transparent'}}
              />
            ) : (
              <Text style={{paddingHorizontal: K.spacing, paddingVertical: K.spacing / 2, opacity: 0.5}}>
                {productOrderOwner && `${productOrderOwner.firstName} ${productOrderOwner.lastName}`}
              </Text>
            )}
          </LabelledView>
        )}
        <ProjectInfoForm {...{productOrder, totalPrice}}/>
        {(activeOrg.role === 'owner' || activeOrg.role === 'member') && orgId === 850 && (
          <>
            <LabelledView gray label={'Effective pricing date'} styles={{outerView: {marginBottom: K.margin}}}>
              <DateInput
                placeholder={'MM/DD'}
                onChange={({value}) => this.handleInputChange({propKey: 'effectivePricingDate', value: this.formatDate(value)})}
                value={productOrder['effectivePricingDate'] && moment(moment.utc(productOrder['effectivePricingDate']).local().format('YYYY-MM-DD'))}
              />
            </LabelledView>
            <LabelledView gray label={'Shipping'} styles={{outerView: {marginBottom: K.margin}}}>
              <View style={{flexDirection: 'row', justifyContent: 'space-between'}}>
                {shippingMode === 'rate' ?
                  <NumberInput
                    style={{backgroundColor: 'transparent'}}
                    onChange={({value}) => this.handleInputChange({propKey: 'shippingPercentage', value})}
                    emptyStringIsNull
                    value={_.get(productOrder, 'shippingPercentage')}
                  /> :
                  <CurrencyInput
                    style={{backgroundColor: 'transparent', textAlign: 'left'}}
                    onChange={({value}) => this.handleInputChange({propKey: 'shippingFlatAmount', value})}
                    emptyStringIsNull
                    value={_.get(productOrder, 'shippingFlatAmount')}
                  />
                }
                <View style={{flexDirection: 'row', alignItems: 'center'}}>
                  <Button
                    label={'$'}
                    style={{backgroundColor: 'transparent', ...K.defaultIconSize, opacity: shippingMode === 'amount' ? 1 : 0.3}}
                    onPress={() => this.handleInputChange({propKey: 'shippingMode', value: 'amount'})}
                  />
                  <Button
                    label={'%'}
                    style={{backgroundColor: 'transparent', ...K.defaultIconSize, opacity: shippingMode === 'rate' ? 1 : 0.3}}
                    onPress={() => this.handleInputChange({propKey: 'shippingMode', value: 'rate'})}
                  />
                </View>
              </View>
            </LabelledView>
            <LabelledView gray label={'Tax Rate'} styles={{outerView: {marginBottom: K.margin}}}>
              <View style={{flexDirection: 'row', justifyContent: 'space-between'}}>
                <NumberInput
                  disabled={salesTaxIsDisabled}
                  style={{backgroundColor: 'transparent', ...(salesTaxIsDisabled && {textDecoration: 'line-through'})}}
                  onChange={({value}) => this.handleInputChange({propKey: 'taxPercentage', value})}
                  value={_.get(productOrder, 'taxPercentage', 0)}
                />
                <View style={{flexDirection: 'row', alignItems: 'center'}}>
                  <Button
                    label={salesTaxIsDisabled ? 'enable' : 'disable'}
                    style={{backgroundColor: 'transparent'}}
                    onPress={() => this.toggleDisableSalesTax()}
                  />
                </View>
              </View>
            </LabelledView>
            <LabelledView gray label={'Discount'} styles={{outerView: {marginBottom: K.margin}}}>
              <View style={{flexDirection: 'row', justifyContent: 'space-between'}}>
                {discountMode === 'rate' ?
                  <NumberInput
                    style={{backgroundColor: 'transparent'}}
                    onChange={({value}) => this.handleDiscountChange({value, type: 'percentage'})}
                    value={_.get(productOrder, 'discountPercentage', 0)}
                  /> :
                  <CurrencyInput
                    style={{backgroundColor: 'transparent', textAlign: 'left'}}
                    onChange={({value}) => this.handleDiscountChange({value, type: 'flatAmount'})}
                    value={_.get(productOrder, 'discountFlatAmount', 0)}
                  />
                }
                <View style={{flexDirection: 'row', alignItems: 'center'}}>
                  <Button
                    label={'$'}
                    style={{backgroundColor: 'transparent', ...K.defaultIconSize, opacity: discountMode === 'amount' ? 1 : 0.3}}
                    onPress={() => this.handleInputChange({propKey: 'discountMode', value: 'amount'})}
                  />
                  <Button
                    label={'%'}
                    style={{backgroundColor: 'transparent', ...K.defaultIconSize, opacity: discountMode === 'rate' ? 1 : 0.3}}
                    onPress={() => this.handleInputChange({propKey: 'discountMode', value: 'rate'})}
                  />
                </View>
              </View>
            </LabelledView>
            <CurrencyInput
              grayLabelledView
              style={{textAlign: 'left'}}
              label={'AMOUNT PAID'}
              labelledViewStyles={{outerView: {marginBottom: K.margin}}}
              onChange={({value}) => this.handleAmountPaidInCentsChange({productOrderId: productOrder.id, orgId, value})}
              value={_.get(productOrder, 'amountPaidInCents', '')}
            />
            <LabelledView gray label={'Primary Note'} styles={{outerView: {marginBottom: K.margin}}}>
              <TextInput
                multiline
                blurOnSubmit
                standardAutoheightStyles
                onChange={({value}) => this.handleInputChange({propKey: 'primaryNote', value})}
                value={_.defaultTo(_.get(productOrder, 'primaryNote', ''), '')}
                labelledViewStyles={{outerView: {marginBottom: K.margin}}}
              />
            </LabelledView>
            <LabelledView gray label={'Secondary note'} styles={{outerView: {marginBottom: K.margin}}}>
              <TextInput
                multiline
                blurOnSubmit
                standardAutoheightStyles
                onChange={({value}) => this.handleInputChange({propKey: 'secondaryNote', value})}
                value={_.defaultTo(_.get(productOrder, 'secondaryNote', ''), '')}
                labelledViewStyles={{outerView: {marginBottom: K.margin}}}
              />
            </LabelledView>
            <TextInput
              grayLabelledView
              label={'Command Number'}
              labelledViewStyles={{outerView: {marginBottom: K.margin}}}
              onChange={({value}) => this.handleCommandDataChange({propKey: 'number', value})}
              value={_.get(productOrder, 'commandData.number', '')}
            />
            <LabelledView gray label={'Command Date'} styles={{outerView: {marginBottom: K.margin}}}>
              <DateInput
                placeholder={'MM/DD'}
                onChange={({value}) => this.handleCommandDataChange({propKey: 'date', value: this.formatDate(value)})}
                value={_.get(productOrder, 'commandData.date') && moment.utc(_.get(productOrder, 'commandData.date'))}
              />
            </LabelledView>
            <CurrencyInput
              grayLabelledView
              style={{textAlign: 'left'}}
              label={'Command Total (€)'}
              labelledViewStyles={{outerView: {marginBottom: K.margin}}}
              onChange={({value}) => this.handleCommandDataChange({propKey: 'totalInCents', value})}
              value={_.get(productOrder, 'commandData.totalInCents')}
            />
            {/* <Button
              label='Requires Changes'
              onPress={this.duplicateProductOrder}
              style={{marginTop: K.spacing}}
            /> */}
            {/* <Button
              label='Delete'
              onPress={this.deleteProductOrder}
              style={{backgroundColor: K.colors.deleteRed, marginTop: K.spacing}}
            /> */}
          </>
        )}
        {(orgId === 1053 && _.includes([104, 105], productOrder.productId)) && (
          <Button
            label={'Export CSV'}
            onPress={() => this.handleExportCSVButtonPress()}
            style={{marginTop: K.spacing}}
          />
        )}
        {(isEmployee || isGuest || isGuestMode) && (
          <>
            <Button
              label={'Duplicate'}
              onPress={this.props.duplicateProductOrder}
              style={{marginTop: K.spacing}}
            />
            <Button
              label={productOrder.archived === 0 ? 'ARCHIVE' : 'UNARCHIVE'}
              dark
              onPress={this.toggleArchive}
              style={{marginTop: K.spacing}}
            />
          </>
        )}
      </Popup>
    );
  }
}

export default connect({
  mapState: (state, ownProps) => {
    var productOrderId = ownProps.productOrder.id;
    var productInstancesById = _.get(state.resources.productInstances, `byFieldKeyIndex.productOrderId.${productOrderId}`);
    var productOptionsById = state.resources.productOptions.byId;
    var productRulesById = state.resources.productRules.byId;
    var productPropertiesById = state.resources.productProperties.byId;
    var productsById = state.resources.products.byId;

    if (productOrderId && state.resources.archCustomViews.byFieldKeyIndex) {
      var archCustomViews = state.resources.archCustomViews.byFieldKeyIndex.productOrderId[productOrderId];
    }

    return {
      productInstancesById,
      productPropertiesById,
      productOptionsById,
      productsById,
      productRulesById,
      archCustomViews,
      usersById: state.resources.users.byId,
      guestsById: state.resources.guests.byId
    };
  },
  mapDispatch: {
    ..._.pick(resourceActions.productOrders, ['updateProductOrder']),
  }
})(ProductOrderSettingsPopup);
