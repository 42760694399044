import { View } from 'react-native';
import { Group } from 'react-konva';
import { getCenteredCanvasContentOffset } from '~/helpers/canvas/canvas-helpers';
import { getCalicoMuralTextureOption, getMuralMaskingPolygons } from '~/helpers/custom/calico/custom-calico-helpers';

import { useRef, useState } from 'react';

import _ from 'lodash';
import uuid from 'uuid';
import lib from '~/lib';
import CanvasText from '~/components/canvas/text/canvas-text.js';
import Page from '~/components/page';
import ArchDataContext from '~/contexts/arch-data-context';
import CanvasView from '~/components/canvas/view/canvas-view.js';
import DrawingsArchFooter from '~/pages/drawings/page-types/arch/footer/drawings-arch-footer.js';
import DrawingsCalicoFooterNotes from '~/pages/drawings/page-types/custom/calico/drawings-calico-footer-notes';
import DrawingsPageHeader from '~/pages/drawings/page-header/drawings-page-header';
import CalicoCanvasMuralTexture from '~/components/custom/calico/calico-canvas-mural-texture';
import CalicoCanvasMismatchLine from '~/components/custom/calico/calico-canvas-mismatch-line';
import CalicoWallLabel from '~/components/custom/calico/calico-wall-label';
import CalicoCanvasMuralSeam from '~/components/custom/calico/calico-canvas-mural-seam';
import CanvasViewSvgRenderer from '~/components/canvas/canvas-view-svg-renderer/canvas-view-svg-renderer';

export default function DrawingsCustomCalicoMuralSeamPage({page, pageNumber, pageSize, pageCount, isPrinting, canvasSize, productOrder, productOptionsById}) {
  var {calicoMuralTextures, calicoMuralTexture, calicoMuralSeams, calicoMuralPanels} = page;
  var [forceUpdateKey, setForceUpdateKey] = useState(null);
  var layerRef = useRef();

  var calicoMuralTextureOption = getCalicoMuralTextureOption({calicoMuralTexture: page.calicoMuralTexture, productOptionsById});

  var muralMaskingPolygons = getMuralMaskingPolygons({calicoMuralPanels});
  var offset = getCenteredCanvasContentOffset({positions: _.flatten(muralMaskingPolygons), scale: page.scale});
  var invertedOffset = getCenteredCanvasContentOffset({positions: _.flatten(muralMaskingPolygons), invert: true, scale: page.scale});

  calicoMuralPanels = _.sortBy(calicoMuralPanels, ['data.position.y', 'data.position.x']);

  return (
    <Page {...{isPrinting, pageSize}} orientation={'landscape'}>
      <DrawingsPageHeader title='Installer Layout' subtitle={'The panels overlap on the wall to be double-cut.'}/>
      <View style={{flex: 1, marginTop: 66}}>
        <CanvasViewSvgRenderer {...{layerRef, canvasSize}} forceCanvasUpdate={() => setForceUpdateKey(uuid())}>
          <CanvasView textDimsScale={0.75} isStatic scale={page.scale} getOffset={() => invertedOffset} {...{canvasSize, layerRef, forceUpdateKey}}>
            <ArchDataContext.Provider value={{activeView: {viewKey: 'custom'}}}>
              {_.map(calicoMuralTextures, calicoMuralTexture => (
                <CalicoCanvasMuralTexture key={calicoMuralTexture.id} {...{calicoMuralTexture, muralMaskingPolygons}}/>
              ))}
              {_.map(calicoMuralSeams, calicoMuralSeam => (
                <CalicoCanvasMuralSeam key={calicoMuralSeam.id} {...{calicoMuralSeam}}/>
              ))}
              {_.map(page.calicoWallLabels, calicoWallLabel => (
                <CalicoWallLabel isStatic key={calicoWallLabel.id} {...{calicoWallLabel}}/>
              ))}
              {_.map(page.calicoMismatchLines, calicoMismatchLine => (
                <CalicoCanvasMismatchLine isStatic key={calicoMismatchLine.id} {...{calicoMismatchLine}}/>
              ))}
            </ArchDataContext.Provider>
            <Group {...lib.object.sum(offset, {x: canvasSize.width / 2, y: canvasSize.height / 2})}>
              {_.map(calicoMuralPanels, (calicoMuralPanel, index) => {
                return (
                  <CanvasText
                    key={calicoMuralPanel.id}
                    fontSize={14}
                    text={`${calicoMuralPanel.data.panelNumber || (index + 1)}`}
                    fontWeight='bold'
                    align='center'
                    alignVertical='middle'
                    width={50}
                    height={11}
                    x={(calicoMuralPanel.data.position.x + calicoMuralPanel.data.size.width / 2) * page.scale}
                    y={(calicoMuralPanel.data.position.y + calicoMuralPanel.data.size.height / 2) * page.scale}
                    fontFamily='HelveticaNeue-Bold'
                    fill={calicoMuralTextureOption.data.isDark ? 'white' : 'black'}
                  />
                );
              })}
            </Group>
          </CanvasView>
        </CanvasViewSvgRenderer>
      </View>
      <DrawingsArchFooter
        {...{page, pageNumber, pageCount, productOrder}}
        notesTitle={'Installer Layout Notes'}
        archNotes={<DrawingsCalicoFooterNotes {...{page}}/>}
      />
    </Page>
  );
}
