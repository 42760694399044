import React from 'react';
import K from '~/k';

import { View } from 'react-native';
import { Text } from '@symbolic/rn-lib';

class AdminSection extends React.PureComponent {
  render() {
    var style = {
      zIndex: 1,
      outerView: {
        paddingBottom: K.spacing,
        paddingTop: K.spacing,
        marginBottom: K.spacing * 2
      },
      innerView: {
        flexWrap: 'wrap'
      },
      title: {
        ...K.fonts.pageHeader,
        marginLeft: K.spacing
      },
      horizontalGrayLine: {
        height: K.calc(1),
        marginTop: K.calc(5),
        marginBottom: K.calc(12),
        backgroundColor: K.colors.doubleGray,
        width: '100%'
      }
    };

    return (
      <View style={{...style.outerView}}>
        <View>
          <Text style={{...style.title}}>{this.props.title}</Text>
          <View style={{...style.horizontalGrayLine}}/>
        </View>
        <View style={{...style.innerView, ...this.props.contentStyle}}>
          {this.props.children}
        </View>
      </View>
    );
  }
}

export default AdminSection;
